<h1 mat-dialog-title>EVENT HANDLER</h1>
<div mat-dialog-content>
  <div>
    <h2 style="margin: 0;">
      <mat-label *ngIf="user.firstName">{{user.firstName}} </mat-label>
      <mat-label *ngIf="user.firstName">{{user.lastName}}</mat-label>
    </h2>
    <div style="margin: 8px 0 8px" (click)="onCandidateClick()">
      <mat-label *ngIf="candidate.firstName" style="cursor: pointer;">{{candidate.firstName}} </mat-label>
      <mat-label *ngIf="candidate.lastName" style="cursor: pointer;"> {{candidate.lastName}}</mat-label>
    </div>
  </div>
  <div (click)="onJobOrderClick()" style="margin:  0 0 -10px">
    <mat-label *ngIf="jobOrder.title" style="cursor: pointer;"> {{jobOrder.title}}</mat-label>
  </div>
  <br>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Type</mat-label>
    <mat-select [(ngModel)]="calendarEvent.type">
      <mat-option value="100">Call</mat-option>
      <mat-option value="200">Email</mat-option>
      <mat-option value="300">Meeting</mat-option>
      <mat-option value="400">Other</mat-option>
      <mat-option value="450">Linkedin</mat-option>
      <mat-option value="500">Call (Talked)</mat-option>
      <mat-option value="600">Call (LVM)</mat-option>
      <mat-option value="650">Skype</mat-option>
      <mat-option value="700">Call (Missed)</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Title</mat-label>
    <input matInput [(ngModel)]="calendarEvent.title">
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Description</mat-label>
    <textarea matInput [(ngModel)]="calendarEvent.description"></textarea>
  </mat-form-field>
  <br>
  <mat-form-field style="width: 100%" appearance="outline">
    <mat-label>Choose a date</mat-label>
    <input matInput [matDatepicker]="picker" [(ngModel)]="dateForDatePicker">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <br>
  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>Length</mat-label>
    <mat-select [(ngModel)]="calendarEvent.duration">
      <mat-option value="15">15 minutes</mat-option>
      <mat-option value="30">30 minutes</mat-option>
      <mat-option value="45">45 minutes</mat-option>
      <mat-option value="60">1 hour</mat-option>
      <mat-option value="90">1.5 hours</mat-option>
      <mat-option value="120">2 hours</mat-option>
      <mat-option value="180">3 hours</mat-option>
      <mat-option value="240">4 hours</mat-option>
      <mat-option value="300">More than 4 hours</mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <mat-label>Start</mat-label>
  <br>
  <div [hidden]="isAllDayEvent" style="display: flex; justify-content: space-between;">
    <mat-form-field appearance="outline" style="width: 40%;">
      <mat-select [(ngModel)]="hour">
        <mat-option value="1">1</mat-option>
        <mat-option value="2">2</mat-option>
        <mat-option value="3">3</mat-option>
        <mat-option value="4">4</mat-option>
        <mat-option value="5">5</mat-option>
        <mat-option value="6">6</mat-option>
        <mat-option value="7">7</mat-option>
        <mat-option value="8">8</mat-option>
        <mat-option value="9">9</mat-option>
        <mat-option value="10">10</mat-option>
        <mat-option value="11">11</mat-option>
        <mat-option value="12">12</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 40%;">
      <mat-select [(ngModel)]="minute">
        <mat-option value="00">00</mat-option>
        <mat-option value="15">15</mat-option>
        <mat-option value="30">30</mat-option>
        <mat-option value="45">45</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width: 18%">
      <mat-select [(ngModel)]="meridiem">
        <mat-option value="AM">AM</mat-option>
        <mat-option value="PM">PM</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <mat-checkbox [(ngModel)]="calendarEvent.allDay" (change)="switchAllDay()">ALL DAY</mat-checkbox>
</div>
<div mat-dialog-actions>
  <button (click)="onCancel()" mat-button>CANCEL</button>
  <button (click)="onCreate()" *ngIf="!data.isEditMode" mat-button cdkFocusInitial>CREATE</button>
  <button (click)="onEdit()" *ngIf="data.isEditMode" mat-button cdkFocusInitial>EDIT</button>
  <button (click)="onDelete()" *ngIf="data.isEditMode" mat-button cdkFocusInitial>DELETE</button>
</div>