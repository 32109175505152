import { OnInit, Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { CandidateDetail, ActivityLogEntry } from '../../app-services/candidate-detail.type';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {DomSanitizer} from "@angular/platform-browser";
import {animate, state, style, transition, trigger} from "@angular/animations";
import { ActivityType } from '../../app-services/core.service'
import { ContactItem } from 'core/dist/types/contact-item-type';

@Component({
  selector: 'app-search-item-activity-logs-table',
  templateUrl: './search-item-activity-logs-table.component.html',
  styleUrls: ['./search-item-activity-logs-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class CandidateDetailActivityLogComponent implements OnInit {

  private itemsContact: ContactItem[];
  
  constructor(
    private sanitizer: DomSanitizer
  ) {}

  @Output() onViewContactEvent = new EventEmitter<ContactItem>();

  @Input() public set data(data: ActivityType[]) {
    this.applyDataSource(data);
  }

  @Input() private set contactItems(data: ContactItem[]) {
    this.itemsContact = data
  }

  @Input() isLoading: boolean;

  displayedColumns: string[] = ['date', 'type', 'author', 'regarding', 'notes'];
  dataSource: MatTableDataSource<ActivityType>;
  private paginator: MatPaginator;
  private sort: MatSort;
  private sortState: Sort = {active: 'dateCreated', direction: 'desc'}


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: ActivityType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(data.length) {
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit(this.sortState);
    }
  }

  onViewContact(contactId: string) {
    if(!contactId) { return }
    this.itemsContact.find( (val:ContactItem) => {
      if(val.contactId === contactId) {
        this.onViewContactEvent.emit(val);
      }
    })
  }

  ngOnInit(): void {}

  setStyles(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}
