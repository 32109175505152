"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebContactItemsController = void 0;
class WebContactItemsController {
    constructor(api, contactItemsService) {
        this.api = api;
        this.contactItemsService = contactItemsService;
    }
    list(from, to, successCallback, errorCallback) {
        this.api.postRequest('contactItem/list', { from: from, to: to }, response => {
            if (response.data.valid === true)
                successCallback({ response: this.contactItemsService.mapContactItems(response.data.data), totalContactItems: response.data.total_contact_items });
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('contactItem/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.contactItemsService.mapContactItem(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('contactItem/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    edit(contactItem, successCallback, errorCallback) {
        const src = this.contactItemsService.mapReverseContactItem(contactItem);
        this.api.postRequest('contactItem/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    create(contactItem, successCallback, errorCallback) {
        const src = this.contactItemsService.mapReverseContactItem(contactItem);
        this.api.postRequest('contactItem/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    quickSearch(search, successCallback, errorCallback) {
        this.api.postRequest('contactItem/search', {
            search: search
        }, response => {
            if (response.data.valid === true)
                successCallback(this.contactItemsService.mapContactItems(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    find(contactItem, successCallback, errorCallback) { }
    sort(contactItems, contactItemField, desc, successCallback, errorCallback) { }
}
exports.WebContactItemsController = WebContactItemsController;
