import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-experience-detail-tab',
  templateUrl: './experience-detail-tab.component.html',
  styleUrls: ['./experience-detail-tab.component.scss']
})
export class ExperienceDetailTabComponent implements OnInit {

  experience = [
    {id: 1, dateFrom: '2020-09-10', dateTo: '2020-09-23', companyName: 'Company 1', position: 'Junior Front-End', notes: 'Notes from junior'},
    {id: 2, dateFrom: '2020-07-13', dateTo: '2020-09-10', companyName: 'Company 2', position: 'Middle Front-End', notes: 'Notes from middle'},
    {id: 3, dateFrom: '2020-07-13', dateTo: '2020-09-10', companyName: 'Company 3', position: 'Senior Front-End', notes: 'Notes from Senior'},
  ]
  minDate: Date;
  maxDate: Date = new Date();
  addExperienceMode = false;

  tillNowFlag: boolean = false;

  editExpirienceField = 0;
  experienceForm:FormGroup = new FormGroup({
    dateFrom: new FormControl({value: '', disabled: true}, [Validators.required]),
    dateTo: new FormControl({value: '', disabled: true}, [Validators.required]),
    position: new FormControl('', [Validators.required]),
    company: new FormControl('', [Validators.required]),
    notes: new FormControl('', [Validators.required]),
  })

  constructor() { }

  ngOnInit(): void {
  }

  initForm(form) {
    if(this.addExperienceMode) { 
      this.addExperienceMode = false;
    }
    this.experienceForm = new FormGroup({
      dateFrom: new FormControl({value: form.dateFrom, disabled: true}, [Validators.required]),
      dateTo: new FormControl({value: form.dateTo, disabled: true}, [Validators.required]),
      position: new FormControl(form.companyName, [Validators.required]),
      company: new FormControl(form.position, [Validators.required]),
      notes: new FormControl(form.notes, [Validators.required]),
    })
  }

  setMinDate(date: string) {
    this.minDate = new Date(date)
  }

  setMaxDate(date: string) {
    this.maxDate = new Date(date)
  }

  tillNow(flag: boolean) {
    if(flag) {
      this.experienceForm.get('dateTo').setValue(new Date())
      this.tillNowFlag = true;
      this.maxDate = new Date();
    } else {
      this.tillNowFlag = false;
    }
  }

  saveEdit(id: number) {
    let experience = this.experience.find( val => val.id === id);
    this.experienceForm.enable()
    const formExperience = this.experienceForm.value
    experience.companyName = formExperience.company
    experience.dateFrom = formExperience.dateFrom
    experience.dateTo = formExperience.dateTo
    experience.position = formExperience.position
    experience.notes = formExperience.notes;
    this.editExpirienceField = 0;
    this.experienceForm.reset();
    this.initForm({})
    this.tillNowFlag = false
  }

  closeEdit() {
    this.editExpirienceField = 0;
    this.experienceForm.reset();
    this.initForm({})
    this.tillNowFlag = false
  }

  showAddExperienceForm() {
    this.editExpirienceField = 0;
    this.experienceForm.reset();
    this.initForm({})
    this.addExperienceMode = true;
  }

  addNewExperience() {
    this.experienceForm.enable()
    const formExperience = this.experienceForm.value
    const experience = {
      id: this.experience.length +1,
      dateFrom: formExperience.dateFrom, 
      dateTo: formExperience.dateFrom, 
      companyName: formExperience.company, 
      position: formExperience.position,
      notes: formExperience.notes
    }
    this.experience.unshift(experience);
    this.experienceForm.reset();
    this.initForm({})
    this.addExperienceMode = false;
    this.tillNowFlag = false;
  }

  deleteExperience(id: number) {
    this.experience = this.experience.filter( val => val.id !== id)
  }

}
