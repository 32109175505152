import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType, YhCore } from '../app-services/core.service';

@Component({
  selector: 'app-registration-page',
  templateUrl: './registration-page.component.html',
  styleUrls: ['./registration-page.component.scss']
})
export class RegistrationPageComponent implements OnInit {

  public signUpForm: FormGroup;
  public hide = true;
  public hideEmail = true;

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe( param => {
      const cryptedStr = param.params;
      if(cryptedStr) {
        const data = JSON.parse(cryptedStr.split(/\,/).map(val => String.fromCharCode(val)).join(''));
        this.initForm(data)
      } else {
        this.initForm({})
      }
    })
  }

  initForm(data) {
    this.signUpForm = new FormGroup({
      firstName: new FormControl(data.firstName || '', [Validators.required]),
      lastName: new FormControl(data.lastName || '', [Validators.required]),
      login: new FormControl(data.login || '', [Validators.required]),
      password: new FormControl(data.password || '', [Validators.required]),
      email: new FormControl(data.email || '', [Validators.required, Validators.email]),
      emailPassword: new FormControl(data.emailPassword || '', [Validators.required]),
    })
  }

  get f() {
    return this.signUpForm.controls;
  }

  signUp(user: UserType):Promise<boolean> {
    return new Promise( (resolve, reject) => {
      YhCore.authentication.signUp(user, res => {
        resolve(res);
      }, err => this.errorHandling(err))
    })
  }

  async onSubmit() {
    const value:UserType = this.signUpForm.value
    const result = await this.signUp(value);

    if(result) {
      this.signUpForm.reset();
      this.snackBar.open('User was successfully created', 'OK', {duration: 3000});
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 3000);
    }
  }

  goToLogin() {
    this.router.navigateByUrl('/login');
  }

  errorHandling(err) {
    this.snackBar.open(err, 'ERROR', {duration: 5000})
  }
}
