
<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="upcaming-events">
        <div class="upcaming-events__top">
          <div class="upcaming-events__column">
            <button mat-mini-fab color="primary"  mwlCalendarPreviousView [view]="view" style="margin-right: 2.5px;"
                    [(viewDate)]="viewDate" aria-label="Example icon button with a menu icon" (viewDateChange)="closeOpenMonthViewDay()">
              <mat-icon>arrow_left</mat-icon>
            </button>
            <button mat-mini-fab color="primary" mwlCalendarNextView [view]="view" style="margin: 0px 2.5px"
                    [(viewDate)]="viewDate" aria-label="Example icon button with a menu icon" (viewDateChange)="closeOpenMonthViewDay()">
              <mat-icon>arrow_right</mat-icon>
            </button>
            <button mat-flat-button color="primary" mwlCalendarToday [(viewDate)]="viewDate" style="margin: 0px 2.5px"> 
              TODAY
            </button>
          </div>
          <div class="upcaming-events__column" #date>{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</div>
          <div class="upcaming-events__column">
            <mat-button-toggle-group appearance="legacy" value="week">
              <mat-button-toggle value="week" (click)="setView(CalendarView.Week); closeOpenMonthViewDay()"
                [class.active]="view === CalendarView.Week">
                WEEK
              </mat-button-toggle>
              <mat-button-toggle value="day" (click)="setView(CalendarView.Day); closeOpenMonthViewDay()" [class.active]="view === CalendarView.Day">
                DAY
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
        <div class="upcaming-events__lists lists-events">
          <div class="lists-events__body" *ngIf="eventCalendars.length; else noData">
            <div class="lists-events__item item-list-event" *ngFor="let item of eventCalendars">
              <div class="item-list-event__top">
                <span>{{item.date | date: 'EEEE'}}</span>
                <span>{{item.date | date: 'MMMM.dd.yyyy'}}</span>
              </div>
              <div class="item-list-event__bottom">
                <span style="margin-right: 10px;">
                  {{item.date | date: 'shortTime'}}
                </span>
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
          <ng-template #noData>
            <div class="lists-events__no-data">No data available in table</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
