import { OnInit, Component, Input, ViewChild , EventEmitter,  Output, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer } from '@angular/platform-browser';
import { AppService } from '../../app-services/app.service';
import { YhCore, SearchItemType, WorkItemStatusType, WorkItemType, WorkItemPipelineType } from 'src/app/app-services/core.service';


@Component({
  selector: 'app-search-item-pipelines-table',
  templateUrl: './search-item-pipelines-table.component.html',
  styleUrls: ['./search-item-pipelines-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('void', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('*', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})

export class SearchItemPipelinesTableComponent implements OnInit {

  @Input() public set data(data: any[]) {
    this.applyDataSource(data);
  }

  @Input() public jobOrder: WorkItemType;

  @Input() public statuses: WorkItemStatusType[];

  @Output() deleteFromPipelineEvent = new EventEmitter<WorkItemPipelineType>();

  public displayedColumns =
  ['searchItem', 'city', 'dateModified', 'dateCreated', 'owner', 'status'];
  public dataSource: MatTableDataSource<any>;
  public activities: any;
  public dataSourceCopy: MatTableDataSource<WorkItemPipelineType>;
  public expandedElement: WorkItemPipelineType | null = null;
  public activites: any;
  private paginator: MatPaginator;
  private sort: MatSort;
  selectedStatuses: string[] = [];

  constructor(
    public app: AppService,
    private sanitizer: DomSanitizer
  ) { }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    // if (this.paginator && this.sort) {
    //   this.applyFilter('');
    // }
  }

  applyDataSource(data: WorkItemPipelineType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllActivity(candidate: SearchItemType) {
    YhCore.searchItemActivites.bySearchItemId(+candidate.candidateId, activities=> {
      candidate.activities = activities;
    }, err=> {})
  }

  ngOnInit(): void {}

  applyFilter(filterValue: string, filterBy: string) {
    // Preparing filterValue
    filterValue = filterValue.toLowerCase().trim();

    // Preparing data for future filter
    switch(filterBy) {
      case 'bySearchItem':
        this.dataSource.data.map( (workItem: WorkItemPipelineType) => {
          const searchItem = `${workItem.candidateFirstName} ${workItem.candidateLastName}`;
          if (searchItem.toLowerCase().includes(filterValue)) {
            workItem.filteredBySearchItem = '1';
          } else {
            workItem.filteredBySearchItem = '0';
          }
        });
        break;
      case 'byOwner':
        this.dataSource.data.map( workItem => {
          const owner = `${workItem.enteredByFirstName} ${workItem.enteredByLastName}`;
          if (owner.toLowerCase().includes(filterValue)) {
            workItem.filteredByOwner = '1'
          } else {
            workItem.filteredByOwner = '0';
          }
        });
        break;
      case 'byCity':
        this.dataSource.data.map( workItem => {
          if (workItem.city.toLowerCase().includes(filterValue)) {
            workItem.filteredByCity = '1'
          } else {
            workItem.filteredByCity = '0';
          }
        });
        break;
    }
    // Applying all active filters
    this.applyAllFilters();
  }

  // Separate filter because here is a bit different logic than in filter above
  filterByStatuses() {
    if (this.selectedStatuses.length === 0) {
      this.dataSource.data.map(workItem => workItem.filteredByStatus = '1')
    } else {
      this.dataSource.data.map(workItem => {
        if (this.selectedStatuses.indexOf(workItem.statusShortDescription) >= 0) {
         workItem.filteredByStatus = '1';
        } else {
          workItem.filteredByStatus = '0';
        }
       })
    }

    // Applying all active filters
    this.applyAllFilters();
  }

  applyAllFilters() {
    // Defining how data should be filtered
    this.dataSource.filterPredicate = (data, filter: string): boolean => {
      return data.filteredByOwner.includes(filter)
              && data.filteredBySearchItem.includes(filter)
              && data.filteredByStatus.includes(filter)
              && data.filteredByCity.includes(filter)
    }

    // Applying all filters
    this.dataSource.filter = '1';
  }

  setInnerHtml(value) {
    return  this.sanitizer.bypassSecurityTrustHtml(value);
  }


  selectAllSatuses() {
    // Setting all data.filteredbyStatus to 1
    this.selectedStatuses = this.statuses.map(status => status.statusName);
    this.filterByStatuses()
  }

  clearAllSatuses() {
    // Setting all data.filteredbyStatus to 1
    this.selectedStatuses = [];
    this.filterByStatuses();
  }

  deleteFromPipeline(pipeline: WorkItemPipelineType) {
    this.deleteFromPipelineEvent.emit( pipeline);
  }
}
