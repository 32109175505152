"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtraFieldsController = void 0;
class ExtraFieldsController {
    constructor(api, extraFieldsService) {
        this.api = api;
        this.extraFieldsService = extraFieldsService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('types/extraFields', {}, response => {
            if (response.data.valid === true)
                successCallback(this.extraFieldsService.mapExtraFields(response.data.types));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    read(id, successCallback, errorCallback) {
    }
    remove(id, successCallback, errorCallback) {
    }
    edit(extraField, successCallback, errorCallback) {
        const src = this.extraFieldsService.mapReverseExtraField(extraField);
        this.api.postRequest('extraFields/edit', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    create(extraField, successCallback, errorCallback) {
        const src = this.extraFieldsService.mapReverseExtraField(extraField);
        this.api.postRequest('extraFields/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    find(extraField, successCallback, errorCallback) { }
    sort(extraField, desc, successCallback, errorCallback) { }
}
exports.ExtraFieldsController = ExtraFieldsController;
