<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle>Share Norms</mat-card-subtitle>
      <!-- Fixing min height issue -->
      <div class="empty-space"></div>
  </mat-card>
  <mat-card class="main-content">
      <mat-card-content>
          <app-share-norms-table [data]="user" (saveValueEvent)="saveValue()"></app-share-norms-table>
      </mat-card-content>
  </mat-card>
  </div>
</app-navigation> 