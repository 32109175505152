<div class="main-table-wrap">
  <div  class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>

      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["title"] }}</mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["dateCreated"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="adminFirstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Admin First Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["adminFirstName"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="adminLastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Admin Last Name </mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["adminLastName"] }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToDepartment(row)" style="cursor: pointer"></mat-row>
      </mat-table>

      <div id="spinner" class="spinner" *ngIf="loading">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </div>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
