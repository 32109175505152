"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebGroupUsersController = void 0;
class WebGroupUsersController {
    constructor(api, groupUsersService) {
        this.api = api;
        this.groupUsersService = groupUsersService;
    }
    create(payload, successCallback, errorCallback) {
        this.api.postRequest("groupUser/create", { group_name: payload.groupName }, res => {
            if (res.data.valid === true) {
                successCallback(res.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    edit(payload, successCallback, errorCallback) {
        this.api.postRequest("groupUser/update", { group_user_id: payload.groupUserId, group_name: payload.groupName }, res => {
            if (res.data.valid === true) {
                successCallback(res.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    list(successCallback, errorCallback) {
        this.api.postRequest("groupUser/list", { list_departament_users: 0 }, res => {
            if (res.data.valid === true) {
                successCallback(this.groupUsersService.mapGroupUsers(res.data.data));
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    listWithUsers(payload, successCallback, errorCallback) {
        this.api.postRequest("groupUser/list", { list_departament_users: payload.listDepartamentUser }, res => {
            if (res.data.valid === true) {
                successCallback(this.groupUsersService.mapGroupWithUsers(res.data.data));
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    read(payload, successCallback, errorCallback) {
        this.api.postRequest("groupUser/read", { group_user_id: payload.groupUserId }, res => {
            if (res.data.valid === true) {
                successCallback({
                    groupUserRow: this.groupUsersService.mapGroupUser(res.data.data.group_user_row),
                    listUsersInGroup: this.groupUsersService.mapGroupUserItems(res.data.data.list_user_in_group)
                });
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebGroupUsersController = WebGroupUsersController;
