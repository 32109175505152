"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareNormsService = void 0;
const share_norms_type_1 = require("./../types/share-norms-type");
class ShareNormsService {
    constructor() {
    }
    mapReverseShareNorms(src) {
        const result = {};
        if (src.sharingId)
            result.id = src.sharingId;
        if (src.accessLevelId)
            result.access_level_id = src.accessLevelId;
        if (src.shortDescription)
            result.short_description = src.shortDescription;
        if (src.norm)
            result.norm = src.norm;
        if (src.normChangeOwner)
            result.norm_change_owner = src.normChangeOwner;
        if (src.userId)
            result.user_id = src.userId;
        if (src.limitDownload)
            result.limit_download = src.limitDownload;
        if (src.limitViewCandidateProfile)
            result.limit_view_candidate_profile = src.limitViewCandidateProfile;
        if (src.limitViewFreeCandidateProfile)
            result.limit_view_free_candidate_profile = src.limitViewFreeCandidateProfile;
        return result;
    }
    mapShareNorms(raw) {
        return raw.map((val) => this.mapShareNorm(val));
    }
    mapShareNorm(raw) {
        const result = new share_norms_type_1.ShareNorms();
        result.sharingId = raw.sharing_id;
        result.accessLevelId = raw.access_level_id;
        result.shortDescription = raw.short_description;
        result.norm = raw.norm;
        result.normChangeOwner = raw.norm_change_owner;
        result.userId = raw.user_id;
        result.limitDownload = raw.limit_download;
        result.limitViewCandidateProfile = raw.limit_view_candidate_profile;
        result.limitViewFreeCandidateProfile = raw.limit_view_free_candidate_profile;
        return result;
    }
}
exports.ShareNormsService = ShareNormsService;
