import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
  selector: 'app-cv-field-settings-table',
  templateUrl: './cv-field-settings-table.component.html',
  styleUrls: ['./cv-field-settings-table.component.scss']
})

export class CvFieldSettingsTableComponent {
  public displayedColumns = ['filedName', 'isActive', 'block'];
  public blockArr = [{ value: '1', viewValue: 'block-1' }, { value: '2', viewValue: 'block-2' }, { value: '3', viewValue: 'block-3' }]
  public dataSource: MatTableDataSource<any>;
  private sort: MatSort;

  constructor() {

  }

  @Input() set data(data: any[]) {
    this.applyDataSource(data);
  }


  @Output() setDataFieldEvent = new EventEmitter<any>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.sort = this.sort;

    if (this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }

  setDataField() {
    this.setDataFieldEvent.emit(this.data);
  }
}
