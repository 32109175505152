import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-work-item-decline-condition-dialog',
  templateUrl: './work-item-decline-condition-dialog.component.html',
  styleUrls: ['./work-item-decline-condition-dialog.component.scss']
})
export class WorkItemDeclineConditionDialogComponent implements OnInit {

  comment: FormControl = new FormControl('', [Validators.required])

  constructor(
    private dialogRef: MatDialogRef<WorkItemDeclineConditionDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(false)
  }

  onSave() {
    if (this.comment.invalid) return

    this.dialogRef.close(this.comment.value)
  }

}
