<h2 mat-dialog-title>Add {{ app.type.names['contactItem'] }}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline" >
    <mat-label>{{ app.type.contactItemNames['firstName'] }}</mat-label>
    <input matInput [(ngModel)]="contact.firstName">
  </mat-form-field>
  <mat-form-field appearance="outline" >
    <mat-label>{{ app.type.contactItemNames['lastName'] }}</mat-label>
    <input matInput [(ngModel)]="contact.lastName">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['title'] }}</mat-label>
    <input matInput [(ngModel)]="contact.title">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['phoneWork'] }}</mat-label>
    <input matInput [(ngModel)]="contact.phoneWork">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['phoneCell'] }}</mat-label>
    <input matInput [(ngModel)]="contact.phoneCell">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['phoneOther'] }}</mat-label>
    <input matInput [(ngModel)]="contact.phoneOther">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['email1'] }}</mat-label>
    <input matInput [(ngModel)]="contact.email1">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['email2'] }}</mat-label>
    <input matInput [(ngModel)]="contact.email2">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['state'] }}</mat-label>
    <input matInput [(ngModel)]="contact.state">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['city'] }}</mat-label>
    <input matInput [(ngModel)]="contact.city">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['address'] }}</mat-label>
    <input matInput [(ngModel)]="contact.address">
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.contactItemNames['notes'] }}</mat-label>
    <textarea matInput [(ngModel)]="contact.notes"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onAddContact()">Add</button>
</div>
