<h1 mat-dialog-title>Edit Status</h1>
<div mat-dialog-content>
  <form #form="ngForm" (ngSubmit)="onSaveClick()"  [formGroup]="workItemStatusForm" class="form">

    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="form__field">
        <mat-label>Status Name</mat-label>
        <input matInput formControlName="statusName">

        <div class="form__error" *ngIf="f.statusName.dirty && f.statusName.invalid">
          Required
        </div>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="form__field">
        <mat-label>Status Rank</mat-label>
        <input matInput type="number" formControlName="statusRank">

        <div class="form__error" *ngIf="f.statusRank.dirty && f.statusRank.invalid">
          Required & Only number
        </div>
      </mat-form-field>
    </div>

    <button style="margin-right: 5px;" type="reset" mat-flat-button color="primary" (click)="onCancelClick()">Cancel</button>
    <button style="margin-right: 5px;" type="submit" mat-flat-button color="primary" [disabled]="workItemStatusForm.invalid || workItemStatusForm.pristine">Save</button>

  </form>

</div>