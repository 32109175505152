"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerNotesRaw = exports.CustomerNotes = exports.Customer = void 0;
class Customer {
}
exports.Customer = Customer;
class CustomerNotes {
}
exports.CustomerNotes = CustomerNotes;
class CustomerNotesRaw {
}
exports.CustomerNotesRaw = CustomerNotesRaw;
