<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
  </mat-form-field>

  <div>
    <div class="table-wrap">
      <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="contactId">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-label="id">ID</mat-header-cell>
            <mat-cell *matCellDef="let contact" data-label="id">{{contact['contactId']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="firstName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['firstName'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['firstName']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['lastName'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['lastName']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['title'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['title']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="phoneWork">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['phoneWork'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['phoneWork']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email1">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['email1'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">
              {{contact['email1']}}
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email2">

            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['email2'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">
              {{contact['email2']}}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="owner">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.contactItemNames['owner'] }}</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['owner']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Created</mat-header-cell>
            <mat-cell *matCellDef="let contact">{{contact['dateCreated']}}</mat-cell>
          </ng-container>
          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
            <mat-cell *matCellDef="let contact">
              <button (click)="onViewContact(contact)" mat-icon-button matTooltip="Show Detail Contact Info">
                <mat-icon>account_box</mat-icon>
              </button>
              <span [hidden]="!contact['email1']">
                <button (click)="onEmailSend(contact)" mat-icon-button matTooltip="Send Email">
                  <mat-icon>email</mat-icon>
                </button>
              </span>
              <button mat-icon-button (click)="onDeleteContact(contact)"><mat-icon>close</mat-icon></button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let contact; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
