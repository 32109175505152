<div class="condition">
  <div class="condition__title">{{condition.title}}</div>
  <div class="condition__body">
    <div class="condition__item item-condition">
      <div class="item-condition__title">Short Description:</div>
      <div class="item-condition__text">{{condition.description}}</div>
    </div>
    <div class="condition__item item-condition">
      <div class="item-condition__title">SLAS:</div>
      <div class="item-condition__text">{{condition.slas}}</div>
    </div>
    <div class="condition__item item-condition">
      <div class="item-condition__title">Payments:</div>
      <div class="item-condition__text">
        <div>
          <span>Deposit:</span>
          <span style="font-weight: 600;">{{condition.prepaymentsAmount === '' ? 'No': condition.prepaymentsAmount}}</span>
        </div>
        <div>
          Agence fee <span style="font-weight: 600;">{{condition.fee}}</span> from <span style="font-weight: 600;">{{condition.feeType}}</span> candidate income
        </div>
      </div>
    </div>
  </div>
</div>