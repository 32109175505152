<app-navigation>
  <div class="main-container-no-scroll">
    <mat-tab-group class="tab-group">
      <mat-tab label="Settings" *ngIf="userAccessLevel >= 300" class="tab-group__tab">
        <mat-action-list>
          <button mat-list-item matTooltip="Add, edit users" matTooltipPosition="below" routerLink="/settings/manage-users" routerLinkActive="active"> Manage users </button>
        </mat-action-list>
      </mat-tab>
      <mat-tab label="Candidate Statuses" class="tab-group__tab" *ngIf="+userAccessLevel >= 400">
        <mat-action-list>
          <button mat-list-item matTooltip="Here you can manage candidate statuses list" matTooltipPosition="below" matTooltipClass="tooltip"  routerLink="/settings/statuses" routerLinkActive="active">Candidate statuses</button>
        </mat-action-list>
      </mat-tab>
      <mat-tab label="Matrix Fields" *ngIf="+userAccessLevel >= 400">
        <mat-action-list>
          <button mat-list-item mat-Tooltip="Matrix Search Item Fields Settings" matTooltipPosition="below" matTooltipClass="tooltip" routerLink="/settings/matrix-search-item-fields">Matrix Fields</button>
        </mat-action-list>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-navigation>
