import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupUsersType, UserType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-group-users-dialog',
  templateUrl: './group-users-dialog.component.html',
  styleUrls: ['./group-users-dialog.component.scss']
})
export class GroupUsersDialogComponent implements OnInit {

  public groupUserForm: FormGroup = new FormGroup({});
  public createMode:boolean = false;
  public currentUser:UserType = YhCore.localStorageService.getItem("YHminiuser")

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<GroupUsersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {groupUser: GroupUsersType, createMode: boolean},
  ) { 
    if(+this.currentUser.accessLevel < 400) {
      throw new Error("Access denied by ACL rules");
    }
  }

  ngOnInit(): void {
    this.initForm(this.data.groupUser);
    this.createMode = this.data.createMode
  }

  initForm(data: GroupUsersType|{}) {
    this.groupUserForm = new FormGroup({
      title: new FormControl("title" in data ? data.title: '', [Validators.required])
    })
  }

  createGroupUser(data: {groupName: string}):Promise<{valid: boolean}> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.create(data, res => resolve(res), err => reject(err))
    })
  }
  updateGroupUser(data: {groupName: string, groupUserId: number}):Promise<{valid: boolean}> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.edit(data, res => resolve(res), err => reject(err))
    })
  }

  onClose() {
    this.dialogRef.close(false)
  }

  onSave() {
    const value = this.groupUserForm.value;
    if(this.data.createMode) {
      this.createGroupUser({groupName: value.title}).then(res => {
        this.snackBar.open("Group Users was successfully created", "OK", {duration: 3000})
        this.dialogRef.close(true)
      })
      return
    }
    this.updateGroupUser({groupName: value.title, groupUserId: this.data.groupUser.id}).then( res => {
      this.snackBar.open("Group Users was successfully updated", "OK", {duration: 3000})
      this.dialogRef.close({title: value.title})
    })
  }

}
