<h2 mat-dialog-title>{{createMode ? "Add new Group User": "Edit Grou User"}}</h2>
<div mat-dialog-content>
  <form class="form" [formGroup]="groupUserForm">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Title</mat-label>
      <input type="text" matInput formControlName="title">
    </mat-form-field>
    <button style="margin-right: 10px;" mat-stroked-button (click)="onClose()">CLOSE</button>
    <button mat-flat-button (click)="onSave()" color="primary">SAVE</button>
  </form>
</div>