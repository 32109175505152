"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const notifications_type_1 = require("../types/notifications-type");
const requests_from_client_1 = require("../types/requests-from-client");
class NotificationService {
    constructor() { }
    mapReverseNotification(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.description)
            result.description = src.description;
        if (src.action)
            result.action = src.action;
        if (src.isViewed)
            result.is_viewed = src.isViewed;
        if (src.isForUser)
            result.is_for_user = src.isForUser;
        if (src.isForAccessLevel)
            result.is_for_access_level = src.isForAccessLevel;
        if (src.dataItemId)
            result.data_item_id = src.dataItemId;
        if (src.dataItemType)
            result.data_item_type = src.dataItemType;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        return result;
    }
    mapNotifications(raw) {
        return raw.map((val) => this.mapNotification(val));
    }
    mapNotification(raw) {
        const result = new notifications_type_1.Notification();
        result.id = raw.id;
        result.dateCreated = raw.date_created;
        result.description = raw.description;
        result.action = raw.action;
        result.isViewed = raw.is_viewed;
        result.isForUser = raw.is_for_user;
        result.isForAccessLevel = raw.is_for_access_level;
        result.dataItemId = raw.data_item_id;
        result.dataItemType = raw.data_item_type;
        result.enteredBy = raw.entered_by;
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        return result;
    }
    mapRequestsFromClient(raw) {
        return raw.map(val => this.mapRequestFromClient(val));
    }
    mapRequestFromClient(raw) {
        const result = new requests_from_client_1.RequestsFromClient();
        result.id = raw.id;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.status = raw.status;
        result.candidateId = raw.candidate_id;
        result.enteredBy = raw.entered_by;
        return result;
    }
}
exports.NotificationService = NotificationService;
