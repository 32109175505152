"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CvSettingsLayoutService = void 0;
const cv_settings_layout_type_1 = require("../types/cv-settings-layout-type");
class CvSettingsLayoutService {
    constructor() { }
    mapReversCvSettingsLayout(raw) {
        const result = {};
        if (raw.userId)
            result.user_id = raw.userId;
        if (raw.id)
            result.id = raw.id;
        if (raw.cvFooter)
            result.cv_footer = raw.cvFooter;
        if (raw.cvHeader)
            result.cv_header = raw.cvHeader;
        return result;
    }
    mapCvSettingsLayouts(raw) {
        return raw.map(val => this.mapCvSettingLayout(val));
    }
    mapCvSettingLayout(raw) {
        const result = new cv_settings_layout_type_1.CvSettingsLayout();
        result.id = raw.id;
        result.userId = raw.user_id;
        result.cvFooter = raw.cv_footer;
        result.cvHeader = raw.cv_header;
        return result;
    }
}
exports.CvSettingsLayoutService = CvSettingsLayoutService;
