"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportsService = void 0;
const reports_type_1 = require("../types/reports-type");
class ReportsService {
    constructor() { }
    mapReversePeriod(src) {
        const result = {};
        if (src.splittingBy)
            result.splitting_by = src.splittingBy;
        if (src.startDate)
            result.start_date = src.startDate;
        if (src.endDate)
            result.end_date = src.endDate;
        if (src.userId)
            result.user_id = src.userId;
        return result;
    }
    mapReports(raw) {
        const result = [];
        for (const value in raw) {
            raw[value].id = value;
            result.push(raw[value]);
        }
        return result;
    }
    mapReport(raw) {
        const result = new reports_type_1.Report();
        return result;
    }
}
exports.ReportsService = ReportsService;
