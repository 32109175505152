"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSearchItemsController = void 0;
class WebSearchItemsController {
    constructor(api, searchItemsService, contactItemsService, historyServise) {
        this.api = api;
        this.searchItemsService = searchItemsService;
        this.contactItemsService = contactItemsService;
        this.historyServise = historyServise;
    }
    // Works with ER Api - YHclient
    list(from, to, successCallback, errorCallback) {
        this.api.postRequest('searchItem/list', { from: from, to: to }, response => {
            successCallback({ candidates: this.searchItemsService.mapSearchItems(response.data.data), totalSearchItems: response.data.total_search_items });
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    read(candidateId, successCallback, errorCallback) {
        this.api.postRequest('searchItem/read', { id: candidateId }, response => {
            if (response.data.valid === true) {
                successCallback(this.searchItemsService.mapSearchItem(response.data.data));
            }
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('searchItem/delete', { id: id }, response => {
            successCallback(true);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    edit(searchItem, successCallback, errorCallback) {
        const src = this.searchItemsService.mapReverseSearchItem(searchItem);
        this.api.postRequest('searchItem/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    create(searchItem, successCallback, errorCallback) {
        const src = this.searchItemsService.mapReverseSearchItem(searchItem);
        this.api.postRequest('searchItem/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    uploadImage(formData, successCallback, errorCallback) {
        this.api.postRequest('searchItem/uploadImage', formData, response => {
            if (response.data.valid === true)
                successCallback(response.data.img_link);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    find(searchItem, successCallback, errorCallback) { }
    sort(searchItems, searchItemField, desc, successCallback, errorCallback) { }
    listContacts(id, successCallback, errorCallback) {
        this.api.postRequest('searchItem/contacts', { candidate_id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.contactItemsService.mapContactItems(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    quickSearch(search, successCallback, errorCallback) {
        this.api.postRequest('searchItem/search', { search: search }, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsService.mapSearchItems(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getContactedCandidates(successCallback, errorCallback) {
        this.api.postRequest('searchItem/contactedCandidates', {}, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsService.mapSearchItems(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    getCandidatesInWork(checkShowMy, successCallback, errorCallback) {
        this.api.postRequest('searchItem/importantCandidates', { check_show_my: checkShowMy }, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsService.mapSearchItemsInWork(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    getPlacedCandidates(successCallback, errorCallback) {
        this.api.postRequest('searchItem/placedCandidates', {}, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsService.mapSearchItems(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    getOfferedCandidates(successCallback, errorCallback) {
        this.api.postRequest('searchItem/offeredCandidates', {}, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsService.mapSearchItems(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    listAllExtraFields(successCallback, errorCallback) {
    }
    getSearchItemHistory(id, successCallback, errorCallback) {
        this.api.postRequest('searchItem/getSearchItemHistory', { id: id }, response => {
            if (response.data.valid === true) {
                successCallback(this.searchItemsService.mapSearchItemsHistory(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    shareSearchItemToEr(candidateId, companyId, companyName, joborderId, shareType, fileName = '', successCallback, errorCallback, calibrationId) {
        const src = {
            candidate_id: candidateId,
            company_id: companyId,
            company_name: companyName,
            joborder_id: joborderId,
            share_type: shareType,
            calibration_id: calibrationId || '',
            file_name: fileName
        };
        this.api.postRequest('searchItem/shareSearchItemToEr', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    shareSearchItemList(candidateId, successCallback, errorCallback) {
        this.api.postRequest('searchItem/shareSearchItemList', { candidate_id: candidateId }, response => {
            if (response.data.valid === true) {
                successCallback(this.searchItemsService.mapShareSearchItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    updateShareSearchItemToFull(candidateId, successCallback, errorCallback) {
        this.api.postRequest('searchItem/updateShareSearchItemToFull', { candidate_id: candidateId }, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    cancelShareSearchItemToFull(id, successCallback, errorCallback) {
        this.api.postRequest('searchItem/cancelShareSearchItemToFull', { id: id }, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getActiveCandidatePoll(payload, successCallback, errorCallback) {
        this.api.postRequest('searchItem/getActiveCandidatePoll', { field: payload.field, values: payload.values }, response => {
            if (response.data.valid) {
                successCallback(this.searchItemsService.mapSearchItems(response.data.data));
            }
            else {
                errorCallback(response.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebSearchItemsController = WebSearchItemsController;
