import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CustomerType, UserType, YhCore } from '../app-services/core.service'

@Injectable()
export class AuthGuard implements CanActivate {
    currentUser:UserType;
    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if(JSON.parse(localStorage.getItem('YHminiuser'))){
            this.currentUser = JSON.parse(localStorage.getItem('YHminiuser'))
        }

        if (YhCore.api.isValid()) {
            YhCore.customers.list(suc => {
                let currentComapny: boolean = false
                suc.map(el => {
                    if (+el.owner === +this.currentUser.userId) {
                        currentComapny = true;
                    }
                })
                if (!currentComapny && +this.currentUser.accessLevel >= 400) {
                    this.router.navigate(['/create-company-profile']);
                }
                else{
                    if(this.router.url === '/create-company-profile' ){
                        this.router.navigate(['/']);
                    }
                }
            }, err => { })
            return true
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
