"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebHashtagsController = void 0;
class WebHashtagsController {
    constructor(api, hashtagsService) {
        this.api = api;
        this.hashtagsService = hashtagsService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('hashtags/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.hashtagsService.mapHashtags(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('hashtags/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.hashtagsService.mapHashtag(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('hashtags/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    edit(hashtag, successCallback, errorCallback) {
        const src = this.hashtagsService.mapReverseHashtag(hashtag);
        this.api.postRequest('hashtags/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    create(hashtag, successCallback, errorCallback) {
        const src = this.hashtagsService.mapReverseHashtag(hashtag);
        this.api.postRequest('hashtags/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    searchItemsByHashtag(id, successCallback, errorCallback) {
        this.api.postRequest('hashtags/candidatesList', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.hashtagsService.mapHashtags(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    searchItemHashtags(id, successCallback, errorCallback) {
        this.api.postRequest('hashtags/listByCandidate', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.hashtagsService.mapsearchItemHashtags(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    createRelation(hashtag, successCallback, errorCallback) {
        const src = this.hashtagsService.mapReverseHashtag(hashtag);
        this.api.postRequest('hashtags/createRelation', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    deleteRelation(id, successCallback, errorCallback) {
        this.api.postRequest('hashtags/deleteRelation', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    find(hashtag, successCallback, errorCallback) { }
    sort(hashtags, hashtagField, desc, successCallback, errorCallback) { }
}
exports.WebHashtagsController = WebHashtagsController;
