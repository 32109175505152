"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebConditionController = void 0;
class WebConditionController {
    constructor(api, conditonService) {
        this.api = api;
        this.conditonService = conditonService;
    }
    edit(condition, successCallback, errorCallback) {
        const src = this.conditonService.mapReversCondition(condition);
        this.api.postRequest('condition/update', src, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    delete(conditionId, successCallback, errorCallback) {
        this.api.postRequest('condition/delete', { id: conditionId }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    unSubmit(conditionId, successCallback, errorCallback) {
        this.api.postRequest('condition/unsubmit', { id: conditionId }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    submit(conditionId, successCallback, errorCallback) {
        this.api.postRequest('condition/submit', { id: conditionId }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    list(workItemId, successCallback, errorCallback) {
        this.api.postRequest('condition/list', { joborder_id: workItemId }, response => {
            if (response.data.valid === true) {
                successCallback(this.conditonService.mapConditions(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    create(condition, successCallback, errorCallback) {
        const src = this.conditonService.mapReversCondition(condition);
        this.api.postRequest('condition/create', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data.data);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
}
exports.WebConditionController = WebConditionController;
