<h2 mat-dialog-title>Add New Template</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>Title:</mat-label>
    <input matInput [(ngModel)]="templateDialog.title">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Subject:</mat-label>
    <input matInput [(ngModel)]="templateDialog.subject">
  </mat-form-field>

  <mat-chip-list class="chip-list">
    <mat-chip style="cursor: pointer;" *ngFor="let item of templateVariables" (click)="setVariable(item)">{{item}}</mat-chip>
  </mat-chip-list>

  <mat-form-field appearance="outline">
    <!-- <mat-label>Body:</mat-label> -->
    <textarea class="template" matInput rows="10" spellcheck="false" [(ngModel)]="templateDialog.body" [placeholder]="exampleBodyPlaceholder"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="dialogSubmit()">Save</button>
</div>

