import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { JoborderGroupType, UserType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-work-item-group-dialog',
  templateUrl: './work-item-group-dialog.component.html',
  styleUrls: ['./work-item-group-dialog.component.scss']
})
export class WorkItemGroupDialogComponent implements OnInit {
  public addJoborderGroupForm: FormGroup = new FormGroup({title: new FormControl('', [Validators.required])})
  public currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));

  constructor(
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<WorkItemGroupDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  
  addNewJoborderGroup() {
    if(+this.currentUser.accessLevel < 400) return
    if(this.addJoborderGroupForm.invalid) return
    this.createJoborderGroup(this.addJoborderGroupForm.value).then(result => {
      this.snackBar.open('New Joborder Group was added', "OK", {duration: 3000});
      this.dialogRef.close(true)
    })
  }

  createJoborderGroup(payload: JoborderGroupType): Promise<any>{
    return new Promise( (resolve, reject) => {
      YhCore.joborderGroup.create(payload, res => resolve(res), err => reject(err))
    })
  }
}
