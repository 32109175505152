<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
  </mat-form-field>

  <div>
  <div class="table-wrap">
    <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="Date">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date</mat-header-cell>
            <mat-cell *matCellDef="let data">{{data['dateCreated']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="Description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let data"><span [innerHTML]="data['description']"></span></mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="Action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
            <mat-cell *matCellDef="let data">{{data['action']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Share Item</mat-header-cell>
            <mat-cell *matCellDef="let data">{{data['candidateFirstName']}} {{data['candidateLastName']}}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let data; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
