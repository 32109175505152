<mat-card>
  <mat-card-content>
    <div class="history">
      <div class="history__row">
        <div class="history__column candidate">
          <div class="candidate__row">
            <ng-container *ngFor="let item of candidateFields">
              <div class="candidate__column">
                <strong [ngStyle]="{'color': item.length > 2 ? 'orange': '#000'}">{{item[0]}}: </strong> 
                <span [ngStyle]="{'text-decoration': item.length > 2 ? 'line-through': 'none'}"> {{item[1]}}</span> 
                <span *ngIf="item.length > 2" style="font-weight: 500;"> {{item[2]}}</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="history__column history-lists">
          <mat-selection-list #items [multiple]="false" [disableRipple]="true">
            <mat-list-option *ngFor="let item of candidateChangeHistory"
                              (click)="item.isShowedHistory = !item.isShowedHistory; showHistoryChanged(item.theField, item.previousValue)"
                              style="margin-bottom: 10px; cursor: pointer;">
              <div>{{item.setDate}}: {{candidateEmail}}</div>
              <div>{{item.description}}</div>
            </mat-list-option>
          </mat-selection-list>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
