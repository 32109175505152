import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YhCore, CalendarType, ContactItemType,  ActivityType, WorkItemStatusType, SearchItemPipelineType, ActivityStatusType } from '../../app-services/core.service'
import { AppService } from '../../app-services/app.service';
import { DatePipe } from '@angular/common';
import { MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-search-item-create-action-dialog',
  templateUrl: 'search-item-create-action-dialog.component.html',
  styleUrls: ['search-item-create-action-dialog.component.scss']
})
export class SearchItemCreateActionDialogComponent implements OnInit {
  public candidateStatuses: WorkItemStatusType[] = [];
  public activityStatuses: ActivityStatusType[] = [];
  public isChangeStatus = true;
  public isLogActivity = true;
  public isScheduleEvent = false;
  selectedContact: ContactItemType;
  newWorkItemStatus;
  newActivity: ActivityType;

  calendarEvent: CalendarType = new CalendarType();
  isAllDayEvent = false;
  minute: string;
  hour: any;
  meridiem: string;
  dateForDatePicker: any;

  constructor(
    public dialogRef: MatDialogRef<SearchItemCreateActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public datePipe: DatePipe,
    public app: AppService,
    private snackBar: MatSnackBar,
    ) { }

  switchAllDay() {
    this.isAllDayEvent = !this.isAllDayEvent;
  }

  convertDate() {
    this.calendarEvent.date = this.datePipe.transform(this.dateForDatePicker, 'yyyy-MM-dd');
    if (this.meridiem === 'PM') {
      this.hour = 12 + +this.hour;
    }
    this.calendarEvent.date += ' ' + `${this.hour}` + ':' + `${this.minute}` + ':00'
  }

  onCreate(): void {
    this.dialogRef.close(false);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick(): void {
    // If isLogActivity is true but no activity was actually selected
    if (this.isLogActivity && !this.newActivity.type) {
      this.snackBar.open('Please, choose activity type', 'OK', {duration: 5000});
      return;
    }
    if (this.selectedContact) {
      this.newActivity.notes !== undefined ? this.newActivity.notes: this.newActivity.notes = '';
      this.newActivity.notes += ' - activity related to contact - <span style="cursor:pointer; text-decoration:underline;font-weight: 500;" data-contactId="' + this.selectedContact.contactId + '">' + this.selectedContact.firstName + '</span>';
    }
    this.convertDate();
    if(this.newActivity.joborderId){
      this.calendarEvent.joborderId = this.newActivity.joborderId;
    }
    // this.candidateActivity.changeStatus = this.isChangeStatus ? '1' : '0';
    // this.candidateActivity.addActivity = this.isLogActivity ? '1' : '0';
    if (!this.isScheduleEvent) this.calendarEvent = new CalendarType();
    this.dialogRef.close({calendar: this.calendarEvent, activity: this.newActivity, status: this.newWorkItemStatus})
  }

  async ngOnInit() {
    this.newActivity= new ActivityType();
    this.newActivity.dataItemId = this.data.pipelines[0].candidateId;
    this.newActivity.dataItemType = '100';
    this.newActivity.siteId = '3';
    this.candidateStatuses = await JSON.parse(YhCore.localStorageService.getItem('workItemSatuses'));
    this.activityStatuses = await JSON.parse(YhCore.localStorageService.getItem('activityStatuses'));
  }
}

export interface DialogData {
  activities: ActivityType[];
  pipelines: SearchItemPipelineType[];
  contacts: ContactItemType[];
}

export class CandidateActivity {
  activityNote = '';
  activityTypeId: string;
  regardingId: string;
  statusId: string;
  changeStatus: string;
  addActivity: string;
}
