import {Injectable} from '@angular/core';
import {User} from "./user.type";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {YhApiService} from "./yh-api.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private api: YhApiService
  ) { }

  userGetInfo(userId: string): Observable<User> {
    return this.api.request('setting/UserGetInfo', {userId: userId})
      .pipe(map((val: any) => {
        const userObj = {user: null, accessLevelDescription: ''};
        userObj.user = val.userData;
        return this.mapUser(userObj);
      }));
  }

  setCurrentUser(user: User) {
  }

  mapUsers(raw: any): User[] {
    return raw.map(val => this.mapUser(val));
  }

  mapUserObjects(raw: any): User[] {
    return raw.map(val => this.mapUserObject(val));
  }

  mapUserObject(raw: any): User {
    const result = new User();

    result.accessLevel = raw.access_level;
    result.address = raw.address;
    result.canChangePassword = raw.can_change_password;
    result.canSeeEeoInfo = raw.can_see_eeo_info;
    result.categories = raw.categories;
    result.city = raw.city;
    result.columnPreferences = raw.column_preferences;
    result.company = raw.company;
    result.company_id = raw.company_id;
    result.country = raw.country;
    result.email = raw.email;
    result.firstName = raw.first_name;
    result.forceLogout = raw.force_logout;
    result.isDemo = raw.is_demo;
    result.isTestUser = raw.is_test_user;
    result.jobOrderStatusAccess = raw.joborder_status_access;
    result.key = raw.key;
    result.lastName = raw.last_name;
    result.notes = raw.notes;
    result.password = raw.password;
    result.phoneCell = raw.phone_cell;
    result.phoneOther = raw.phone_other;
    result.phoneWork = raw.phone_work;
    result.pipelineEntriesPerPage = raw.pipeline_entries_per_page;
    result.positionId = raw.position_id;
    result.salary = raw.salary;
    result.sessionCookie = raw.session_cookie;
    result.siteId = raw.site_id;
    result.state = raw.state;
    result.title = raw.title;
    result.userId = raw.user_id;
    result.userName = raw.user_name;
    result.zipCode = raw.zip_code;

    return result;
  }

  mapUser(raw: any): User {
    const result = new User();

    result.accessLevel = raw.user.access_level;
    result.address = raw.user.address;
    result.canChangePassword = raw.user.can_change_password;
    result.canSeeEeoInfo = raw.user.can_see_eeo_info;
    result.categories = raw.user.categories;
    result.city = raw.user.city;
    result.columnPreferences = raw.user.column_preferences;
    result.company = raw.user.company;
    result.company_id = raw.user.company_id;
    result.country = raw.user.country;
    result.email = raw.user.email;
    result.firstName = raw.user.first_name;
    result.forceLogout = raw.user.force_logout;
    result.isDemo = raw.user.is_demo;
    result.isTestUser = raw.user.is_test_user;
    result.jobOrderStatusAccess = raw.user.joborder_status_access;
    result.key = raw.user.key;
    result.lastName = raw.user.last_name;
    result.notes = raw.user.notes;
    result.password = raw.user.password;
    result.phoneCell = raw.user.phone_cell;
    result.phoneOther = raw.user.phone_other;
    result.phoneWork = raw.user.phone_work;
    result.pipelineEntriesPerPage = raw.user.pipeline_entries_per_page;
    result.positionId = raw.user.position_id;
    result.salary = raw.user.salary;
    result.sessionCookie = raw.user.session_cookie;
    result.siteId = raw.user.site_id;
    result.state = raw.user.state;
    result.title = raw.user.title;
    result.userId = raw.user.user_id;
    result.userName = raw.user.user_name;
    result.zipCode = raw.user.zip_code;
    result.accessLevelDescription = raw.access_level_description;

    return result;
  }
}
