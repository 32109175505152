import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-work-item-submit-dialog',
  templateUrl: './work-item-submit-dialog.component.html',
  styleUrls: ['./work-item-submit-dialog.component.scss']
})
export class WorkItemSubmitDialogComponent implements OnInit {

  public partners = [{displayName: 'HuntME', partnerUrl: 'huntme.zone', contactEmail: 'info@huntme.ua'}];
  public selection = new SelectionModel<any>(true, []);

  constructor(
    private dialogRef: MatDialogRef<WorkItemSubmitDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  submitTo() {
    if(this.selection.selected.length) {
      this.dialogRef.close(this.selection.selected)
    }
  }

}
