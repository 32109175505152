<div class="table-wrap">
  <div class="table-wrap__inner">
    <mat-form-field>
        <label>
            <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
        </label>
    </mat-form-field> 
        <mat-table [dataSource]="dataSource" matSort class="share-norms-table">

            <ng-container matColumnDef="login">
                <mat-header-cell *matHeaderCellDef mat-sort-header>User</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row['login']}}</mat-cell>
            </ng-container>
            <ng-container matColumnDef="description">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Access level</mat-header-cell>
                <mat-cell *matCellDef="let row">{{row['description']}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="attachment">
                <mat-header-cell *matHeaderCellDef>Download attachment per day</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="row.limitDownload">
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="changeOwner">
                <mat-header-cell *matHeaderCellDef>Change owner</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="row.normChangeOwner">
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="candidateProfile">
                <mat-header-cell *matHeaderCellDef>View candidate profile</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="row.limitViewCandidateProfile">
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="freeProfile">
                <mat-header-cell *matHeaderCellDef>View candidate free profile</mat-header-cell>
                <mat-cell *matCellDef="let row">
                    <mat-form-field appearance="outline">
                        <input matInput [(ngModel)]="row.limitViewFreeCandidateProfile">
                    </mat-form-field>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <div class="share-norms-table-wrap">
            <button mat-button class="share-save-button" (click)="saveValue()">SAVE</button>
            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
    </div>
</div>