<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle> <span *ngIf="currentHashtag.description">{{currentHashtag.description}}</span>
        <mat-spinner *ngIf="!currentHashtag.description" [diameter]="15" class="progress-spinner"></mat-spinner>
      </mat-card-subtitle>
      <!-- Fixing min height issue -->
      <div class="empty-space"></div>
    </mat-card>
    <mat-card class="main-content">
      <mat-card-content>
        <mat-spinner *ngIf="!tableReady" [diameter]="25" class="progress-spinner"></mat-spinner>
        <app-search-items-table 
              *ngIf="tableReady" 
              #candidatesTableComponent 
              [data]="searchItems"
              [loading] = "mainTableLoading"
              (onCandidateSelect) = "navigateToCandidate($event)"
              (loadingEvent) = "loading($event)"
              [columns]="['firstName', 'lastName', 'city', 'owner', 'addedToList', 'remove']" 
              (removeRelationEvent) ="removeRelation($event)"></app-search-items-table>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>

