import { YhCore } from './../app-services/core.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss']
})
export class DashboardPageComponent implements OnInit {
  positions = [{ id: 1, value: 'Back-End' }, { id: 2, value: 'Front-End' }]
  candidates = [
    { id: 1, name: 'Misha', position: 'Front-End', relevant: '4.1', },
    { id: 2, name: 'Max', position: 'Back-End', relevant: '5.1', },
    { id: 3, name: 'Erik', position: 'Front-End', relevant: '3.1', },
    { id: 4, name: 'Miss', position: 'Back-End', relevant: '2.1', },
    { id: 5, name: 'Lii', position: 'Front-End', relevant: '1.1', },
    { id: 6, name: 'Worker', position: 'Back-End', relevant: '4.5', },
    { id: 7, name: 'Andry', position: 'Front-End', relevant: '4.2', },
    { id: 8, name: 'Alex', position: 'Back-End', relevant: '3.5', },
    { id: 9, name: 'Saryman', position: 'Front-End', relevant: '2.6', },
    { id: 10, name: 'Hobbi', position: 'Back-End', relevant: '3.8', },
    { id: 11, name: 'Misha1', position: 'Front-End', relevant: '4.1', },
    { id: 12, name: 'Max2', position: 'Back-End', relevant: '5.1', },
    { id: 13, name: 'Erik3', position: 'Front-End', relevant: '3.1', },
    { id: 14, name: 'Miss4', position: 'Back-End', relevant: '2.1', },
    { id: 15, name: 'Lii5', position: 'Front-End', relevant: '1.1', },
    { id: 16, name: 'Worker6', position: 'Back-End', relevant: '4.5', },
    { id: 17, name: 'Andry7', position: 'Front-End', relevant: '4.2', },
    { id: 18, name: 'Alex8', position: 'Back-End', relevant: '3.5', },
    { id: 19, name: 'Saryman9', position: 'Front-End', relevant: '2.6', },
    { id: 20, name: 'Hobbi10', position: 'Back-End', relevant: '3.8', },
  ];
  workItems = []
  offeredCandidates = [] = []
  placedCandidates = [] = []
  contactedCandidates = [] = []
  userList=[]=[];

  constructor(
    private router: Router,
  ) { }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.getOfferedCandidates()
  }

  getOfferedCandidates() {
    YhCore.searchItems.getOfferedCandidates(suc => {
      this.offeredCandidates = suc
      console.log(this.offeredCandidates)
      this.getContactedCandidates()
    }, err => { });
  }

  getContactedCandidates() {
    YhCore.searchItems.getContactedCandidates(suc => {
      this.contactedCandidates = suc
      console.log(this.contactedCandidates);
      this.getPlacedCandidates();
    }, err => { });
  }

  getPlacedCandidates() {
    YhCore.searchItems.getPlacedCandidates(suc => {
      this.placedCandidates = suc
      console.log(this.placedCandidates)
      this.getList()
    }, err => { })
  }

  getList(){
    YhCore.users.list(suc=>{
      this.userList = suc
      this.userList.map(el=>{
        this.contactedCandidates.map(j=>{
          if(el.userId === j.owner){
            Object.assign(j, {ownerFirstName : el.firstName , ownerLastName: el.lastName})
          }
        })

        this.offeredCandidates.map(j=>{
          if(el.userId === j.owner){
            Object.assign(j, {ownerFirstName : el.firstName , ownerLastName: el.lastName})
          }
        })

        this.placedCandidates.map(j=>{
          if(el.userId === j.owner){
            Object.assign(j, {ownerFirstName : el.firstName , ownerLastName: el.lastName})
          }
        })
      })
    },err=>{})
  }

  selectWorkItem(position: string): void {
    this.workItems = this.candidates.filter(val => val.position === position);
  }

}