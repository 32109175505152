<div class="main-table-wrap">
  <div *ngIf = "!loading" class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between">
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>

      <div>
        <!-- <button style="margin-right: 10px;" mat-flat-button color="primary" *ngIf="this.selection.selected.length">Send Mail ({{this.selection.selected.length}})</button> -->
        <!-- <button style="margin-right: 10px;" mat-flat-button color="primary" *ngIf="this.selection.selected.length" (click)="masterToggle()" >Deselect All</button> -->
        <!-- <button style="margin-right: 10px;" mat-flat-button color="primary" *ngIf="!this.selection.selected.length" (click)="masterToggle()">Select All</button> -->
      </div>

      <mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef style="max-width: 80px;">
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let row" style="max-width: 80px;">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="candidateId">
          <mat-header-cell *matHeaderCellDef mat-sort-header data-label="id">ID</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="id">{{ row["candidateId"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Name">{{ row["firstName"] }}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastName">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Name">{{ row["lastName"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email1">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["email1"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef mat-sort-header>City</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row['city'] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="state">
          <mat-header-cell *matHeaderCellDef mat-sort-header>State</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row['state'] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="owner">
          <mat-header-cell *matHeaderCellDef mat-sort-header>From Partner</mat-header-cell>
          <mat-cell *matCellDef="let row">HuntME</mat-cell>
        </ng-container>

        <ng-container matColumnDef="keySkills">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Key Skills</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <span>
              <span [hidden]="!row['showMoreKeySkills']"> {{ row['keySkills'] }}</span>
              <span [hidden]="row['showMoreKeySkills']"> {{ row['keySkills'] ? row['keySkills'].substring(0, 100) : '' }}</span>

              <span [hidden]="row['keySkills'] ? row['keySkills'].length < 100 : true">
                <button mat-icon-button style="line-height: 0 !important; height: 0 !important;" (click)="row['showMoreKeySkills'] = !row['showMoreKeySkills']">
                  <mat-icon>more_horiz</mat-icon>
                </button>
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["dateCreated"] }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateModified">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Date Modified</mat-header-cell>
          <mat-cell *matCellDef="let row">{{ row["dateModified"] }}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectCandidate(row)" style="cursor: pointer"></mat-row>
      </mat-table>

      <mat-card id="spinner" *ngIf="isLoading">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div><!-- End of Table Wrap Inner-->
  </div>
</div>
