<mat-card class="card">
  <div class="card__front card-front">
    <mat-card-title>Login</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSubmit()">
<!--        <div class="qr-code">-->
<!--          <qrcode [qrdata]="id" [width]="72" [errorCorrectionLevel]="'L'" [scale]="'4'"></qrcode>-->
<!--        </div>-->
        <mat-form-field class="width-100">
          <input matInput placeholder="Username" [formControl]="username" [errorStateMatcher]="matcher" required>
          <mat-error *ngIf="username.hasError('required')">
            Username is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100">
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" [formControl]="password"
            [errorStateMatcher]="matcher" required>
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="password.hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>

        <div class="form-group">
          <button type="submit" class="hor-item" [disabled]="loading" mat-stroked-button>Login</button>
<!--          <button type="button" class="form-group__forgot" mat-flat-button color="primary" (click)="goToSignUp()">Sign Up</button>-->
<!--          <button type="button" class="form-group__forgot" mat-flat-button color="primary" showBackCard>Forgot Password</button>-->
        </div>
      </form>
    </mat-card-content>
  </div>
  <div class="card__back card-back">
    <form [formGroup]="forgotPasswordForm">
      <mat-card-title>
        <div class="card-back__title">
          Forgot Password ?
        </div>
        <div class="card-back__sub-title">
          Don`t worry. Just fill in your email and we`ll send you password
        </div>
      </mat-card-title>
      <mat-form-field class="width-100">
        <mat-label>Email</mat-label>
        <input matInput placeholder="Emial" formControlName="email">
        <mat-error *ngIf="f.email.hasError('required')">
          Email is required
        </mat-error>
      </mat-form-field>

      <button mat-flat-button color="primary" class="form-group__forgot" [disabled]="!forgotPasswordForm.valid" (click)="sendPassword()">Reset Password</button>
      <button mat-flat-button color="primary" type="button" class="form-group__forgot" showFrontCard>Back</button>
    </form>
  </div>
</mat-card>
