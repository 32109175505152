<app-navigation>
    <div class="main-container-no-scroll">
        <mat-card class="main-header">
            <mat-card-subtitle class="mat-card-subtitle">Follow up</mat-card-subtitle>
        </mat-card>
        <mat-card class="main-content">
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab [label]="item.title" *ngFor="let item of followUpList">
                        <app-follow-up-table [data]="item" (deleteFollowUpEvent)="deleteFollowUp($event)"></app-follow-up-table>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>
</app-navigation>