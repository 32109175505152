"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebCalendarController = void 0;
class WebCalendarController {
    constructor(api, calendarService) {
        this.api = api;
        this.calendarService = calendarService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('calendar/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.calendarService.mapCalendarItems(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('calendar/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.calendarService.mapCalendarItem(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('calendar/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    edit(calendar, successCallback, errorCallback) {
        const src = this.calendarService.mapReverseCalendarItem(calendar);
        this.api.postRequest('calendar/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    create(calendar, successCallback, errorCallback) {
        const src = this.calendarService.mapReverseCalendarItem(calendar);
        this.api.postRequest('calendar/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    find(calendar, successCallback, errorCallback) { }
    sort(calendar, customerField, desc, successCallback, errorCallback) { }
}
exports.WebCalendarController = WebCalendarController;
