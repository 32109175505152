"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItemPipeline = void 0;
class WorkItemPipeline {
    constructor() {
        this.filteredBySearchItem = '1';
        this.filteredByOwner = '1';
        this.filteredByStatus = '1';
        this.filteredByCity = '1';
        this.deleting = false;
    }
}
exports.WorkItemPipeline = WorkItemPipeline;
