import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { TemplateType } from '../../app-services/core.service';

@Component({
  selector: 'app-templates-table',
  templateUrl: './templates-table.component.html',
  styleUrls: ['./templates-table.component.scss']
})
export class TemplatesTableComponent {
  public displayedColumns = ['id', 'title', 'subject', 'body', 'dateCreated', 'actions'];

  public dataSource: MatTableDataSource<TemplateType>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: TemplateType[]) {
    this.applyDataSource(data);
  }

  @Output() onRemoveTemplateDialogEvent = new EventEmitter<TemplateType>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: TemplateType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onRemoveTemplateDialog(templateDialog: TemplateType) {
    this.onRemoveTemplateDialogEvent.emit(templateDialog);
    templateDialog.deleting = true;
  }

}
