"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SkillService = void 0;
const types_skills_type_1 = require("../types/types-skills-type");
class SkillService {
    constructor() {
    }
    mapReverseSkill(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        if (src.name)
            result.name = src.name;
        return result;
    }
    mapSkills(raw) {
        return raw.map((val) => this.mapSkill(val));
    }
    mapSkill(raw) {
        const result = new types_skills_type_1.Skills();
        result.id = raw.id;
        result.name = raw.name;
        return result;
    }
}
exports.SkillService = SkillService;
