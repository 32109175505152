import { Component, OnInit, Input } from '@angular/core';
import { Calibrationtype, WorkItemType, YhCore } from 'src/app/app-services/core.service';
import { AppService } from '../../../app-services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-calibration-tab',
  templateUrl: './calibration-tab.component.html',
  styleUrls: ['./calibration-tab.component.scss']
})
export class CalibrationTabComponent implements OnInit {

  current = 0;
  showTabs = false;
  scoringModeOn = true;
  activeTab = 0;
  scoringFinished = false;
  public calibrations: Calibrationtype[]

  @Input() set workItem( data: WorkItemType) {
    if(data) {
      this.getCalibrations(data.joborderId)
    }
  }
  constructor(
    public app:AppService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  getCalibrations(joborderId: string) {
    YhCore.calibrationClient.listByJoborder(joborderId, (calibrations: Calibrationtype[]) => {
      calibrations.map((item: Calibrationtype) => {
        item.experienceDetails = JSON.parse(item.experienceDetails);
        return item;
      });
      this.calibrations = calibrations;
    }, error =>{})
  }


  scoreCandidate(index: number, calibration: Calibrationtype, i: number) {
    this.current = index;
    this.scoringModeOn = false;

    this.nextCandidate(calibration, i, true, index);

  }

  nextCandidate(calibration: Calibrationtype, i: number, isScored: boolean, index?: number) {

    const calib = new Calibrationtype();
    calib.id = calibration.id;
    calib.candidateStatus = 'Reviewed';
    calib.candidateActivity = (isScored) ? index + '' : 'Not marked';

    YhCore.calibrationClient.edit(calib, () => {
      this.current = 0;
      this.scoringModeOn = true;
      this.activeTab = i + 1;
      if (i === this.calibrations.length-1) {this.scoringFinished = true; this.showTabs = false}
    }, error =>{
      this.snackBar.open(error, 'OK', {duration: 7000})
    })
  }

}
