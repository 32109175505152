"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
// Web controllers import
const web_api_controller_1 = require("./controllers/web/web-api-controller");
const web_authentication_controller_1 = require("./controllers/web/web-authentication-controller");
const web_contact_items_controller_1 = require("./controllers/web/web-contact-items-controller");
const web_customers_controller_1 = require("./controllers/web/web-customers-controller");
const web_emails_controller_1 = require("./controllers/web/web-emails-controller");
const web_hashtags_controller_1 = require("./controllers/web/web-hashtags-controller");
const web_search_items_controller_1 = require("./controllers/web/web-search-items-controller");
const web_users_controller_1 = require("./controllers/web/web-users-controller");
const web_work_items_controller_1 = require("./controllers/web/web-work-items-controller");
const web_work_items_statuses_controller_1 = require("./controllers/web/web-work-items-statuses-controller");
const notifications_controller_1 = require("./controllers/web/notifications-controller");
const web_work_items_pipeline_controller_1 = require("./controllers/web/web-work-items-pipeline-controller");
const web_search_item_sharing_controller_1 = require("./controllers/web/web-search-item-sharing-controller");
const web_search_item_activity_controller_1 = require("./controllers/web/web-search-item-activity-controller");
const web_matrix_users_controller_1 = require("./controllers/web/web-matrix-users-controller");
const web_messenger_controller_1 = require("./controllers/web/web-messenger-controller");
const web_templates_controller_1 = require("./controllers/web/web-templates-controller");
const web_reports_controller_1 = require("./controllers/web/web-reports-controller");
const web_share_norms_controller_1 = require("./controllers/web/web-share-norms-controller");
const web_calendar_controller_1 = require("./controllers/web/web-calendar-controller");
const web_follow_up_controller_1 = require("./controllers/web/web-follow-up-controller");
const web_extra_fields_controller_1 = require("./controllers/web/web-extra-fields-controller");
const web_share_norms_salary_calendar_controller_1 = require("./controllers/web/web-share-norms-salary-calendar-controller");
const web_type_controller_1 = require("./controllers/web/web-type-controller");
const web_attachmet_controller_1 = require("./controllers/web/web-attachmet-controller");
const web_fileds_order_config_controller_1 = require("./controllers/web/web-fileds-order-config-controller");
const web_sourcing_controller_1 = require("./controllers/web/web-sourcing-controller");
const web_condition_controller_1 = require("./controllers/web/web-condition-controller");
const web_cv_setting_fied_controller_1 = require("./controllers/web/web-cv-setting-fied-controller");
const web_cv_settings_layout_controller_1 = require("./controllers/web/web-cv-settings-layout-controller");
const web_calibration_controller_1 = require("./controllers/web/web-calibration-controller");
const web_client_authorization_controller_1 = require("./controllers/web/web-client-authorization-controller");
const web_group_users_controller_1 = require("./controllers/web/web-group-users-controller");
const web_joborder_group_controller_1 = require("./controllers/web/web-joborder-group-controller");
// Mobile controllers import
// Service import
const contact_items_service_1 = require("./services/contact-items-service");
const customers_service_1 = require("./services/customers-service");
const emails_service_1 = require("./services/emails-service");
const hashtags_service_1 = require("./services/hashtags-service");
const search_items_service_1 = require("./services/search-items-service");
const users_service_1 = require("./services/users-service");
const work_items_service_1 = require("./services/work-items-service");
const local_storage_service_1 = require("./services/local-storage-service");
const work_items_statuses_service_1 = require("./services/work-items-statuses-service");
const notifications_service_1 = require("./services/notifications-service");
const work_items_pipeline_service_1 = require("./services/work-items-pipeline-service");
const search_items_sharing_service_1 = require("./services/search-items-sharing-service");
const search_item_activity_service_1 = require("./services/search-item-activity-service");
const matrix_users_service_1 = require("./services/matrix-users-service");
const messenger_service_1 = require("./services/messenger-service");
const templates_service_1 = require("./services/templates-service");
const reports_controller_service_1 = require("./services/reports-controller-service");
const share_norms_service_1 = require("./services/share-norms-service");
const calendar_service_1 = require("./services/calendar-service");
const follow_up_service_1 = require("./services/follow-up-service");
const share_norms_salary_calendar_service_1 = require("./services/share-norms-salary-calendar-service");
const skills_service_1 = require("./services/skills-service");
const attachments_service_1 = require("./services/attachments-service");
const condition_service_1 = require("./services/condition-service");
const calibration_service_1 = require("./services/calibration-service");
const cv_settings_field_service_1 = require("./services/cv-settings-field-service");
const cv_settings_layout_service_1 = require("./services/cv-settings-layout-service");
const search_item_history_service_1 = require("./services/search-item-history-service");
const conditions_service_1 = require("./services/conditions-service");
const work_item_notes_service_1 = require("./services/work-item-notes-service");
const extra_fields_service_1 = require("./services/extra-fields-service");
const client_calibration_service_1 = require("./services/client-calibration-service");
const group_users_service_1 = require("./services/group-users-service");
const joborder_group_service_1 = require("./services/joborder-group-service");
class YouHuntedCore {
    constructor() { }
    init(type, apiUrl) {
        switch (type) {
            case 'web':
                this.initDefault(apiUrl, 'web');
                this.type = 'web';
                break;
            case 'mobile':
                this.initDefault(apiUrl, 'mobile');
                this.initMobile(apiUrl);
                this.type = 'mobile';
                break;
            default:
                this.initDefault(apiUrl, 'web');
                this.type = 'web';
                break;
        }
    }
    initDefault(apiUrl, type) {
        this.contactItemsService = new contact_items_service_1.ContactItemsService();
        this.customersService = new customers_service_1.CustomersService();
        this.emailsService = new emails_service_1.EmailsService();
        this.hashtagsService = new hashtags_service_1.HashtagsService();
        this.extraFieldService = new extra_fields_service_1.ExtraFieldsService();
        this.searchItemsService = new search_items_service_1.SearchItemsService(this.extraFieldService);
        this.usersService = new users_service_1.UsersService();
        this.workItemsService = new work_items_service_1.WorkItemsService();
        this.localStorageService = new local_storage_service_1.LocalStorageService(type);
        this.workItemsStatusesService = new work_items_statuses_service_1.WorkItemsStatusesService();
        this.notificationService = new notifications_service_1.NotificationService();
        this.workItemPipelineService = new work_items_pipeline_service_1.WorkItemPipelineService();
        this.searchItemsSharingService = new search_items_sharing_service_1.SearchItemsSharingService();
        this.searchItemActivitesService = new search_item_activity_service_1.ActivityService();
        this.matrixUsersService = new matrix_users_service_1.MatrixUsersService();
        this.messengerService = new messenger_service_1.MessengerService();
        this.templatesService = new templates_service_1.TemplatesService();
        this.reportsService = new reports_controller_service_1.ReportsService();
        this.shareNormsService = new share_norms_service_1.ShareNormsService();
        this.calendarService = new calendar_service_1.CalendarService();
        this.followUpService = new follow_up_service_1.FollowUpService();
        this.extraFieldsService = new extra_fields_service_1.ExtraFieldsService();
        this.searchItemsService = new search_items_service_1.SearchItemsService(this.extraFieldsService);
        this.shareNormsSalaryCalendarService = new share_norms_salary_calendar_service_1.ShareNormsSallaryCallendarService();
        this.skillService = new skills_service_1.SkillService();
        this.attachmetService = new attachments_service_1.AttachmentService();
        this.conditionService = new condition_service_1.ConditionService();
        this.calibrationService = new calibration_service_1.CalibrationService();
        this.cvSettingsFieldService = new cv_settings_field_service_1.CvSettingsFieldService();
        this.cvSettingsLayoutService = new cv_settings_layout_service_1.CvSettingsLayoutService();
        this.historyServise = new search_item_history_service_1.HistoryServise();
        this.conditionsService = new conditions_service_1.ConditionsService();
        this.workItemNotesService = new work_item_notes_service_1.WorkItemNotesService();
        this.calibrationsService = new client_calibration_service_1.CalibrationsService();
        this.groupUserService = new group_users_service_1.GroupUsersService();
        this.joborderGroupService = new joborder_group_service_1.JoborderGroupService();
        this.api = new web_api_controller_1.WebApiController(apiUrl, this.localStorageService);
        this.authentication = new web_authentication_controller_1.WebAuthenticationController(this.usersService, this.api);
        this.contactItems = new web_contact_items_controller_1.WebContactItemsController(this.api, this.contactItemsService);
        this.customers = new web_customers_controller_1.WebCustomersController(this.api, this.customersService, this.workItemsService);
        this.emails = new web_emails_controller_1.WebEmailsController(this.api, this.emailsService);
        this.hashtags = new web_hashtags_controller_1.WebHashtagsController(this.api, this.hashtagsService);
        this.searchItems = new web_search_items_controller_1.WebSearchItemsController(this.api, this.searchItemsService, this.contactItemsService, this.historyServise);
        this.users = new web_users_controller_1.WebUsersController(this.api, this.usersService, this.localStorageService);
        this.workItems = new web_work_items_controller_1.WebWorkItemsController(this.api, this.workItemsService, this.customersService, this.conditionsService, this.workItemNotesService);
        this.workItemsStatuses = new web_work_items_statuses_controller_1.WebWorkItemsStatusesController(this.api, this.workItemsStatusesService);
        this.notifications = new notifications_controller_1.NotificationsController(this.api, this.notificationService);
        this.workItemsPipeline = new web_work_items_pipeline_controller_1.WebWorkItemsPipelineController(this.api, this.workItemPipelineService);
        this.searchItemSharing = new web_search_item_sharing_controller_1.WebSearchItemsSharingController(this.api, this.searchItemsSharingService);
        this.searchItemActivites = new web_search_item_activity_controller_1.WebSearchItemsActivityController(this.api, this.searchItemActivitesService);
        this.matrixUsers = new web_matrix_users_controller_1.WebMatrixUsersController(this.api, this.matrixUsersService);
        this.searchItemActivites = new web_search_item_activity_controller_1.WebSearchItemsActivityController(this.api, this.searchItemActivitesService);
        this.messenger = new web_messenger_controller_1.WebMessengerController(this.api, this.messengerService);
        this.templates = new web_templates_controller_1.WebTemplateController(this.api, this.templatesService);
        this.reports = new web_reports_controller_1.WebReportsController(this.api, this.reportsService);
        this.shareNorms = new web_share_norms_controller_1.WebShareNormsController(this.api, this.shareNormsService);
        this.calendar = new web_calendar_controller_1.WebCalendarController(this.api, this.calendarService);
        this.followUp = new web_follow_up_controller_1.WebFollowUpsController(this.api, this.followUpService);
        this.extraFields = new web_extra_fields_controller_1.ExtraFieldsController(this.api, this.extraFieldsService);
        this.shareNormsSalaryCalendar = new web_share_norms_salary_calendar_controller_1.WebShareNormSalaryCalendarController(this.api, this.shareNormsSalaryCalendarService);
        this.types = new web_type_controller_1.WebTypeController(this.api, this.skillService);
        this.attachment = new web_attachmet_controller_1.WebAttachmentController(this.api, this.attachmetService);
        this.fieldsOrder = new web_fileds_order_config_controller_1.FieldsOrderController(this.api, this.extraFieldsService);
        this.sourcing = new web_sourcing_controller_1.WebSourcingController(this.api);
        this.fieldsOrder = new web_fileds_order_config_controller_1.FieldsOrderController(this.api, this.extraFieldsService);
        this.condition = new web_condition_controller_1.WebConditionController(this.api, this.conditionService);
        this.cvSettingsField = new web_cv_setting_fied_controller_1.WebCvSettingController(this.api, this.cvSettingsFieldService);
        this.cvSettingsLayout = new web_cv_settings_layout_controller_1.WebCvSettingLayoutController(this.api, this.cvSettingsLayoutService);
        this.calibrationClient = new web_calibration_controller_1.WebCalibrationController(this.api, this.calibrationsService);
        this.clientAuthorization = new web_client_authorization_controller_1.WebClientAuthorizationController(this.api);
        this.groupUsers = new web_group_users_controller_1.WebGroupUsersController(this.api, this.groupUserService);
        this.joborderGroup = new web_joborder_group_controller_1.WebJoborderGroupController(this.api, this.joborderGroupService);
    }
    initMobile(apiUrl) { }
}
const yh = new YouHuntedCore();
exports.default = yh;
