import { Component, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, refCount, publishReplay } from 'rxjs/operators';
import { YhCore, SearchItemType, HashtagType, UserType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';

@Component({
  selector: 'app-hashtag-search-items',
  templateUrl: './hashtag-search-items.component.html',
  styleUrls: ['./hashtag-search-items.component.scss']
})
export class HashtagSearchItemsComponent implements AfterViewInit {

  searchItems: SearchItemType[] = [];
  tableReady = false;
  currentHashtag = new HashtagType();
  users: UserType[] = [];
  relations: HashtagType[] = [];
  mainTableLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private app: AppService
  ) { }

  ngAfterViewInit(): void {
    const tuple = this.route.paramMap.pipe(
      map(params => {
        return params.get('id');
      }), publishReplay(1), refCount()
    );

    tuple.subscribe(async currentHashtagId => {
      // Getting Current Hashtag
      this.currentHashtag = await this.getCurrentHashtag(currentHashtagId);
      // Getting list of Hastag-SearchItem Relations
      this.relations = await this.getRelations(currentHashtagId);
    })
  }

  getCurrentHashtag(id: string): Promise<HashtagType> {
    return new Promise<HashtagType>(resolve => {
      YhCore.hashtags.read(+id, (currentHashtag: HashtagType) => {
        resolve(currentHashtag)
      }, err => {})
    })
  }

  getUsers(): Promise<UserType[]> {
    return new Promise<UserType[]>(resolve => {
      YhCore.users.list(users => {
        resolve(users);
      }, err => {})
    })
  }


  getRelations(id: string): Promise<HashtagType[]> {
    return new Promise<HashtagType[]>(resolve => {
      YhCore.hashtags.searchItemsByHashtag(+id, relations => {
        if (relations.length === 0) {
          this.searchItems = []
          this.tableReady = true;
          this.mainTableLoading = false;
          return;
        }
        this.mapRelations(relations);
        resolve(relations)
      }, err => {})
    })
  }

  // Preparing data to display in table
  async mapRelations(relations: HashtagType[]) {
    this.searchItems = [];
    this.users = await this.getUsers();
    relations.map((relation: HashtagType) => {
      YhCore.searchItems.read(relation.dataItemId, (searchItem:SearchItemType) => {
        searchItem.addedToList = relation.dateCreated;
        this.users.map(user => {
          if (user.userId === searchItem.enteredBy) {
            searchItem.ownerName = `${user.firstName} ${user.lastName}`
          }
        })
        this.searchItems = [...this.searchItems, searchItem];
        this.mainTableLoading = false;
        this.tableReady = true;
      }, error => {
        // User has no access to this search item
        this.searchItems = [...this.searchItems];
        this.mainTableLoading = false;
        this.tableReady = true;
      })
    })
  }

  removeRelation(searchItem: SearchItemType) {
      this.relations.map(relation => {
        if (relation.dataItemId === searchItem.candidateId) {
          YhCore.hashtags.deleteRelation(+relation.savedListEntryId, async success => {
            this.relations = await this.getRelations(this.currentHashtag.savedListId);
            this.mainTableLoading = false;
          }, error => {})
        }
      })
  }

  loading(event) {
    this.mainTableLoading = event;
  }

  navigateToCandidate(row: SearchItemType) {
    this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), row.candidateId]).then();
  }
}
