<app-navigation>
  <mat-card>
    <mat-card-subtitle>
      Add {{ app.type.names['workItem'] }}
    </mat-card-subtitle>
    <mat-card-content>
      <div>
        <form [formGroup]="addJoborderForm" (submit)="onSubmit()">
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemItemNames.title  }}</mat-label>
            <input matInput formControlName="title" placeholder="Job order title" required>
            <mat-error *ngIf="addJoborderForm.controls['title'].errors">Field {{ app.type.workItemItemNames.title  }} is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemItemNames.description }} </mat-label>
            <input matInput formControlName="description" placeholder="Job order description" required>
            <mat-error *ngIf="addJoborderForm.controls['description'].errors">Field {{ app.type.workItemItemNames.description }} is required
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemNotesNames.positionLocation }}</mat-label>
            <mat-select formControlName="positionLocation" required>
              <mat-option value="remotePosition">Remote position</mat-option>
              <mat-option value="side">On side</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemNotesNames.testPeriod }}</mat-label>
            <mat-select [(ngModel)]="jobOrderNotes.testPeriod" [ngModelOptions]="{standalone: true}">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="jobOrderNotes.testPeriod === 'yes'">
            <input matInput formControlName="testPeriodInfo" placeholder="More information">
          </mat-form-field>

          <div class="mobile-input-50">
            <mat-form-field appearance="outline" (change)="sameMode()">
              <mat-label>{{ app.type.workItemNotesNames.payment }} From</mat-label>
              <input matInput formControlName="paymentFrom" placeholder="From" required>
              <mat-error *ngIf="addJoborderForm.controls['paymentFrom'].errors">Field is required
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" (change)="sameMode()">
              <mat-label>{{ app.type.workItemNotesNames.payment }} To</mat-label>
              <input matInput formControlName="paymentTo" placeholder="To" required>
              <mat-error *ngIf="addJoborderForm.controls['paymentTo'].errors">Field is required
              </mat-error>
            </mat-form-field>
          </div>

          <mat-checkbox formControlName="paymentSame" style="display: block; margin-bottom: 1.4em;"
            (change)="editSameFoo()" *ngIf="jobOrderNotes.testPeriod === 'yes'">Same</mat-checkbox>

          <div class="mobile-input-50" *ngIf="jobOrderNotes.testPeriod === 'yes'">
            <mat-form-field appearance="outline" *ngIf="!editSame">
              <mat-label>{{ app.type.workItemItemNames.workItemNotesNames }} From: </mat-label>
              <input matInput formControlName="paymentProbationPeriodFrom" placeholder="From">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="editSame">
              <mat-label>{{ app.type.workItemItemNames.workItemNotesNames }} From: </mat-label>
              <input matInput [(ngModel)]="samePaymentFrom" placeholder="From" [ngModelOptions]="{standalone: true}">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="!editSame">
              <mat-label>{{ app.type.workItemItemNames.workItemNotesNames }} To:</mat-label>
              <input matInput formControlName="paymentProbationPeriodTo" placeholder="To">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="editSame">
              <mat-label>{{ app.type.workItemItemNames.workItemNotesNames }} To:</mat-label>
              <input matInput [(ngModel)]="samePaymentTo" placeholder="To" [ngModelOptions]="{standalone: true}">
            </mat-form-field>
          </div>

          <mat-checkbox formControlName="paymentBonuses" style="display: block; margin-bottom: 1.4em;"> Bonuses
          </mat-checkbox>

          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemNotesNames.workingDay }}</mat-label>
            <mat-select formControlName="workingDay">
              <mat-option value="freeSchedule">Free schedule</mat-option>
              <mat-option value="partTime">Part time</mat-option>
              <mat-option value="fullDay">Full day</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.workItemNotesNames.socialBenefits }}</mat-label>
            <mat-select [(ngModel)]="jobOrderNotes.socialBenefits" [ngModelOptions]="{standalone: true}">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="jobOrderNotes.socialBenefits === 'yes'">
            <input matInput formControlName="socialBenefitsList" placeholder="List of social benefits">
          </mat-form-field>

<!--          <div class="flex flex-align-start">-->
<!--            <mat-form-field appearance="outline">-->
<!--              <mat-label>Select group</mat-label>-->
<!--              <mat-select [formControl]="joborderGroupControll">-->
<!--                <mat-option *ngFor="let item of joborderGroups" [value]="item.id">{{item.title}}</mat-option>-->
<!--              </mat-select>-->
<!--            </mat-form-field>-->
<!--            <button mat-flat-button color="primary" *ngIf="currentUser.accessLevel >= '400'" (click)="openDialogAddJoborderGroup($event)" class="add-group-button">ADD NEW GROUP</button>-->
<!--          </div>-->

          <div class="mb-14">
            Additional Information
            <button mat-flat-button color="primary" *ngIf="showAdditionalInfo"
              (click)="showAdditionalInfoFoo()">SHOW</button>
            <button mat-flat-button color="primary" *ngIf="!showAdditionalInfo"
              (click)="showAdditionalInfoFoo()">HIDE</button>
          </div>

          <div *ngIf="!showAdditionalInfo">
            <div style="text-align: center;" class="mb-14">
              {{ app.type.workItemNotesNames.workConditions.toUpperCase() }}
            </div>
            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.contract }} </mat-label>
              <mat-select formControlName="contract">
                <mat-option value="temporary">Temporary</mat-option>
                <mat-option value="permanent">Permanent</mat-option>
              </mat-select>
            </mat-form-field>


            <div class="mobile-input-33">
              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.workScheduleName  +  app.type.workItemNotesNames.workScheduleObject.startEndWorkingDay }} </mat-label>
                <input matInput formControlName="startEndWorkingDay"
                  placeholder="Start and End of working day (E.g. Sun / Sat)">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.workScheduleName   +  app.type.workItemNotesNames.workScheduleObject.lunchBreak }} </mat-label>
                <input matInput formControlName="lunchBreak" placeholder="Lunch break (E.g. 12:00-13:00)">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.workScheduleName   +  app.type.workItemNotesNames.workScheduleObject.freeDays }} </mat-label>
                <input matInput formControlName="freeDays" placeholder="Free days (E.g. Sun / Sat)">
              </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.businessTrip }}</mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.businessTrip" [ngModelOptions]="{standalone: true}">
                <mat-option value="noTrip">No trip</mat-option>
                <mat-option value="abroad">Abroad</mat-option>
                <mat-option value="withinUkr">Within Ukraine </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.businessTrip !== 'noTrip'">
              <input matInput formControlName="frequencyOfTrips" placeholder="A frequency of trips">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.subordinates }}</mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.subordinates" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.subordinates === 'yes'">
              <input matInput formControlName="subordinatesAmount" placeholder="Amount employees">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.growth }} </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.growth" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.growth === 'yes'">
              <input matInput formControlName="growthPossiblePosition" placeholder="Possible position">
            </mat-form-field>

            <div class="mobile-input-33">
              <mat-form-field appearance="outline">
                <mat-label>  {{ app.type.workItemNotesNames.directManagerName   +  app.type.workItemNotesNames.directManagerObject.name }}</mat-label>
                <input matInput formControlName="name" placeholder="Name">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.directManagerName   +  app.type.workItemNotesNames.directManagerObject.position }}</mat-label>
                <input matInput formControlName="position" placeholder="Position">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.directManagerName   +  app.type.workItemNotesNames.directManagerObject.phone }}</mat-label>
                <input matInput formControlName="phone" placeholder="Contact phone">
              </mat-form-field>
            </div>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.guarantees}} </mat-label>
              <input matInput formControlName="guarantees" placeholder="Vacation (calendar days)">
            </mat-form-field>
            <div style="text-align: center;" class="mb-14">
              {{ app.type.workItemNotesNames.requirementsFromCandidatesName.toUpperCase()}}
            </div>

            <div class="mb-14 mobile-input-50 ">
              <mat-form-field appearance="outline">
                <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.age}} From</mat-label>
                <input matInput formControlName="ageFrom" placeholder="From" [readonly]="ageNotImportant">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.age}} To </mat-label>
                <input matInput formControlName="ageTo" placeholder="To" [readonly]="ageNotImportant">
              </mat-form-field>
              <mat-checkbox [(ngModel)]="ageNotImportant" [ngModelOptions]="{standalone: true}">Not impotrtant
              </mat-checkbox>
            </div>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.cadidateSex}}</mat-label>
              <mat-select formControlName="cadidateSex">
                <mat-option value="doesNotMatter">Does not matter</mat-option>
                <mat-option value="male">Male</mat-option>
                <mat-option value="female">Female</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.candidateEducation}}</mat-label>
              <input matInput formControlName="candidateEducation" placeholder="To" [readonly]="educationNotImportant">
            </mat-form-field>
            <mat-checkbox [(ngModel)]="educationNotImportant" [ngModelOptions]="{standalone: true}" class="mb-14"
              style="display: block;">Not impotrtant
            </mat-checkbox>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.candidateDesiredDegree}} </mat-label>
              <input matInput formControlName="candidateDesiredDegree" placeholder="To"
                [readonly]="desiredNotImportant">
            </mat-form-field>
            <mat-checkbox [(ngModel)]="desiredNotImportant" [ngModelOptions]="{standalone: true}" class="mb-14"
              style="display: block;">Not impotrtant
            </mat-checkbox>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.combineWorkStudying}}</mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.combineWorkStudying" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.combineWorkStudying === 'yes'">
              <input matInput formControlName="combineWorkStudyingInfo" placeholder="More information">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.mainResponsibilities}}              </mat-label>
              <input matInput formControlName="mainResponsibilities">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.desireWorkExp}}    </mat-label>
              <input matInput formControlName="desireWorkExp">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.requiredSkills}}  </mat-label>
              <input matInput formControlName="requiredSkills">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.additionalSkills}}               </mat-label>
              <input matInput formControlName="additionalSkills">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.managementExp}}         </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.managementExp" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.managementExp === 'yes'">
              <input matInput formControlName="managementExpInfo" placeholder="More information">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.foreignLanguages}}                 </mat-label>
              <input matInput formControlName="foreignLanguages">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.personalCharacteristic}}                </mat-label>
              <input matInput formControlName="personalCharacteristic">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.requirementsFromCandidatesObject.residence}}             </mat-label>
              <mat-select formControlName="residence">
                <mat-option value="noMatter">Does not matter</mat-option>
                <mat-option value="cityCompanyLocation">City of company location</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>                {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.driverLicense}}                   </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.driverLicense" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.driverLicense === 'yes'">
              <input matInput formControlName="driverLicenseType" placeholder="Type">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.ownVehicle}} </mat-label>
              <mat-select formControlName="ownVehicle">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.requirementsFromCandidatesObject.maritalStatusKids}} </mat-label>
              <input matInput formControlName="maritalStatusKids" [readonly]="kidsNotImportant">
            </mat-form-field>
            <mat-checkbox [(ngModel)]="kidsNotImportant" [ngModelOptions]="{standalone: true}" class="mb-14"
              style="display: block;">Not impotrtant
            </mat-checkbox>
            <div style="text-align: center;" class="mb-14">
              {{ app.type.workItemNotesNames.informationForExecutantName.toUpperCase() }}
            </div>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.informationForExecutantObject.dateOpeningPosition}}   </mat-label>
              <input matInput [matDatepicker]="date1" formControlName="dateOpeningPosition">
              <mat-datepicker-toggle matSuffix [for]="date1"></mat-datepicker-toggle>
              <mat-datepicker #date1 disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> {{ app.type.workItemNotesNames.informationForExecutantObject.durationRequestExecution}}   </mat-label>
              <input matInput [matDatepicker]="date2" formControlName="durationRequestExecution">
              <mat-datepicker-toggle matSuffix [for]="date2"></mat-datepicker-toggle>
              <mat-datepicker #date2 disabled="false"></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.enhancedPrivacy}} </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.enhancedPrivacy" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.enhancedPrivacy === 'yes'">
              <input matInput formControlName="enhancedPrivacyInfo" placeholder="More information">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.reasonPositionOpening}}</mat-label>
              <textarea matInput formControlName="reasonPositionOpening" placeholder="Expansion"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.necessarilyQuestion}} </mat-label>
              <textarea matInput formControlName="necessarilyQuestion"></textarea>
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.interviver}}</mat-label>
              <input matInput formControlName="interviver" placeholder="Skype interview, HR + Technical Lead">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.finalDecisionHiring}} </mat-label>
              <input matInput formControlName="finalDecisionHiring" placeholder="HR">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.interviewStages}}  </mat-label>
              <input matInput formControlName="interviewStages" placeholder="1 or 2 interviews and maybe a task">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.particularPeopleHire}}</mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.particularPeopleHire" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.particularPeopleHire === 'yes'">
              <input matInput formControlName="particularPeopleHireInfo" placeholder="More information">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.amountInterviewInScope}} </mat-label>
              <input matInput formControlName="amountInterviewInScope">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.otherRecruiterAgencies}}  </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.otherRecruiterAgencies" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.otherRecruiterAgencies === 'yes'">
              <input matInput formControlName="nameOtherRecruiterAgencies"
                placeholder="List of other recruiting agencies">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.someJobAds}} </mat-label>
              <mat-select [(ngModel)]="jobOrderNotes.someJobAds" [ngModelOptions]="{standalone: true}">
                <mat-option value="yes">Yes</mat-option>
                <mat-option value="no">No</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="jobOrderNotes.someJobAds === 'yes'">
              <input matInput formControlName="whereSomeJobAds" placeholder="Where?">
            </mat-form-field>


            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.publicLinkJobDescription}}              </mat-label>
              <input matInput formControlName="publicLinkJobDescription">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.shedleInterviewing}}                 </mat-label>
              <input matInput formControlName="shedleInterviewing">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.numberPeopleForHire}}                    </mat-label>
              <input matInput formControlName="numberPeopleForHire">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.additionDesires}}                         </mat-label>
              <input matInput formControlName="additionDesires">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemNotesNames.informationForExecutantObject.requiredDocForCandidate}}                  </mat-label>
              <input matInput formControlName="requiredDocForCandidate">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>{{ app.type.workItemItemNames.type }} </mat-label>
              <mat-select formControlName="type">
                <mat-option value="H">H (Hire)</mat-option>
                <mat-option value="C2H">C2H (Contract to Hire)</mat-option>
                <mat-option value="C">C (Contract)</mat-option>
                <mat-option value="FL">FL (Freelance)</mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="addJoborderForm.controls.positionLocation.value === 'side'">
              <mat-form-field appearance="outline">
                <mat-label> {{ app.type.workItemNotesNames.relocationHelp}}</mat-label>
                <mat-select [(ngModel)]="jobOrderNotes.relocationHelp" [ngModelOptions]="{standalone: true}">
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" *ngIf="jobOrderNotes.relocationHelp === 'yes'">
                <input matInput formControlName="relocationHelpInfo" placeholder="More information">
              </mat-form-field>

              <mat-form-field appearance="outline">
                <mat-label>{{ app.type.workItemNotesNames.positionOpenInCountry}}
                </mat-label>
                <input matInput formControlName="state">
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>{{ app.type.workItemNotesNames.positionOpenInCity}}
                </mat-label>
                <input matInput formControlName="city">
              </mat-form-field>
            </div>

          </div>
          <button mat-flat-button color="primary">ADD JOBORDER</button>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</app-navigation>
