import { YhCore } from 'src/app/app-services/core.service';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-follow-up-table',
  templateUrl: './follow-up-table.component.html',
  styleUrls: ['./follow-up-table.component.scss']
})
export class FollowUpTableComponent {
  public displayedColumns = [];
  public dataSource: MatTableDataSource<any> = new MatTableDataSource();
  private paginator: MatPaginator;
  private sort: MatSort;
  dataId: string;

  @Input() public set data(data: any[]) {
    this.applyDataSource(data);
  }

  @Output() deleteFollowUpEvent = new EventEmitter<any>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    if(!this.dataSource) {return}
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: any) {
    this.dataId = data.id;
    YhCore.followUp.getFollowUpData(+data.id, suc => {
      this.dataSource = new MatTableDataSource(suc);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      data.select = data.select.split(',');
      const b = [];
      for (let item of data.select) {
        item = item.split('.')
        item.shift();
        for (const j of item) {
          item = j.split('_');
        }
        const firstItem = item[0];
        item.shift();
        let lastItems = '';
        for (const j of item) {
          lastItems += j.charAt(0).toUpperCase() + j.substr(1);
        }
        b.push(firstItem + lastItems);
      }
      data.select = b;

      for (const item in this.dataSource.filteredData[0]) {
        if(item){
        for (const j of  data.select) {
          if (item===j) {
            this.displayedColumns.push(item);
            }
          }
        }
      }
      this.applyDataSourceAttributes();
    }, err => { })
  }

  deleteFollowUp() {
    this.deleteFollowUpEvent.emit(this.dataId);
  }
}

