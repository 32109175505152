"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalibrationsService = void 0;
const client_calibration_1 = require("../types/client-calibration");
class CalibrationsService {
    constructor() { }
    mapReverceCalibrations(raw) {
        let result = {};
        if (raw.id)
            result.id = raw.id;
        if (raw.joborderId)
            result.joborder_id = raw.joborderId;
        if (raw.joborderTitle)
            result.joborder_title = raw.joborderTitle;
        if (raw.companyId)
            result.company_id = raw.companyId;
        if (raw.experience)
            result.company_name = raw.experience;
        if (raw.exchangerId)
            result.exchanger_id = raw.exchangerId;
        if (raw.experienceDetails)
            result.exchanger_name = raw.experienceDetails;
        if (raw.candidateId)
            result.candidate_id = raw.candidateId;
        if (raw.sharingType)
            result.sharing_type = raw.sharingType;
        if (raw.sharingTime)
            result.sharing_time = raw.sharingTime;
        if (raw.calibrationId)
            result.candidate_id_er = raw.calibrationId;
        if (raw.candidateActivity)
            result.candidate_activity = raw.candidateActivity;
        if (raw.candidateStatus)
            result.candidate_status = raw.candidateStatus;
        return result;
    }
    mapCalibrations(raw) {
        return raw.map((val) => this.mapCalibration(val));
    }
    mapCalibration(raw) {
        const result = new client_calibration_1.Calibration();
        result.id = raw.id;
        result.joborderId = raw.joborder_id;
        result.joborderTitle = raw.joborder_title;
        result.companyId = raw.company_id;
        result.experience = raw.company_name;
        result.exchangerId = raw.exchanger_id;
        result.experienceDetails = raw.exchanger_name;
        result.candidateId = raw.candidate_id;
        result.sharingType = raw.sharing_type;
        result.sharingTime = raw.sharing_time;
        result.calibrationId = raw.candidate_id_er;
        result.candidateActivity = raw.candidate_activity;
        result.candidateStatus = raw.candidate_status;
        return result;
    }
}
exports.CalibrationsService = CalibrationsService;
