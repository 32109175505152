"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSourcingController = void 0;
class WebSourcingController {
    constructor(api) {
        this.api = api;
    }
    searchSearchItem(workItemId, skills, searchSize, searchItemIds, successCallback, errorCallback) {
        this.api.postRequest('sourcing/searchSearchItem', {
            work_item_id: workItemId,
            skills: skills,
            search_size: searchSize,
            search_item_ids: searchItemIds
        }, response => { successCallback(response); }, error => { errorCallback(error); });
    }
    addSearchItem(searchItemId, successCallback, errorCallback) {
        this.api.postRequest('sourcing/addSearchItem', {
            search_item_id: searchItemId
        }, response => { successCallback(response); }, error => { errorCallback(error); });
    }
}
exports.WebSourcingController = WebSourcingController;
