"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HistoryServise = void 0;
const search_item_history_type_1 = require("../types/search-item-history-type");
class HistoryServise {
    constructor() { }
    mapReverseHistory(src) {
        const result = {};
        if (src.dataItemId)
            result.data_item_id = src.dataItemId;
        if (src.dataItemType)
            result.data_item_type = src.dataItemType;
        if (src.description)
            result.description = src.description;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        if (src.historyId)
            result.history_id = src.historyId;
        if (src.newValue)
            result.new_value = src.newValue;
        if (src.previousValue)
            result.previous_value = src.previousValue;
        if (src.setDate)
            result.set_date = src.setDate;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.theField)
            result.the_field = src.theField;
        return result;
    }
    mapHistories(raw) {
        return raw.map((val) => this.mapHistory(val.history));
    }
    mapHistory(raw) {
        const result = new search_item_history_type_1.SearchItemHistory();
        result.dataItemId = raw.data_item_id;
        result.dataItemType = raw.data_item_type;
        result.description = raw.description;
        result.enteredBy = raw.entered_by;
        result.historyId = raw.history_id;
        result.newValue = raw.new_value;
        result.previousValue = raw.previous_value;
        result.setDate = raw.set_date;
        result.siteId = raw.site_id;
        result.theField = raw.the_field;
        return result;
    }
}
exports.HistoryServise = HistoryServise;
