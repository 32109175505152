<app-navigation>
  <mat-tab-group>
    <mat-tab label="Main">
      <mat-card *ngIf="candidate" class="search-item-wrap">
        <mat-card-content>
          <div class="header flex xs-column md-row width-100 flex-end p-xs-x-2" *ngIf="candidate">
            <div class="avatar-name-container flex flex-start flex-wrap" style="align-items: center;">
              <div class="avatar">
                <img class="img-responsive" [src] ="searchItemImgURL">
              </div>
              <div class="search-item-info">
                <div class="search-item-name" [class.non-active]="editMode">
                  <h2>{{candidate.lastName}}</h2>
                </div>
<!--                <div class="search-item-qrcode">-->
<!--                  <button mat-icon-button (click)="showPreviewCode(qrcode)"><mat-icon [inline]="true">qr_code_scanner</mat-icon></button>-->
<!--                  <ng-template #qrcode>-->
<!--                    <div id="qrcode">-->
<!--                      <qrcode [qrdata]="qrData" [width]="72" [errorCorrectionLevel]="'L'" [scale]="4"></qrcode>-->
<!--                    </div>-->
<!--                  </ng-template>-->
<!--                </div>-->
              </div>
            </div> <!--End of avatar-name-container-->
            <div class="buttons-wrapper flex flex-xs-end xs-order-0 md-order-2 width-xs-100 width-md-auto flex-align-center">
<!--              <button *ngIf="+candidate.isActive === 0" mat-icon-button color="primary" (click)="onShareCandidateFull()" matTooltip="Share Candidate Full"><mat-icon>share</mat-icon></button>-->
<!--              <button mat-icon-button color="primary" *ngIf="!editMode" (click)="beginEdit()" matTooltip="Edit"><mat-icon>edit</mat-icon></button>-->
<!--              <button mat-icon-button color="primary" *ngIf="editMode" (click)="saveEdit()" matTooltip="Save" ><mat-icon>save</mat-icon></button>-->
<!--              <button mat-icon-button color="warn" (click)="onDeleteCandidate()" matTooltip="Delete"><mat-icon>delete_forever</mat-icon></button>-->
            </div>
          </div>

          <mat-divider></mat-divider>

          <div style="padding-top: 30px;" class="width-100 flex sm-row xs-column margin-t-1 flex-between">
            <div class="fields">
              <ng-container *ngFor="let fields of fieldsArray">
                <ng-container *ngFor="let field of fields">
                  <div class="width-xs-100 width-md-50">
                    <ng-container [ngTemplateOutlet]="candidateField" [ngTemplateOutletContext]="{field:field}"></ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
            <div class="width-xs-100 width-lg-33">
              <!-- Extra Fileds -->
              <div class="extra-fields-wrap">
                <div class="extra-fields" *ngFor="let extraField of candidate.extraFields">
                    <!--Text Box Extra Field-->
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!editMode" *ngIf="extraField.extraFieldType === '1'">
                      <mat-label>{{extraField.fieldName}}: </mat-label>
                      <input matInput [(ngModel)]="extraField.extraFieldValue" [readonly]="!editMode" *ngIf="!extraField.extraFieldValue || extraField.extraFieldValue.length <= 40">
                      <textarea matInput [(ngModel)]="extraField.extraFieldValue" [readonly]="!editMode" rows = '6' *ngIf="extraField.extraFieldValue && extraField.extraFieldValue.length > 40"></textarea>
                    </mat-form-field>
                    <!--CheckBox Extra Field-->
                    <div class="field" *ngIf="extraField.extraFieldType === '3'">
                      <mat-checkbox (change)="extraField.extraFieldValue = !extraField.extraFieldValue" [checked]="extraField.extraFieldValue" [disabled]="!editMode">
                        {{extraField.fieldName}}
                      </mat-checkbox>
                    </div>
                    <!--Dropdown Extra Field-->
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!editMode" *ngIf="extraField.extraFieldType === '5'">
                      <mat-label>{{extraField.fieldName}}:</mat-label>
                      <mat-select [(ngModel)]="extraField.extraFieldValue" [disabled]="!editMode">
                        <mat-option *ngFor="let extraFieldOption of extraField.extraFieldOptionsArray" [value]="extraFieldOption">
                          {{extraFieldOption}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <!--Radio Buttons Extra Field-->
                    <div class="field" *ngIf="extraField.extraFieldType === '6'">
                      <mat-radio-group class="radio-button-extra-filed" [(ngModel)]="extraField.extraFieldValue" aria-label="extraField.fieldName" [disabled]="!editMode">
                        <mat-label>{{extraField.fieldName}}:</mat-label>
                        <mat-radio-button *ngFor="let extraFieldOption of extraField.extraFieldOptionsArray" [value]="extraFieldOption">{{extraFieldOption}}</mat-radio-button>
                      </mat-radio-group>
                    </div>
                    <!--Date Extra Field-->
                    <mat-form-field appearance="outline" floatLabel="always" *ngIf="extraField.extraFieldType === '4'" [class.non-active]="!editMode">
                      <mat-label>{{extraField.fieldName}}:</mat-label>
                      <input matInput [matDatepicker]="picker" [(ngModel)]="extraField.extraFieldValue" [readonly]="!editMode">
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                  </div>
              </div> <!-- End of Extra Fileds -->
            </div> <!--End  of 3rd column-->
          </div>
        </mat-card-content>
      </mat-card>
    </mat-tab>
    <mat-tab label="Attachments">
      <app-attachments-tab [data]="candidate"></app-attachments-tab>
    </mat-tab>
  </mat-tab-group>
</app-navigation>


<ng-template #candidateField let-field='field'>
  <div [ngSwitch]="field[0]">
    <div *ngSwitchCase="'canRelocate'">
      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!editMode">
        <mat-label>{{ app.type.searchItemNames[field[0]] }}: </mat-label>
        <input [hidden]="editMode" matInput  value="{{candidate[field[0]] === '0' ? 'No': 'Yes'}}" [readonly]="!editMode">
        <mat-checkbox *ngIf="editMode" [checked]="candidate[field[0]] === '0' ? false : true" (change)="candidate[field[0]] = candidate[field[0]] === '0' ? '1': '0'"></mat-checkbox>
      </mat-form-field>
    </div>
    <div *ngSwitchCase="'notes' || 'dialog'">
      <mat-form-field appearance="outline" floatLabel="always" floatLabel="always" [class.non-active]="!editMode">
        <mat-label>{{ app.type.searchItemNames[field[0]] }}: </mat-label>
        <textarea matInput  [(ngModel)]="candidate[field[0]]" [readonly]="!editMode" rows="6"></textarea>
      </mat-form-field>
    </div>
    <div *ngSwitchDefault>
      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!editMode">
        <mat-label>{{ app.type.searchItemNames[field[0]] }}: </mat-label>
        <input matInput  [(ngModel)]="candidate[field[0]]" [readonly]="!editMode">
      </mat-form-field>
    </div>
  </div>
</ng-template>
