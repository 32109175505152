import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { YhCore, SearchItemPipelineType, ContactItemType } from '../app-services/core.service';
import { ContactItemDialogComponent } from '../app-dialogs/contact-item-dialog/contact-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CandidateDetail } from '../app-services/candidate-detail.type';
import { Candidate } from '../app-services/candidate.type';
import { SearchItemSendEmailDialogComponent } from '../app-dialogs/search-item-send-email-dialog/search-item-send-email-dialog.component';
import { ContactItem } from 'core/dist/types/contact-item-type';
import { AppService } from "../app-services/app.service";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-items-page',
  templateUrl: './contact-items-page.component.html',
  styleUrls: ['./contact-items-page.component.scss']
})
export class ContactItemsPageComponent implements AfterViewInit {
  @ViewChild('contactsTable') contactsTable;
  public candidateDetail: CandidateDetail = new CandidateDetail();
  public candidate: Candidate = new Candidate();
  contacts: ContactItem[]=[];
  candidatePipeline: SearchItemPipelineType[] = [];
  isLoadingContacts =  true;

  entriesCount = 0;
  entriesStep:number = 1000;
  totalSearchItems: number = 0;

  constructor(
    public app: AppService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  ngAfterViewInit() {
    this.getContacts();
  }

  getContacts(){
    this.isLoadingContacts = true;
    YhCore.contactItems.list(0, 1000,data => {
      this.isLoadingContacts = false
      this.totalSearchItems = data.totalContactItems
      this.contactsTable.applyDataSource(data.response);
    }, err => {
      this.isLoadingContacts = false
    })
  }

  openDialogViewContact(contact: ContactItem) {
    const dialogRef = this.dialog.open(
      ContactItemDialogComponent,
      {
        width: '550px',
        data: {
          contact: contact
        }
      });
  }

  openDialogLeadSendEmail(email: string, contactActivity: boolean, contact = new ContactItemType()): void {
    const dialogRef = this.dialog.open(
      SearchItemSendEmailDialogComponent,
      {
        width: '550px',
        data: {
          candidate: this.candidate,
          candidatePipeline: this.candidatePipeline,
          email: email,
          contactActivity: contactActivity,
          contact: contact
        }
      });
  }

  onEntriesChange() {
    this.isLoadingContacts = true;
    YhCore.contactItems.list(this.entriesCount,this.entriesStep, data => {
      this.contactsTable.applyDataSource(data.response);
      this.totalSearchItems = data.totalSearchItems
      this.isLoadingContacts = false
      }
      , error => {
        this.isLoadingContacts = false
    })
  }

  deleteContact(contact: ContactItem) {
    YhCore.contactItems.remove(+contact.contactId, (res) => {
      this.snackBar.open('Contact deleted', 'OK', {duration:3000})
      this.getContacts()
    }, err => {
      this.snackBar.open(err, 'ERROR', {duration:3000})
    })

  }

}
