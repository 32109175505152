import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-quick-sourcing-table',
  templateUrl: './quick-sourcing-table.component.html',
  styleUrls: ['./quick-sourcing-table.component.scss']
})
export class QuickSourcingTableComponent implements OnInit {

  public displayedColumns = ['name', 'relevant'];
  public dataSource: MatTableDataSource<any>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: any[]) {
    this.applyDataSource(data);
  }
  
  constructor() { }

  ngOnInit(): void {
  }

  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


}
