import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerAccessItem } from 'core/dist/types/customer-access-item-type';
import { Subscription } from 'rxjs';
import { AppService } from 'src/app/app-services/app.service';
import { UserType, WorkItemType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-customer-access-offers-or-work-items-dialog',
  templateUrl: './customer-access-offers-or-work-items-dialog.component.html',
  styleUrls: ['./customer-access-offers-or-work-items-dialog.component.scss']
})
export class CustomerAccessOffersOrWorkItemsDialogComponent implements OnInit, OnDestroy {

  private subCompany:Subscription;
  private subWorkItem:Subscription;

  public users: UserType[];
  public accessForm: FormGroup;
  public accessUsers: UserType[] = [];
  public customerWorkItem: WorkItemType[];

  constructor(
    public app: AppService,
    public dialog: MatDialogRef<CustomerAccessOffersOrWorkItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {users: UserType[], accessCustomers: CustomerAccessItem[], customerWorkItemList: WorkItemType[], onlyWorkItem: boolean},
  ) { }
  ngOnDestroy(): void {
    this.subCompany ? this.subCompany.unsubscribe(): ''
    this.subWorkItem ? this.subWorkItem.unsubscribe(): ''
  }

  ngOnInit(): void {
    this.initForm()

    this.users = this.data.users;
    this.customerWorkItem = this.data.customerWorkItemList;
    this.setAccessUsers(this.data.accessCustomers)
  }

  setAccessUsers(accessCustomers) {
    this.accessUsers = [];
    this.clearFormArray(this.selectedUsersFromForm);
    this.data.users.forEach( user => {
      accessCustomers.forEach( accessItem => {
        if(+accessItem.userId === +user.userId){
          this.accessUsers.push(user)
          this.selectedUsersFromForm.push(new FormControl(user))
        }
      })
    })
  }

  initForm() {
    if(!this.data.onlyWorkItem){
      this.accessForm = new FormGroup({
        company: new FormControl('company',),
        selectedUsers: new FormArray([]),
      })
    } else {
      this.accessForm = new FormGroup({
        company: new FormControl('workItem',),
        selectedUsers: new FormArray([]),
      })
    }

    this.subCompany = this.accessForm.get('company').valueChanges.subscribe(value => {
      if (value === 'workItem') {
        this.accessForm.addControl('workItem', new FormControl(''));
        this.clearFormArray(this.selectedUsersFromForm)
        this.accessUsers = [];
        this.toogleCustomerWorkItem();
        this.accessForm.get('workItem').setValue(this.customerWorkItem[0])
      } else {
        if(this.accessForm.get('workItem')) {
          this.accessForm.removeControl('workItem');
          this.setAccessUsers(this.data.accessCustomers);
        }
      }
    })
  }

  clearFormArray(formArray: FormArray){
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  getWorkItemAccessItems(id: number):Promise<CustomerAccessItem[]> {
    return new Promise( (resolve, reject) => {
      YhCore.workItems.getAccessItem(id, (workItems:CustomerAccessItem[]) => {
        resolve(workItems)
      }, err => reject(err))
    })
  }

  get ifWorkItemAccess() {
    return this.accessForm.get('company').value === 'workItem' ? true: false;
  }

  get selectedUsersFromForm(): FormArray{
    return this.accessForm.get('selectedUsers') as FormArray;
  }

  toogleCustomerWorkItem() {
    this.subWorkItem = this.accessForm.get('workItem').valueChanges.subscribe( async (workItem:WorkItemType) => {
      const items:CustomerAccessItem[] = await this.getWorkItemAccessItems(+workItem.joborderId);
      this.setAccessUsers(items);
    })
  }

  toogleUser(item: any, event: MatButtonToggleChange) {
    if(event.source.checked) {
      this.selectedUsersFromForm.push(new FormControl(item))
    } else {
      const findUser = this.selectedUsersFromForm.value.findIndex(val => val === item)
      this.selectedUsersFromForm.removeAt(findUser)
    }
  }

  save() {
    this.dialog.close(this.accessForm.value)
  }

  close() {
    this.dialog.close(false);
  }

}
