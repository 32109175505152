<mat-dialog-content>
  <form [formGroup]="cvSettingsForm">
    <mat-horizontal-stepper linear #stepper>
      <mat-step [stepControl]="f['headerField']">
        <ng-template matStepLabel>Header Settings</ng-template>
        <ckeditor [editor]="Editor" formControlName="headerField"
                  [data]="dataHeaderField">
        </ckeditor>
      </mat-step>
      <mat-step  [stepControl]="f['footerField']">
        <ng-template matStepLabel>Footer Settings</ng-template>
        <ckeditor [editor]="Editor" formControlName="footerField"
                  [data]="dataFooterField">
        </ckeditor>
      </mat-step>
      <mat-step [stepControl]="f['dataField']">
        <ng-template matStepLabel>Field Settings</ng-template>
        <app-cv-field-settings-table [data]="cvSettingsData"
                              (setDataFieldEvent)="saveDataField($event)"></app-cv-field-settings-table>
      </mat-step>
    </mat-horizontal-stepper>
  </form>
</mat-dialog-content>