"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebMatrixUsersController = void 0;
class WebMatrixUsersController {
    constructor(api, matrixUserService) {
        this.api = api;
        this.matrixUserService = matrixUserService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('matrixUsers/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.matrixUserService.mapMatrixUsers(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('matrixUsers/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.matrixUserService.mapMatrixUser(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    edit(matrixUser, successCallback, errorCallback) {
        const src = this.matrixUserService.mapReverseMatrixUser(matrixUser);
        this.api.postRequest('matrixUsers/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('matrixUsers/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    create(matrixUser, successCallback, errorCallback) {
        const src = this.matrixUserService.mapReverseMatrixUserCreate(matrixUser);
        this.api.postRequest('matrixUsers/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    listByUserId(id, successCallback, errorCallback) {
        this.api.postRequest('matrixUsers/listByUserId', { user_id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.matrixUserService.mapMatrixUsers(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    getMatrixFieldSearchItemSettings(successCallback, errorCallback) {
        this.api.postRequest("matrixUsers/getMatrixFieldsSearchItem", {}, res => {
            if (res.data.valid === true) {
                successCallback(this.matrixUserService.matMatrixFieldsSearchItems(res.data.data));
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    setMatrixFieldSearchItemSettings(payload, successCallback, errorCallback) {
        const src = this.matrixUserService.mapReverseMatrixFieldsSearchItems(payload.fields);
        this.api.postRequest("matrixUsers/setMatrixFieldsSearchItem", { fields: src }, res => {
            if (res.data.valid === true) {
                successCallback(res.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    find(matrixUser, successCallback, errorCallback) { }
    sort(matrixUser, userMatrixField, desc, successCallback, errorCallback) { }
}
exports.WebMatrixUsersController = WebMatrixUsersController;
