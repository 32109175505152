import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { YhCore, SearchItemSharingType, SearchItemSharingHistoryType } from "../app-services/core.service";
import { FormControl } from '@angular/forms';
import { UserService } from '../app-services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-shared-search-items',
  templateUrl: './shared-search-items-page.component.html',
  styleUrls: ['./shared-search-items-page.component.scss']
})

export class SharedSearchItemsComponent implements AfterViewInit {
  @ViewChild('requestShareSearchItemsTable') requestShareSearchItemsTable;
  @ViewChild('firstDatePicker') firstDatePicker;
  @ViewChild('secondDatePiker') secondDatePiker;
  searchItemSharingHistories: SearchItemSharingHistoryType[] = [];
  searchItemSharings: SearchItemSharingType[] = [];
  nameButtons = [] = [];
  requestButtons = [] = [];
  addItemsForUsers = [] = [];
  addItemsForRequest = [] = [];
  firstDate: string;
  secondDate: string;
  arr = [] = [];
  saveIndex = [] = [];

  constructor(
    private userService: UserService,
    private router: Router
  ) {
  }

  ngAfterViewInit() {
    parseInt(JSON.parse(localStorage.getItem('YHminiuser')).accessLevel) >= 500 ? '' : this.router.navigateByUrl('/settings')
    this.getSharingList();
    this.getSharingHistories('2');
    this.getRequestButton();
  }

  getDate(value) {
    this.getSharingHistories(value);
  }

  getSharingList() {
    YhCore.searchItemSharing.list(success => {
      this.searchItemSharings = success;
      this.getNameButtons();
      this.filterRequestSharedItems('1')
    }, error => { });
  }

  getNameButtons() {
    this.nameButtons = this.searchItemSharings.filter((el, index, self) =>
      index === self.findIndex((t) => (
        t.enteredBy === el.enteredBy
      )))
  }

  getSharingHistories(date) {
    YhCore.searchItemSharing.statistic(date, success => {
      this.searchItemSharingHistories = success;
    }, error => { });
  }

  getSharingHistoriesDatePicker(periodStart, periodEnd) {
    YhCore.searchItemSharing.statisticForDatePicker('8', periodStart, periodEnd, success => {
      this.searchItemSharingHistories = success;
    }, error => { });
  }

  getRequestButton() {
    YhCore.searchItemSharing.sharingTypes(success => {
      this.requestButtons = success;
    }, error => {});
  }

  getDatepickerData() {
    this.firstDate = this.firstDatePicker.nativeElement.value;
    this.secondDate = this.secondDatePiker.nativeElement.value;
    if (this.firstDate !== undefined && this.secondDate !== undefined) {
      this.arr = this.firstDate.split('/').reverse();
      this.saveIndex = this.arr[1];
      this.arr.splice(1, 1);
      this.arr.push(this.saveIndex);
      this.firstDate = this.arr.join('-');

      this.arr = this.secondDate.split('/').reverse()
      this.saveIndex = this.arr[1];
      this.arr.splice(1, 1);
      this.arr.push(this.saveIndex);
      this.secondDate = this.arr.join('-');

      this.getSharingHistoriesDatePicker(this.firstDate, this.secondDate);
    }

    else {
      alert('Please enter two date in datepicker!')
    }
  }

  filterRequestSharedItems(item) {
    if (!this.addItemsForRequest.includes(item)) {
      this.addItemsForRequest.push(item);
    }
    else {
      this.addItemsForRequest = this.addItemsForRequest.filter(el => el !== item);
    }
    this.searchItemSharings.map((el) => {
      if (this.addItemsForRequest.includes(el.status)) {
        Object.assign(el, { hide: false });
      }
      else {
        Object.assign(el, { hide: true });
      }
    })
  }

  filterRequestUserSharedItems(item) {

    if (!this.addItemsForUsers.includes(item)) {
      this.addItemsForUsers.push(item);
    }
    else {
      this.addItemsForUsers = this.addItemsForUsers.filter(el => el !== item);
    }
    this.searchItemSharings.map((el) => {
      if (this.addItemsForUsers.includes(el.enteredBy)) {
        Object.assign(el, { hide: true });
      }
      else {
        Object.assign(el, { hide: false });
      }
    })
  }

  onClickAllow(searchItemSharing: SearchItemSharingType) {
    YhCore.searchItemSharing.changeStatus(+searchItemSharing.id, 2, success => { }, error => { });
  }

  onClickDenied(searchItemSharing: SearchItemSharingType) {
    YhCore.searchItemSharing.changeStatus(+searchItemSharing.id, 3, success => { }, error => { });
  }

  applyAllCheckedData() {
    console.log(this.requestShareSearchItemsTable.selection.selected)
    alert('all data apply')
  }

  deniedAllCheckedData() {
    console.log(this.requestShareSearchItemsTable.selection.selected)
    alert('all data denied')
  }

}
