<div>
  <mat-form-field>
    <label>
      <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
    </label>
  </mat-form-field>

  <div>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="dataShared">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Data Shared</mat-header-cell>
        <mat-cell *matCellDef="let sharedSearchItem">{{sharedSearchItem['setDate']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="candidate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Candidate</mat-header-cell>
        <mat-cell *matCellDef="let sharedSearchItem">{{sharedSearchItem['candidateFirstName']}} {{sharedSearchItem['candidateLastName']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="previousOwner">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Previous Owner</mat-header-cell>
        <mat-cell *matCellDef="let sharedSearchItem">{{sharedSearchItem['prevOwnerFullName']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="newOwnerFullName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Next Owner</mat-header-cell>
        <mat-cell *matCellDef="let sharedSearchItem">{{sharedSearchItem['newOwnerFullName']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="sharedBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Shared By</mat-header-cell>
        <mat-cell *matCellDef="let sharedSearchItem">{{sharedSearchItem['sharedBy']}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let sharedSearchItem; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
