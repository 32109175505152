import { MatSnackBar } from '@angular/material/snack-bar';
import { YhCore, CustomerType } from './../app-services/core.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyDetail } from '../app-services/company-detail.type';
import { JobOrder } from '../app-services/job-order.type';
import { AppService } from '../app-services/app.service';

@Component({
  selector: 'app-customer-page',
  templateUrl: './customer-page.component.html',
  styleUrls: ['./customer-page.component.scss']
})
export class CompanyDetailComponent implements OnInit {
  @ViewChild('jobOrdersTableComponent') jobOrdersTableComponent;

  company: any = new CustomerType();
  companyDetail: any = new CompanyDetail();
  getId: string;

  isEditMode = false
  isRecrutingMode = true

  constructor(
    public app: AppService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getCompany();
    if (this.app.type.names['customer'] === 'Project')
      this.isRecrutingMode = false
  }


   getCompany() {
    const tuple = this.route.paramMap.pipe(
      map(params => { this.getId = params.get('id')
      }), publishReplay(1), refCount()
    );
    const company$ = tuple.pipe(switchMap(t => t[0]));
    const companyDetail$ = tuple.pipe(switchMap(t => t[1]));

    companyDetail$.subscribe(d => this.companyDetail = d);
    company$.subscribe(c => this.company = c);

    YhCore.customers.read(this.getId, res => {
      this.company = res;
    }, err => { });
  }

  updateCompany() {
    this.isEditMode = false;
    YhCore.customers.edit(this.company, res => { 
      this.snackBar.open('Follow up was successfully create', 'OK', {duration: 3000})
    }, err => { });
  }

  deleteCompany() {
    // YhCore.customers.remove(this.getId, res => {
    //   this.router.navigate([this.app.type.names['customers'].toLowerCase()]);
    //   this.snackBar.open('Follow up was successfully create', 'OK', {duration: 3000})
    // }, err => { });
  }

  navigateToJobOrder(jobOrder: JobOrder) {
    this.router.navigate([this.app.type.names['workItems'].toLowerCase(), jobOrder.id]);
  }

}
