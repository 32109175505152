<h1 mat-dialog-title>Add to Calibration</h1>
<mat-dialog-content>
  <div>
    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Companies</mat-label>
      <mat-select>
        <mat-option *ngFor="let item of companies" [value]="item.value">
          {{item.viewName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Job Orded</mat-label>
      <mat-select>
        <mat-option *ngFor="let item of jobOrders" [value]="item.value">
          {{item.viewName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-flat-button (click)="onCloseClick()">Close</button>
  <button mat-flat-button color="primary" (click)="onAddClick()">Add</button>
</div>