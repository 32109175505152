<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
        <mat-card-subtitle>CV Generation Settings</mat-card-subtitle>
        <mat-card-content>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>{{cvSettings.length === 0 ? 'You dont have any settings' : 'Selected Settings'}}</mat-label>
                    <mat-select #matSelect [(value)]="defaultSelectedSettingId" 
                                (selectionChange)="selectedSetting($event.value)" 
                                [disabled]="disabledSelectSettings">
                        <mat-option *ngFor="let setting of cvSettings" [value]="setting.id">
                            {{setting.viewName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
          <button style="margin: 2.5px;" (click)="openAddSettingsDialog()" mat-flat-button color="primary">ADD SETTINGS</button>
          <button style="margin: 2.5px;" (click)="openPreviewSettingsDialog()" mat-flat-button color="primary">PREVIEW</button>
        </mat-card-content>
      <!-- Fixing min height issue -->
      <div class="empty-space"></div>
    </mat-card>
    <mat-card class="main-content" *ngIf="cvSettings.length !== 0">
      <mat-card-content>
          <mat-tab-group>
              <mat-tab label="Header Settings">
                  <ng-template matTabContent>
                      <ckeditor [editor]="Editor" [(ngModel)]="currentCvSettings['headerText']"
                          (ngModelChange)="saveDataHeaderEvent($event)"></ckeditor>
                  </ng-template>
              </mat-tab>
              <mat-tab label="Footer Settings">
                  <ng-template matTabContent>
                      <ckeditor [editor]="Editor" [(ngModel)]="currentCvSettings['footerText']"
                          (ngModelChange)="saveDataFooterEvent($event)"></ckeditor>
                  </ng-template>
              </mat-tab>
              <mat-tab label="Fields Settings">
                  <ng-template matTabContent>
                      <app-cv-field-settings-table [data]="currentCvSettings['fieldSettingsData']"
                          (setDataFieldEvent)="saveDataField($event)"></app-cv-field-settings-table>
                  </ng-template>
              </mat-tab>
          </mat-tab-group>
      </mat-card-content>
  </mat-card>
  </div>
</app-navigation>

<!-- 
    <mat-tab label="Preview Settings">
                  <ng-template matTabContent>
                      <mat-form-field appearance="outline" class="selectedUserCV">
                          <mat-label>Write user id for preview</mat-label>
                          <input matInput [(ngModel)]="selectedCandidate">
                      </mat-form-field>
                      <div [class.hideElementPreview]="hideElement" style="display: none;">
                          <div [innerHTML]="dataHeaderField"></div>
                          <div #block1></div>
                          <div #block2></div>
                          <div #block3></div>
                          <div [innerHTML]="dataFooterField"></div>
                      </div>
                      <button mat-button class="cv-preview-btn" (click)="showPreview()">PREVIEW</button>
                  </ng-template>
              </mat-tab>
 -->