import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Condition } from 'core/dist/types/conditions-type';

@Component({
  selector: 'app-work-item-accept-condition-dialog',
  templateUrl: './work-item-accept-condition-dialog.component.html',
  styleUrls: ['./work-item-accept-condition-dialog.component.scss']
})
export class WorkItemAcceptConditionDialogComponent implements OnInit {

  public condition: Condition
  public preview: boolean = false;;
  constructor(
    private dialogRef: MatDialogRef<WorkItemAcceptConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {preview: boolean, condition: Condition},
  ) { }

  ngOnInit(): void {
    this.condition = this.data.condition
    this.preview = this.data.preview || false;
  }

  accept() {
    this.dialogRef.close(this.data.condition)
  }

  close(){
    this.dialogRef.close(false)
  }

}
