import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GroupUsersDialogComponent } from '../app-dialogs/group-users-dialog/group-users-dialog.component';
import { GroupUsersType, YhCore } from '../app-services/core.service';

@Component({
  selector: 'app-departmnets-page',
  templateUrl: './departmnets-page.component.html',
  styleUrls: ['./departmnets-page.component.scss']
})
export class DepartmnetsPageComponent implements OnInit {

  public loading: boolean = false;
  public groupUser: GroupUsersType[];
  public currentUser = JSON.parse(YhCore.localStorageService.getItem("YHminiuser"))

  constructor(
    private router: Router,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.getGroupUser().then( res => this.groupUser = res)
  }

  getGroupUser(): Promise<GroupUsersType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.list(res => resolve(res), err => reject(err))
    })
  }

  navigateToDepartment(row: any) {
    this.router.navigate(['departmnets', row.id])
  }

  addNewGroup() {
    this.dialog.open(GroupUsersDialogComponent, {width: '600px', data: {groupUser: {}} }).afterClosed().subscribe(res => res ? this.getData(): '')
  }

}
