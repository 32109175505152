<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav *ngIf="!isHorizontalSideNav" #drawer class="sidenav" fixedInViewport="false" [@openCloseSidenav]="isExpanded  ? 'open': 'closed'"
              [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
              [mode]="(isHandset$ | async) ? 'over' : 'side'"
              [opened]="!(isHandset$ | async)">
    <mat-toolbar color="primary" class="side-bar-toolbar">
      <span *ngIf="isExpanded">
        {{ app.type.names['project'].toUpperCase() }}
      </span>
      <span *ngIf="!isExpanded">
        {{ app.type.names['project'].toUpperCase().substring(0,1) }}
      </span>
      <div fxFlex></div>
      <p *ngIf="!(isHandset$ | async)">
        <span style="min-width: 15px;"></span>
        <mat-icon mat-icon style="cursor: pointer; transition: 0.5s"
                  (click)="isExpanded = !isExpanded; saveSideNavState()"
                  [ngStyle]="{'transform': isExpanded ? 'rotate(0deg)' : 'rotate(180deg)'}"
                  >chevron_left
        </mat-icon>
      </p>
    </mat-toolbar>
    <mat-nav-list class="main-navigation">
<!--      <a mat-list-item routerLink="/active-poll-candidate" [class.active]="isActiveLink('active-poll-candidate')">-->
<!--        <mat-icon mat-list-icon>assignment</mat-icon>-->
<!--        <span *ngIf="isExpanded" mat-line>ACTIVE POLL</span>-->
<!--      </a>-->
<!--      <a mat-list-item routerLink="/company-profile-page" [class.active]="isActiveLink('company-profile-page')" *ngIf="+currentUser.accessLevel >= 400">-->
<!--        <mat-icon mat-list-icon>assignment</mat-icon>-->
<!--        <span *ngIf="isExpanded" mat-line >MY PROFILE</span>-->
<!--      </a>-->
<!--      <a mat-list-item routerLink="/departmnets" [class.active]="isActiveLink('departmnets')">-->
<!--        <mat-icon mat-list-icon>assignment</mat-icon>-->
<!--        <span *ngIf="isExpanded" mat-line >DEPARTMNETS</span>-->
<!--      </a>-->
      <a mat-list-item routerLink="{{ '/'.concat(app.type.names['searchItems'].toLowerCase()) }}" [class.active]="isActiveLink(app.type.names['searchItems'].toLowerCase())">
        <mat-icon mat-list-icon>people</mat-icon>
        <!-- <span *ngIf="isExpanded" mat-line >{{ app.type.names['searchItems'].toUpperCase() }}</span> --> <!--delete-->
        <span *ngIf="isExpanded" mat-line >CANDIDATES</span>
      </a>
      <a mat-list-item routerLink="{{ '/'.concat(app.type.names['workItems'].toLowerCase()) }}" [class.active]="isActiveLink(app.type.names['workItems'].toLowerCase())">
        <mat-icon mat-list-icon>work</mat-icon>
        <!-- <span *ngIf="isExpanded" mat-line >{{ app.type.names['workItems'].toUpperCase() }}</span> --> <!--delete-->
        <span *ngIf="isExpanded" mat-line >JOBORDERS</span>
      </a>
<!--      <a mat-list-item routerLink="/settings" [class.active]="isActiveLink('settings')" *ngIf="+currentUser.accessLevel >= 300">-->
<!--        <mat-icon mat-list-icon>settings</mat-icon>-->
<!--        <span *ngIf="isExpanded" mat-line >SETTINGS</span>-->
<!--      </a>-->
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content [@openCloseSidenavContent]="( (isHandset$ | async) || isHorizontalSideNav) ? 'fullClosed' : (isExpanded  ? 'open': 'closed')">
    <mat-toolbar color="primary" class="main-toolbar" >
      <mat-toolbar-row>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="toogleDrawer()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div *ngIf="false">
          <mat-form-field appearance="standard" class="quick-search" style="font-size: inherit;">
            <input matInput [placeholder]="(isVerySmall | async) ? 'SEARCH' : 'QUICK SEARCH'" [(ngModel)]="searchValue">
          </mat-form-field>
          <button mat-icon-button aria-label="Search" (click)="onQuickSearch()" matTooltip="Search">
            <mat-icon>search</mat-icon>
          </button>
        </div>
        <div fxFlex></div>
        <div class="current-user">
          <div class="current-user__icon">
            <mat-icon mat-list-icon>person</mat-icon>
            <span>{{currentUser['firstName']}} {{currentUser['lastName']}}</span>
          </div>
        </div>
        <!-- EVENTS BUTTON -->
<!--        <button-->
<!--            matTooltip="Show Activities"-->
<!--            type="button"-->
<!--            mat-icon-button-->
<!--            #notification_btn-->
<!--            (click) = "setViewedNotifications()"-->
<!--            class = "nav-icon-button"-->
<!--            >-->
<!--          <app-my-events-overlay [flyoutFor]="notification_btn" [template]="notification_flyout"></app-my-events-overlay>-->
<!--          <ng-template #notification_flyout>-->
<!--            <mat-card>-->
<!--              <mat-card-header>-->
<!--                <mat-card-title class="candidate-flyout-header"></mat-card-title>-->
<!--                <div fxFlex *ngIf="events && events.length > 0"></div>-->
<!--                <div *ngIf="events && events.length === 0" class ="no-notifications">No new notifications</div>-->
<!--                <button mat-flat-button color="warn" (click) = "goToViewAllEventsPage()">VIEW ALL</button>-->
<!--              </mat-card-header>-->
<!--              <app-abstract-table *ngIf="events && events.length > 0"-->
<!--                                [data]="events"-->
<!--                                [columns]="displayedColumns"-->
<!--                                [tablePaginator]="false"-->
<!--                                [search]="false">-->
<!--              </app-abstract-table>-->
<!--            </mat-card>-->
<!--          </ng-template>-->
<!--          <div class="bell-wrap"-->
<!--              [matBadge]="newNotificationsIds.length"-->
<!--              [matBadgeHidden] = "newNotificationsIds.length === 0"-->
<!--              matBadgeSize="medium"-->
<!--              matBadgeColor="warn"-->
<!--          >-->
<!--            <mat-icon [@rotatedState]='state'>notifications</mat-icon>-->
<!--          </div>-->
<!--        </button>-->
        <!-- EVENTS BUTTON -->
        <button type="button" mat-icon-button (click)="logOut()" class = "nav-icon-button" matTooltip="Logout">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </mat-toolbar-row>
      <mat-toolbar-row *ngIf="(isHorizontalSideNav && !(isHandset$ | async))">
        <span>
          {{ app.type.names['project'].toUpperCase() }}
        </span>
        <div fxFlex></div>
        <!-- <mat-list fxLayout="row" class="horizontal-menu">
          <a mat-list-item routerLink="{{ '/'.concat(app.type.names['searchItems'].toLowerCase()) }}" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >{{ app.type.names['searchItems'].toUpperCase() }}</span>
          </a>
          <a mat-list-item routerLink="{{ '/'.concat(app.type.names['workItems'].toLowerCase()) }}" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >{{ app.type.names['workItems'].toUpperCase() }}</span>
          </a>
          <a *ngIf="false" mat-list-item routerLink="{{ '/'.concat(app.type.names['customers'].toLowerCase()) }}" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >{{ app.type.names['customers'].toUpperCase() }}</span>
          </a>
          <a mat-list-item routerLink="/calendar" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line>CALENDAR</span>
          </a>
          <a mat-list-item routerLink="{{ '/'.concat(app.type.names['hashtags'].toLowerCase()) }}" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >{{ app.type.names['hashtags'].toUpperCase() }}</span>
          </a>
          <a mat-list-item routerLink="/settings" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >SETTINGS</span>
          </a>
          <a mat-list-item routerLink="{{ '/'.concat(app.type.names['contactItems'].toLowerCase()) }}" routerLinkActive="active">
            <span *ngIf="isExpanded" mat-line >CONTACTS</span>
          </a>
        </mat-list> -->
      </mat-toolbar-row>
    </mat-toolbar>

    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
