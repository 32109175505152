import { Component, OnInit, ViewChild } from '@angular/core';
import { YhCore } from '../app-services/core.service'
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AddCvSettingsDialogComponent } from '../app-dialogs/add-cv-settings-dialog/add-cv-settings-dialog.component';
import { PreviewCvSettingsDialogComponent } from '../app-dialogs/preview-cv-settings-dialog/preview-cv-settings-dialog.component';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cv-settings-page',
  templateUrl: './cv-settings-page.component.html',
  styleUrls: ['./cv-settings-page.component.scss']
})
export class CvSettingsComponent implements OnInit {

  @ViewChild('matSelect') matSelect: MatSelect;
  public Editor = ClassicEditor;
  fieldSettingsData = [
    {
      filedNameSet: 'firstName',
      filedName: 'First name',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'lastName',
      filedName: 'Second name',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'email1',
      filedName: 'Email',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'email2',
      filedName: 'Email 2',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'city',
      filedName: 'City',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'state',
      filedName: 'State',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'desiredPay',
      filedName: 'Desired pay',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'bestTimeToCall',
      filedName: 'Best time to call',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'keySkills',
      filedName: 'Key skills',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'notes',
      filedName: 'Notes',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'dialog',
      filedName: 'Dialog',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'canRelocate',
      filedName: 'Can relocate',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'webSite',
      filedName: 'Web site',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'address',
      filedName: 'Address',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'currenEmp',
      filedName: 'Current employer',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'currentPay',
      filedName: 'Current pay',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'attachment',
      filedName: 'Attachment',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'skype',
      filedName: 'Skype',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'candLvl',
      filedName: 'Candidate level',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'eng',
      filedName: 'English level	',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'birth',
      filedName: 'Date of birth	',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'otherLang',
      filedName: 'Other languages	',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'exp',
      filedName: 'Experience',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'position',
      filedName: 'Position',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'source',
      filedName: 'Source',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'firstName',
      filedName: 'Multiline',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'checkbox',
      filedName: 'Checkbox',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'exp',
      filedName: 'Experience details',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'phoneCell',
      filedName: 'Phone cell',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'phoneHome',
      filedName: 'Phone home',
      isActive: false,
      block: '1',
    },
    {
      filedNameSet: 'phoneWork',
      filedName: 'Phone work',
      isActive: false,
      block: '1',
    }
  ];

  cvSettings = [
    {
      id: 1,
      viewName: 'Setting 1',
      headerText: 'Header setting 1',
      fieldSettingsData: [
        {
          filedNameSet: 'firstName',
          filedName: 'First name',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'lastName',
          filedName: 'Second name',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'email1',
          filedName: 'Email',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'email2',
          filedName: 'Email 2',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'city',
          filedName: 'City',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'state',
          filedName: 'State',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'desiredPay',
          filedName: 'Desired pay',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'bestTimeToCall',
          filedName: 'Best time to call',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'keySkills',
          filedName: 'Key skills',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'notes',
          filedName: 'Notes',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'dialog',
          filedName: 'Dialog',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'canRelocate',
          filedName: 'Can relocate',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'webSite',
          filedName: 'Web site',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'address',
          filedName: 'Address',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'currenEmp',
          filedName: 'Current employer',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'currentPay',
          filedName: 'Current pay',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'attachment',
          filedName: 'Attachment',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'skype',
          filedName: 'Skype',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'candLvl',
          filedName: 'Candidate level',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'eng',
          filedName: 'English level	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'birth',
          filedName: 'Date of birth	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'otherLang',
          filedName: 'Other languages	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'exp',
          filedName: 'Experience',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'position',
          filedName: 'Position',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'source',
          filedName: 'Source',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'firstName',
          filedName: 'Multiline',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'checkbox',
          filedName: 'Checkbox',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'exp',
          filedName: 'Experience details',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneCell',
          filedName: 'Phone cell',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneHome',
          filedName: 'Phone home',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneWork',
          filedName: 'Phone work',
          isActive: false,
          block: '1',
        }
      ],
      footerText: 'Footer setting 1',
    },
    {
      id: 2,
      viewName: 'Setting 2',
      headerText: 'Header setting 2',
      fieldSettingsData: [
        {
          filedNameSet: 'firstName',
          filedName: 'First name',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'lastName',
          filedName: 'Second name',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'email1',
          filedName: 'Email',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'email2',
          filedName: 'Email 2',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'city',
          filedName: 'City',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'state',
          filedName: 'State',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'desiredPay',
          filedName: 'Desired pay',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'bestTimeToCall',
          filedName: 'Best time to call',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'keySkills',
          filedName: 'Key skills',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'notes',
          filedName: 'Notes',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'dialog',
          filedName: 'Dialog',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'canRelocate',
          filedName: 'Can relocate',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'webSite',
          filedName: 'Web site',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'address',
          filedName: 'Address',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'currenEmp',
          filedName: 'Current employer',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'currentPay',
          filedName: 'Current pay',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'attachment',
          filedName: 'Attachment',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'skype',
          filedName: 'Skype',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'candLvl',
          filedName: 'Candidate level',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'eng',
          filedName: 'English level	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'birth',
          filedName: 'Date of birth	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'otherLang',
          filedName: 'Other languages	',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'exp',
          filedName: 'Experience',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'position',
          filedName: 'Position',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'source',
          filedName: 'Source',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'firstName',
          filedName: 'Multiline',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'checkbox',
          filedName: 'Checkbox',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'exp',
          filedName: 'Experience details',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneCell',
          filedName: 'Phone cell',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneHome',
          filedName: 'Phone home',
          isActive: false,
          block: '1',
        },
        {
          filedNameSet: 'phoneWork',
          filedName: 'Phone work',
          isActive: false,
          block: '1',
        }
      ],
      footerText: 'Footer setting 2',
    },
  ];
  defaultSelectedSettingId: number = 1;
  private currentCvSettingsId: number = 1;

  public currentCvSettings;
  public disabledSelectSettings: boolean = this.cvSettings.length === 0 ? true : false;
  public hideElement: boolean;
  public selectedCandidate: any;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }
  ;

  ngOnInit(): void {
    parseInt(JSON.parse(localStorage.getItem('YHminiuser')).accessLevel) >= 500 ? '' : this.router.navigateByUrl('/settings')
    if (this.cvSettings.length) {
      this.currentCvSettings = Object.assign({}, this.cvSettings[0])
    }
  }

  openAddSettingsDialog() {
    const dialogRef = this.dialog.open(AddCvSettingsDialogComponent,
      { width: '1000px', height: '80vh', data: { cvSettings: this.fieldSettingsData, } });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const newSettings = {
          id: this.cvSettings.length + 1,
          viewName: `Setting ${this.cvSettings.length + 1}`,
          headerText: res['headerField'],
          footerText: res['footerField'],
          fieldSettingsData: res['dataField']
        }
        this.cvSettings.push(newSettings)
        this.currentCvSettingsId = this.cvSettings.length - 1;
        this.currentCvSettings = newSettings
        this.matSelect.valueChange.emit(this.cvSettings.length);
        this.snackBar.open('Setting ADD', 'OK', { duration: 5000 });
      }
    })
  }

  openPreviewSettingsDialog() {
    const dialogRef = this.dialog.open(PreviewCvSettingsDialogComponent,
      { width: '1000px', height: '80vh', data: { cvSettings: this.currentCvSettings } });
  }
  saveDataField(data: any) {
    this.cvSettings.find(val => val.id === this.currentCvSettingsId).fieldSettingsData = data;
    this.currentCvSettings.fieldSettingsData = data;
    this.snackBar.open('Fields is saved', 'OK', { duration: 3000 })
  }

  saveDataHeaderEvent(data) {
    this.cvSettings.find(val => val.id === this.currentCvSettingsId).headerText = data
    this.currentCvSettings.headerText = data
  }

  saveDataFooterEvent(data) {
    this.cvSettings.find(val => val.id === this.currentCvSettingsId).footerText = data
    this.currentCvSettings.footerText = data
  }

  selectedSetting(id: number) {
    this.currentCvSettingsId = id;
    const settings = this.cvSettings.find(val => val.id === id)
    this.currentCvSettings = settings
  }

}
