
export class CandidateDetail {
  pipelines: CandidatePipeline[];
  activityLog: ActivityLogEntry[];
  activityTypes: CandidateActivityType[];
  attachments: CandidateAttachment[];
}

export class CandidatePipeline {
  id: string;
  joborderId: string;
  title: string;
  company: string;
  companyId: string;
  color: string;
  author: string;
  status: string;
  created: string;
}

export class CandidateActivityType {
  id: string;
  value: string;
}

export class ActivityLogEntry {
  date: string;
  notes: string;
  title: string;
  firstName: string;
  lastName: string;
  activityType: string;
}

export class CandidateAttachment {
  id: string;
  isFromAnotherServer: string;
  dataItemId: string;
  dataItemType: string;
  siteId: string;
  title: string;
  originalFilename: string;
  storedFilename: string;
  contentType: string;
  resume: string;
  text: string;
  dateCreated: string;
  dateModified: string;
  profileImage: string;
  directoryName: string;
  md5Sum: string;
  fileSizeKb: string;
  md5SumText: string;
  isHidden: string;
}
