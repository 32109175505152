<h2 mat-dialog-title>Confirm Lead Creation  <mat-icon class="color-red">info</mat-icon></h2>
<div mat-dialog-content class="flex flex-align-center">
  <p>
    Lead with id <span class="color-blue cursor-pointer" (click) = "redirectToLeadPage()">{{data.searchItemId}}</span> 
    already has Contact with the same email you are trying to add <span class="color-blue">{{data.email}}</span>. 
    Would you like to proceed with creating this Lead? It will be added without Contact information in this case.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="warn" (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSubmit()">Create</button>
</div>
