import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { CalendarPageComponent } from './calendar-page/calendar-page.component';
import { SearchItemsPageComponent } from './search-items-page/search-items-page.component';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { WorkItemsPageComponent } from './work-items-page/work-items-page.component';
import { SourcingPageComponent } from './sourcing-page/sourcing-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AuthGuard } from './app-guards/auth.guard';
import { LoginPageComponent } from './login-page/login-page.component';
import { SearchItemPageComponent } from './search-item-page/search-item-page.component';
import { WorkItemPageComponent } from './work-item-page/work-item-page.component';
import { environment } from 'src/environments/environment';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { CompanyDetailComponent } from './customer-page/customer-page.component';
import { QuickSearchPageComponent } from './quick-search-page/quick-search-page.component';
import { CreateSearchItemPageComponent } from './create-search-item-page/create-search-item-page.component';
import { CreateCustomerPageComponent } from './create-customer-page/create-customer-page.component';
import { CreateWorkItemPageComponent } from './create-work-item-page/create-work-item-page.component';
import { TemplateSettingsPageComponent } from './template-settings-page/template-settings-page.component';
import { MyEventsPageComponent } from './my-events-page/my-events-page.component'
import { HashtagsPageComponent } from './hashtags-page/hashtags-page.component';
import { ContactItemsPageComponent } from './contact-items-page/contact-items-page.component';
import { AppService } from './app-services/app.service';
import { SharedSearchItemsComponent } from './shared-search-items-page/shared-search-items-page.component';
import { MatrixUsersComponent } from './matrix-users-page/matrix-users-page.component';
import { ReportsComponent } from './reports/reports.component';
import { ShareNormsComponent } from './share-norms-page/share-norms-page.component';
import { CvSettingsComponent } from './cv-settings-page/cv-settings-page.component';
import { HashtagSearchItemsComponent } from './hashtag-search-items/hashtag-search-items.component'
import { ManageUsersComponent } from './manage-users-page/manage-users-page.component';
import { FollowUpSettingsComponent } from './follow-up-settings-page/follow-up-settings-page.component';
import { FollowUpComponent } from './follow-up-page/follow-up-page.component';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { CompanyProfilePageComponent } from './company-profile-page/company-profile-page.component';
import { SearchItemStatusesPageComponent } from './search-item-statuses-page/search-item-statuses-page.component';
import { RootRoleGuard } from './app-guards/root-role.guard';
import { AuthRedirectPageComponent } from './auth-redirect-page/auth-redirect-page.component';
import { AuthRedirectPageGuard } from './app-guards/auth-redirect-page.guard';
import { ActivePollPageComponent } from './active-poll-page/active-poll-page.component';
import { DepartmnetsPageComponent } from './departmnets-page/departmnets-page.component';
import { DepartmentPageComponent } from './department-page/department-page.component';
import { MyProfileGuard } from './app-guards/my-profile.guard';
import { SettingsGuard } from './app-guards/settings.guard';
import { MatrixSearchItemFieldsComponent } from './matrix-search-item-fields/matrix-search-item-fields.component';

const app = new AppService();



const routes: Routes = [
  { path: 'login', component: LoginPageComponent },

  { path: 'registration', component: RegistrationPageComponent },
  { path: 'auth-redirect', component: AuthRedirectPageComponent, canActivate: [AuthRedirectPageGuard] },

  // { path: 'calendar', component: CalendarPageComponent, canActivate: [AuthGuard] },

  { path: 'create-company-profile', component: CreateCustomerPageComponent, canActivate: [AuthGuard, MyProfileGuard] },
  // { path: app.type.names['searchItems'].toLowerCase().concat('/create-').concat(app.type.names['searchItem'].toLowerCase()), component: CreateSearchItemPageComponent, canActivate: [AuthGuard, RootRoleGuard] },
  { path: app.type.names['searchItems'].toLowerCase(), component: SearchItemsPageComponent, canActivate: [AuthGuard] },
  { path: app.type.names['searchItems'].toLowerCase().concat('/:id'), component: SearchItemPageComponent, canActivate: [AuthGuard] },

  { path: app.type.names['workItems'].toLowerCase().concat('/create-').concat(app.type.names['workItem'].toLowerCase()), component: CreateWorkItemPageComponent, canActivate: [AuthGuard] },
  { path: app.type.names['workItems'].toLowerCase(), component: WorkItemsPageComponent, canActivate: [AuthGuard] },
  { path: app.type.names['workItems'].toLowerCase().concat('/:id'), component: WorkItemPageComponent, canActivate: [AuthGuard] },

  // { path: app.type.names['customers'].toLowerCase().concat('/create-').concat(app.type.names['customer'].toLowerCase()), component: CreateCustomerPageComponent, canActivate: [AuthGuard] },
  // { path: app.type.names['customers'].toLowerCase(), component: CustomersPageComponent, canActivate: [AuthGuard] },
  // { path: app.type.names['customers'].toLowerCase().concat('/:id'), component: CompanyDetailComponent, canActivate: [AuthGuard] },

  // { path: app.type.names['hashtags'].toLowerCase(), component: HashtagsPageComponent, canActivate: [AuthGuard] },
  // { path: app.type.names['hashtags'].toLowerCase().concat('/:id'), component: HashtagSearchItemsComponent, canActivate: [AuthGuard] },

  { path: 'settings', component: SettingsPageComponent, canActivate: [AuthGuard, SettingsGuard] },
  { path: 'settings/statuses', component: SearchItemStatusesPageComponent, canActivate: [AuthGuard, RootRoleGuard] },
  { path: 'settings/manage-users', component: ManageUsersComponent, canActivate: [AuthGuard, SettingsGuard] },
  { path: 'settings/matrix-search-item-fields', component: MatrixSearchItemFieldsComponent, canActivate: [AuthGuard, SettingsGuard] },
  // { path: 'settings/share-norms', component: ShareNormsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/cv-settings', component: CvSettingsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/follow-up', component: FollowUpComponent, canActivate: [AuthGuard] },
  // { path: 'settings/follow-up-settings', component: FollowUpSettingsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/shared-search-items', component: SharedSearchItemsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/reports', component: ReportsComponent, canActivate: [AuthGuard] },
  // { path: 'settings/matrix-users', component: MatrixUsersComponent, canActivate: [AuthGuard] },
  // { path: 'settings/template-settings', component: TemplateSettingsPageComponent, canActivate: [AuthGuard] },

  // { path: app.type.names['contactItems'].toLowerCase(), component: ContactItemsPageComponent, canActivate: [AuthGuard] },

  // { path: 'companies', component: CustomersPageComponent, canActivate: [AuthGuard] },

  // { path: 'dashboard', component: DashboardPageComponent, canActivate: [AuthGuard] },

  // { path: 'sourcing', component: SourcingPageComponent, canActivate: [AuthGuard] },

  { path: 'active-poll-candidate', component: ActivePollPageComponent, canActivate: [AuthGuard] },

  { path: 'quick-search', component: QuickSearchPageComponent, canActivate: [AuthGuard] },
  { path: 'departmnets', component: DepartmnetsPageComponent, canActivate: [AuthGuard] },
  { path: 'departmnets/:id', component: DepartmentPageComponent, canActivate: [AuthGuard] },

  { path: 'my-events', component: MyEventsPageComponent, canActivate: [AuthGuard] },
  // DOUBLE ROUTE
  { path: app.type.names['customer'].toLowerCase(), component: CompanyDetailComponent, canActivate: [AuthGuard] },
  {
    path: '',
    redirectTo: '/'.concat(app.type.names['searchItems'].toLowerCase()),
    pathMatch: 'full'
  },
  { path: 'company-profile-page', component: CompanyProfilePageComponent , canActivate: [AuthGuard, MyProfileGuard] },
  { path: '**', component: NotFoundPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      { enableTracing: !environment.production }
    ),
    CommonModule
  ],
  exports: [
    RouterModule
  ],
  declarations: []
})
export class AppRoutingModule { }
