"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchItemsService = void 0;
const search_item_type_1 = require("../types/search-item-type");
const search_item_in_work_type_1 = require("../types/search-item-in-work-type");
const search_item_history_type_1 = require("../types/search-item-history-type");
const share_search_item_type_1 = require("../types/share-search-item-type");
class SearchItemsService {
    constructor(extraFieldsService) {
        this.extraFieldsService = extraFieldsService;
    }
    mapReverseSearchItem(src) {
        const result = {};
        if (src.address || src.address === '')
            result.address = src.address;
        if (src.bestTimeToCall)
            result.best_time_to_call = src.bestTimeToCall;
        if (src.canRelocate || src.canRelocate === '')
            result.can_relocate = src.canRelocate;
        if (src.candidateId)
            result.id = src.candidateId;
        if (src.city || src.city === '')
            result.city = src.city;
        if (src.currentEmployer)
            result.current_employer = src.currentEmployer;
        if (src.currentPay)
            result.current_pay = src.currentPay;
        if (src.dateAvailable)
            result.date_available = src.dateAvailable;
        if (src.desiredPay)
            result.desired_pay = src.desiredPay;
        if (src.dialog || src.dialog === '')
            result.dialog = src.dialog;
        if (src.eeoDisabilityStatus)
            result.eeo_disability_status = src.eeoDisabilityStatus;
        if (src.eeoEthnicTypeId)
            result.eeo_ethnic_type_id = src.eeoEthnicTypeId;
        if (src.eeoGender)
            result.eeo_gender = src.eeoGender;
        if (src.eeoVeteranTypeId)
            result.eeo_veteran_type_id = src.eeoVeteranTypeId;
        if (src.email1 || src.email1 === '')
            result.email1 = src.email1;
        if (src.email2)
            result.email2 = src.email2;
        if (src.email2 === '')
            result.email2 = null;
        if (src.firstName || src.firstName === null)
            result.first_name = src.firstName;
        if (src.img)
            result.img = src.img;
        if (src.imgResize)
            result.img_resize = src.imgResize;
        if (src.importId)
            result.import_id = src.importId;
        if (src.isActive)
            result.is_active = src.isActive;
        if (src.isAdminHidden)
            result.is_admin_hidden = src.isAdminHidden;
        if (src.isHot)
            result.is_hot = src.isHot;
        if (src.keySkills || src.keySkills === '')
            result.key_skills = src.keySkills;
        if (src.lastName || src.lastName === '')
            result.last_name = src.lastName;
        if (src.middleName)
            result.middle_name = src.middleName;
        if (src.notes || src.notes === '')
            result.notes = src.notes;
        if (src.owner || src.owner === '')
            result.owner = src.owner;
        if (src.phoneCell || src.phoneCell === '')
            result.phone_cell = src.phoneCell;
        if (src.phoneHome)
            result.phone_home = src.phoneHome;
        if (src.phoneWork)
            result.phone_work = src.phoneWork;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.source)
            result.source = src.source;
        if (src.state || src.state === '')
            result.state = src.state;
        if (src.webSite || src.webSite === '')
            result.web_site = src.webSite;
        if (src.zip)
            result.zip = src.zip;
        if (src.extraFields)
            result.extraFields = this.extraFieldsService.mapReverseExtraFields(src.extraFields);
        return result;
    }
    mapSearchItems(raw) {
        return raw.map((val) => this.mapSearchItem(val));
    }
    mapSearchItem(raw) {
        let result = new search_item_type_1.SearchItem();
        result = keysToCamel(raw);
        return result;
    }
    mapSearchItemsInWork(raw) {
        return raw.map(val => this.mapSearchItemInWork(val));
    }
    mapSearchItemInWork(raw) {
        const result = new search_item_in_work_type_1.SearchItemInWork();
        result.activityNotes = raw.activity_notes;
        result.activityType = raw.activity_type;
        result.activityTypeDescription = raw.activity_type_description;
        result.candidateId = raw.candidate_id;
        result.candidateJoborderId = raw.candidate_joborder_id;
        result.companyId = raw.company_id;
        result.companyIsHot = raw.company_is_hot;
        result.companyName = raw.company_name;
        result.dateModified = raw.date_modified;
        result.dateModifiedSort = raw.date_modified_sort;
        result.firsName = raw.first_name;
        result.img = raw.img;
        result.isHot = raw.is_hot;
        result.joborderId = raw.joborder_id;
        result.joborderIsHot = raw.joborder_is_hot;
        result.joborderTitle = raw.joborder_title;
        result.lastName = raw.last_name;
        result.ownerId = raw.owner_id;
        result.phoneCell = raw.phone_cell;
        result.phoneHome = raw.phone_home;
        result.phoneWork = raw.phone_work;
        result.siteId = raw.site_id;
        result.status = raw.status;
        result.statusSort = raw.status_sort;
        result.userFirstName = raw.user_first_name;
        result.userLastName = raw.user_last_name;
        return result;
    }
    mapSearchItemsHistory(raw) {
        return raw.map(val => this.mapSearchItemHistory(val));
    }
    mapSearchItemHistory(raw) {
        const result = new search_item_history_type_1.SearchItemHistory();
        result.historyId = raw.history_id;
        result.dataItemId = raw.data_item_id;
        result.dataItemType = raw.data_item_type;
        result.theField = raw.the_field;
        result.previousValue = raw.previous_value;
        result.newValue = raw.new_value;
        result.description = raw.description;
        result.setDate = raw.set_date;
        result.enteredBy = raw.entered_by;
        result.siteId = raw.site_id;
        return result;
    }
    mapShareSearchItems(raw) {
        return raw.map(val => this.mapShareSearchItem(val));
    }
    mapShareSearchItem(raw) {
        const result = new share_search_item_type_1.ShareSearchItem();
        result.id = raw.id;
        result.workItemId = raw.joborder_id;
        result.workItemTitle = raw.joborder_title;
        result.companyId = raw.company_id;
        result.companyName = raw.company_name;
        result.exchangerId = raw.exchanger_id;
        result.exchangerName = raw.exchanger_name;
        result.candidateId = raw.candidate_id;
        result.sharingType = raw.sharing_type;
        result.sharingTime = raw.sharing_time;
        result.candidateIdEr = raw.candidate_id_er;
        result.candidateActivity = raw.candidate_activity;
        result.candidateStatus = raw.candidate_status;
        return result;
    }
}
exports.SearchItemsService = SearchItemsService;
const toCamel = (s) => {
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase().replace('-', '').replace('_', '');
    });
};
const isArray = function (a) {
    return Array.isArray(a);
};
const isObject = function (o) {
    return o === Object(o) && !isArray(o) && typeof o !== 'function';
};
const keysToCamel = function (o) {
    if (isObject(o)) {
        const n = {};
        Object.keys(o)
            .forEach((k) => {
            n[toCamel(k)] = keysToCamel(o[k]);
        });
        return n;
    }
    else if (isArray(o)) {
        return o.map((i) => {
            return keysToCamel(i);
        });
    }
    return o;
};
