import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { GroupUsersType, UserType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-user-actions-dialog',
  templateUrl: './user-actions-dialog.component.html',
  styleUrls: ['./user-actions-dialog.component.scss']
})
export class UserActionsDialogComponent implements OnInit {

  
  public currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));
  public creationMode:boolean = true;
  public userForm: FormGroup;
  public groupUsers: GroupUsersType[]

  constructor(
    public dialogRef: MatDialogRef<UserActionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {user: UserType | null, creationMode: boolean},
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.creationMode = this.data.creationMode;
    this.getData();
  }

  async getData() {
    if(+this.currentUser.accessLevel >= 400) {
      await this.getGroupUser().then(res => this.groupUsers = res)
    }
    this.initForm(this.data.user)
  }

  initForm(user: UserType | null) {
    console.log(user)
    this.userForm = new FormGroup({
      firstName: new FormControl(user ? user.firstName: '', [Validators.required]),
      lastName: new FormControl(user ? user.lastName: '', [Validators.required]),
      email: new FormControl(user ? user.email: '', [Validators.required]),
      login: new FormControl(user ? user.login: '', [Validators.required]),
      password: new FormControl(user ? user.password: '', [Validators.required]),
      groupUserId: new FormControl(user ? user.groupUserId: ''),
      accessLevel: new FormControl(user ? user.accessLevel: "200", [Validators.required])
    });
    if(+this.currentUser.accessLevel >= 400) {
      this.userForm.get('groupUserId').setValidators([Validators.required]);
    }
  }

  getGroupUser(): Promise<GroupUsersType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.list(res => resolve(res), err => reject(err))
    })
  }

  createUser(user: UserType):Promise<boolean> {
    return new Promise( (resolve, reject) => {
      YhCore.users.create(user, res => resolve(res), err => reject(err))
    })
  }

  updateUser(user: UserType): Promise<boolean> {
    return new Promise( (resolve, reject) => {
      YhCore.users.edit(user, res => resolve(res), err => reject(err))
    })
  }

  onSave() {
    const value = this.userForm.value;
    value.companyId = this.currentUser.companyId;
    if(this.creationMode) {
      this.createUser(value).then( res => {
        this.snackBar.open("User was successfully created", "OK", {duration: 3000});
        this.dialogRef.close(true)
      })
      return
    }
    this.updateUser(value).then( res => {
      this.snackBar.open("User was successfully updated", "OK", {duration: 3000});
      this.dialogRef.close(true);
    })
  }

  onCancel() {
    this.dialogRef.close(false)
  }

}
