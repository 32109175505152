<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle>Add {{app.type.names['searchItem']}}</mat-card-subtitle>
    </mat-card>
    <mat-card class="main-content">
      <mat-card-content>

        <div>
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['firstName'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.firstName">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['lastName'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.lastName">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['email1'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.email1">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['email2'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.email2">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['webSite'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.webSite">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['address'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.address">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['phoneHome'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.phoneHome">
          </mat-form-field>
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['phoneCell'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.phoneCell">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['phoneWork'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.phoneWork">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['city'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.city">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['state'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.state">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['desiredPay'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.desiredPay">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['bestTimeToCall'] }}</mat-label>
            <input matInput [(ngModel)]="candidate.bestTimeToCall">
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['keySkills'] }}</mat-label>
            <textarea matInput [(ngModel)]="candidate.keySkills"></textarea>
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['notes'] }}</mat-label>
            <textarea matInput [(ngModel)]="candidate.notes"></textarea>
          </mat-form-field>
    
    
          <mat-form-field appearance="outline">
            <mat-label>{{ app.type.searchItemNames['dialog'] }}</mat-label>
            <textarea matInput [(ngModel)]="candidate.dialog"></textarea>
          </mat-form-field>
        </div>
  
        <mat-checkbox [(checked)]="isCanRelocate" (change)="isCanRelocate = !isCanRelocate; candidate.canRelocate = isCanRelocate ? '1': '0'">{{ app.type.searchItemNames['canRelocate'] }}</mat-checkbox>
  
        <br>
  
        <!-- <label for="attachment">{{ app.type.searchItemNames[''] }}</label> -->
        <!-- <input (change)="onFileSelected($event, 'FRONT')" type="file" id="attachment"> -->
  
        <br>
  
        <button mat-flat-button color="primary" (click)="onAddCandidate()" [disabled] = "loading">ADD</button>
  
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>
