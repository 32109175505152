<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>

      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row['statusName']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="order">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Order</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row['statusRank']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
          <mat-cell *matCellDef="let row">
            <button mat-icon-button (click)="onDelete(row)">
              <mat-icon>close</mat-icon>
            </button>
            <button mat-icon-button (click)="onEdit(row)">
              <mat-icon>edit</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-card id="spinner" *ngIf="isLoading">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div><!-- End of Table Wrap Inner-->
  </div>
</div>
