"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersService = void 0;
const user_type_1 = require("../types/user-type");
class UsersService {
    constructor() {
    }
    mapReverseUser(src) {
        const result = {};
        if (src.userId)
            result.user_id = src.userId;
        if (src.login)
            result.login = src.login;
        if (src.email)
            result.email = src.email;
        if (src.accessLevel)
            result.access_level = src.accessLevel;
        if (src.lastName)
            result.last_name = src.lastName;
        if (src.firstName)
            result.first_name = src.firstName;
        if (src.password)
            result.password = src.password;
        if (src.emailPassword)
            result.email_password = src.emailPassword;
        if (src.groupUserId)
            result.group_user_id = src.groupUserId;
        if (src.companyId)
            result.company_id = src.companyId;
        return result;
    }
    mapUsers(raw) {
        return raw.map((val) => this.mapUser(val));
    }
    mapUser(raw) {
        const result = new user_type_1.User();
        result.userId = raw.user_id;
        result.login = raw.login;
        result.email = raw.email;
        result.accessLevel = raw.access_level;
        result.lastName = raw.last_name;
        result.firstName = raw.first_name;
        result.groupUserId = raw.group_user_id;
        result.companyId = raw.company_id;
        return result;
    }
}
exports.UsersService = UsersService;
