import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { YhCore, CustomerType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-company-profile-page',
  templateUrl: './company-profile-page.component.html',
  styleUrls: ['./company-profile-page.component.scss']
})
export class CompanyProfilePageComponent implements OnInit {
  public company: CustomerType;
  public editMode = false;

  constructor(
    private router: Router,
    public app: AppService,
    private snackBar: MatSnackBar
  ) { }

  async ngOnInit() {
    this.company = await this.getCompany();
  }

  getCompany():Promise<CustomerType> {
    return new Promise( (resolve, reject) => {
      YhCore.customers.read('', (res:CustomerType) => {
        res.notes = JSON.parse(res.notes);
        resolve(res)
      }, err => {this.errorHandling(err)})
    })
  }

  deleteProfile() {
    YhCore.customers.remove(+this.company.companyId, suc => {
      YhCore.api.invalidate();
      this.router.navigate(['/login']);
    }, err => { })
  }

  editProfile(){
    this.editMode = false
    YhCore.customers.edit(this.company, suc=>{
      this.snackBar.open('Company data was successfully saved', 'OK', {duration: 3000})
    }, err=>{})
  }

  errorHandling(err) {
    this.snackBar.open(err, "ERROR", {duration: 5000})
  }

}
