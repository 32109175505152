import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NavigationComponent } from './app-components/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatStepperModule} from '@angular/material/stepper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppRoutingModule } from './app-routing.module';
import { SearchItemsPageComponent } from './search-items-page/search-items-page.component';
import { DashboardPageComponent } from './dashboard-page/dashboard-page.component';
import { SourcingPageComponent } from './sourcing-page/sourcing-page.component';
import { WorkItemsPageComponent } from './work-items-page/work-items-page.component';
import { WorkItemsTableComponent } from './app-tables/work-items-table/work-items-table.component';
import { CustomersPageComponent } from './customers-page/customers-page.component';
import { CalendarPageComponent } from './calendar-page/calendar-page.component';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { HttpClientModule } from '@angular/common/http';
import { SearchItemPageComponent } from './search-item-page/search-item-page.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginPageComponent } from './login-page/login-page.component';
import { AuthGuard } from './app-guards/auth.guard';
import { WorkItemPageComponent } from './work-item-page/work-item-page.component';
import { SearchItemCreateActionDialogComponent } from './app-dialogs/search-item-create-action-dialog/search-item-create-action-dialog.component';
import { SearchItemAddToPipelineDialogComponent } from './app-dialogs/search-item-add-to-pipeline-dialog/search-item-add-to-pipeline-dialog.component';
import { CandidateDetailPipelineTableComponent } from './app-tables/work-item-pipelines-table/work-item-pipelines-table.component';
import { CandidateDetailActivityLogComponent } from './app-tables/search-item-activity-logs-table/search-item-activity-logs-table.component';
import { SettingsPageComponent } from './settings-page/settings-page.component';
import { MyEventsOverlayComponent } from './app-overlays/my-events-overlay/my-events-overlay.component';
import { CompanyDetailComponent } from './customer-page/customer-page.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {NgxChartsModule} from '@swimlane/ngx-charts';
import { SearchItemPipelinesTableComponent } from './app-tables/search-item-pipelines-table/search-item-pipelines-table.component';
import { CustomersTableComponent } from './app-tables/customers-table/customers-table.component';
import { QuickSearchPageComponent } from './quick-search-page/quick-search-page.component';
import { SearchItemsTableComponent } from './app-tables/search-items-table/search-items-table.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { CreateSearchItemPageComponent } from './create-search-item-page/create-search-item-page.component';
import { CreateWorkItemPageComponent } from './create-work-item-page/create-work-item-page.component';
import { CreateCustomerPageComponent } from './create-customer-page/create-customer-page.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarHandleEventDialogComponent } from './app-dialogs/calendar-handle-event-dialog/calendar-handle-event-dialog.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import { DatePipe } from '@angular/common'
import { SearchItemSendEmailDialogComponent } from './app-dialogs/search-item-send-email-dialog/search-item-send-email-dialog.component';
import { TemplateSettingsPageComponent } from './template-settings-page/template-settings-page.component';
import { TemplatesTableComponent } from './app-tables/templates-table/templates-table.component';
import { ContactItemsTableComponent } from './app-tables/contact-items-table/contact-items-table.component';
import { CreateContactItemDialogComponent } from './app-dialogs/create-contact-item-dialog/create-contact-item-dialog.component';
import { CreateTemplateDialogComponent } from './app-dialogs/create-template-dialog/create-template-dialog.component';
import { ContactItemDialogComponent } from './app-dialogs/contact-item-dialog/contact-item-dialog.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MyEventsPageComponent } from './my-events-page/my-events-page.component';
import { EventsTableComponent } from './app-tables/events-table/events-table.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { A11yModule } from '@angular/cdk/a11y';
import {CdkDetailRowDirective} from './app-tables/search-item-pipelines-table/cdk-detail-row.directive';
import { HashtagsPageComponent } from './hashtags-page/hashtags-page.component';
import { HashtagsTableComponent } from './app-tables/hashtags-table/hashtags-table.component';
import { CreateHashtagDialogComponent } from './app-dialogs/create-hashtag-dialog/create-hashtag-dialog.component';
import { MessengerOverlayComponent } from './app-overlays/messenger-overlay/messenger-overlay.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { ContactItemsPageComponent } from './contact-items-page/contact-items-page.component';
import { SharedSearchItemsTableComponent } from './app-tables/shared-search-items-table/shared-search-items-table.component';
import { SharedSearchItemsComponent } from './shared-search-items-page/shared-search-items-page.component';
import { RequestForShareSearchItemsTableComponent } from './app-tables/request-for-share-search-items-table/request-for-share-search-items-table.component';
import { MatrixUsersComponent } from './matrix-users-page/matrix-users-page.component';
import { ReportsComponent } from './reports/reports.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ShareNormsComponent } from './share-norms-page/share-norms-page.component';
import { ShareNormsTableComponent } from './app-tables/share-norms-table/share-norms-table.component';
import { CvSettingsComponent } from './cv-settings-page/cv-settings-page.component';
import { CvFieldSettingsTableComponent } from './app-tables/cv-field-settings-table/cv-field-settings-table.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ShowAllHashtagsDialogComponent } from './app-dialogs/show-all-hashtags-dialog/show-all-hashtags-dialog.component';
import { HashtagSearchItemsComponent } from './hashtag-search-items/hashtag-search-items.component';
import {ManageUsersComponent} from './manage-users-page/manage-users-page.component';
import {ManageUserTableComponent} from './app-tables/manage-user-table/manage-user-table.component';
import { FollowUpSettingsComponent } from './follow-up-settings-page/follow-up-settings-page.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { FollowUpComponent } from './follow-up-page/follow-up-page.component';
import { DialogConfirmLeadCreationComponent } from './app-dialogs/dialog-confirm-lead-creation/dialog-confirm-lead-creation.component';
import { ContactedSearchItemsTableComponent } from './app-tables/contacted-search-items-table/contacted-search-items-table.component';
import { SearchItemsWorkTableComponent } from './app-tables/search-items-work-table/search-items-work-table.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { QuickSourcingTableComponent } from './app-tables/quick-sourcing-table/quick-sourcing-table.component';
import { RecentHiresOfferTableComponent } from './app-tables/recent-hires-offer-table/recent-hires-offer-table.component';
import { RecentHiresPlaceTableComponent } from './app-tables/recent-hires-place-table/recent-hires-place-table.component';
import { FollowUpTableComponent } from './app-tables/follow-up-table/follow-up-table.component';
import { AddCalibrationDialogComponent } from './app-dialogs/add-calibration-dialog/add-calibration-dialog.component';
import { AttachmentsTabComponent } from './search-item-page/tab-component/attachments-tab/attachments-tab.component';
import { HistoryTabComponent } from './search-item-page/tab-component/history-tab/history-tab.component';
import { ExperienceDetailTabComponent } from './search-item-page/tab-component/experience-detail-tab/experience-detail-tab.component';
import { AddCvSettingsDialogComponent } from './app-dialogs/add-cv-settings-dialog/add-cv-settings-dialog.component';
import { PreviewCvSettingsDialogComponent } from './app-dialogs/preview-cv-settings-dialog/preview-cv-settings-dialog.component';
import { AttachmentsTableComponent } from './app-tables/attachments-table/attachments-table.component';
import { QuickSearchService } from './app-services/quick-search.service';
import { RegistrationPageComponent } from './registration-page/registration-page.component';
import { CompanyProfilePageComponent } from './company-profile-page/company-profile-page.component';
import { SearchItemsCardViewComponent } from './search-items-card-view/search-items-card-view.component';
import { SearchItemStatusesPageComponent } from './search-item-statuses-page/search-item-statuses-page.component';
import { SearchItemStatusesTableComponent } from './app-tables/search-item-statuses-table/search-item-statuses-table.component';
import { SearchItemAddStatusDialogComponent } from './app-dialogs/search-item-add-status-dialog/search-item-add-status-dialog.component';
import { SearchItemEditStatusDialogComponent } from './app-dialogs/search-item-edit-status-dialog/search-item-edit-status-dialog.component';
import { WorkItemSubmitDialogComponent } from './app-dialogs/work-item-submit-dialog/work-item-submit-dialog.component';
import { WorkItemSubmitTableComponent } from './app-tables/work-item-submit-table/work-item-submit-table.component';
import { ConditionTabComponent } from './work-item-page/tab-component/condition-tab/condition-tab.component';
import { WorkItemConditionTableComponent } from './app-tables/work-item-condition-table/work-item-condition-table.component';
import { WorkItemAcceptConditionDialogComponent } from './app-dialogs/work-item-accept-condition-dialog/work-item-accept-condition-dialog.component';
import { WorkItemAcceptedConditionComponent } from './work-item-accepted-condition/work-item-accepted-condition.component';
import { WorkItemDeclineConditionDialogComponent } from './app-dialogs/work-item-decline-condition-dialog/work-item-decline-condition-dialog.component';
import { AbstractTableComponent } from './abstract-table/abstract-table.component';
import { ShowBackCardDirective } from './login-page/directive/show-back-card.directive';
import { ShowFrontCardDirective } from './login-page/directive/show-front-card.directive';
import { CalibrationTabComponent } from './work-item-page/tab-component/calibration-tab/calibration-tab.component';
import { AuthRedirectPageComponent } from './auth-redirect-page/auth-redirect-page.component'
import { QRCodeModule } from 'angularx-qrcode';
import { ActivePollPageComponent } from './active-poll-page/active-poll-page.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { AutoCompleteComponent } from './auto-complete/auto-complete.component';
import { OptionsScrollDirective } from './app-directive/options-scroll.directive';
import { JoborderGroupPipe } from './work-items-page/joborder-group.pipe';
import { WorkItemGroupDialogComponent } from './app-dialogs/work-item-group-dialog/work-item-group-dialog.component';
import { DepartmentsTableComponent } from './app-tables/departments-table/departments-table.component';
import { DepartmnetsPageComponent } from './departmnets-page/departmnets-page.component';
import { DepartmentPageComponent } from './department-page/department-page.component';
import { GroupUserTableComponent } from './app-tables/group-user-table/group-user-table.component';
import { CustomerAccessOffersOrWorkItemsDialogComponent } from './app-dialogs/customer-access-offers-or-work-items-dialog/customer-access-offers-or-work-items-dialog.component';
import { GroupUsersDialogComponent } from './app-dialogs/group-users-dialog/group-users-dialog.component';
import { ConfirmCreateAdminGroupDialogComponent } from './app-dialogs/confirm-create-admin-group-dialog/confirm-create-admin-group-dialog.component';
import { UserActionsDialogComponent } from './app-dialogs/user-actions-dialog/user-actions-dialog.component';
import { AccessWorkItemDialogComponent } from './app-dialogs/access-work-item-dialog/access-work-item-dialog.component';
import { MatrixSearchItemFieldsComponent } from './matrix-search-item-fields/matrix-search-item-fields.component'


@NgModule({
  declarations: [AppComponent,
    NavigationComponent,
    SearchItemsPageComponent,
    DashboardPageComponent,
    SourcingPageComponent,
    WorkItemsPageComponent,
    CustomersPageComponent,
    CalendarPageComponent,
    NotFoundPageComponent,
    LoginPageComponent,
    SearchItemPageComponent,
    WorkItemPageComponent,
    SearchItemCreateActionDialogComponent,
    CandidateDetailPipelineTableComponent,
    CandidateDetailActivityLogComponent,
    SettingsPageComponent,
    CompanyDetailComponent,
    SearchItemAddToPipelineDialogComponent,
    WorkItemsTableComponent,
    SearchItemPipelinesTableComponent,
    CustomersTableComponent,
    QuickSearchPageComponent,
    SearchItemsTableComponent,
    CreateSearchItemPageComponent,
    CreateWorkItemPageComponent,
    CreateCustomerPageComponent,
    CalendarHandleEventDialogComponent,
    SearchItemSendEmailDialogComponent,
    TemplateSettingsPageComponent,
    TemplatesTableComponent,
    ContactItemsTableComponent,
    CreateContactItemDialogComponent,
    CreateTemplateDialogComponent,
    ContactItemDialogComponent,
    MyEventsPageComponent,
    EventsTableComponent,
    CdkDetailRowDirective,
    HashtagsPageComponent,
    HashtagsTableComponent,
    CreateHashtagDialogComponent,
    MessengerOverlayComponent,
    ContactItemsPageComponent,
    MyEventsOverlayComponent,
    SharedSearchItemsTableComponent,
    SharedSearchItemsComponent,
    RequestForShareSearchItemsTableComponent,
    MatrixUsersComponent,
    ReportsComponent,
    ShareNormsComponent,
    ShareNormsTableComponent,
    CvSettingsComponent,
    CvFieldSettingsTableComponent,
    ShowAllHashtagsDialogComponent,
    HashtagSearchItemsComponent,
    ManageUserTableComponent,
    ManageUsersComponent,
    DialogConfirmLeadCreationComponent,
    ContactedSearchItemsTableComponent,
    SearchItemsWorkTableComponent,
    UpcomingEventsComponent,
    QuickSourcingTableComponent,
    RecentHiresOfferTableComponent,
    RecentHiresPlaceTableComponent,
    AddCalibrationDialogComponent,
    FollowUpSettingsComponent,
    FollowUpComponent,
    FollowUpTableComponent,
    AttachmentsTabComponent,
    HistoryTabComponent,
    ExperienceDetailTabComponent,
    AttachmentsTableComponent,
    ExperienceDetailTabComponent,
    AddCvSettingsDialogComponent,
    PreviewCvSettingsDialogComponent,
    RegistrationPageComponent,
    CompanyProfilePageComponent,
    CompanyProfilePageComponent,
    SearchItemsCardViewComponent,
    SearchItemStatusesPageComponent,
    SearchItemStatusesTableComponent,
    SearchItemAddStatusDialogComponent,
    SearchItemEditStatusDialogComponent,
    WorkItemSubmitDialogComponent,
    WorkItemSubmitTableComponent,
    ConditionTabComponent,
    WorkItemConditionTableComponent,
    WorkItemAcceptConditionDialogComponent,
    WorkItemAcceptedConditionComponent,
    WorkItemDeclineConditionDialogComponent,
    AbstractTableComponent,
    ShowBackCardDirective,
    ShowFrontCardDirective,
    CalibrationTabComponent,
    AuthRedirectPageComponent,
    ActivePollPageComponent,
    AutoCompleteComponent,
    OptionsScrollDirective,
    JoborderGroupPipe,
    WorkItemGroupDialogComponent,
    DepartmentsTableComponent,
    DepartmnetsPageComponent,
    DepartmentPageComponent,
    GroupUserTableComponent,
    CustomerAccessOffersOrWorkItemsDialogComponent,
    GroupUsersDialogComponent,
    ConfirmCreateAdminGroupDialogComponent,
    UserActionsDialogComponent,
    AccessWorkItemDialogComponent,
    MatrixSearchItemFieldsComponent,
  ],
  imports: [ BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    AppRoutingModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSortModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatInputModule,
    MatDividerModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatTooltipModule,
    MatStepperModule,
    MatAutocompleteModule,
    PdfViewerModule,
    NgxChartsModule,
    ScrollingModule,
    MatButtonToggleModule,
    CalendarModule.forRoot({
        provide: DateAdapter,
        useFactory: adapterFactory,
    }),
    MatDatepickerModule,
    MatNativeDateModule,
    MatRadioModule,
    MatBadgeModule,
    OverlayModule,
    A11yModule,
    MatExpansionModule,
    SlickCarouselModule,
    MatSlideToggleModule,
    CKEditorModule,
    DragDropModule,
    MatChipsModule,
    QRCodeModule,
  ],
  entryComponents: [
    SearchItemCreateActionDialogComponent
  ],
  providers: [
    AuthGuard,
    QuickSearchService,
    DatePipe,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
    { provide: 'API_URL', useValue: 'https://admapi.hiringrequest.com/hunt_rest/' },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
