export class YhNotification {
  public id: string;
  public action: string;
  public itemId: string;
  public itemType: string;
  public enteredBy: string;
  public firstName: string;
  public lastName: string;
  public date: string;
  public forAccessLevel: string;
  public forUser: string;
  public viewed: boolean;
  public description: string;
}
