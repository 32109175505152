<app-navigation>
  <div style="flex-grow: 1;">
    <mat-tab-group [(selectedIndex)]='activeTabs' class="tab-group">
      <mat-tab label="Main info" class="tab-group__tab">

        <ng-container *ngIf="workItem && workItemNotes">
          <mat-card>
            <mat-card-content class="work-item-wrap">
              <div class="header width-100 flex flex-end xs-column sm-row flex-align-center">
                <div class="work-item-info flex flex-align-center" [class.edit-mode]="isEditMode">
                  <div class="work-item-name">
                    <div *ngIf="!isEditMode">
                      <h2>{{ workItem.title}}</h2>
                    </div>
                  </div>

                  <mat-form-field appearance="outline" floatLabel="always" class="width-100" *ngIf="isEditMode">
                    <mat-label>{{ app.type.workItemItemNames['title'] }}</mat-label>
                    <input matInput [(ngModel)]="workItem.title" [readonly]="!isEditMode">
                  </mat-form-field>
                </div>

                <div class="work-item-submit-buttons" *ngIf="!isEditMode">
                  <ng-container *ngIf="workItem.public === '0'">
                    <button mat-flat-button color="primary" (click)="submitTo()">Submit To</button>
                  </ng-container>
                  <ng-container *ngIf="workItem.public === '20'">
                    <button mat-flat-button color="primary" style="cursor: auto;">Submitted to HuntME</button>
                  </ng-container>
                  <ng-container *ngIf="workItem.public === '30'">
                    <button mat-flat-button color="accent" (click)="navigateToWorkItemCondition()">Proposal from HuntME</button>
                  </ng-container>
                  <ng-container *ngIf="workItem.public === '40'">
                    <button mat-flat-button class="conditions-acepted" (click)="navigateToWorkItemCondition()">Conditions Accepted</button>
                  </ng-container>
                </div>

                <div class="header__actions xs-order-0 sm-order-2">
<!--                  <button mat-flat-button color="primary" (click)="openDialogAccessCustomer()">ACCESS</button>-->
                  <button mat-icon-button color="primary" *ngIf="!isEditMode" (click)="startEditMode()" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
                  <button mat-icon-button color="primary" *ngIf="isEditMode" (click)="updateJobOrder()" matTooltip="Save"><mat-icon>save</mat-icon></button>
                  <button mat-icon-button color="warn" (click)="onDeleteWorkItem()" matTooltip="Delete"><mat-icon>delete_forever</mat-icon></button>
                </div>

              </div>

              <mat-divider></mat-divider>

              <div class="work-item-area">
                <div class="work-item-area__row">
                  <!-- MAIN INFO END -->
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['contract'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.contract" [disabled]="!isEditMode">
                        <mat-option value="temporary">Temporary</mat-option>
                        <mat-option value="permanent">Permanent</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['workingDay'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.workingDay" [disabled]="!isEditMode">
                        <mat-option value="freeSchedule">Free schedule</mat-option>
                        <mat-option value="partTime">Part-time</mat-option>
                        <mat-option value="fullDay">Full day</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['workScheduleObject']['startEndWorkingDay'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.startEndWorkingDay" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['workScheduleObject']['lunchBreak'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.lunchBreak" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['workScheduleObject']['freeDays'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.freeDays" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['businessTrip'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.businessTrip" [disabled]="!isEditMode">
                        <mat-option value="noTrip">No trip</mat-option>
                        <mat-option value="abroad">Abroad</mat-option>
                        <mat-option value="withinUkraine">Within Ukraine</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['subordinates'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.subordinates" [disabled]="!isEditMode">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['growth'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.growth" [disabled]="!isEditMode">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['directManagerObject']['name'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.name" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['directManagerObject']['position'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.position" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['directManagerObject']['phone'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.phone" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['testPeriod'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.testPeriod" [disabled]="!isEditMode">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item item-area">
                    <ng-container *ngIf="!isEditMode">

                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['payment'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.payment" [readonly]="!isEditMode">
                      </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="isEditMode">

                      <div class="item-area__row">
                        <div class="item-area__column">
                          <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                            <mat-label>{{ app.type.workItemNotesNames['paymentFrom'] }}</mat-label>
                            <input matInput [(ngModel)]="workItemNotes.paymentFrom">
                          </mat-form-field>
                        </div>
                        <div class="item-area__column">
                          <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                            <mat-label>{{ app.type.workItemNotesNames['paymentTo'] }}</mat-label>
                            <input  matInput [(ngModel)]="workItemNotes.paymentTo">
                          </mat-form-field>
                        </div>
                      </div>
                      <mat-checkbox [(checked)]="workItemNotes.paymentSame" (change) ="onPaymentSameChecked()">Same for probation period</mat-checkbox>

                    </ng-container>
                  </div>
                  <div class="work-item-area__item item-area">
                    <ng-container *ngIf="!isEditMode">

                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['paymentProbationPeriod'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.paymentProbationPeriod" [readonly]="!isEditMode">
                      </mat-form-field>

                    </ng-container>
                    <ng-container *ngIf="isEditMode">

                      <div class="item-area__row">

                        <div class="item-area__column">
                          <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode || workItemNotes.paymentSame">
                            <mat-label>{{ app.type.workItemNotesNames['paymentProbationPeriodFrom'] }}</mat-label>
                              <input [disabled]="workItemNotes.paymentSame" matInput [(ngModel)]="workItemNotes.paymentProbationPeriodFrom">
                          </mat-form-field>
                        </div>

                        <div class="item-area__column">
                          <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode || workItemNotes.paymentSame">
                            <mat-label>{{ app.type.workItemNotesNames['paymentProbationPeriodTo'] }}</mat-label>
                              <input [disabled]="workItemNotes.paymentSame" matInput [(ngModel)]=" workItemNotes.paymentProbationPeriodTo">
                          </mat-form-field>
                        </div>

                      </div>
                      <mat-checkbox [(ngModel)]="workItemNotes.paymentBonuses">Bonuses</mat-checkbox>

                    </ng-container>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['guarantees'] }}</mat-label>
                      <input matInput [(ngModel)]="workItemNotes.guarantees" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['socialBenefits'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.socialBenefits" [disabled]="!isEditMode">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemItemNames['status'] }}</mat-label>
                      <mat-select [(ngModel)]="workItem.status" [disabled]="!isEditMode">
                        <mat-option value="Active">Active</mat-option>
                        <mat-option value="onHold">On Hold</mat-option>
                        <mat-option value="full">Full</mat-option>
                        <mat-option value="closed">Closed</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['positionLocation'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.positionLocation" [disabled]="!isEditMode">
                        <mat-option value="remotePosition">Remote position</mat-option>
                        <mat-option value="onSide">On Side</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemNotesNames['relocationHelp'] }}</mat-label>
                      <mat-select [(ngModel)]="workItemNotes.relocationHelp" [disabled]="!isEditMode">
                        <mat-option value="yes">Yes</mat-option>
                        <mat-option value="no">No</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemItemNames['state'] }}</mat-label>
                      <input matInput [(ngModel)]="workItem.state" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <div class="work-item-area__item">
                    <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                      <mat-label>{{ app.type.workItemItemNames['city'] }}</mat-label>
                      <input matInput [(ngModel)]="workItem.city" [readonly]="!isEditMode">
                    </mat-form-field>
                  </div>
                  <!-- MAIN INFO END -->
                  <ng-container *ngIf="moreInfo">
                    <!-- INFORMATIONFOREXECUTANTNAME -->
                    <div class="work-item-area__title">{{ app.type.workItemNotesNames['informationForExecutantName']}}</div>
                    <div class="work-item-area__item">
                      <mat-form-field class="date-picker" appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['dateOpeningPosition'] }}</mat-label>
                        <input matInput [matDatepicker]="picker" [formControl]="dateOpening">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker [disabled]="!isEditMode"></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field class="date-picker" appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['durationRequestExecution'] }}</mat-label>
                        <input matInput [matDatepicker]="dpd" [formControl]="dateDuration">
                        <mat-datepicker-toggle matSuffix [for]="dpd"></mat-datepicker-toggle>
                        <mat-datepicker #dpd [disabled]="!isEditMode"></mat-datepicker>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['enhancedPrivacy'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.enhancedPrivacy" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['reasonPositionOpening'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.reasonPositionOpening" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['necessarilyQuestion'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.necessarilyQuestion" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['interviver'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.interviver" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['finalDecisionHiring'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.finalDecisionHiring" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['interviewStages'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.interviewStages" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['particularPeopleHire'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.particularPeopleHire" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['amountInterviewInScope'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.amountInterviewInScope" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['otherRecruiterAgencies'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.otherRecruiterAgencies" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['someJobAds'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.someJobAds" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['publicLinkJobDescription'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.publicLinkJobDescription" [readonly]="!isEditMode">
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['shedleInterviewing'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.shedleInterviewing" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['numberPeopleForHire'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.numberPeopleForHire" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['additionDesires'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.additionDesires" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['informationForExecutantObject']['requiredDocForCandidate'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.requiredDocForCandidate" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemItemNames['description'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItem.description" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <!-- INFORMATIONFOREXECUTANTNAME -->
                    <!-- REQUIREMENTSFROMCANDIDATESNAME -->
                    <div class="work-item-area__title">{{ app.type.workItemNotesNames['requirementsFromCandidatesName']}}</div>
                    <div class="work-item-area__item item-area">
                      <ng-container *ngIf="!isEditMode || workItemNotes.age === 'Not important'">

                        <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode" >
                          <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['age'] }}</mat-label>
                          <input matInput [(ngModel)]="workItemNotes.age" [disabled] = "isEditMode && this.workItemNotes.age === 'Not important'" [readonly]="!isEditMode" >
                        </mat-form-field>

                      </ng-container>
                      <ng-container *ngIf="isEditMode">
                        <ng-container *ngIf="workItemNotes.age !== 'Not important'">
                          <div class="item-area__row">

                            <div class="item-area__column" >
                              <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                                <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['age'] }} From</mat-label>
                                <input type="number" [disabled]="important.checked" matInput [(ngModel)]="ageFrom">
                              </mat-form-field>
                            </div>

                            <div class="item-area__column" >
                              <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                                <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['age'] }} To</mat-label>
                                <input type="number" [disabled]="important.checked" matInput [(ngModel)]="ageTo">
                              </mat-form-field>
                            </div>

                          </div>
                        </ng-container>
                        <mat-checkbox #important [checked]="workItemNotes.age === 'Not important'" (change) = "this.workItemNotes.age = (this.workItemNotes.age === 'Not important') ? ' ': 'Not important'">Age not important</mat-checkbox>

                      </ng-container>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['cadidateSex'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.cadidateSex" [disabled]="!isEditMode">
                          <mat-option value="doesNotMatter">Does not matter</mat-option>
                          <mat-option value="female">Female</mat-option>
                          <mat-option value="male">Male</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">

                        <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                          <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['candidateEducation'] }}</mat-label>
                          <input matInput [(ngModel)]="workItemNotes.candidateEducation" [readonly]="!isEditMode" [disabled]="isEditMode && workItemNotes.candidateEducation === 'Not important'">
                        </mat-form-field>

                      <ng-container *ngIf="isEditMode">
                        <mat-checkbox #education
                                      [checked]="workItemNotes.candidateEducation === 'Not important'"
                                      (change) = "workItemNotes.candidateEducation = (workItemNotes.candidateEducation === 'Not important') ? '': 'Not important'">Education not important
                        </mat-checkbox>
                      </ng-container>
                    </div>
                    <div class="work-item-area__item">

                        <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                          <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['candidateDesiredDegree'] }}</mat-label>
                          <input matInput [(ngModel)]="workItemNotes.candidateDesiredDegree" [readonly]="!isEditMode" [disabled]="isEditMode && workItemNotes.candidateDesiredDegree === 'Not important'">
                        </mat-form-field>

                      <ng-container *ngIf="isEditMode">
                        <mat-checkbox #degree
                                      [checked]="workItemNotes.candidateDesiredDegree === 'Not important'"
                                      (change) = "workItemNotes.candidateDesiredDegree = (workItemNotes.candidateDesiredDegree === 'Not important') ? '': 'Not important'">Degree not important
                        </mat-checkbox>
                      </ng-container>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['combineWorkStudying'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.combineWorkStudying" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['mainResponsibilities'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.mainResponsibilities" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['desireWorkExp'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.desireWorkExp" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['requiredSkills'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.requiredSkills" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['additionalSkills'] }}</mat-label>
                        <textarea matInput rows="1" [(ngModel)]="workItemNotes.additionalSkills" [readonly]="!isEditMode"></textarea>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['managementExp'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.managementExp" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['foreignLanguages'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.foreignLanguages" [readonly]="!isEditMode">
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['personalCharacteristic'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.personalCharacteristic" [readonly]="!isEditMode">
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['driverLicense'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.driverLicense" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">
                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['ownVehicle'] }}</mat-label>
                        <mat-select [(ngModel)]="workItemNotes.ownVehicle" [disabled]="!isEditMode">
                          <mat-option value="yes">Yes</mat-option>
                          <mat-option value="no">No</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="work-item-area__item">

                      <mat-form-field appearance="outline" floatLabel="always" [class.non-active]="!isEditMode">
                        <mat-label>{{ app.type.workItemNotesNames['requirementsFromCandidatesObject']['maritalStatusKids'] }}</mat-label>
                        <input matInput [(ngModel)]="workItemNotes.maritalStatusKids" [readonly]="!isEditMode" [disabled]="isEditMode && workItemNotes.maritalStatusKids === 'Not important'">
                      </mat-form-field>

                    <ng-container *ngIf="isEditMode">
                      <mat-checkbox #degree
                                    [checked]="workItemNotes.maritalStatusKids === 'Not important'"
                                    (change) = "workItemNotes.maritalStatusKids = (workItemNotes.maritalStatusKids === 'Not important') ? '': 'Not important'">Marital status not important
                      </mat-checkbox>
                    </ng-container>
                  </div>
                    <!-- REQUIREMENTSFROMCANDIDATESNAME -->
                  </ng-container>
                </div>
                <div class="flex flex-row">
                  <button mat-flat-button color="primary" (click)="moreInfo = !moreInfo" *ngIf="!moreInfo">Show More Info</button>
                  <button mat-flat-button color="primary" (click)="moreInfo = !moreInfo" *ngIf="moreInfo">Hide More Info</button>
                  <button mat-flat-button class="margin-l-1" color="primary" *ngIf="isEditMode" (click)="updateJobOrder()" matTooltip="Save">Save</button>
                </div>
              </div>

            </mat-card-content>
          </mat-card>
        </ng-container>


        <mat-card>
          <mat-card-header>
            <mat-card-title>{{ app.type.names['searchItem'] }} Pipeline</mat-card-title>
          </mat-card-header>

          <div class="wrap">
            <div class="width-lg-50 centered width-xs-100">
              <div [style.height.px]="250">
                <ngx-charts-bar-vertical
                  [results]="chartData"
                  [barPadding]="10"
                  [scheme]= "{domain: chartColors}"
                  [gradient]="false"
                  [xAxis]="true"
                  [yAxis]="true"
                  [yAxisLabel]="'Total'"
                  [showXAxisLabel]="true"
                  [showYAxisLabel]="true">
                </ngx-charts-bar-vertical>
              </div>
            </div>
          </div>

          <app-search-item-pipelines-table #pipelineTableComponent
                                            [data]="workItemPipeline"
                                            [jobOrder]="workItem" [statuses] = "workItemStatuses">
          </app-search-item-pipelines-table>
        </mat-card>
      </mat-tab>
<!--      <mat-tab label="Condition" class="tab-group__tab">-->
<!--        <mat-card class="condition-card">-->
<!--          <mat-card-content>-->
<!--            <app-condition-tab [workItem]="workItem"></app-condition-tab>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </mat-tab>-->
<!--      <mat-tab label="Calibration" class="tab-group__tab">-->
<!--        <mat-card class="calibration-card">-->
<!--          <mat-card-content>-->
<!--            <app-calibration-tab [workItem]="workItem" style="display: flex; flex-grow: 1"></app-calibration-tab>-->
<!--          </mat-card-content>-->
<!--        </mat-card>-->
<!--      </mat-tab>-->
    </mat-tab-group>
  </div>
</app-navigation>
