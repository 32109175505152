"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalibrationService = void 0;
const calibration_type_1 = require("../types/calibration-type");
class CalibrationService {
    constructor() { }
    mapReverseCalibration(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        if (src.candidateId)
            result.candidate_id = src.candidateId;
        if (src.candidateMark)
            result.candidate_mark = src.candidateMark;
        if (src.candidatePreviewExpDetails)
            result.candidate_preview_exp_details = src.candidatePreviewExpDetails;
        if (src.candidatePreviewExp)
            result.candidate_preview_exp = src.candidatePreviewExp;
        if (src.candidateStatus)
            result.candidate_status = src.candidateStatus;
        if (src.companyId)
            result.company_id = src.companyId;
        if (src.joborderId)
            result.joborder_id = src.joborderId;
        if (src.partnerUrl)
            result.partner_url = src.partnerUrl;
        if (src.partnerId)
            result.partner_id = src.partnerId;
        return result;
    }
    mapCalibrations(raw) {
        return raw.map((val) => this.mapCalibration(val));
    }
    mapCalibration(raw) {
        const result = new calibration_type_1.Calibration();
        result.id = raw.id;
        result.candidateId = raw.candidate_id;
        result.candidateMark = raw.candidate_mark;
        result.candidatePreviewExpDetails = raw.candidate_preview_exp_details;
        result.candidatePreviewExp = raw.candidate_preview_exp;
        result.candidateStatus = raw.candidate_status;
        result.companyId = raw.company_id;
        result.joborderId = raw.joborder_id;
        result.partnerUrl = raw.partner_url;
        result.partnerId = raw.partner_id;
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        return result;
    }
}
exports.CalibrationService = CalibrationService;
