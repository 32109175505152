<h1 mat-dialog-title>Condition</h1>
<div mat-dialog-content>

  <app-work-item-accepted-condition [condition]="condition"></app-work-item-accepted-condition>

</div>

<div mat-dialog-actions>
  <button mat-flat-button color="basic" (click)="close()">Close</button>
  <button mat-flat-button color="primary" (click)="accept()" *ngIf="!preview">Accept</button>
</div>