import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Company } from "../../app-services/company.type";

@Component({
  selector: 'app-customers-table',
  templateUrl: './customers-table.component.html',
  styleUrls: ['./customers-table.component.scss']
})
export class CustomersTableComponent {
  public displayedColumns = ['name', 'countJobOrder', 'state', 'city', 'enteredByUserName', 'ownerUserName', 'dateCreated'];

  public dataSource: MatTableDataSource<Company>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: Company[]) {
    this.applyDataSource(data);
  }

  @Output() onCompanySelect = new EventEmitter<Company>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: Company[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectCompany(row: Company) {
    this.onCompanySelect.emit(row);
  }

}
