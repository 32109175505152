
<div class="sign-up">
  <mat-card class="sign-up__wrap">
    <mat-card-title>Sign Up</mat-card-title>
    <mat-card-content>
      <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput placeholder="First Name" formControlName="firstName">
          <mat-error *ngIf="f.firstName.hasError('required')">
            First Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput placeholder="Last Name" formControlName="lastName">
          <mat-error *ngIf="f.lastName.hasError('required')">
            Last Name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>Login</mat-label>
          <input matInput placeholder="Login" formControlName="login">
          <mat-error *ngIf="f.login.hasError('required')">
            Login is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput placeholder="Last Name" formControlName="email">
          <mat-error *ngIf="f.email.hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="f.email.hasError('email')">
            Email is not valid
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput placeholder="Password" [type]="hide ? 'password' : 'text'" formControlName="password">
          <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide">
            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="f.password.hasError('required')">
            Password is required
          </mat-error>
        </mat-form-field>

        <mat-form-field class="width-100" appearance="outline">
          <mat-label>Email Password</mat-label>
          <input matInput placeholder="Email Password" [type]="hideEmail ? 'password' : 'text'" formControlName="emailPassword">
          <button type="button" mat-icon-button matSuffix (click)="hideEmail = !hideEmail">
            <mat-icon>{{hideEmail ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="f.password.hasError('required')">
            Email Password is required
          </mat-error>
        </mat-form-field>

        <button type="submit" class="sign-up__button" [disabled]="!signUpForm.valid" mat-flat-button color="primary">SIGN UP</button>
        <button type="submit" class="sign-up__button" (click)="goToLogin()" mat-flat-button color="primary">BACK</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
