"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HashtagsService = void 0;
const hashtag_type_1 = require("../types/hashtag-type");
class HashtagsService {
    constructor() { }
    mapReverseHashtag(src) {
        const result = {};
        if (src.savedListId)
            result.saved_list_id = src.savedListId;
        if (src.savedListEntryId)
            result.id = src.savedListEntryId;
        if (src.dataItemId)
            result.data_item_id = src.dataItemId;
        if (src.description)
            result.description = src.description;
        if (src.dataItemType)
            result.data_item_type = src.dataItemType;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.isDynamic)
            result.is_dynamic = src.isDynamic;
        if (src.datagridInstance)
            result.datagrid_instance = src.datagridInstance;
        if (src.parameters)
            result.parameters = src.parameters;
        if (src.createdBy)
            result.created_by = src.createdBy;
        if (src.numberEntries)
            result.number_entries = src.numberEntries;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        return result;
    }
    mapHashtags(raw) {
        return raw.map((val) => this.mapHashtag(val));
    }
    mapsearchItemHashtags(raw) {
        let hashtags = [];
        for (let element in raw) {
            if (element !== 'private_lists_count') {
                hashtags.push(raw[element]);
            }
        }
        hashtags = hashtags.map((val) => this.mapHashtag(val));
        const result = {
            hashtags: hashtags,
            privateListCount: raw['private_lists_count']
        };
        return result;
    }
    mapHashtag(raw) {
        const result = new hashtag_type_1.Hashtag();
        result.savedListId = raw.saved_list_id;
        result.dataItemId = raw.data_item_id;
        result.savedListEntryId = raw.saved_list_entry_id;
        result.description = raw.description;
        result.dataItemType = raw.data_item_type;
        result.siteId = raw.site_id;
        result.isDynamic = raw.is_dynamic;
        result.datagridInstance = raw.datagrid_instance;
        result.parameters = raw.parameters;
        result.createdBy = raw.created_by;
        result.numberEntries = raw.number_entries;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        return result;
    }
}
exports.HashtagsService = HashtagsService;
