import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";


@Component({
  selector: 'app-share-norms-table',
  templateUrl: './share-norms-table.component.html',
  styleUrls: ['./share-norms-table.component.scss']
})
export class ShareNormsTableComponent  {
  public displayedColumns = ['login', 'description', 'attachment', 'changeOwner', 'candidateProfile', 'freeProfile'];
  public dataSource: MatTableDataSource<any>;
  private paginator: MatPaginator;
  private sort: MatSort;
  
  @Output() saveValueEvent = new EventEmitter<boolean>();
  
  @Input() public set data(data: any[]) {
    this.applyDataSource(data);
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  saveValue(){
    this.saveValueEvent.emit()
  }

}
