"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebCalibrationController = void 0;
class WebCalibrationController {
    constructor(api, calibrationsService) {
        this.api = api;
        this.calibrationsService = calibrationsService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('clientCalibration/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.calibrationsService.mapCalibrations(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    listByJoborder(joborderId, successCallback, errorCallback) {
        this.api.postRequest('clientCalibration/listByJoborder', { joborder_id: joborderId }, response => {
            if (response.data.valid === true)
                successCallback(this.calibrationsService.mapCalibrations(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('clientCalibration/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.calibrationsService.mapCalibration(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('clientCalibration/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    edit(calibration, successCallback, errorCallback) {
        const src = this.calibrationsService.mapReverceCalibrations(calibration);
        this.api.postRequest('clientCalibration/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    create(calibration, successCallback, errorCallback) {
        const src = this.calibrationsService.mapReverceCalibrations(calibration);
        this.api.postRequest('clientCalibration/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    find(calendar, successCallback, errorCallback) { }
    sort(calendar, customerField, desc, successCallback, errorCallback) { }
}
exports.WebCalibrationController = WebCalibrationController;
