<app-navigation>
  <div class="main-container-no-scroll">
  <mat-card class="main-header"> 
    <mat-card-subtitle>{{ app.type.names['searchItems'] }}</mat-card-subtitle>
  </mat-card>
  <mat-card class="main-content">
    <mat-card-content>
      <app-search-items-table #candidatesTableComponent [data]="seachItems" (onCandidateSelect)="navigateToCandidate($event)" [columns]="['candidateId', 'lastName']"></app-search-items-table>
    </mat-card-content>
  </mat-card>
</div>
</app-navigation>
