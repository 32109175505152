<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 30%;">Name</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 30%;">
              <span class="user-icon"></span>
              {{ row["name"] }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="job">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center; flex: 1 1 auto;">Company/Positon</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex-direction: column;justify-content: center; align-self: stretch; flex: 1 1 auto;">
              <div><span style="color: #0073ff;">{{ row["job"]["company"] }}</span>/<span style="color: #0073ff;">{{ row["job"]["position"] }}</span></div>
              <div [ngClass]="{hidden: row['job']['submited'] ? true: false}">Submited</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="started">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="justify-content: center; flex: 0 0 30%;"> <mat-icon>query_builder</mat-icon> </mat-header-cell>
            <mat-cell *matCellDef="let row" style="justify-content: center; flex: 0 0 30%;">{{ row["started"] }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5,10,25,50]"></mat-paginator>
    </div>
  </div>
</div>