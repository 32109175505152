"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupUsersService = void 0;
const group_user_item_type_1 = require("../types/group-user-item-type");
const group_users_type_1 = require("../types/group-users-type");
class GroupUsersService {
    constructor() { }
    mapReverseGroupUsers(src) {
        const result = {};
        if (src.id)
            result.group_user_id = src.id;
        if (src.title)
            result.title = src.title;
        if (src.owner)
            result.owner = src.owner;
        if (src.adminUserId)
            result.admin_user_id = src.adminUserId;
        if (src.adminLastName)
            result.admin_last_name = src.adminLastName;
        if (src.adminFirstName)
            result.admin_first_name = src.adminFirstName;
        if (src.countItem)
            result.count_item = src.countItem;
        return result;
    }
    mapGroupUsers(raw) {
        return raw.map((val) => this.mapGroupUser(val));
    }
    mapGroupUser(raw) {
        const result = new group_users_type_1.GroupUsers();
        result.id = raw.id;
        result.title = raw.title;
        result.owner = raw.owner;
        result.dateCreated = raw.date_created;
        result.adminUserId = raw.admin_user_id;
        result.adminLastName = raw.admin_last_name;
        result.adminFirstName = raw.admin_first_name;
        result.countItem = raw.count_item;
        return result;
    }
    mapGroupUserItems(raw) {
        return raw.map(val => this.mapGroupUserItem(val));
    }
    mapGroupUserItem(raw) {
        const result = new group_user_item_type_1.GroupUserItem();
        result.id = raw.id;
        result.groupUserId = raw.group_user_id;
        result.userId = raw.user_id;
        result.firstName = raw.first_name;
        result.lastName = raw.last_name;
        result.isAdmin = raw.is_admin;
        return result;
    }
    mapGroupWithUsers(raw) {
        return raw.map((val) => this.mapGroupWithUser(val));
    }
    mapGroupWithUser(raw) {
        const result = new group_users_type_1.GroupWithUsers();
        const data = this.mapGroupUser(raw);
        Object.assign(result, data);
        result.users = raw.users.map(user => this.mapUserInGroup(user));
        return result;
    }
    mapUserInGroup(raw) {
        const result = new group_users_type_1.UserInGroup();
        result.userId = raw.user_id;
        result.firstName = raw.first_name;
        result.lastName = raw.last_name;
        result.isAdmin = raw.is_admin;
        return result;
    }
}
exports.GroupUsersService = GroupUsersService;
