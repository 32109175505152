import {Component, OnInit, ViewChild} from '@angular/core';
import { Router} from '@angular/router';
import { QuickSearchService } from '../app-services/quick-search.service';
import { AppService } from '../app-services/app.service';
import { YhCore, SearchItemType } from '../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-quick-search-page',
  templateUrl: './quick-search-page.component.html',
  styleUrls: ['./quick-search-page.component.scss']
})
export class QuickSearchPageComponent implements OnInit {
  @ViewChild('candidatesTableComponent') candidatesTableComponent;

  seachItems: SearchItemType[] = [];

  constructor(
    public app: AppService,
    private router: Router,
    private quickSearchService: QuickSearchService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.quickSearchService.quickSearchObserver.subscribe(searchValue => {
      this.quickSearch(searchValue);
    })
  }

  async quickSearch(value: string) {
    YhCore.searchItems.quickSearch(value, searchResults => {
      this.seachItems = searchResults;
    }, error => {
      this.snackBar.open(error, 'OK', {duration: 5000});
    })
  }

  navigateToCandidate(row: any) {
    this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), row.candidateId]);
  }

}
