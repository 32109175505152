import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppService } from '../../app-services/app.service';

@Component({
  selector: 'app-dialog-confirm-lead-creation',
  templateUrl: './dialog-confirm-lead-creation.component.html',
  styleUrls: ['./dialog-confirm-lead-creation.component.scss']
})
export class DialogConfirmLeadCreationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmLeadCreationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private app: AppService
  ) { }

  ngOnInit(): void {
  }

  redirectToLeadPage() {
    window.open( `/${this.app.type.names['searchItem'].toLocaleLowerCase()}/${this.data.searchItemId}`, '_blank');
  }

  onSubmit() {
    this.dialogRef.close(true);
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}