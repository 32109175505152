import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-calibration-dialog',
  templateUrl: './add-calibration-dialog.component.html',
  styleUrls: ['./add-calibration-dialog.component.scss']
})
export class AddCalibrationDialogComponent implements OnInit {

  companies = [
    {viewName: 'Company 1', value: 'company 1'},
    {viewName: 'Company 2', value: 'company 2'},
    {viewName: 'Company 3', value: 'company 3'},
    {viewName: 'Company 4', value: 'company 4'},
  ]

  jobOrders = [
    {viewName: 'Job Orders 1', value: 'Job Orders 1'},
    {viewName: 'Job Orders 2', value: 'Job Orders 2'},
    {viewName: 'Job Orders 3', value: 'Job Orders 3'},
    {viewName: 'Job Orders 4', value: 'Job Orders 4'},
  ]

  constructor(
    public dialogRef: MatDialogRef<AddCalibrationDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCloseClick(): void {
    this.dialogRef.close(false);
  }

  onAddClick(): void {
    this.dialogRef.close(true);
  }

}
