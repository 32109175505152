"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactItemsService = void 0;
const contact_item_type_1 = require("../types/contact-item-type");
class ContactItemsService {
    constructor() { }
    mapReverseContactItem(src) {
        const result = {};
        if (src.city || src.city === '')
            result.city = src.city;
        if (src.address || src.address === '')
            result.address = src.address;
        if (src.companyDepartmentId)
            result.company_department_id = src.companyDepartmentId;
        if (src.companyId)
            result.company_id = src.companyId;
        if (src.contactId)
            result.id = src.contactId;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        if (src.email1 || src.email1 === '')
            result.email1 = src.email1;
        if (src.email2 || src.email2 === '')
            result.email2 = src.email2;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        if (src.firstName || src.firstName === '')
            result.first_name = src.firstName;
        if (src.importId)
            result.import_id = src.importId;
        if (src.isHot)
            result.is_hot = src.isHot;
        if (src.lastName)
            result.last_name = src.lastName;
        if (src.leftCompany)
            result.left_company = src.leftCompany;
        if (src.notes || src.notes === '')
            result.notes = src.notes;
        if (src.owner)
            result.owner = src.owner;
        if (src.phoneCell || src.phoneCell === '')
            result.phone_cell = src.phoneCell;
        if (src.phoneOther || src.phoneOther === '')
            result.phone_other = src.phoneOther;
        if (src.phoneWork || src.phoneWork === '')
            result.phone_work = src.phoneWork;
        if (src.reportsTo)
            result.reports_to = src.reportsTo;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.state || src.state === '')
            result.state = src.state;
        if (src.title || src.title === '')
            result.title = src.title;
        if (src.zip)
            result.zip = src.zip;
        return result;
    }
    mapContactItems(raw) {
        return raw.map((val) => this.mapContactItem(val));
    }
    mapContactItem(raw) {
        const result = new contact_item_type_1.ContactItem();
        result.address = raw.address;
        result.city = raw.city;
        result.companyDepartmentId = raw.company_department_id;
        result.companyId = raw.company_id;
        result.contactId = raw.contact_id;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.email1 = raw.email1;
        result.email2 = raw.email2;
        result.enteredBy = raw.entered_by;
        result.firstName = raw.first_name;
        result.importId = raw.import_id;
        result.isHot = raw.is_hot;
        result.lastName = raw.last_name;
        result.leftCompany = raw.left_company;
        result.notes = raw.notes;
        result.owner = raw.owner;
        result.phoneCell = raw.phone_cell;
        result.phoneOther = raw.phone_other;
        result.phoneWork = raw.phone_work;
        result.reportsTo = raw.reports_to;
        result.siteId = raw.site_id;
        result.state = raw.state;
        result.title = raw.title;
        result.zip = raw.zip;
        return result;
    }
}
exports.ContactItemsService = ContactItemsService;
