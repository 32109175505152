"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CvSettingsFieldService = void 0;
const cv_settings_field_type_1 = require("../types/cv-settings-field-type");
class CvSettingsFieldService {
    constructor() { }
    mapReversCvSettingsField(raw) {
        const result = {};
        if (raw.userId)
            result.user_id = raw.userId;
        if (raw.id)
            result.id = raw.id;
        if (raw.fields)
            result.fields = this.mapReversFields(JSON.parse(raw.fields));
        return result;
    }
    mapCvSettingsFields(raw) {
        return raw.map(val => this.mapCvSettingsField(val));
    }
    mapCvSettingsField(raw) {
        const result = new cv_settings_field_type_1.CvSettingsFiled();
        result.id = raw.id;
        result.userId = raw.user_id;
        result.fields = this.mapFields(JSON.parse(raw.fields));
        return result;
    }
    mapFields(raw) {
        return JSON.stringify(raw.map(val => this.mapField(val)));
    }
    mapField(raw) {
        const result = new cv_settings_field_type_1.CvFields();
        result.block = raw.block;
        result.cvAnnonym = raw.cv_annonym;
        result.cvFull = raw.cv_full;
        result.fieldFullName = raw.field_full_name;
        result.fieldName = raw.field_name;
        result.fieldType = raw.field_type;
        return result;
    }
    mapReversFields(raw) {
        return JSON.stringify(raw.map(val => this.mapReversField(val)));
    }
    mapReversField(raw) {
        const result = {};
        result.block = raw.block;
        result.cv_annonym = raw.cvAnnonym;
        result.cv_full = raw.cvFull;
        result.field_full_name = raw.fieldFullName;
        result.field_name = raw.fieldName;
        result.field_type = raw.fieldType;
        return result;
    }
}
exports.CvSettingsFieldService = CvSettingsFieldService;
