import { Component, OnInit, } from '@angular/core';
import {Router } from '@angular/router';
import { YhCore, CustomerType, CustomerNotesType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-comapny-profile-item-page',
  templateUrl: './create-customer-page.component.html',
  styleUrls: ['./create-customer-page.component.scss']
})
export class CreateCustomerPageComponent implements OnInit {
  addCompanyForm: FormGroup;
  company: CustomerType = new CustomerType();
  public showAdditionalInfo: boolean = true;
  constructor(
    public app: AppService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) { }


  ngOnInit(): void {
    this.initForm()
  }

  initForm() {
    this.addCompanyForm = this.fb.group({
      name: ['', Validators.required],
      address: ['', Validators.required],
      numberEmployeesCompany: '',
      mainActivities: '',
      yearFaundation: '',
      contactPersonName: '',
      contactPersonPosition: '',
      contactPersonPhone: '',
      numberEmployeesDepartment: '',
      clientsManagerName: '',
      clientsManagerPosition: '',
      clientsManagerPhone: '',
      state: '-',
      city: '-',
      zip: '',
      phone1: '',
      phone2: '',
      faxNumber: '',
    })
  }

  submit() {
    const valueForm = this.addCompanyForm.value;
    if (this.addCompanyForm.invalid) {
      this.addCompanyForm.markAllAsTouched();
      return;
    }
    else {
      this.companyInit(valueForm);
      
      YhCore.customers.create(this.company, () => {
        this.router.navigate(['/company-profile-page']);
        YhCore.users.getStaticData(res => {}, err => {});
      }, err => { this.errorHandling(err) })
    }
  }

  companyInit(valueForm) {
    this.company.name = valueForm.name;
    this.company.address = valueForm.address;
    this.company.name = valueForm.name;
    this.company.state = valueForm.state;
    this.company.city = valueForm.city;
    this.company.zip = valueForm.zip;
    this.company.phone1 = valueForm.phone1;
    this.company.phone2 = valueForm.phone2;
    this.company.faxNumber = valueForm.faxNumber;
    this.company.isHot = 0;
    this.company.notes = JSON.stringify(this.companyNotesInit(valueForm));
  }

  companyNotesInit(valueForm) {
    const companyNotes = new CustomerNotesType();

    companyNotes.numberEmployeesCompany = valueForm.numberEmployeesCompany;
    companyNotes.mainActivities = valueForm.mainActivities;
    companyNotes.yearFaundation = valueForm.yearFaundation;
    companyNotes.contactPersonName = valueForm.contactPersonName;
    companyNotes.contactPersonPosition = valueForm.contactPersonPosition;
    companyNotes.contactPersonPhone = valueForm.contactPersonPhone;
    companyNotes.numberEmployeesDepartment = valueForm.numberEmployeesDepartment;
    companyNotes.clientsManagerName = valueForm.clientsManagerName;
    companyNotes.clientsManagerPosition = valueForm.clientsManagerPosition;
    companyNotes.clientsManagerPhone = valueForm.clientsManagerPhone;

    return companyNotes;
  }

  errorHandling(err) {
    this.snackBar.open(err, 'ERROR', {duration: 5000})
  }

}


