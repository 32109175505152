<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-content">
      <mat-card-content>
        <app-abstract-table [data]="events" 
                            [columns]="displayedColumns"
                            [actions]="actions"
                            [sortBy]="'dateCreated'"
                            (abstractActionViewContactEvent)="navigateToSearchItem($event)"
                            (abstractActionEditEvent)="shareFullCandidate($event)">
        </app-abstract-table>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>
