<div class="table-wrap">
  <div class="table-wrap__inner">

      <mat-form-field appearance="outline" class="pipeline-filter">
        <mat-label>Filter by {{ app.type.names['searchItem'] }}</mat-label>
        <input matInput (keyup)="applyFilter($event.target['value'], 'bySearchItem')">
      </mat-form-field>
      
      <mat-form-field appearance="outline" class="pipeline-filter">
        <mat-label>Filter by {{ app.type.searchItemNames['city']}}</mat-label>
        <input matInput (keyup)="applyFilter($event.target['value'], 'byCity')">
      </mat-form-field>

      <mat-form-field appearance="outline" class="pipeline-filter">
        <mat-label>Filter by {{ app.type.searchItemNames['owner']}}</mat-label>
        <input matInput (keyup)="applyFilter($event.target['value'], 'byOwner')">
      </mat-form-field>

      <mat-form-field class="filter pipeline-filter" appearance="outline">
        <mat-label>Filter by status</mat-label>
        <mat-select #filterByStatus [(ngModel)]="selectedStatuses" (ngModelChange)="filterByStatuses()" multiple disableOptionCentering >
          <div class="filter__top">
            <button mat-flat-button color="primary" (click)="selectAllSatuses()">Select All</button>
            <button mat-flat-button color="warn" (click)="clearAllSatuses()">Clear All</button>
          </div>
          <mat-option *ngFor="let status of statuses" [value]="status.statusName">{{ status.statusName }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="searchItem">
          <mat-header-cell *matHeaderCellDef>{{ app.type.names['searchItem'] }}</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row.candidateFirstName}} {{row.candidateLastName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef> {{ app.type.searchItemNames['city']}} </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row['city']}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateCreated">
          <mat-header-cell *matHeaderCellDef> {{ app.type.searchItemNames['dateCreated']}} </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row['dateCreated']}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dateModified">
          <mat-header-cell *matHeaderCellDef> {{ app.type.searchItemNames['dateModified']}} </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row['dateModified']}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="owner">
          <mat-header-cell *matHeaderCellDef> {{ app.type.searchItemNames['owner']}}</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row.enteredByFirstName}} {{row.enteredByLastName}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
          <mat-cell *matCellDef="let row"> {{row['statusShortDescription']}} </mat-cell>
        </ng-container>

        <!-- <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
          <mat-cell *matCellDef="let row" (click) = "deleteFromPipeline(row)">
            <button mat-icon-button class="action-remove">
              <mat-icon [class.show] ="!row.deleting" [class.hide]="row.deleting">close</mat-icon>
              <mat-spinner [class.show] ="row.deleting" [class.hide]="!row.deleting" [diameter]="20"></mat-spinner>
            </button>
          </mat-cell>
        </ng-container> -->

        <ng-container matColumnDef="lastActivity">
          <mat-header-cell *matHeaderCellDef>Last Activity</mat-header-cell>
          <mat-cell *matCellDef="let row" [innerHTML]="row.lastActivity ? setInnerHtml(row.lastActivity.substring(0, 100)) : ''">
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" class="element-row" (click)="getAllActivity(row)" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"></mat-row>
      </mat-table>
      <mat-paginator [length]="1000" [pageIndex]="0" [pageSize]="5" [pageSizeOptions]="[5, 10, 15, 20]"></mat-paginator>

      <ng-template #tpl let-element>
        <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden">
          <p *ngFor="let activity of element.activities;" [innerHTML]="activity.notes"></p>
        </div>
      </ng-template>

  </div>
</div>