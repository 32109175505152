
import { Pipe, PipeTransform } from '@angular/core';
import { WorkItemType } from 'src/app/app-services/core.service';

@Pipe({
  name: 'joborderGroup'
})
export class JoborderGroupPipe implements PipeTransform {

  transform(data: WorkItemType[] = [], groupJoborderId: number): WorkItemType[] {
    if(groupJoborderId === 0) {return data}
    return data.filter( val => +val.joborderGroupId === +groupJoborderId);
  }

}
