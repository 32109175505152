import { Component, OnInit } from '@angular/core';
import { UserType } from "../app-services/core.service";
import { YhCore } from '../app-services/core.service'
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserActionsDialogComponent } from '../app-dialogs/user-actions-dialog/user-actions-dialog.component';

@Component({
  selector: 'app-manage-users-page',
  templateUrl: './manage-users-page.component.html',
  styleUrls: ['./manage-users-page.component.scss']
})
export class ManageUsersComponent implements OnInit {
  public user: UserType[];

  constructor(
    private dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
    JSON.parse(YhCore.localStorageService.getItem('YHminiuser')).accessLevel >= 300 ? '' : this.router.navigateByUrl('/settings')
    this.getUsers();
  }

  getUsers() {
    YhCore.users.list(success => {
      this.user = success;
    }, err => { })
  }

  addUserOpenDialog() {
    this.dialog.open(UserActionsDialogComponent, {width: "600px", data: {user: null, creationMode: true}}).afterClosed().subscribe( res => {
      if(res) {
        this.getUsers();
      }
    })
  }

  editUserOpenDialog(user: UserType) {
    this.dialog.open(UserActionsDialogComponent, {width: "600px", data: {user: user, creationMode: false}}).afterClosed().subscribe( res => {
      if(res) {
        this.getUsers();
      }
    })
  }
}