import { Component, Input, ViewChild, ElementRef, AfterViewChecked, OnInit, Output, EventEmitter } from '@angular/core';
import { SettingService } from '../../app-services/setting.service';
import { YhCore, MessageType, SearchItemType, ActivityStatusType,
WorkItemPipelineType, SearchItemPipelineType, TemplateType, ContactItemType } from '../../app-services/core.service';
import { Router } from '@angular/router';
import { AppService } from '../../app-services/app.service';
import { IncomingEmails } from '../../app-components/navigation/navigation.component';
import { Validators, FormControl} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-messenger-overlay',
  templateUrl: './messenger-overlay.component.html',
  styleUrls: ['./messenger-overlay.component.scss']
})
export class MessengerOverlayComponent implements AfterViewChecked, OnInit {

  newEmails: IncomingEmails[] = [];
  newEmailsUids: number[] = [];
  @Output() onMarkAsSeen = new EventEmitter<boolean>()
  @Input() public set emails(emails: IncomingEmails[]) {

    // Checking if new emails list has emails we don't have in our list so far
    const veryNewEmails = this.veryNewEmails(emails);

    // Updating the list only if new has emails we don't have in our list
    if (veryNewEmails.length > 0) {
      veryNewEmails.map((email: IncomingEmails) => {
        this.newEmails = [...this.newEmails, email];
      });

      this.updateUidsArray(emails);
      this.addThreads(veryNewEmails);
    } else {
      this.loadingMessages = false;
      this.activeItemNotSet = true;
    }
  }

  isSearchMode = false;
  searchItems: SearchItemType[] = [];
  contactItems: ContactItemType[] = [];
  filteredSearchItems: SearchItemType[] = [];
  filteredcontactItems: ContactItemType[] = [];
  email = undefined;
  loadingMessages = true;
  activeThread: MessageType[] = [];
  currentUserEmail = JSON.parse(YhCore.localStorageService.getItem('YHminiuser')).email;
  activeItem; // Serach Item or Contact Item
  filter: string;
  newMessage: MessageType = new MessageType();
  @ViewChild('scrollArea') scrollContainer: ElementRef;
  scroll = true;
  statuses: ActivityStatusType[] = [];
  newStatus: string;
  searchItemPipelines: SearchItemPipelineType[] = [];
  chosenEmail: string;
  chosenPipeline = new FormControl('', [Validators.required]);
  username = new FormControl('', [Validators.required]);
  chosenTemplate = new FormControl('')
  showError =  false;
  templates: TemplateType[] = [];
  quickSearchError = false;
  activeItemNotSet = false;
  isSmallDevice = false;
  showMessages = false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public settingService: SettingService,
    private router: Router,
    public app: AppService,
    private snackBar: MatSnackBar,
  ) {
    breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small
    ]).subscribe(result => {
      if (result.matches) {
        this.isSmallDevice = true;
      } else {
        this.isSmallDevice = false;
      }
    });
  }

  updateUidsArray(emails: IncomingEmails[]) {
    this.newEmailsUids = [];
    emails.map((email: IncomingEmails) => {
      this.newEmailsUids = [...this.newEmailsUids, +email.uid];
    })
  }

  veryNewEmails(emails) {
    let result = [];
    if (this.newEmailsUids.length === 0 && emails.length > 0) {
      this.updateUidsArray(emails);
      result = emails;
    } else {
      // Checking new emails
      emails.map((email: IncomingEmails) => {
        if (this.newEmailsUids.indexOf(+email.uid) < 0) {
          result = [...result, email];
        }
      })
    }
    return result;
  }

  async ngOnInit() {
    // this.statuses = await this.getStatuses();
    this.templates = await this.getTemplates();
  }

  async addThreads(newEmails: IncomingEmails[]) {
    await this.mapThreads(newEmails);

    // Setting active item in case it wasn't set yet
    if (!this.activeItem) {
      this.activeItem = this.searchItems[0] || this.contactItems[0];
      const type = (this.activeItem.candidateId) ? 'searchItem' : 'contactItem';
      this.chooseThread(this.activeItem, type);
    }
  }

  mapThreads(newEmails) {
    return new Promise<any> (resolve => {
      newEmails.map(async (item: IncomingEmails) => {
        if (item.type === 'searchItem') {
          const searchItem = await this.getSearchItem(item.id);
          searchItem.newEmails = 0;
          const index = this.isOnTheListSI(searchItem, this.searchItems);
          // If this search item is already rendered in sidebar
            if (index >= 0) {
              // if this search item is active we just need to reload the thread
              if (searchItem.candidateId === this.activeItem.candidateId) {
                this.chooseThread(this.searchItems[index], 'searchItem');
              } else {
                // Desplaying new emails count in badge
                this.searchItems[index].newEmails = +this.searchItems[index].newEmails + 1;
              }
            }
            // Pushihn search item onto search items lead to display in sidebar
            else this.searchItems.push(searchItem);
            resolve();
        }
        if (item.type !== 'searchItem') {
          const contactItem = await this.getContactItem(item.id);
          contactItem.newEmails = 0;
          const index = this.isOnTheListCI(contactItem, this.contactItems);
          if (index >= 0) {
            if (contactItem.contactId === this.activeItem.contactId) {
              this.chooseThread(this.contactItems[index], 'contactItem');
            } else {
              this.contactItems[index].newEmails = +this.contactItems[index].newEmails + 1;
            }
          }
          else this.contactItems.push(contactItem);
          resolve();
        }
      })
    })
  }

  isOnTheListSI(thisItem: SearchItemType, items: SearchItemType[]) {
    let result = -1;
    items.map((item: SearchItemType, index: number) => {
      if (item.candidateId === thisItem.candidateId) {
        result = index
      }
    })
    return result;
  }

  isOnTheListCI(thisItem: ContactItemType, items: ContactItemType[]) {
    let result = -1;
    items.map((item: ContactItemType, index: number) => {
      if (item.contactId === thisItem.contactId) {
        result = index
      }
    })
    return result;
  }

  getSearchItem(id: string) {
    return new Promise<SearchItemType> ((resolve) => {
      YhCore.searchItems.read(id, searchItem => {
        if (searchItem.img.length > 0) searchItem.img = 'http://yhminiapi.hiringrequest.com/' + searchItem.img;
        resolve(searchItem);
      }, err => {
      this.snackBar.open(`${err}`, 'OK', {duration: 3000})
      })
    })
  }

  getContactItem(id: string){
    return new Promise<ContactItemType> ((resolve) => {
      YhCore.contactItems.read(+id, contactItem => {
        resolve(contactItem);
      }, err => {
        this.snackBar.open(`${err}`, 'OK', {duration: 3000})
      })
    })
  }

  async markAsSeen(uid: string){
    YhCore.messenger.markAsSeen([uid], success => {}, error => {})
  }

  // getStatuses(): Promise<ActivityStatusType[]>{
  //   return new Promise<ActivityStatusType[]>((resolve) => {
  //     YhCore.workItemsStatuses.list(success => {resolve(success)}, error => {})
  //   })
  // }

  getTemplates(): Promise<TemplateType[]>{
    return new Promise<TemplateType[]>((resolve) => {
      YhCore.templates.list(success => {
        resolve(success)}, error => {})
    })
  }

  getSearchItemPipelines(): Promise<SearchItemPipelineType[]>{
    return new Promise<SearchItemPipelineType[]>((resolve) => {
      const id = this.activeItem.candidateId || this.activeItem.companyId
      YhCore.workItemsPipeline.getPipelinesBySearchItem(+id, searchItemsPipelines => {
        YhCore.workItems.list(workItems => {
          workItems.map(item => {
            searchItemsPipelines.map(sipipiline => {
              if (sipipiline.joborderId === item.joborderId) {
                sipipiline.title = item.title;
              }
            })
          })
          resolve(searchItemsPipelines)
        }, err => {})
      },
      error => {resolve([])})
    })
  }

  fetchThread(email: string, subject: string) {
    return new Promise<boolean>( async resolve => {
          // Getting PIpelines for this search item
      this.searchItemPipelines = await this.getSearchItemPipelines();
      // Fetching thread
      YhCore.messenger.fetchThread(email, thread => {
        this.activeThread = thread
        this.loadingMessages = false;
        this.scroll = true;
        // Preparing data for new message
        this.newMessage.subject = subject;
        this.newMessage.to = email;
        resolve(true)
      }, err => {})
    })
  }

  ngAfterViewChecked() {
    // Scroll to bottom once thread is loaded fro convenience.
    // AfterviewInit fires every 5 seconds (nav component is reinited)
    // so I added this.scroll to prevent it from scrolling bottom every 5 seconds
    if (this.scroll === true) {
      this.scrollToBottom();
    }
  }

  resetAllData() {
    this.chosenPipeline = new FormControl('', [Validators.required]);
    this.chosenTemplate = new FormControl('')
    this.newMessage.message = '';
    this.newMessage.subject = '';
    this.newStatus = '';
  }

  async itemChose(item: any) {
    this.activeThread = [];
    const id = item.candidateId || item.contactId;
    this.resetAllData()
    // Fetching contact of search item depending on what was chosen
    if (item.candidateId) this.activeItem = await this.getSearchItem(id);
    if (item.contactId) this.activeItem = await this.getContactItem(id);

    // On Small devices displaying container with messeges
    this.showMessages = true;

    this.activeItemNotSet = false;

    if (this.activeItem.email1 != null && this.activeItem.email2 != null) {
      this.email = true;
    } else {
      this.email = false;
      const email =
      (this.activeItem.email1 != null && this.activeItem.email1.length > 0) ? this.activeItem.email1 : this.activeItem.email2
      // Fetching thread for non empty and non null email
      this.loadingMessages = true;
      this.fetchThread(email, '')
    }
  }

  onChooseEmail() {
    this.email = false
    this.loadingMessages = true;
    this.fetchThread(this.chosenEmail, '').then(res => {
      this.chosenEmail = '';
    })
  }

  enableSearchMode() {
    this.isSearchMode = true;
    this.activeThread = [];
    this.activeItem = {};
    this.activeItemNotSet = true

    YhCore.searchItems.quickSearch(this.filter, filteredSearchItems => {
      this.filteredSearchItems = filteredSearchItems;
      this.quickSearchError = false;
    }, err => {this.quickSearchError = true})

    YhCore.contactItems.quickSearch(this.filter, filteredContactItems => {
      this.filteredcontactItems = filteredContactItems;
      this.quickSearchError = false;
    }, err => {this.quickSearchError = true})
  }

  disableSearchMode() {
    this.isSearchMode = false;
    this.email = undefined;
    this.activeThread = [];
    this.filteredSearchItems = [];
    this.filteredcontactItems = [];
  }

  async chooseThread(item: any, type: string) {
    this.activeThread = [];
    this.newMessage.message = '';
    this.loadingMessages = true;
    this.activeItem = item;
    this.activeItem.newEmails = null;

    // On Small devices displaying container with messeges
    this.showMessages = true;

    // Getting that exact email the message came from
    const currentEmail = this.newEmails.filter(email => {
      return (email.id === item.candidateId || email.id === item.contactId) && email.type === type;
    });
    // Fetching thread for this email
    this.fetchThread(currentEmail[0].email, currentEmail[0].subject).then( async res => {
      this.activeThread.forEach(val => {
        if(val.uid === parseInt(currentEmail[currentEmail.length - 1].uid) && val.seen === 0){
          val['isNew'] = true
        }
      })
      for( let item of currentEmail) {
        await this.markAsSeen(item.uid)
      }
      this.onMarkAsSeen.emit(true)
    })
  }

  onChangePipeline(){
    if (!this.chosenPipeline.invalid && this.newMessage.subject === '') {
      this.showError = false;
      this.newMessage.subject = this.chosenPipeline.value.title;
    }
  }

  constructMessageBody(message: string): string {
    message = message.replace('%contact_name%', this.activeItem.firstName);
    message = message.replace('%company_name%', this.activeItem.lastName);
    message = message.replace('%offer_name%', this.chosenPipeline.value.title);
    return message;
  }

  applyTemplate($event:MatSelectChange ) {
    if(!this.chosenPipeline.value) {
      this.snackBar.open('Please select Regarding', 'OK', {duration:3000})
      this.chosenTemplate.reset()
      return
    }
    if ($event.value) {
      this.newMessage.message = this.constructMessageBody($event.value.body);
      this.newMessage.subject = $event.value.subject;
    }
    else {
      this.newMessage.message = '';
    }
  }

  sendMessage() {
    if (this.chosenPipeline.invalid) {
      this.showError = true;
      return;
    }
     // Setting blured message to show in thread before it's sent
    this.scroll = true;
    const newMessage: MessageType = JSON.parse(JSON.stringify(this.newMessage))
    newMessage.from = this.currentUserEmail;
    newMessage.messageSending = true;
    this.activeThread.push(newMessage);

    // Adding data needed to log new activity
    this.newMessage.joborderId = this.chosenPipeline.value.joborderId;
    this.newMessage.searchitemId = this.activeItem.candidateId || this.activeItem.companyId;

    YhCore.messenger.send(this.newMessage, () => {
      // Updating status in pipeline if new status was passed in
      if (this.newStatus) {
        this.pipelineStatusUpdate();
      }
      // Fetching updated thread
      this.fetchThread(this.newMessage.to, this.newMessage.subject);
      // Reseting data
      if (!this.newStatus) {
        this.resetAllData()
      }
    }, err => {
      this.snackBar.open(err, 'ERROR', {duration:3000})
    })
  }

  async pipelineStatusUpdate() {
    const id = this.activeItem.contactId ? this.activeItem.companyId : this.activeItem.candidateId;

    YhCore.workItemsPipeline.getPipelinesByWorkItem(+this.chosenPipeline.value.joborderId, workItemPipelines => {
      let pipelineToEdit: string;
      workItemPipelines.map((item: WorkItemPipelineType) => {
        if (item.candidateId === id) {
          pipelineToEdit = item.candidateJoborderId;
        }
      })

      const editedPipeline = new WorkItemPipelineType();

      editedPipeline.candidateJoborderId = pipelineToEdit;
      editedPipeline.status = this.newStatus;
      // YhCore.workItemsPipeline.edit(editedPipeline, success => {
      //   this.newStatus = '';
      //   this.chosenPipeline = new FormControl('', [Validators.required]);
      //   this.chosenTemplate = new FormControl('');
      // }, err=> {})
    }, error => {})
  }

  scrollToBottom(): void {
    this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
   if (this.scrollContainer.nativeElement.scrollTop > 0) {
     this.scroll = false;
   }
  }

  goToProfile(event, item) {
    event.stopPropagation();
    this.router.navigate([`${this.app.type.names.searchItems.toLowerCase()}/${item.candidateId}`])
  }
}
