"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareNormsSallaryCallendarService = void 0;
const share_norms_salary_calendar_type_1 = require("../types/share-norms-salary-calendar-type");
class ShareNormsSallaryCallendarService {
    constructor() {
    }
    mapReversShareNormsSallary(raw) {
        const result = {};
        if (raw.id)
            result.id = raw.id;
        if (raw.userId)
            result.user_id = raw.userId;
        if (raw.normActivityCandidate)
            result.norm_activity_candidate = raw.normActivityCandidate;
        if (raw.normAddCandidate)
            result.norm_add_candidate = raw.normAddCandidate;
        if (raw.normSubmittedCandidates)
            result.norm_submitted_candidates = raw.normSubmittedCandidates;
        return result;
    }
    mapShareNormsSallary(raw) {
        return raw.map(val => this.mapShareNormSallary(val));
    }
    mapShareNormSallary(raw) {
        const result = new share_norms_salary_calendar_type_1.ShareNormsSalaryCallendar();
        result.id = raw.id;
        result.userId = raw.user_id;
        result.firstName = raw.first_name;
        result.lastName = raw.last_name;
        result.normActivityCandidate = raw.norm_activity_candidate;
        result.normAddCandidate = raw.norm_add_candidate;
        result.normSubmittedCandidates = raw.norm_submitted_candidates;
        return result;
    }
}
exports.ShareNormsSallaryCallendarService = ShareNormsSallaryCallendarService;
