import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { YhCore } from '../app-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class AuthRedirectPageGuard implements CanActivate {
  constructor(private router: Router, private activatedRoute: ActivatedRoute,) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(YhCore.api.isValid()) {
      this.router.navigateByUrl('/');
      return false
    };
    return true;
  }
  
}
