<app-navigation>
  <mat-card class="main-header">
    <mat-card-content>
      <button mat-flat-button color="primary" (click)="router.navigate([app.type.names['customers'].toLowerCase().concat('/create-').concat(app.type.names['customer'].toLowerCase())])">ADD {{app.type.names['customers'].toUpperCase()}}</button>
    </mat-card-content>
  </mat-card>
  <mat-card class="main-content">
    <mat-card-content>
      <app-customers-table #companiesTableComponent [data]="companies" (onCompanySelect)="navigateToCompany($event)"></app-customers-table>
    </mat-card-content>
  </mat-card>
</app-navigation>
