import { MatSnackBar } from '@angular/material/snack-bar';
import { YhCore } from 'src/app/app-services/core.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-follow-up-page',
  templateUrl: './follow-up-page.component.html',
  styleUrls: ['./follow-up-page.component.scss']
})
export class FollowUpComponent implements OnInit {
  followUpList = [] = []

  constructor(
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getFollowUpList();
  }

  getFollowUpList() {
    YhCore.followUp.list(suc => {
      this.followUpList = suc;
    }, err => { })
  }

  deleteFollowUp($event){
    YhCore.followUp.remove(+$event ,suc => {
      this.getFollowUpList();
      this.snackBar.open('Follow up was successfully delete', 'OK', {duration: 3000})
    }, err => { })
  }
}