import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, publishReplay, refCount } from 'rxjs/operators';
import { YhCore, WorkItemType, WorkItemStatusType, CustomerType, WorkItemPipelineType, UserType, WorkItemNotesType, Calibrationtype } from '../app-services/core.service';
import { Observable } from 'rxjs';
import { AppService } from '../app-services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { WorkItemSubmitDialogComponent } from '../app-dialogs/work-item-submit-dialog/work-item-submit-dialog.component';
import { FormControl } from '@angular/forms';
import { CustomerAccessOffersOrWorkItemsDialogComponent } from '../app-dialogs/customer-access-offers-or-work-items-dialog/customer-access-offers-or-work-items-dialog.component';
import { CustomerAccessItem } from 'core/dist/types/customer-access-item-type';
import { AccessWorkItemDialogComponent } from '../app-dialogs/access-work-item-dialog/access-work-item-dialog.component';

@Component({
  selector: 'app-work-item-page',
  templateUrl: './work-item-page.component.html',
  styleUrls: ['./work-item-page.component.scss']
})
export class WorkItemPageComponent implements OnInit {
  @ViewChild('pipelineTableComponent') pipelineTableComponent;
  users: UserType[] = [];
  customers: CustomerType[] = [];
  workItem: WorkItemType;
  workItemNotes: WorkItemNotesType = new WorkItemNotesType();
  workItemPipeline: WorkItemPipelineType[] = [];
  workItemStatuses: WorkItemStatusType[] = [];
  isEditMode = false;
  chartData: any[] = [];
  chartColors: any[] = [];
  getWorkItemId: Observable<string>;
  accessLevelEdit = true;
  activeTabs = 0;
  ageFrom: string;
  ageTo: string;
  moreInfo: boolean = false;
  dateOpening = new FormControl;
  dateDuration = new FormControl;
  companyId: string;
  calibrations: Calibrationtype[] = [];

  constructor(
    public app: AppService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private router: Router,
  ) { }

  async ngOnInit() {

    this.route.queryParams.subscribe( data => {
      if (data['tabs'] === 'condition'){
        this.activeTabs = 1;
      }
    })
    await this.getCompany()
    this.getJobOrder();
    this.getUsers()
  }
  
  getCompany():Promise<any> {
    return new Promise( (resolve, reject) => {
      YhCore.customers.list( (res: CustomerType[]) => {
        this.companyId = res[0].companyId
        resolve(true)
      }, err => { resolve(false)})
    })
  }

  getUsers():Promise<UserType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.users.list(res => {
        this.users = res;
        resolve(res)
      }, err => reject(err))
    })
  }

  getJobOrder() {
    this.chartData = [];
    this.workItemPipeline = [];
    this.getWorkItemId = this.route.paramMap.pipe(
      map(params => {
        const workItemId = params.get('id');
        return workItemId;
      }), publishReplay(1), refCount()
    );

    this.getWorkItemId.subscribe(thisWorkItemId => {
      YhCore.workItems.read(thisWorkItemId, async ([workItem, workItemNotes]) => {
        this.workItem = workItem;
        this.workItemNotes = workItemNotes;
        if (workItemNotes.age) {
          this.setAge(workItemNotes.age)
        }
        this.setDate()
      }, error => { })
      YhCore.workItemsPipeline.getPipelinesByWorkItem(+thisWorkItemId, async pipeline => {
        // Fetching Pipeline data
        this.workItemPipeline = pipeline;

        // Fetching statuses
        YhCore.workItemsStatuses.list(this.companyId, (statuses: WorkItemStatusType[]) => {
          this.workItemStatuses = statuses;

          // Preparing chart data
          this.prepareDataForChart()
        }, err => {})
      }, error => { })
    })
  }

  prepareDataForChart() {
    this.workItemStatuses.map(statusItem => {
      this.chartData.push({
        name: statusItem['statusName'],
        value: 0
      })
      if(this.workItemPipeline.length) {
        const pipeline = this.workItemPipeline.find( val => val.statusShortDescription.toLowerCase() === statusItem['statusName'].toLowerCase())
        if (pipeline && pipeline.statusColor)
          this.chartColors.push(pipeline.statusColor);
        else
          this.chartColors.push('#7aacac')
      }
    })

    // Statuses quantity for chart
    this.workItemPipeline.map(candidate => {
      this.chartData.map(charItem => {
        if (charItem.name === candidate.statusShortDescription) {
          charItem.value += 1;
        }
      })
    })

    this.chartData = this.chartData.filter(charItem => {
      if (charItem.value > 0) {
        return true;
      } else {
        return false;
      }
    })
  }

  setAge(age:string) {
    if(!/\d{1,2}/.test(age)) {
      this.ageFrom = '';
      this.ageTo = '';
      return
    }
    this.ageFrom = /\d{1,3}/.test(age) ? age.match(/\d{1,3}/)[0]: '';
    this.ageTo = /\d{1,3}$/.test(age) ? age.match(/\d{1,3}$/)[0]: '';
  }

  setDate() {
    this.workItemNotes.dateOpeningPosition ? this.dateOpening = new FormControl({ value: new Date(this.workItemNotes.dateOpeningPosition), disabled: true }) : this.dateOpening = new FormControl({value: '', disabled: true});
    this.workItemNotes.durationRequestExecution ? this.dateDuration = new FormControl({ value: new Date(this.workItemNotes.durationRequestExecution), disabled: true }) : this.dateDuration = new FormControl({value: '', disabled: true});
  }

  startEditMode() {
    this.isEditMode = true;

    if (this.workItemNotes.paymentSame) {
      this.workItemNotes.paymentProbationPeriodTo = this.workItemNotes.paymentTo;
      this.workItemNotes.paymentProbationPeriodFrom = this.workItemNotes.paymentFrom;
    }
    // if(this.yhUser.accessLevel >= 400 || this.workItem.owner === this.yhUser.userId){
      // this.accessLevelEdit = false;
    // }
  }

  updateJobOrder() {
    this.isEditMode = false;
    this.accessLevelEdit = true;

    if (this.workItemNotes.age !== 'Not important' && (this.ageFrom || this.ageTo)) {
      this.workItemNotes.age = `${this.ageFrom || 0} - ${this.ageTo}`
    }
    // Saving payment for probation period
    this.workItemNotes.paymentProbationPeriod = this.workItemNotes.paymentProbationPeriodFrom + ' - ' + this.workItemNotes.paymentProbationPeriodTo 

    // Important to pass these values or workitem wont't be updated
    this.workItem.isPublic = +this.workItem.public;
    this.workItemNotes.dateOpeningPosition = this.dateOpening.value;
    this.workItemNotes.durationRequestExecution = this.dateDuration.value;

    YhCore.workItems.edit(this.workItem, this.workItemNotes, success => {
      this.snackBar.open(this.app.type.names['workItem'] + ' was successfully updated', 'OK', {
        duration: 3000,
      });
    }, error => { this.snackBar.open(error, 'ERROR', {duration: 5000}) })
  }

  onDeleteWorkItem(): void {
    YhCore.workItems.remove(this.workItem.joborderId, success => {
      this.router.navigate([this.app.type.names['workItems'].toLowerCase()]);
      this.snackBar.open(this.app.type.names['workItem'] + ' was successfully delete', 'OK', {
        duration: 3000,
      });
    }, error => { this.snackBar.open(error, 'ERROR', {duration: 5000}) })
  }

  deleteFromPipeline(pipeline) {
    pipeline.deleting = true;
    YhCore.workItemsPipeline.remove(+pipeline.candidateJoborderId, () => {
      this.getJobOrder();
      this.snackBar.open('Pipeline delete', 'OK', {duration: 5000})
    }, error => { this.snackBar.open(error, 'ERROR', {duration: 5000})})
  }

  navigateToWorkItemCondition() {
    this.activeTabs = 1;
  }

  navigateToJobOrder() {
    this.router.navigate([this.app.type.names['workItems'].toLowerCase(), this.workItem.joborderId]);
  }

  submitTo() {
    const dialogRef = this.dialog.open(WorkItemSubmitDialogComponent, {width: '900px'});

    dialogRef.afterClosed().subscribe( res => {
      if(res) {
        YhCore.workItems.submit(this.workItem.joborderId, res => {
          window.location.reload()
        }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
      }
    })
  }

  onPaymentSameChecked() {
    this.workItemNotes.paymentSame = !this.workItemNotes.paymentSame;
    if (this.workItemNotes.paymentSame) {
      this.workItemNotes.paymentProbationPeriodTo = this.workItemNotes.paymentTo;
      this.workItemNotes.paymentProbationPeriodFrom = this.workItemNotes.paymentFrom;
    } else {
      this.workItemNotes.paymentProbationPeriodTo = '';
      this.workItemNotes.paymentProbationPeriodFrom = '';
    }
  }

  async openDialogAccessCustomer() {
    this.dialog.open(AccessWorkItemDialogComponent, {width: "600px", data:{workItemId: +this.workItem.joborderId}})
  }
}
