<h1 mat-dialog-title>Edit Status</h1>
<div mat-dialog-content>

  <form #form="ngForm" [formGroup]="workItemStatusForm" class="form">

    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="form__field">
        <mat-label>Status Name</mat-label>
        <input matInput formControlName="statusName">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline" floatLabel="always" class="form__field">
        <mat-label>Status Rank</mat-label>
        <input matInput type="number"  formControlName="statusRank">

        <div class="form__error" *ngIf="f.statusRank.errors">
          Required & Only number
        </div>
      </mat-form-field>
    </div>

    <button style="margin-right: 5px;" mat-flat-button color="primary" (click)="onCancelClick()">Cancel</button>
    <button style="margin-right: 5px;" mat-flat-button color="primary" (click)="onSaveClick()" [disabled]="workItemStatusForm.invalid || workItemStatusForm.pristine">Save</button>

  </form>

</div>