"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebFollowUpsController = void 0;
class WebFollowUpsController {
    constructor(api, followUpService) {
        this.api = api;
        this.followUpService = followUpService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('followUps/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.followUpService.mapFollowUpItems(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('followUps/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.followUpService.mapFollowUpItem(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('followUps/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    edit(followUp, successCallback, errorCallback) {
        const src = this.followUpService.mapReverseFollowUpItem(followUp);
        this.api.postRequest('followUps/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    create(followUp, successCallback, errorCallback) {
        const src = this.followUpService.mapReverseFollowUpItem(followUp);
        this.api.postRequest('followUps/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    getFollowUpData(id, successCallback, errorCallback) {
        this.api.postRequest('followUps/getData', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.followUpService.mapFollowUpDataItems(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    getFields(successCallback, errorCallback) {
        this.api.postRequest('followUps/getFields', {}, response => {
            if (response.data.valid === true)
                successCallback(this.followUpService.mapFollowUpGetFieldsItem(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    find(followUp, successCallback, errorCallback) { }
    sort(followUps, desc, successCallback, errorCallback) { }
}
exports.WebFollowUpsController = WebFollowUpsController;
