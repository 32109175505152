"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebJoborderGroupController = void 0;
class WebJoborderGroupController {
    constructor(api, joborderService) {
        this.api = api;
        this.joborderService = joborderService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('joborder-group/list', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.joborderService.mapJoborderGroups(response.data.data));
            }
            else {
                errorCallback(response.data);
            }
        }, err => errorCallback(err));
    }
    create(payload, successCallback, errorCallback) {
        const src = this.joborderService.mapReverseJoborderGroup(payload);
        this.api.postRequest('joborder-group/create', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data);
            }
            else {
                errorCallback(response.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebJoborderGroupController = WebJoborderGroupController;
