"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AttachmentService = void 0;
const attachments_type_1 = require("./../types/attachments-type");
class AttachmentService {
    constructor() { }
    mapAttachmets(raw) {
        return raw.map((val) => this.mapAttachmet(val));
    }
    mapAttachmet(raw) {
        const result = new attachments_type_1.Attachment();
        result.attachmentId = raw.attachment_id;
        result.isFromAnotherServer = raw.is_from_another_server;
        result.dataItemId = raw.data_item_id;
        result.dataItemType = raw.data_item_type;
        result.siteId = raw.site_id;
        result.title = raw.title;
        result.originalFilename = raw.original_filename;
        result.storedFilename = raw.stored_filename;
        result.contentType = raw.content_type;
        result.resume = raw.resume;
        result.text = raw.text;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.profileImage = raw.profile_image;
        result.directoryName = raw.directory_name;
        result.isHidden = raw.is_hidden;
        return result;
    }
}
exports.AttachmentService = AttachmentService;
