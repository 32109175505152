"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItemsService = void 0;
const work_item_type_1 = require("../types/work-item-type");
const work_item_status_1 = require("../types/work-item-status");
const search_item_by_skills_1 = require("../types/search-item-by-skills");
const work_item_load_skills_type_1 = require("../types/work-item-load-skills-type");
class WorkItemsService {
    constructor() { }
    mapReverseWorkItem(src) {
        const result = {};
        if (src.alias)
            result.alias = src.alias;
        if (src.city)
            result.city = src.city;
        if (src.cityEn)
            result.city_en = src.cityEn;
        if (src.cityRu)
            result.city_ru = src.cityRu;
        if (src.cityUa)
            result.city_ua = src.cityUa;
        if (src.clientJobId)
            result.client_job_id = src.clientJobId;
        if (src.companyDepartmentId)
            result.compan_department_Id = src.companyDepartmentId;
        if (src.companyId)
            result.company_id = src.companyId;
        if (src.contactId)
            result.contact_id = src.contactId;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        if (src.description || src.description === '')
            result.description = src.description;
        if (src.duration)
            result.duration = src.duration;
        if (src.entereBy)
            result.entere_by = src.entereBy;
        if (src.isAdminHidden)
            result.is_admin_hidden = src.isAdminHidden;
        if (src.isHot)
            result.is_hot = src.isHot;
        if (src.joborderId)
            result.id = src.joborderId;
        if (src.notes || src.notes === '')
            result.notes = src.notes;
        if (src.openings)
            result.openings = src.openings;
        if (src.openingsAvailable)
            result.openings_available = src.openingsAvailable;
        if (src.owner)
            result.owner = src.owner;
        if (src.public)
            result.public = src.public;
        if (src.questionnaireId)
            result.questionnaire_id = src.questionnaireId;
        if (src.rateMax)
            result.rate_max = src.rateMax;
        if (src.recruiter)
            result.recruiter = src.recruiter;
        if (src.salary)
            result.salary = src.salary;
        if (src.seoDescriptionEn)
            result.seo_description_en = src.seoDescriptionEn;
        if (src.seoKeywordsEn)
            result.seo_keywords_en = src.seoKeywordsEn;
        if (src.seoTitleEn)
            result.seo_title_en = src.seoTitleEn;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.startDate)
            result.start_date = src.startDate;
        if (src.state || src.state === '')
            result.state = src.state;
        if (src.status)
            result.status = src.status;
        if (src.title || src.title === '')
            result.title = src.title;
        if (src.type)
            result.type = src.type;
        if (src.joborderGroupId)
            result.joborder_group_id = src.joborderGroupId;
        if (src.skillList)
            result.skillList = this.mapReverseSkillsList(src.skillList);
        return result;
    }
    mapReverseSkillsList(raw) {
        return raw.map(val => {
            return {
                id: val.id,
                skill_name: val.name
            };
        });
    }
    mapWorkItems(raw) {
        return raw.map((val) => this.mapWorkItem(val));
    }
    mapWorkItem(raw) {
        const result = new work_item_type_1.WorkItem();
        result.alias = raw.alias;
        result.city = raw.city;
        result.cityEn = raw.city_en;
        result.cityRu = raw.city_ru;
        result.cityUa = raw.city_ua;
        result.clientJobId = raw.client_job_id;
        result.companyDepartmentId = raw.compan_department_Id;
        result.companyId = raw.company_id;
        result.contactId = raw.contact_id;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.description = raw.description;
        result.duration = raw.duration;
        result.entereBy = raw.entere_by;
        result.isAdminHidden = raw.is_admin_hidden;
        result.isHot = raw.is_hot;
        result.joborderId = raw.joborder_id;
        result.notes = raw.notes;
        result.openings = raw.openings;
        result.openingsAvailable = raw.openings_available;
        result.owner = raw.owner;
        result.public = raw.public;
        result.questionnaireId = raw.questionnaire_id;
        result.rateMax = raw.rate_max;
        result.recruiter = raw.recruiter;
        result.salary = raw.salary;
        result.seoDescriptionEn = raw.seo_description_en;
        result.seoKeywordsEn = raw.seo_keywords_en;
        result.seoTitleEn = raw.seo_title_en;
        result.siteId = raw.site_id;
        result.startDate = raw.start_date;
        result.state = raw.state;
        result.status = raw.status;
        result.title = raw.title;
        result.type = raw.type;
        result.isPublic = raw.is_public;
        result.skillListJobOrder = raw.skillListJobOrder;
        result.joborderGroupId = raw.joborder_group_id;
        result.joborderGroupTitle = raw.joborder_group_title;
        if (raw.owner_name)
            result.ownerName = raw.owner_name;
        if (raw.company_name)
            result.companyName = raw.company_name;
        if (raw.recruiter_name)
            result.recruiterName = raw.recruiter_name;
        return result;
    }
    mapWorkItemStatuses(raw) {
        return raw.map((val) => this.mapWorkItemStatus(val));
    }
    mapWorkItemStatus(raw) {
        const result = new work_item_status_1.WorkItemStatus();
        result.canBeScheduled = raw.can_be_scheduled;
        result.candidateJoborderStatusId = raw.candidate_joborder_status_id;
        result.isEnabled = raw.is_enabled;
        result.shortDescription = raw.short_description;
        result.statusColor = raw.status_color;
        result.triggersEmail = raw.triggers_email;
        return result;
    }
    mapWorkItemsFindSearchItemsBySkills(raw) {
        return raw.map((val) => this.mapWorkItemsFindSearchItemsBySkill(val));
    }
    mapWorkItemsFindSearchItemsBySkill(raw) {
        const result = new search_item_by_skills_1.SearchItemBySkills();
        result.candidateId = raw.candidate_id;
        result.firstName = raw.first_name;
        result.lastName = raw.last_name;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.keySkills = raw.key_skills;
        result.img = raw.img;
        result.relevant = raw.relevant;
        return result;
    }
    mapLoadWorkItemsSkills(raw) {
        return raw.listWorkItemsSkills.map((val) => val.map(this.mapLoadWorkItemsSkill));
    }
    mapLoadWorkItemsSkill(raw) {
        const result = new work_item_load_skills_type_1.WorkItemLoadSkills();
        result.id = raw.id;
        result.jobOrderId = raw.joborder_id;
        result.skillId = raw.skill_id;
        result.name = raw.name;
        return result;
    }
}
exports.WorkItemsService = WorkItemsService;
