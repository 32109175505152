<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
  </mat-form-field>

  <div>
    <div class="table-wrap">
      <div class="table-wrap__inner">
          <mat-table [dataSource]="dataSource" matSort> 

            <ng-container matColumnDef="firstName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>First name</mat-header-cell>
              <mat-cell *matCellDef="let user">{{user['firstName']}}</mat-cell> 
            </ng-container>

            <ng-container matColumnDef="lastName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Last name</mat-header-cell>
              <mat-cell *matCellDef="let user">{{user['lastName']}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="userName">
              <mat-header-cell *matHeaderCellDef mat-sort-header>User name</mat-header-cell>
              <mat-cell *matCellDef="let user">{{user['login']}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="accessLevel">
              <mat-header-cell *matHeaderCellDef mat-sort-header>Access level</mat-header-cell>
              <mat-cell *matCellDef="let user">{{user['description']}}</mat-cell>
            </ng-container>

            <ng-container matColumnDef="edit"> 
              <mat-header-cell *matHeaderCellDef mat-sort-header style="padding-right: 6px; margin-left: 18px;">Edit</mat-header-cell>
              <mat-cell *matCellDef="let user">
                  <button (click)="editUser(user)"  aria-label="Edit" mat-button class="add-users-button">
                    Edit
                  </button>
              </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let user; columns: displayedColumns;"></mat-row>
          </mat-table>

          <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
        </div>
      </div>
  </div>
</div>
