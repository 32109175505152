"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldsOrderController = void 0;
class FieldsOrderController {
    constructor(api, extraFieldsService) {
        this.api = api;
        this.extraFieldsService = extraFieldsService;
    }
    list(isSales, successCallback, errorCallback) {
        this.api.postRequest('configExtraFields/list', { is_sales: isSales }, response => {
            if (response.data.valid === true)
                successCallback(this.extraFieldsService.mapExtraFields(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    read(id, successCallback, errorCallback) {
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('configExtraFields/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    edit(extraField, successCallback, errorCallback) {
        const src = this.extraFieldsService.mapReverseFieldConfigforEdit(extraField);
        this.api.postRequest('configExtraFields/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    create(extraField, successCallback, errorCallback) {
        const src = this.extraFieldsService.mapReverseFieldConfig(extraField);
        this.api.postRequest('configExtraFields/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    find(extraField, successCallback, errorCallback) { }
    sort(extraField, desc, successCallback, errorCallback) { }
}
exports.FieldsOrderController = FieldsOrderController;
