import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-attachments-table',
  templateUrl: './attachments-table.component.html',
  styleUrls: ['./attachments-table.component.scss']
})
export class AttachmentsTableComponent implements OnInit {

  public displayedColumns = ['id', 'title', 'dateCreated', 'actions'];
  private paginator: MatPaginator;
  private sort: MatSort;

  isLoading: boolean = true;

  dataSource:MatTableDataSource<any> = new MatTableDataSource();

  @Output() onDeleteCv = new EventEmitter<number>();
  @Output() onReviewCv = new EventEmitter<number>();

  @Input() public set data(data) {
    this.applyDataSource(data);
  }
  
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  constructor() {}

  ngOnInit(): void {
  }

  applyDataSource(data) {
    this.dataSource = new MatTableDataSource(data);
    this.applyDataSourceAttributes();
    this.isLoading = false;
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  deleteCv(id: number){
    this.onDeleteCv.emit(id)
  }

  reviewCv(id: number){
    this.onReviewCv.emit(id)
  }

}
