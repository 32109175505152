<mat-card>
  <mat-card-subtitle>
    Experience 
    <button mat-icon-button matTooltip="Add Expirience" [matTooltipPosition]="'right'" (click)="showAddExperienceForm()">
      <mat-icon>add_task</mat-icon>
    </button>
  </mat-card-subtitle>
  <mat-card-content>
    <div class="add-experience" *ngIf="addExperienceMode">
      <form [formGroup]="experienceForm" novalidate>
        <div class="add-experience__date">
          <mat-form-field appearance="fill">
            <mat-label>From</mat-label>
            <input formControlName="dateFrom" matInput [max]="maxDate" [matDatepicker]="picker" (dateChange)="setMinDate($event.value)">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker disabled="false"></mat-datepicker>
          </mat-form-field>
          <span style="min-width: 15px; display: inline-block;"></span>
          <mat-form-field appearance="fill">
            <mat-label>To</mat-label>
            <input formControlName="dateTo" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" (dateChange)="setMaxDate($event.value)">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 [disabled]="tillNowFlag"></mat-datepicker>
          </mat-form-field>
        </div>
        <div style="padding-bottom: 10px;">
          Till now: <mat-checkbox color="primary" (change)="tillNow($event.checked)"></mat-checkbox>
        </div>
        <div>
          <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
            <mat-label>Position</mat-label>
            <input matInput placeholder="Position" value="" formControlName="position" >
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
            <mat-label>Company Name</mat-label>
            <input matInput placeholder="Company Name" value="" formControlName="company">
          </mat-form-field>
        </div>
        <div>
          <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
            <mat-label>Notes</mat-label>
            <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
          </mat-form-field>
        </div>
        <div>
          <button mat-flat-button type="button" (click)="addExperienceMode = !addExperienceMode; experienceForm.reset()">Close</button>
          <button mat-stroked-button type="button" (click)="addNewExperience()" [disabled]="experienceForm.invalid">Save</button>
        </div>
      </form>
    </div>
    <mat-list class="experience-list">
      <mat-list-item *ngFor="let item of experience; index as i;" class="experience-list__column" style="height: auto !important;">
        <div class="item-experience">
          <div class="item-experience__body" *ngIf="editExpirienceField != i + 1; else editExpirienceForm">
            <div style="text-align: right;">
              <button mat-icon-button color="primary" matTooltip="Edit" (click)="editExpirienceField=i+1; initForm(item)" style="cursor: pointer;"><mat-icon>edit</mat-icon></button>
              <button color="warn" style="max-width: 30px; max-height: 30px;" mat-icon-button  matTooltip="Delete" (click)="deleteExperience(item.id)"><mat-icon>delete_forever</mat-icon></button>
            </div>
            <div> 
              <mat-form-field appearance="outline" floatLabel="always" class="non-active">
                <mat-label>Date From - Date To</mat-label>
                <input matInput  readonly="true" value="{{item.dateFrom}} - {{item.dateTo}}">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="non-active">
                <mat-label>Company name</mat-label>
                <input matInput  readonly="true" [value]="item.companyName">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="non-active">
                <mat-label>Position</mat-label>
                <input matInput  readonly="true" [value]="item.position">
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" floatLabel="always" class="non-active">
                <mat-label>Notes</mat-label>
                <textarea matInput placeholder="Notes" [value]="item.notes"></textarea>
              </mat-form-field>
            </div>
          </div>
          <ng-template class="item-experience__form form-item-experience" #editExpirienceForm>
            <form [formGroup]="experienceForm" novalidate>
              <div class="form-item-experience__date">
                <mat-form-field appearance="fill">
                  <mat-label>From</mat-label>
                  <input formControlName="dateFrom" matInput [max]="maxDateFrom" [matDatepicker]="picker" (dateChange)="setMinDate($event.value)">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker [disabled]="tillNowFlag"></mat-datepicker>
                </mat-form-field>
                <span style="min-width: 15px; display: inline-block;"></span>
                <mat-form-field appearance="fill">
                  <mat-label>To</mat-label>
                  <input formControlName="dateTo" matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" (dateChange)="setMaxDate($event.value)">
                  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                </mat-form-field>
              </div>
              <div style="padding-bottom: 10px;">
                Till now: <mat-checkbox color="primary" (change)="tillNow($event.checked)"></mat-checkbox>
              </div>
              <div>
                <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
                  <mat-label>Position</mat-label>
                  <input matInput placeholder="Position" value="" formControlName="position" >
                </mat-form-field>
              </div>
              <div>
                <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
                  <mat-label>Company Name</mat-label>
                  <input matInput placeholder="Company Name" value="" formControlName="company">
                </mat-form-field>
              </div>
              <div>
                <mat-form-field style="width: 100%;" appearance="outline" floatLabel="always">
                  <mat-label>Notes</mat-label>
                  <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
                </mat-form-field>
              </div>
              <div>
                <button mat-flat-button type="button" (click)="closeEdit()">Close</button>
                <button mat-stroked-button type="button" (click)="saveEdit(item.id)" [disabled]="experienceForm.invalid">Save</button>
              </div>
            </form>
          </ng-template>
        </div>
      </mat-list-item>
     </mat-list>
  </mat-card-content>
</mat-card>