import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { WorkItemStatusType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-search-item-statuses-table',
  templateUrl: './search-item-statuses-table.component.html',
  styleUrls: ['./search-item-statuses-table.component.scss']
})
export class SearchItemStatusesTableComponent implements OnInit {

  public displayedColumns = ['name', 'order', 'action'];
  public dataSource: MatTableDataSource<any>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: WorkItemStatusType[]) {
    this.applyDataSource(data);
  }
  @Input() isLoading: boolean;
  @Output() deleteStatus = new EventEmitter<WorkItemStatusType>();
  @Output() editStatus = new EventEmitter<WorkItemStatusType>();
  
  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: WorkItemStatusType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  onDelete(val: WorkItemStatusType) {
    this.deleteStatus.emit(val)
  }

  onEdit(val: WorkItemStatusType) {
    this.editStatus.emit(val)
  }

}
