"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebEmailsController = void 0;
class WebEmailsController {
    constructor(api, emailsService) {
        this.api = api;
        this.emailsService = emailsService;
    }
}
exports.WebEmailsController = WebEmailsController;
