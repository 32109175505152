import { Component, OnInit } from '@angular/core';
import { YhCore, NotificationType, UserType, localStorageService } from '../app-services/core.service'
import { AppService } from '../app-services/app.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-my-events-page',
  templateUrl: './my-events-page.component.html',
  styleUrls: ['./my-events-page.component.scss']
})
export class MyEventsPageComponent implements OnInit {
  public displayedColumns = ['dateCreated', 'author', 'status', 'shareItem', 'actions'];
  public actions = [
    {action: 'edit', ifVisible: 'anonym', tooltip: 'Share Full Candidate', icon: 'share'},
    {action: 'viewContact', tooltip: 'View Candidate', icon: 'account_box'}
  ]
  events: NotificationType[];
  currentUser: UserType = JSON.parse(localStorageService.getItem('YHminiuser'));
  users: UserType[] = JSON.parse(localStorageService.getItem('users'));

  constructor(
    private route: Router,
    private snackBar: MatSnackBar,
    private app: AppService
  ) { }

  async ngOnInit() {

   if (!this.users) this.users = await this.getUsers();
    this.getEvents();
  }

  getEvents() {
    YhCore.notifications.list(events => {
      this.events = events;

      this.events.map((event: NotificationType) => {
        event['status'] = `${event.action}`
        event['shareItem'] = `${event.candidateFirstName ?? ''} ${event.candidateLastName ?? ''}`;
        event['author'] = this.users.find( val => +val.userId === +event.isForUser) ?? ''
        if(event.action.toLowerCase() === 'share anonym candidate' && event.candidateFirstName.toLowerCase() === 'anonym' && +event.isViewed !== 2) {
          event['anonym'] = true;
        }
      })
     }, error => {})
  }

  getUsers() {
    return new Promise<UserType[]>(resolve => {
      YhCore.users.list((usersList: UserType[]) => {resolve(usersList)}, err => {})
    })
  }

  navigateToSearchItem(row:NotificationType) {
    this.route.navigateByUrl(`${this.app.type.names.searchItems.toLowerCase()}/${row.dataItemId}`)
  }

  shareFullCandidate(item: NotificationType) {
    YhCore.searchItemSharing.sendRequestForShareFull(+item.id, +item.dataItemId, res => {
      if(res) {
        this.snackBar.open('We send request for share full candidate', 'OK', {duration: 3000});
        this.getEvents();
      }
    }, err => this.errorHandling(err))
  }

  errorHandling(err) {
    this.snackBar.open(err, "ERROR", {duration: 5000})
  }
}
