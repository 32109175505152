import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-add-cv-settings-dialog',
  templateUrl: './add-cv-settings-dialog.component.html',
  styleUrls: ['./add-cv-settings-dialog.component.scss']
})
export class AddCvSettingsDialogComponent implements OnInit {

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    event.returnValue = false;
  }

  public Editor = ClassicEditor;

  public cvSettingsData;

  public dataHeaderField: string = '';
  public dataFooterField: string = '';

  stepOne: boolean = false;

  cvSettingsForm: FormGroup = new FormGroup({
    headerField: new FormControl('', [Validators.required]),
    dataField: new FormControl('', [Validators.required]),
    footerField: new FormControl('', [Validators.required]),
  })

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddCvSettingsDialogComponent>,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.dialogRef.disableClose = true;
    this.cvSettingsData = this.data.cvSettings;
    this.dialogRef.backdropClick().subscribe( () => {
      let cn = confirm('Data will not be saved. Close ?')
      if(cn) {
        this.dialogRef.close()
      }
    })
  }

  get f() { return this.cvSettingsForm.controls; }

  saveDataField(data: Array<any>) {
    const minFieldsIsActive = data.filter(val => val.isActive === true);
    if(minFieldsIsActive.length <= 2 ) {
      this.snackBar.open('Please choose minimum 3 field', 'OK', {duration: 3000})
      return
    }
    this.f.dataField.setValue(data)
    this.dialogRef.close(this.cvSettingsForm.value)
    this.cvSettingsForm.reset()
  }

}
