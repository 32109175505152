import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkItemStatusType } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-search-item-add-status-dialog',
  templateUrl: './search-item-add-status-dialog.component.html',
  styleUrls: ['./search-item-add-status-dialog.component.scss']
})
export class SearchItemAddStatusDialogComponent implements OnInit {

  public workItemStatusForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<SearchItemAddStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {status: WorkItemStatusType}
  ) { }

  ngOnInit(): void {
    this.initForm()
  }

  get f(): any {
    return this.workItemStatusForm.controls;
  }

  initForm() {
    this. workItemStatusForm = new FormGroup({
      statusName: new FormControl('', [Validators.required]),
      statusRank: new FormControl('', [Validators.required, Validators.pattern(/\d/),]),
    })
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close(this.workItemStatusForm.value)
  }

}
