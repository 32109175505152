import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SelectionModel } from '@angular/cdk/collections';
import { SearchItemSharingType } from "../../app-services/core.service";


@Component({
  selector: 'app-request-for-share-search-items-table',
  templateUrl: './request-for-share-search-items-table.component.html',
  styleUrls: ['./request-for-share-search-items-table.component.scss']
})
export class RequestForShareSearchItemsTableComponent{
  public displayedColumns = ['select', 'id', 'candidateId', 'dateModified', 'enteredBy' , 'candidateOwner', 'requestStatus'];
  public selection = new SelectionModel<SearchItemSharingType>(true, []);
  public dataSource: MatTableDataSource<SearchItemSharingType>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: SearchItemSharingType[]) {
    this.applyDataSource(data);
  }

  @Output() onClickAllowEvent = new EventEmitter<SearchItemSharingType>();
  @Output() onClickDeniedEvent = new EventEmitter<SearchItemSharingType>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: SearchItemSharingType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  checkboxLabel(row?: SearchItemSharingType): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  onClickAllow (requestSharedItems: SearchItemSharingType) {
    this.onClickAllowEvent.emit(requestSharedItems);
  }

  onClickDenied(requestSharedItems: SearchItemSharingType) {
    this.onClickAllowEvent.emit(requestSharedItems);
  }

}
