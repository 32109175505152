import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatButtonToggle, MatButtonToggleChange, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerAccessItem } from 'core/dist/types/customer-access-item-type';
import { AppService } from 'src/app/app-services/app.service';
import { GroupUsersType, GroupWithUsersType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-access-work-item-dialog',
  templateUrl: './access-work-item-dialog.component.html',
  styleUrls: ['./access-work-item-dialog.component.scss']
})
export class AccessWorkItemDialogComponent implements OnInit {

  public loading: boolean = false;
  public form:FormGroup;
  public groupUsers: GroupWithUsersType[] = [];
  public accessWorkItem: CustomerAccessItem[];
  public accessUserIds: string[];
  public selectedUsersByGroup: any[];

  constructor(
    public app: AppService,
    private snackBar: MatSnackBar,
    public dialog: MatDialogRef<AccessWorkItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {workItemId: number},
  ) {
    if(!this.data.workItemId) throw new Error("workItemId is required");
  }

  ngOnInit(): void {
    this.getData()
  }

  async getData() {
    this.loading = true;
    await this.getGroupUsers().then(res => this.groupUsers = res);
    this.getWorkItemAccessItem(this.data.workItemId).then(res => {
      this.accessWorkItem = res;
      this.accessUserIds = res.map(val => val.userId);
      this.initForm();
      this.loading = false;
    });
  }

  initForm() {
    this.form = new FormGroup({
      groupUser: new FormControl('', [Validators.required]),
      users: new FormArray([
        ...this.accessUserIds.map( id => new FormControl(id))
      ])
    })
    this.subscribeChangeGroupUser();
    this.getGroupUserControl.setValue(this.groupUsers[0])
  }

  subscribeChangeGroupUser() {
    this.getGroupUserControl.valueChanges.subscribe(groupUser => {
      this.selectedUsersByGroup = groupUser.users;
    })
  }

  getWorkItemAccessItem(workItemId: number):Promise<CustomerAccessItem[]> {
    return new Promise( (resolve, reject) => {
      YhCore.workItems.getAccessItem(workItemId, res => {
        resolve(res)
      }, err => reject(err))
    })
  }

  getGroupUsers():Promise<GroupWithUsersType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.listWithUsers({listDepartamentUser: 1}, res => resolve(res), err => reject(err))
    })
  }

  onSave() {
    const userIds = this.getUsersFormArray.controls.map( (val: FormControl) => {return {userId: val.value}})
    this.setAccessCustomerWorkItem(userIds, +this.data.workItemId).then(res => {
      this.snackBar.open('Work item accessed', 'OK', {duration:3000});
      this.dialog.close(true)
    })
  }

  changeToggleUser(event: MatButtonToggleChange, id: number) {
    if(event.source.checked) {
      this.getUsersFormArray.push(new FormControl(id))
      return
    }
    const find = this.getUsersFormArray.controls.findIndex(userId => +userId === +id);
    this.getUsersFormArray.removeAt(find);
  }

  setAccessCustomerWorkItem(data: {userId:string}[], workItemId: number) {
    return new Promise( (resolve, reject) => {
      YhCore.workItems.setAccessItem(workItemId, data, res => {
        resolve(res)
      }, err => reject(err))
    })
  }

  get getGroupUserControl() { return this.form.get('groupUser') as AbstractControl}
  get getUsersFormArray() { return this.form.get('users') as FormArray}

}
