<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-content>
        <div class="flex flex-align-end flex-end flex-column">
          <div class="total-items">Total candidates: {{totalSearchItems}}</div>
          <div class="flex flex-align-center button-group group-right">
            <button mat-flat-button color="primary" (click)="entriesCount = entriesCount - entriesStep; onEntriesChange()" [disabled]="entriesCount < entriesStep">PREV</button>
              <p class="padding-x-05" style="text-align: center;">ENTRIES FROM {{entriesCount}} TO {{entriesCount + entriesStep}}</p>
            <button mat-flat-button color="primary" (click)="entriesCount = entriesCount + entriesStep; onEntriesChange()" 
                  [disabled]="(entriesCount + entriesStep) >= totalSearchItems">
                  NEXT
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="main-content">
      <mat-card-content>
        <app-contact-items-table #contactsTable [data]="contacts" [isLoading]="isLoadingContacts"
          (onEmailSendEvent)="openDialogLeadSendEmail($event.email1, true, $event)"
          (onViewContactEvent)="openDialogViewContact($event)"
          (onDeleteContactEvent)="deleteContact($event)"></app-contact-items-table>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>
