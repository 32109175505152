<app-navigation>
  <mat-card>
    <mat-card-subtitle>Follow up settings</mat-card-subtitle>
  </mat-card>
  <mat-card>
    <mat-card-content>

      <mat-form-field appearance="outline">
        <mat-label>Enter title</mat-label>
        <input matInput type="text" [(ngModel)]="title" required>
      </mat-form-field>

      <div cdkDropListGroup>
        <div class="follow-settings-wrap">
          <div class="follow-settings-container">
            <h2>Activity</h2>
            <div cdkDropList [cdkDropListData]="fields.activity" class="follow-settings-list"
              (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" *ngFor="let item of fields.activity" cdkDrag>{{item.field.field_name}}
              </div>
            </div>
          </div>

          <div class="follow-settings-container">
            <h2>Candidates</h2>
            <div cdkDropList [cdkDropListData]="fields.candidate" class="follow-settings-list"
              (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" *ngFor="let item of fields.candidate" cdkDrag>{{item.field.field_name}}
              </div>
            </div>
          </div>

          <div class="follow-settings-container">
            <h2>Joborders status</h2>
            <div cdkDropList [cdkDropListData]="fields.candidateJoborderStatusHistory" class="follow-settings-list"
              (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" *ngFor="let item of fields.candidateJoborderStatusHistory" cdkDrag>
                {{item.field.field_name}}</div>
            </div>
          </div>

          <div class="follow-settings-container">
            <h2>Joborders</h2>
            <div cdkDropList [cdkDropListData]="fields.joborder" class="follow-settings-list"
              (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" *ngFor="let item of fields.joborder" cdkDrag>{{item.field.field_name}}
              </div>
            </div>
          </div>

          <!-- <div class="follow-settings-container">
            <h2>Companies</h2>
            <div cdkDropList [cdkDropListData]="fields" class="follow-settings-list" (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" *ngFor="let item of fields.activity" cdkDrag>{{item}}</div>
            </div>
          </div> -->

          <!-- <div class="follow-settings-container">
            <h2>Contacts</h2>
            <div cdkDropList class="follow-settings-list" (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" cdkDrag></div>
            </div>
          </div> -->

          <div class="follow-settings-container">
            <h2>Boolean operators</h2>
            <div cdkDropList [cdkDropListData]="booleanOperators" class="follow-settings-list"
              (cdkDropListDropped)="drop($event)">
              <div class="follow-settings-box" cdkDrag *ngFor="let item of booleanOperators">{{item.boolean_operator}}
              </div>
            </div>
          </div>

        </div>
        <div class="follow-settings-container follow-settings-query-container">
          <h2>Items for request</h2>

          <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="dateForRequest"
            class="follow-settings-list follow-settings-query-list" (cdkDropListDropped)="dropFromQuery($event)">
            <div class="follow-settings-box follow-settings-query-box" *ngFor="let item of dateForRequest" cdkDrag>
              <div *ngIf="item.boolean_operator">{{item.boolean_operator}} </div>
              <div *ngIf="item.field">
                <div> {{item.field.field_name}} </div>
                <mat-form-field appearance="outline" style="display: block;">
                  <mat-select [(ngModel)]="item.field.compare_sign" placeholder="Operator">
                    <mat-option [(value)]="option.operator" *ngFor="let option of operators">{{option.operator}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <input matInput placeholder="Enter Value" [(ngModel)]="item.field.value">
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="follow-settings-container follow-settings-query-container">
            <h2>Items for table follow-up</h2>

            <div cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="dateForTable"
              class="follow-settings-list follow-settings-query-list "
              (cdkDropListDropped)="dropFromTableFollowUp($event)">
              <div class="follow-settings-box follow-settings-query-box " *ngFor="let item of dateForTable" cdkDrag>
                <div *ngIf="item.field">
                  <div> {{item.field.field_name}} </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button mat-button style="color: white; background: forestgreen;" (click)="saveRequest()">Save</button>

    </mat-card-content>
  </mat-card>
</app-navigation>