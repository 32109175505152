"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchItemsSharingService = void 0;
const search_item_sharing_type_1 = require("../types/search-item-sharing-type");
const search_item_sharing_history_type_1 = require("../types/search-item-sharing-history-type");
class SearchItemsSharingService {
    constructor() { }
    mapReverseSearchItemsSharing(src) {
        const result = {};
        if (src.candidateId)
            result.candidate_id = src.candidateId;
        return result;
    }
    mapSearchItemsSharings(raw) {
        return raw.map((val) => this.mapSearchItemsSharing(val));
    }
    mapSearchItemsSharing(raw) {
        const result = new search_item_sharing_type_1.SearchItemSharing();
        result.candidateId = raw.candidate_id;
        result.candidateOwner = raw.candidate_owner;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.enteredBy = raw.entered_by;
        result.id = raw.id;
        result.owner = raw.owner;
        result.status = raw.status;
        result.candidateOwnerFirstName = raw.candidate_owner_first_name;
        result.candidateOwnerLastName = raw.candidate_owner_last_name;
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        result.requestedByFirstName = raw.request_by_first_name;
        result.requestedByLastName = raw.request_by_last_name;
        result.statusDescription = raw.status_description;
        return result;
    }
    // Sharing history methods
    mapSearchItemSharingHistories(raw) {
        return raw.map((val) => this.mapSearchItemSharingHistory(val));
    }
    mapSearchItemSharingHistory(raw) {
        const result = new search_item_sharing_history_type_1.SearchItemSharingHistory();
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        result.dataItemId = raw.data_item_id;
        result.enteredByFullName = raw.entered_by_full_name;
        result.historyId = raw.history_id;
        result.newOwnerFullName = raw.new_owner_full_name;
        result.prevOwnerFullName = raw.prev_owner_full_name;
        result.setDate = raw.set_date;
        return result;
    }
}
exports.SearchItemsSharingService = SearchItemsSharingService;
