import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Condition } from 'core/dist/types/conditions-type';
import { WorkItemAcceptConditionDialogComponent } from 'src/app/app-dialogs/work-item-accept-condition-dialog/work-item-accept-condition-dialog.component';
import { WorkItemDeclineConditionDialogComponent } from 'src/app/app-dialogs/work-item-decline-condition-dialog/work-item-decline-condition-dialog.component';
import { WorkItemType, YhCore } from '../../../app-services/core.service';

@Component({
  selector: 'app-condition-tab',
  templateUrl: './condition-tab.component.html',
  styleUrls: ['./condition-tab.component.scss']
})
export class ConditionTabComponent implements OnInit {

  public conditions: Condition[];
  public acceptedCondition: Condition;
  public isLoading: boolean = true;
  public ifNotAcceptStatus: boolean;
  public selection = new SelectionModel<Condition>(false, []);

  @Input() set workItem( data: WorkItemType) {
    if(data){
      if(data.joborderId) {
        this.getCondition(data.joborderId)
      }
    }
  };

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
  }

  getCondition(id: string): Promise<any> {
    return new Promise( (resolve, reject) => {
      YhCore.workItems.conditionsList(id, (res:Condition[]) => {
        res = res.filter(condition => +condition.submit === 1);
        this.conditions = res;
        this.acceptedCondition = res[0]
        const condition = res.find(val => parseInt(val.status, 10) === 0);
        this.ifNotAcceptStatus = condition ? true: false;
        this.isLoading = false;
      },err => {})
    })
  }

  previewCondition(condition: boolean) {
    if (condition) {
      const dialogRef = this.dialog.open(WorkItemAcceptConditionDialogComponent, {width: '600px', data: {preview: true, condition}});
    }
  }

  acceptCondition() {
    const dialogRef = this.dialog.open(WorkItemAcceptConditionDialogComponent, {width: '600px', data: {preview: false, condition: this.selection.selected[0]}});

    dialogRef.afterClosed().subscribe( (condition:Condition) => {
      if (condition) {
        this.isLoading = true;
        YhCore.workItems.conditionsAccept(condition.id, res => {
          this.acceptedCondition = condition;
          this.ifNotAcceptStatus = false;
          this.isLoading = false;
        }, err => {
          this.snackBar.open(err, 'ERROR', {duration: 5000})
          this.isLoading = false;
        })
      }
    })
  }

  declineAll() {
    const dialogRef = this.dialog.open(WorkItemDeclineConditionDialogComponent, {width: '600px'})

    dialogRef.afterClosed().subscribe( (comment:string) => {
      if (comment) {
        const conditionsIds = this.conditions.map(val => val.id)
        YhCore.workItems.conditionsDeclineAll(conditionsIds, comment, res => {
          this.conditions = [];
          this.acceptedCondition = null;
          this.ifNotAcceptStatus = false;
          this.snackBar.open('All condition was declined', 'OK', {duration: 3000})
        }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
      }
    })
  }

}
