"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FollowUpService = void 0;
const follow_up_type_1 = require("./../types/follow-up-type");
class FollowUpService {
    constructor() { }
    mapReverseFollowUpItem(src) {
        const result = {};
        if (src.title)
            result.title = src.title;
        if (src.query)
            result.query = src.query;
        if (src.select)
            result.select = src.select;
        return result;
    }
    mapFollowUpDataItems(raw) {
        return raw.map((val) => this.mapFollowUpDataItem(val));
    }
    mapFollowUpDataItem(src) {
        const result = {};
        if (src.company_id)
            result.companyId = src.company_id;
        if (src.title)
            result.title = src.title;
        if (src.joborder_id)
            result.joborderId = src.joborder_id;
        if (src.data_item_id)
            result.dataItemId = src.data_item_id;
        if (src.data_item_type)
            result.dataItemType = src.data_item_type;
        if (src.date_created)
            result.dateCreated = src.date_created;
        if (src.date_modified)
            result.dateModified = src.date_modified;
        if (src.last_name)
            result.lastName = src.last_name;
        if (src.first_name)
            result.firstName = src.first_name;
        if (src.is_active)
            result.isActive = src.is_active;
        if (src.owner)
            result.owner = src.owner;
        if (src.date)
            result.date = src.date;
        if (src.status_to)
            result.statusTo = src.status_to;
        if (src.status_from)
            result.statusFrom = src.status_from;
        if (src.recruiter)
            result.recruiter = src.recruiter;
        if (src.candidate_id)
            result.candidateId = src.candidate_id;
        if (src.description)
            result.description = src.description;
        if (src.start_date)
            result.startDate = src.start_date;
        return result;
    }
    mapFollowUpItems(raw) {
        return raw.map((val) => this.mapFollowUpItem(val));
    }
    mapFollowUpItem(raw) {
        const result = new follow_up_type_1.FollowUp();
        result.title = raw.title;
        result.id = raw.id;
        result.query = raw.query;
        result.select = raw.select;
        result.active = raw.active;
        return result;
    }
    mapFollowUpGetFieldsItem(raw) {
        const result = new follow_up_type_1.FollowUp();
        result.joborder = raw.joborder;
        result.candidateJoborderStatusHistory = raw.candidate_joborder_status_history;
        result.candidate = raw.candidate;
        result.activity = raw.activity;
        return result;
    }
}
exports.FollowUpService = FollowUpService;
