import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';
import {
  isSameDay,
  isSameMonth
} from 'date-fns';
import { Subject } from 'rxjs';
import { CalendarEventTitleFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { CalendarHandleEventDialogComponent } from '../app-dialogs/calendar-handle-event-dialog/calendar-handle-event-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { YhCore } from './../app-services/core.service';
import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  constructor(@Inject(LOCALE_ID) private locale: string) {
    super();
  }

  month(event: CalendarEvent): string {
    if (event.allDay) {
      return `<span>All day </span>${event.title}`
    }
    else {
      return `${new DatePipe(this.locale).transform(
        event.start,
        'h:mm a',
      )} ${event.title}`;
    }
  }

  week(event: CalendarEvent): string {
    if (event.allDay) {
      return `<span>All day </span>${event.title}`
    }
    else {
      return `${new DatePipe(this.locale).transform(
        event.start,
        'h:mm a',
      )} ${event.title}`;
    }
  }

  day(event: CalendarEvent): string {
    if (event.allDay) {
      return `<span>All day </span>${event.title}`
    }
    else {
      console.log(new DatePipe(this.locale).transform(event.start))
      return `${new DatePipe(this.locale).transform(
        event.start,
        'h:mm a',
      )} ${event.title}`;
    }
  }
}
@Component({
  selector: 'app-calendar-page',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar-page.component.html',
  styleUrls: ['./calendar-page.component.scss'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ],
})
export class CalendarPageComponent implements OnInit {
  view: CalendarView = CalendarView.Month;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  events: any[] = [];
  activeDayIsOpen: boolean = false;
  eventCalendars = [];
  CalendarView = CalendarView;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.getCalendarEvents();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) || events.length === 0) {
        this.activeDayIsOpen = false;
      }
      else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.events = this.events.filter((event) => event !== eventToDelete);
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  getCalendarEvents(): void {
    YhCore.calendar.list(success => {
      this.events = success;
      this.convertDateForStart();
      this.refresh.next();
    }, err => { });
  }

  convertDateForStart() {
    this.events.map(el => {
      let arr = el.date.replace(/[ :]+/g, '-')
      arr = arr.split('-');
      arr[1] -= 1;
      Object.assign(el, { start: new Date(arr[0], arr[1], arr[2], arr[3], arr[4], 0), end: new Date(arr[0], arr[1], arr[2], arr[3] , +arr[4]+ +el.duration, 0)  })
      if (el.allDay === '0') {
        el.allDay = false;
      }
      else {
        el.allDay = true
      }
    })
    this.events.sort(
       (a, b) =>{
        if (a.start > b.start) {
          return 1;
        }
        if (a.start < b.start) {
          return -1;
        }
        return 0;
      }
    )
  }

  onAddEvent(): void {
    const dialogRef = this.dialog.open(CalendarHandleEventDialogComponent,
      {
        width: '600px',
        data: { isEditMode: false, calendarEvent: false }
      });
    dialogRef.afterClosed().subscribe(res => {
      this.getCalendarEvents();
    });
  }

  handleEvent(action: string, event: any): void {
    const dialogRef = this.dialog.open(CalendarHandleEventDialogComponent,
      {
        width: '600px',
        data: {
          isEditMode: true,
          calendarEvent: this.events.find(e => e.calendarEventId === event.calendarEventId)
        }
      });
    dialogRef.afterClosed().subscribe(async res => {
      await this.getCalendarEvents();
      if (res) {
        this.activeDayIsOpen = false;
      }
    });
  }

}