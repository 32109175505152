import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserType, YhCore } from '../app-services/core.service'

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SettingsPageComponent implements OnInit {

  public currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'))
  public userAccessLevel: number;

  constructor() { }

  ngOnInit() {
    this.userAccessLevel = +this.currentUser.accessLevel;
  }
}
