import { Component, AfterViewInit } from '@angular/core';
import { CreateTemplateDialogComponent } from '../app-dialogs/create-template-dialog/create-template-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { YhCore, TemplateType } from '../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-template-settings-page',
  templateUrl: './template-settings-page.component.html',
  styleUrls: ['./template-settings-page.component.scss']
})
export class TemplateSettingsPageComponent implements AfterViewInit {

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) { }

  templateDialogs: TemplateType[];

  async ngAfterViewInit() {
    this.templateDialogs = await this.getTemplateDialogs();
  }

  getTemplateDialogs() {
    return new Promise<TemplateType[]>((resolve) => {
      YhCore.templates.list(templates => {resolve(templates)}, error => {})
    })
  }

  onRemoveTemplateDialog(templateDialog: TemplateType) {
    YhCore.templates.remove(+templateDialog.id, async () => {
      this.templateDialogs = await this.getTemplateDialogs();
      templateDialog.deleting = false;
      this.snackBar.open('Template was successfully deleted', 'OK', {
        duration: 3000,
      })
    }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000}) })
  }

  openDialogAddNew() {
    const dialogRef = this.dialog.open(
      CreateTemplateDialogComponent,
      {
        width: '90%',
        minWidth: '300px',
        data: {
        }
      });

      dialogRef.afterClosed().subscribe(async () => {
        this.templateDialogs = await this.getTemplateDialogs();
      })
  }

}
