<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
  </mat-form-field>

  <div >
    <div class="table-wrap">
      <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="date">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Date </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.dateCreated}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Type </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.statusShortDescription}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Author </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.enteredByFirstName}} {{row.enteredByLastName}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="regarding">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Regarding </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.JobOrdertitle}} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="notes">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Notes </mat-header-cell>
            <mat-cell *matCellDef="let row" [innerHTML]="setStyles(row.notes ? row.notes.substring(0, 100): '')"></mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" [cdkDetailRow]="row" [cdkDetailRowTpl]="tpl"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
        </div>

        <ng-template #tpl let-element>
          <div class="mat-row detail-row" [@detailExpand] style="overflow: hidden;" (click)="onViewContact($event.target.dataset.contactid)" [innerHTML]="setStyles(element.notes)"></div>
        </ng-template>
      </div>
    </div>
</div>
