<app-navigation>
  <mat-card class="main-content" *ngIf="groupUser;else spinner">
    <mat-card-title style="margin-bottom: 20px;" class="flex flex-align-center">
      Departmnets
      <button style="margin-left: 5px;" mat-icon-button color="primary" (click)="addNewGroup()" *ngIf="+currentUser.accessLevel >= 400"><mat-icon>add</mat-icon></button>
    </mat-card-title>
    <mat-divider></mat-divider>

    <mat-card-content>
      <app-departments-table [loading]="loading" [data]="groupUser" (rowEvent)="navigateToDepartment($event)"></app-departments-table>
    </mat-card-content>
  </mat-card>
</app-navigation>
<ng-template #spinner>
  <mat-card id="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
</ng-template>