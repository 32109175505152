"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebCvSettingController = void 0;
class WebCvSettingController {
    constructor(api, cvSettingsFieldService) {
        this.api = api;
        this.cvSettingsFieldService = cvSettingsFieldService;
    }
    read(successCallback, errorCallback) {
        this.api.postRequest('cv-settings-fields/read', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.cvSettingsFieldService.mapCvSettingsField(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    edit(cvSettingsField, successCallback, errorCallback) {
        const src = this.cvSettingsFieldService.mapReversCvSettingsField(cvSettingsField);
        this.api.postRequest('cv-settings-fields/update', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    create(successCallback, errorCallback) {
        this.api.postRequest('cv-settings-fields/create', {}, response => {
            if (response.data.valid === true) {
                successCallback(response.data.data);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
}
exports.WebCvSettingController = WebCvSettingController;
