import { Component, Inject, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YhCore, HashtagType, UserType, SearchItemType} from '../../app-services/core.service';
import { AppService } from '../../app-services/app.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  hashtags: HashtagType[],
  searchitemHashtags: HashtagType[],
  hiddenHastags: string,
  searchItem: SearchItemType
}

@Component({
  selector: 'app-show-all-hashtags-dialog',
  templateUrl: './show-all-hashtags-dialog.component.html',
  styleUrls: ['./show-all-hashtags-dialog.component.scss']
})
export class ShowAllHashtagsDialogComponent implements AfterViewInit, OnInit {

  selectedHashtags: HashtagType[] = [];
  searchItemHashtagsIds: string[] = [];
  showAddNewHashtagForm = false;
  users: UserType[] = [];
  hashtag: HashtagType = new HashtagType();
  currentUser = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));
  tableReady = false;
  @ViewChild('hashtagsTable') hashtagsTable;
  constructor(
    public dialogRef: MatDialogRef<ShowAllHashtagsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public app: AppService,
    public snackBar: MatSnackBar
  ) { }

  ngAfterViewInit() {
  }

  async ngOnInit() {
    this.users = await this.getUsers();

    // Collecting Ids of hashtags related to this search item
    this.getSearchItemHasgtagsIds();

    // Mapping hashtags owner to display in table
    this.data.hashtags = this.mapHastagOwner(this.data.hashtags);

    // Filtering hashtags that don't have relations with this search item
    this.filterHashtags();
    this.tableReady = true;

  }

  getSearchItemHasgtagsIds() {
    this.searchItemHashtagsIds = [];
    this.data.searchitemHashtags.forEach((hashtag: HashtagType) => {
      this.searchItemHashtagsIds = [...this.searchItemHashtagsIds, hashtag.savedListId]
    })
  }

  filterHashtags() {
    this.data.hashtags = this.data.hashtags.filter((hashtag: HashtagType)  => {
      return this.searchItemHashtagsIds.indexOf(hashtag.savedListId) >= 0 ? false : true;
    })
  }

  closeDialog() {
    this.dialogRef.close(this.selectedHashtags)
  }

  onAddNewList() {
    this.showAddNewHashtagForm = !this.showAddNewHashtagForm;
  }

  addToList($event) {
    this.selectedHashtags = $event
  }

  createNewList() {
    if (!this.hashtag.description) {
      this.snackBar.open('Description is a required field', 'OK', {
        duration: 3000,
      });
      return;
    }
    this.hashtag.dataItemType = '100';
    this.hashtag.selected = '1';
    this.hashtag.owner = `${this.currentUser.firstName} ${this.currentUser.lastName}`
    // Creating New Hastag
    YhCore.hashtags.create(this.hashtag, (createdId) => {
      this.hashtag.savedListId = createdId;
      this.data.hashtags.unshift(this.hashtag);
      // Filtering Hastags that don't have a relation with this search item yet
      this.filterHashtags();
      // Applying updated table source
      this.hashtagsTable.applyDataSource(this.data.hashtags);
      this.hashtag = new HashtagType();
      this.showAddNewHashtagForm = false;
    }, err => {})
  }

  deleteRelation(item: HashtagType) {
    if (this.currentUser.accessLevel > 300 || this.currentUser.userId ===this.data.searchItem.owner) {
      item.description = '';
      item.loading = true;
      YhCore.hashtags.deleteRelation(+item.savedListEntryId, async () => {
        const searchitemHashtags = await this.getSearchItemHashtags();
        this.data.hashtags = await this.getHashtags();
        this.data.searchitemHashtags =  this.mapHashTags(searchitemHashtags, this.data.hashtags);
        this.getSearchItemHasgtagsIds();
        // Filtering Hastags that don't have a relation with this search item yet
        this.filterHashtags();
      }, err => {})
    }

    else
        this.snackBar.open(`Only ${this.app.type.names.searchItem} owner can perform this action`, 'OK', {duration: 3000});
  }

  getSearchItemHashtags(): Promise<HashtagType[]> {
    return new Promise<HashtagType[]> (resolve => {
      YhCore.hashtags.searchItemHashtags(+this.data.searchitemHashtags[0].dataItemId, searchItemHastags => {
        this.data.hiddenHastags = searchItemHastags.privateListCount;
        resolve(searchItemHastags.hashtags);
      }, err => {})
    })
  }

  getHashtags(): Promise<HashtagType[]> {
    return new Promise<HashtagType[]> (resolve => {
      YhCore.hashtags.list(hashtags => {
        hashtags = this.mapHastagOwner(hashtags);
        resolve(hashtags);
      }, err => {})
    })
  }

  getUsers(): Promise<UserType[]> {
    return new Promise<UserType[]> (resolve => {
      YhCore.users.list(users => {
        resolve(users);
      }, err => {})
    })
  }

  mapHashTags(searchitemHashtags, hashtags) {
    return searchitemHashtags = searchitemHashtags.filter(sihashtag => {
      hashtags.map(hashtag => {
        if(hashtag.savedListId === sihashtag.savedListId) {
          sihashtag.description = hashtag.description;
        }
      })
      // filtering off all private hashtags relations
      if (sihashtag.description !== undefined) {
        return true;
      } else {
        return false
      }
    })
  }

  mapHastagOwner(hashtags: HashtagType[]) {
    this.users.map(user => {
         hashtags.map(hashtag => {
          if (user.userId === hashtag.createdBy) {
            hashtag.owner = `${user.firstName} ${user.lastName}`
          }
        })
      })
      return hashtags;
  }


}
