<app-navigation>
  <div class="main-container-no-scroll">
    <mat-tab-group class="tab-group">
      <mat-tab label="Table View" class="tab-group__tab">
        <mat-card class="main-content">
          <mat-card-content>
            <div class="width-100 flex flex-between flex-wrap flex-align-center" [style.margin-bottom]="isFiltersColumns ? '': '20px'">
              <div class="width-xs-100 width-md-50 button-group"> 
                  <!-- <button mat-flat-button color="primary" (click)="onCreateLead()">ADD {{ app.type.names['searchItem'].toUpperCase() }}</button> -->
                  <button mat-flat-button color="primary" (click)="toggleFilterColumns()">COLUMNS</button>
                  <!-- <button mat-flat-button color="warn" (click)="onFreeCandidates()" [style.background]="isFreeCandidates ? 'forestgreen' : 'orangered'">FREE {{ app.type.names['searchItems'].toUpperCase() }}</button> -->
                  <!-- <button mat-flat-button color="warn" (click)="onMyCandidates()" [style.background]="isMyCandidates ? 'forestgreen' : 'orangered'">MY {{ app.type.names['searchItems'].toUpperCase() }}</button> -->
              </div>
              <div class="width-xs-100 width-md-50 flex flex-align-end flex-end flex-column" *ngIf="false">
                <div class="total-items">Total candidates: {{totalSearchItems}}</div>
                <div class="flex flex-align-center button-group group-right">
                  <button mat-flat-button color="primary" (click)="entriesCount = entriesCount - entriesStep; onEntriesChange()" [disabled]="entriesCount < entriesStep">PREV</button>
                    <p class="padding-x-05">ENTRIES FROM {{entriesCount}} TO {{entriesCount + entriesStep}}</p>
                  <button mat-flat-button color="primary" (click)="entriesCount = entriesCount + entriesStep; onEntriesChange()" 
                        [disabled]="(entriesCount + entriesStep) >= totalSearchItems">
                        NEXT
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="isFiltersColumns" class="filter-columns" style="margin-bottom: 20px;">
              <mat-button-toggle-group [multiple]="true" [(ngModel)]="columnFilters" (ngModelChange) = "onColumnsChange()" class="chose-columns-toggle-group flex flex-wrap padding-t-1">
                <ng-container *ngFor="let column of columnFiltersAll">
                  <mat-button-toggle [value]="column">
                    <span style="text-transform: uppercase;">{{column}}</span>
                  </mat-button-toggle>
                </ng-container>
              </mat-button-toggle-group>
            </div>

            <mat-divider></mat-divider>

            <div style="padding-top: 15px;">
              <app-search-items-table #candidatesTableComponent [isLoading]="isLoadingCandidates" [data]="candidates" (onCandidateSelect)="navigateToCandidate($event)" [columns]="columnFilters"></app-search-items-table>
            </div>

          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Card View" class="tab-group__tab">
        <mat-card class="main-content">
          <mat-card-content>
            <app-search-items-card-view [companyId]="companyId"></app-search-items-card-view>
          </mat-card-content>
        </mat-card>
      </mat-tab>
    </mat-tab-group>
 </div>
</app-navigation>
