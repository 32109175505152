"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplatesService = void 0;
const template_type_1 = require("../types/template-type");
class TemplatesService {
    constructor() {
    }
    mapReverseTemplate(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        if (src.owner)
            result.owner = src.owner;
        if (src.title)
            result.title = src.title;
        if (src.subject)
            result.subject = src.subject;
        if (src.body)
            result.body = src.body;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        return result;
    }
    mapTemplates(raw) {
        return raw.map((val) => this.mapTemplate(val));
    }
    mapTemplate(raw) {
        const result = new template_type_1.Template();
        result.id = raw.id;
        result.owner = raw.owner;
        result.title = raw.title;
        result.subject = raw.subject;
        result.body = raw.body;
        result.dateCreated = raw.date_created;
        return result;
    }
}
exports.TemplatesService = TemplatesService;
