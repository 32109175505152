import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GroupUserItemType } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-group-user-table',
  templateUrl: './group-user-table.component.html',
  styleUrls: ['./group-user-table.component.scss']
})
export class GroupUserTableComponent implements OnInit {

  public dataSource:MatTableDataSource<any> = new MatTableDataSource();
  public displayedColumns = ['firstName', 'lastName', 'isAdmin'];
  private paginator: MatPaginator;
  private sort: MatSort;
  private sortState: Sort = {active: 'dateCreated', direction: 'desc'}

  @Input() public loading:boolean = false

  @Input() public set data(data: any[]) {
    if(data && data.length) {
      this.applyDataSource(data);
    }
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  constructor() { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  applyDataSourceAttributes() {
    if (this.paginator && this.sort) {
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit(this.sortState);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyDataSourceAttributes()
  }

}
