<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle>Matrix User</mat-card-subtitle>
      <!-- Fixing min height issue -->
      <div class="empty-space"></div>
    </mat-card>
    <mat-card class="main-content">
        <mat-card-content>
            <div class='matrix-user-description'>
                <div class="square grey">
                </div>
                <p>
                    No access
                </p>
            </div>
            <div class='matrix-user-description'>
                <div class="square yellow">
                </div>
                <p>
                    Only free candidates
                </p>
            </div>
            <div class='matrix-user-description'>
                <div class="square green">
                </div>
                <p>
                    Access to all candidates
                </p>
            </div>

            <mat-form-field appearance="outline" class="selected-form-matrix-user">
                <mat-label>Select user</mat-label>
                <mat-select name="user" [(ngModel)]="selectedUser">
                    <mat-option (click)="defaultSelectedOption()">
                        Select user
                    </mat-option>
                    <mat-option *ngFor="let user of arrUsers" [value]="user.userId"
                        (click)="chooseSelectedId(user.userId)">
                        {{user.firstName + ' ' +  user.lastName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="check-button-wrap">
                <button  mat-button (click)="clickCheckAll()" [class.disabledButton]='disabledButton'>CHECK ALL</button>
            </div>
            <div class="users-button-wrap">
                <button class="bg-selected-grey" *ngFor="let user of arrUsers" [class.access-1]='user.access1'
                    [class.access-2]='user.access2' [class.access-3]='user.access3' (click)=" clickUserButton(user)">
                    {{user.firstName + ' ' +  user.lastName}}
                </button>
            </div>
            <div class="save-button-wrap">
                <button  mat-button (click)="saveMatrixUsers()" [class.disabledButton]='disabledButton'>SAVE</button>
            </div>
        </mat-card-content>
    </mat-card>
  </div>
</app-navigation>