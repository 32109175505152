<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
  </mat-form-field>

  <div>
    <div class="table-wrap">
      <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>
          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Title </mat-header-cell>
            <mat-cell *matCellDef="let row"> #{{row.joborderId}} {{row.title}} </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Company </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.company}} </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="author">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Entered By </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.enteredByFirstName}} {{row.enteredByLastName}}</mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Status </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.statusShortDescription}} </mat-cell>
          </ng-container>
    
          <ng-container matColumnDef="added">
            <mat-header-cell *matHeaderCellDef mat-sort-header> Added </mat-header-cell>
            <mat-cell *matCellDef="let row"> {{row.dateCreated}} </mat-cell>
          </ng-container>
    
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;" [style.backgroundColor]="row.statusColor"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>
    
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
      </div>
    </div>
  </div>
</div>
