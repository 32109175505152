export class ExtraField {
  dataItemType: string;
  dateCreated: string;
  extraFieldOptions: string[];
  extraFieldSettingsId: string;
  extraFieldType: string;
  extraFieldValue: string;
  fieldName: string;
  importId: string;
  position: string;
  siteId: string;
}
