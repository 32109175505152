"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityService = void 0;
const activity_type_1 = require("../types/activity-type");
const activity_status_type_1 = require("../types/activity-status-type");
class ActivityService {
    constructor() { }
    mapReverseActivity(src) {
        const result = {};
        if (src.activityId)
            result.activity_id = src.activityId;
        if (src.dataItemId)
            result.data_item_id = src.dataItemId;
        if (src.dataItemType)
            result.data_item_type = src.dataItemType;
        if (src.joborderId)
            result.joborder_id = src.joborderId;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.type)
            result.type = src.type;
        if (src.notes)
            result.notes = src.notes;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        return result;
    }
    mapActivities(raw) {
        return raw.map((val) => this.mapActivity(val));
    }
    mapActivity(raw) {
        const result = new activity_type_1.Activity();
        result.activityId = raw.activity_id;
        result.dataItemId = raw.data_item_id;
        result.dataItemType = raw.data_item_type;
        result.joborderId = raw.joborder_id;
        result.siteId = raw.site_id;
        result.enteredBy = raw.entered_by;
        result.dateCreated = raw.date_created;
        result.type = raw.type;
        result.notes = raw.notes;
        result.dateModified = raw.date_modified;
        result.name = raw.first_name + ' ' + raw.last_name;
        return result;
    }
    mapActivityStatuses(raw) {
        return raw.map((val) => this.mapActivityStatus(val));
    }
    mapActivityStatus(raw) {
        const result = new activity_status_type_1.ActivityStatus();
        result.activityTypeId = raw.activity_type_id;
        result.shortDescription = raw.short_description;
        return result;
    }
}
exports.ActivityService = ActivityService;
