"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserInGroup = exports.GroupWithUsers = exports.GroupUsers = void 0;
class GroupUsers {
}
exports.GroupUsers = GroupUsers;
class GroupWithUsers extends GroupUsers {
}
exports.GroupWithUsers = GroupWithUsers;
class UserInGroup {
}
exports.UserInGroup = UserInGroup;
