import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/app-services/app.service';
import { SearchItemHistoryType, SearchItemType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss']
})
export class HistoryTabComponent implements OnInit {

  public candidateFields: any[];
  private _candidate: SearchItemType;
  public candidate: SearchItemType;
  private notDisplayFields: string[] = ['eeoDisabilityStatus', 'extraFields', 'eeoGender', 'img', 'importId', 'eeoEthnicTypeId', 'eeoVeteranTypeId', 'imgResize', 'ownerFirstName', 'ownerLastName', ]
  public candidateChangeHistory: SearchItemHistoryType[];

  @Input() public set data(data: SearchItemType) {
    if(data.candidateId) {
      this.getCandidateHistory(data.candidateId);
      this.setCandidateData(data)
    }
  }


  constructor(
    public app: AppService
  ) { }

  ngOnInit(): void {
  }

  get candidateEmail() {
    return this.candidate.email1 || this.candidate.email2;
  }

  setCandidateData(data: SearchItemType) {
    this.candidate = JSON.parse(JSON.stringify(data));
    this._candidate = JSON.parse(JSON.stringify(data));
    this.notDisplayFields.forEach( val => {
      delete this.candidate[val]
    })
    this.candidateFields = Object.entries(this.candidate)
  }

  getCandidateHistory(id: string):Promise<SearchItemHistoryType> {
    return new Promise( (resolve, reject) => {
      YhCore.searchItems.getSearchItemHistory(+id, res => {
        this.candidateChangeHistory = res.filter( val => val['theField'] !== 'ACTIVITY')
        resolve(res);
      }, err => reject(err))
    })
  }

  showHistoryChanged(key, value) {
    key = this.camelCase(key);
    value = value ? value: '-(NEW)-';
    this.candidateFields = Object.entries(this._candidate);
    this.candidateFields = this.candidateFields.map( val => {
      if(val[0].toLowerCase() === key.toLowerCase()){
        val[2] = value
      }
      return val
    });
  }
  camelCase(str:string):string {
    return str.replace(/(_\w)/g, k => k[1].toUpperCase())
  }


}
