import { MatSnackBar } from '@angular/material/snack-bar';
import { YhCore, FollowUpType } from './../app-services/core.service';
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
@Component({
  selector: 'app-follow-up-settings-page',
  templateUrl: './follow-up-settings-page.component.html',
  styleUrls: ['./follow-up-settings-page.component.scss']
})
export class FollowUpSettingsComponent implements OnInit {
  dateForRequest = [] = [];
  dateForTable: any = [];
  select: any = [];
  operators = [
    { operator: '-', value: '-' },
    { operator: '+', value: '+' },
    { operator: '/', value: '/' },
    { operator: '*', value: '*' },
    { operator: '=', value: '=' },
    { operator: '<', value: '<' },
    { operator: '>', value: '>' },
    { operator: '<=', value: '<=' },
    { operator: '>=', value: '>=' },
  ];
  booleanOperators = [
    { boolean_operator: 'AND' },
    { boolean_operator: 'OR' },
  ];
  fields: any = new FollowUpType();
  requestItem: any = new FollowUpType();
  title: string;

  constructor(   
     private snackBar: MatSnackBar,
    ) { };

  ngOnInit(): void {
    this.getFields();
  }

  getFields() {
    YhCore.followUp.getFields(suc => {
      this.fields = suc
      for (const key in this.fields) {
        if (key) {
          const a = [];
          for (const item of this.fields[key]) {
            a.push(Object.assign({}, { field: { field_name: item, compare_sign: '=', value: '' } }))
          }
          this.fields[key] = [...a];
        }
      }
    }, err => { console.log(err) })
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    for (const key in this.fields) {
      if (key) {
        this.fields[key] = this.fields[key].filter((item, index, self) =>
          index === self.findIndex((t) => (
            t.field.field_name === item.field.field_name
          )))
      }
    }
    this.booleanOperators = this.booleanOperators.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.boolean_operator === item.boolean_operator
      )))
  }

  dropFromQuery(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }

  dropFromTableFollowUp(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      copyArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    this.dateForTable = this.dateForTable.filter((item, index, self) =>
      index === self.findIndex((t) => (
        t.field.field_name === item.field.field_name
      )))
  }

  saveRequest() {
    this.dateForTable.map(el => {
      this.select.push(el.field.field_name);
    })

    let selectStr = '';
    selectStr = this.select.join(',');

    this.requestItem = {
      title: this.title,
      select: selectStr,
      query: this.dateForRequest,
    }
    YhCore.followUp.create(this.requestItem, suc => {
        this.dateForRequest = [],
        this.select = [],
        this.dateForTable = [],
        this.title = '',
        this.snackBar.open('Follow up was successfully create', 'OK', {duration: 3000})
    }, err => {
      this.snackBar.open(err, 'OK', {duration: 3000})
     })
  }
}
