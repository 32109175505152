import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { EventService } from '../../app-services/event.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { QuickSearchService } from '../../app-services/quick-search.service';
import { Router } from '@angular/router';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { YhCore, NotificationType, SearchItemType, ContactItemType, UserType } from '../../app-services/core.service';
import { AppService } from '../../app-services/app.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDrawer } from '@angular/material/sidenav';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [
    // Ring animation
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotatedleft', style({ transform: 'rotate(-5deg)', 'transform-origin': 'top center' })),
      state('rotatedright', style({ transform: 'rotate(5deg)', 'transform-origin': 'top center' })),
      transition('rotated => default', animate('5ms ease-out')),
      transition('default => rotated', animate('5ms ease-in'))
    ]),
    // Show nessenger button animation
    trigger('showHideMessengerButton', [
      state('hidden', style({ bottom: '-45px' })),
      state('show', style({ bottom: '15px' })),
      transition('hidden => show', animate('400ms ease-out')),
      transition('show => hidden', animate('50ms ease-in'))
    ]),
    trigger('openCloseSidenav', [
      state('open', style({ width: '200px', })),
      state('closed', style({ width: '80px' })),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
    trigger('openCloseSidenavContent', [
      state('open', style({ 'margin-left': '200px', })),
      state('closed', style({ 'margin-left': '80px', })),
      state('fullClosed', style({ 'margin-left': '0px', })),
      transition('open <=> closed', [animate('0.3s')]),
    ]),
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {

  public displayedColumns = ['dateCreated', 'author', 'status', 'sharedItem']

  currentUser: UserType;
  users: UserType[];
  searchValue = '';
  state = 'default';
  messengerState = 'hidden';
  rotateAminationInterval;
  newNotificationsIds: string[] = [];
  events: NotificationType[] = [];
  ring = false;
  showMessenger = false;
  eventsTimer;
  newEmails: IncomingEmails[] = [];
  contextMenu = false;
  newMessagesCheckTimer;
  isExpanded: boolean = true;
  isHorizontalSideNav: boolean = false;
  selectedFontFamily = 'Roboto'
  fontFamilyStyles = [
    { viewName: 'Roboto', value: 'Roboto' },
    { viewName: 'Arial', value: 'Arial' },
    { viewName: 'Times New Roman', value: 'Roman' },
    { viewName: 'Lucida Console', value: 'Lucida' },
  ];
  selectedFontSize = 'S'
  fontSizeStyle = [
    { viewName: 'S', value: 'S' },
    { viewName: 'M', value: 'M' },
    { viewName: 'L', value: 'L' },
  ]
  // (max-width: 960px)
  isHandset$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 960px)')
    .pipe(map(result => {
      if (result.matches) {
        this.isExpanded = true;
        this.isHorizontalSideNav = false;
        this.selectedFontSize = 'S'
        this.selectedFontFamily = 'Roboto'
      }
      return result.matches
    }));

  isVerySmall: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.XSmall)
    .pipe(map(result => result.matches));

  @ViewChild('drawer') public drawer: MatDrawer;

  constructor(
    private breakpointObserver: BreakpointObserver,
    public evtService: EventService,
    public router: Router,
    private snackBar: MatSnackBar,
    public quickSearchService: QuickSearchService,
    public app: AppService
  ) { }

  async ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('YHminiuser'));
    this.users = await this.getUsersList();
    this.readSideNavState();
    this.readStyleState();

    this.getUserEvents();
    // this.getNewMessages();

    this.eventsTimer = setInterval(() => {
      this.getUserEvents();
    }, 30000);

    // this.newMessagesCheckTimer = setInterval(() => {
    //   this.getNewMessages();
    // }, 30000);
  }

  ngOnDestroy() {
    clearInterval(this.eventsTimer);
    clearInterval(this.newMessagesCheckTimer);
  }

  readSideNavState() {
    this.isHandset$.subscribe(res => {
      if (!res) {
        const isExpendedState = JSON.parse(localStorage.getItem('sideNavIsExpendedState'));
        const isHorizontalState = JSON.parse(localStorage.getItem('sideNavIsHorizontalState'));
        if ((isHorizontalState && isHorizontalState.isHorizontal) && (isExpendedState && !isExpendedState.isExpended)) {
          this.isHorizontalSideNav = isHorizontalState.isHorizontal;
          this.isExpanded = false;
        } else if (isHorizontalState && isHorizontalState.isHorizontal) {
          this.isHorizontalSideNav = isHorizontalState.isHorizontal;
        } else if (isExpendedState && !isExpendedState.isExpended) {
          this.isExpanded = isExpendedState.isExpended;
        }
      }
    });
  }

  readStyleState() {
    this.isHandset$.subscribe(res => {
      if (!res) {
        const state = JSON.parse(localStorage.getItem('styleState'))
        if (state) {
          if (state.fontFamily && state.fontSize) {
            this.selectedFontFamily = state.fontFamily
            this.selectedFontSize = state.fontSize
          }
        }
      }
    })
  }

  toogleDrawer() {
    this.drawer.toggle();
  }

  async getUserEvents(){
    YhCore.notifications.getEventsForUser(async events => {
      this.events = events;
      // Checking if there are any new notifications
      if (events.length > 0) {
        this.mapEvents(events);
        this.events.map(event => {
          if (this.newNotificationsIds.indexOf(event.id) < 0) {
            this.newNotificationsIds.push(event.id);
            this.ring = true;
          } else {
            this.ring = false;
          }
        })
      }

      const isEventsApplied = this.events.filter((item: NotificationType) => item.action.includes('applied')).length
      // Turn on animation in case of new notifications
      if (this.newNotificationsIds.length > 0
          && this.ring === true
          && (+this.currentUser.accessLevel >=400 || (+this.currentUser.accessLevel < 400 && isEventsApplied) )) {
          this.startAnimation();
          this.playAudio();
          setTimeout(()=> {
            this.ring = false;
            this.stopAnimation()
          }, 500)
      }
    }, error => { return error; })
  }

  async mapEvents(events: NotificationType[]) {
    events.map((event: NotificationType) => {
      event['status'] = event.action;
      event['sharedItem'] = `${event.candidateFirstName || ''} ${event.candidateLastName || ''}`
      this.users.map((user: UserType) => {
        if (user.userId === event.enteredBy) {
          event['author'] = `${user.firstName} ${user.lastName}`;
        }
      })
    })
  }

  getUsersList() {
    return new Promise<UserType[]> (resolve => {
      YhCore.users.list((users: UserType[]) => {
        YhCore.localStorageService.setItem('users', JSON.stringify(users))
        resolve(users);
      }, () => {resolve([])})
    })
  }

  getNewMessages() {
    let emails: IncomingEmails[] = [];
    // Fetching new emails
    YhCore.messenger.getNewEmails(incomingEmails => {
      if (incomingEmails.searchItemsEmails) {
        // If there are any emails from search Items we map them
        emails = this.mapNewEmails('searchItem', incomingEmails.searchItemsEmails);
      }
      if (this.app.isSales && incomingEmails.contactItemsEmails) {
        let emailsExtended = emails;
        // If there are any emails from contact  Items we map them
        const emailsFromContacts = this.mapNewEmails('contactItem', incomingEmails.contactItemsEmails);
        emailsFromContacts.map(email => { emailsExtended = [...emailsExtended, email] });
        // Passing new emails into messenger
        this.newEmails = emailsExtended;
      } else {
        // Passing new emails into messenger
        this.newEmails = emails;
      }
    }, error => { })
  }

  clearNewMessage(flag: boolean) {
    if (flag) this.newEmails = []
  }

  mapNewEmails(type: string, newEmails: any) {
    let accumulator: IncomingEmails[] = [];
    for (const key in newEmails) {
      if (newEmails.hasOwnProperty(key)) {
        this.messengerState = 'show';
        newEmails[key].forEach(email => {
          // Saving item id and email. Email is needed to fetch thread.
          const emailToAddToList: IncomingEmails = {
            id: key,
            email: this.parseEmail(email.from),
            subject: email.subject,
            uid: email.uid,
            type
          }
          accumulator = [...accumulator, emailToAddToList]
        });
      }
    }
    return accumulator;
  }

  getContacttems(): Promise<ContactItemType[]> {
    return new Promise<ContactItemType[]>((resolve) => {
      YhCore.contactItems.list(0, 1000, data => { resolve(data.response) }, error => { })
    })
  }

  getSearchtems(): Promise<SearchItemType[]> {
    return new Promise<SearchItemType[]>((resolve) => {
      YhCore.searchItems.list(0, 1000, data => { resolve(data.candidates) }, error => { })
    })
  }

  onQuickSearch() {
    if (this.searchValue.length > 3) {
      this.quickSearchService.quickSearchChange(this.searchValue);
      this.router.navigate(['quick-search']);
    } else {
      this.snackBar.open('Search value length must be longer than 3 symbols', 'ERROR', {
        duration: 3000,
      });
    }
  }

  playAudio() {
    const audio = new Audio();
    audio.src = '../../assets/sounds/that-was-quick.ogg';
    audio.load();
    audio.play();
  }

  startAnimation() {
    this.rotateAminationInterval = setInterval(() => {
      if (this.state === 'default') {
        this.state = 'rotatedleft'
      } else if (this.state === 'rotatedleft') {
        this.state = 'rotatedright'
      } else if (this.state === 'rotatedright') {
        this.state = 'rotatedleft'
      }
    })
  }

  stopAnimation() {
    this.state = 'default';
    clearInterval(this.rotateAminationInterval);
  }

  setViewedNotifications() {
    this.events.map(event => {
      if (this.newNotificationsIds.indexOf(event.id) >= 0) {
        event.isViewed = '1';
        YhCore.notifications.edit(event, success => { }, error => { })
      }
    })

    this.newNotificationsIds = [];
  }

  goToViewAllEventsPage() {
    if (this.router.url.includes('my-events')) {
      location.reload();
    } else {
      this.router.navigate(['my-events'])
    }
  }

  logOut() {
    YhCore.api.invalidate();
    this.router.navigate(['/login']);
  }

  parseEmail(fragment: string) {
    return fragment.substring(fragment.indexOf('<') + 1, fragment.indexOf('>'))
  }

  onRightClick($event) {
    $event.preventDefault();
    this.contextMenu = true;
  }

  onHideMessengerButton() {
    this.contextMenu = false;
    this.messengerState = 'hidden';
  }

  onShowMessenger() {
    if (this.messengerState === 'hidden') {
      this.messengerState = 'show';
      return;
    }
    this.showMessenger = !this.showMessenger;
  }

  saveSideNavState() {
    const state = {
      isExpended: this.isExpanded
    }
    localStorage.setItem('sideNavIsExpendedState', JSON.stringify(state))
  }

  saveHorizontalState() {
    const state = {
      isHorizontal: this.isHorizontalSideNav
    }
    localStorage.setItem('sideNavIsHorizontalState', JSON.stringify(state))
  }

  saveStyleState() {
    const state = {
      fontSize: this.selectedFontSize,
      fontFamily: this.selectedFontFamily
    }
    localStorage.setItem('styleState', JSON.stringify(state))
  }

  isActiveLink(link:string) {
    return this.router.url.substring(1).startsWith(link);
  }

}

export interface IncomingEmails {
  id: string;
  email: string;
  subject: string;
  uid: string;
  type: string;
}