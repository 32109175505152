export class User {
  accessLevel: string;
  address: string;
  canChangePassword: string;
  canSeeEeoInfo: string;
  categories: string;
  city: string;
  columnPreferences: string;
  company: string;
  company_id: string;
  country: string;
  email: string;
  firstName: string;
  forceLogout: string;
  isDemo: string;
  isTestUser: string;
  jobOrderStatusAccess: string;
  key: string;
  lastName: string;
  notes: string;
  password: string;
  phoneCell: string;
  phoneOther: string;
  phoneWork: string;
  pipelineEntriesPerPage: string;
  positionId: string;
  salary: string;
  sessionCookie: string;
  siteId: string;
  state: string;
  title: string;
  userId: string;
  userName: string;
  zipCode: string;
  accessLevelDescription: string;
  emailPassword: string;
}
