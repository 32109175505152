import { Injectable } from '@angular/core';
import { YhApiService } from './yh-api.service';
import { Observable, timer } from 'rxjs';
import { switchMap, filter, map, publishReplay, refCount } from 'rxjs/operators';
import { YhNotification } from './event.type';
import { YhCore } from './/core.service'

@Injectable({
  providedIn: 'root'
})
export class EventService {
  events$: Observable<YhNotification[]>;

  constructor(private api: YhApiService) {
    this.events$ = timer(0, 5000)
      .pipe(
        filter(() => YhCore.api.isValid()),
        switchMap(() => api.request('setting/getEventData',
          {
            flag_only_not_viewed: false,
            flag_set_viewed: false,
            limit: 3
          })
        ),
        map(evt => this.mapEventResponse(evt)),
        publishReplay(1),
        refCount(),
      );
  }

  getAllEvents(body): Observable<YhNotification[]>{
    return this.api.request('setting/getEventData', body).pipe(map(
      (val: any) => {
        return this.mapEventResponse(val);
      }
    ))
  }

  mapEventResponse(raw: any): YhNotification[] {
    return raw.listEvents.map(evt => this.mapEvent(evt));
  }

  mapEvent(raw: any): YhNotification {
    const result = new YhNotification();
    result.id = raw.id;
    result.action = raw.action;
    result.itemId = raw.data_item_id;
    result.itemType = raw.data_item_type;
    result.enteredBy = raw.entered_by;
    result.firstName = raw.candidate_first_name;
    result.lastName = raw.candidate_last_name;
    result.date = raw.date_created;
    result.forAccessLevel = raw.is_for_access_level;
    result.forUser = raw.is_for_user;
    result.viewed = raw.is_viewed;
    result.description = raw.description;
    return result;
  }

}
