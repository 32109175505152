import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar} from '@angular/material/snack-bar';
import { YhCore, SearchItemType, UserType, ExtraFiledType} from '../app-services/core.service'
import { AppService } from '../app-services/app.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-search-item-page',
  templateUrl: './search-item-page.component.html',
  styleUrls: ['./search-item-page.component.scss']
})

export class SearchItemPageComponent implements OnInit {

  public fieldsArray: [][] = [[],[]];
  public candidate: SearchItemType = new SearchItemType();
  public users: UserType[] = [];
  public editMode = false;
  public searchItemImgURL: string;
  public qrData: string = '';
  private token: string = YhCore.localStorageService.getItem('YHminiToken');
  private refToken: string = YhCore.localStorageService.getItem('YHminiRefreshToken');

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    public app: AppService,
    private dialog: MatDialog
  ) {
  }

  async ngOnInit() {
    this.route.paramMap.subscribe( async param => {
      const id = param.get("id");

      if(parseInt(id, 10)) {
        this.getData(id);
      } else {
        this.snackBar.open('Invalid candidate id', 'OK', {duration: 3000});
        setTimeout( () => this.router.navigateByUrl('/'), 3000)
      }

      this.qrData = JSON.stringify({token: this.token,refToken: this.refToken,candidateId: id})
    })
  }

  async getData(id: string) {
    let searchItem = await this.getSearchItem(id);
    const users = await this.getUsers();
    searchItem = this.mapSearchItemEnteredByName(searchItem,users);
    searchItem = this.mapSearchItemOwnerName(searchItem,users);
    searchItem.extraFields = this.mapExtraFields(searchItem.extraFields);

    this.searchItemImgURL = searchItem.img === '' ? 'assets/img/avatar-logo.jpg': searchItem.img;
    this.fieldsArray = this.chunkArray(Object.entries(searchItem).filter(val => !["extraFields", "candidateId", "img", "importId", "isHot", "eeoEthnicTypeId", "eeoVeteranTypeId", "eeoDisabilityStatus", "eeoGender", "siteId", "dateAvailable", "zip", "currentEmployer", "currentPay", "enteredBy", "owner"].includes(val[0])), 2)
    this.candidate = searchItem;
    this.users = users;
  }
  chunkArray(array: any[], size) {
    const newArray = [];
    for (let i = 0; i < size; i++){
      newArray.push(array.splice(0, Math.ceil(array.length/size)))
    }
    return newArray;
  }

  getSearchItem(id:string):Promise<SearchItemType> {
    return new Promise( (resolve, reject) => {
      YhCore.searchItems.read(id, searchItem => {
        resolve(searchItem);
      }, err => {
        this.errorHandling(err);
        /not found/gmi.test(err) ? this.router.navigateByUrl('/'): ''
      })
    })
  }

  updateSearchItem(searchItem: SearchItemType):Promise<any>{
    return new Promise( (resolve, reject) => {
      YhCore.searchItems.edit(searchItem, res => {
        resolve(res);
      }, err => this.errorHandling(err))
    })
  }

  deleteCandidate(id:string):Promise<any>{
    return new Promise( (resolve, reject) => {
      YhCore.searchItems.remove(id, res => {
        resolve(res)
      }, err => this.errorHandling(err))
    })
  }

  shareCandidateFull(id: number):Promise<any>{
    return new Promise( (resolve, reject) => {
      YhCore.searchItemSharing.sendRequestForShareFull('', id, res => {
        resolve(res)
      }, err => this.errorHandling(err))
    })
  }

  getUsers(): Promise<UserType[]>{
    return new Promise( (resolve, reject) => {
      YhCore.users.list( (users:UserType[]) => {
        resolve(users)
      }, err => this.errorHandling(err))
    })
  }

  mapSearchItemOwnerName(searchItem:SearchItemType, users:UserType[]) {
    const owner = users.find(user => +user.userId === +searchItem.owner);
    owner ? searchItem.ownerName = owner.firstName + ' ' + owner.lastName: ''
    return searchItem
  }

  mapSearchItemEnteredByName(searchItem:SearchItemType, users:UserType[]) {
    const enteredBy = users.find(user => +user.userId === +searchItem.enteredBy);
    enteredBy ? searchItem['enteredByName'] = enteredBy.firstName + ' ' + enteredBy.lastName: ''
    return searchItem
  }

  beginEdit(): void {
    this.editMode = true;
  }

  async saveEdit() {
    this.editMode = false;
    const res = await this.updateSearchItem(this.candidate);

    if(res) {
      this.snackBar.open(`${this.app.type.names['searchItem']} information was successfully updated`, 'OK', {duration: 3000});
    }
  }

  mapExtraFields(extraFields: ExtraFiledType[] = []): ExtraFiledType[] {
    extraFields.map((field: ExtraFiledType) => {
      if (field.extraFieldType === '2' && field.extraFieldSettingsId === '20') {
        field.extraFieldValue = JSON.parse(field.extraFieldValue);
      }
      if (field.extraFieldOptions) {
        field.extraFieldOptionsArray = field.extraFieldOptions.split(',');
        field.extraFieldOptionsArray = field.extraFieldOptionsArray.filter(item => (item && item.length > 0));
      }
      return field;
    })
    return extraFields;
  }

  async onDeleteCandidate() {
    const res = await this.deleteCandidate(this.candidate.candidateId);

    if(res){
      this.snackBar.open(this.app.type.names['searchItem'] + ' was successfully delete ', 'OK', {duration: 3000});
      this.router.navigate([this.app.type.names['searchItems'].toLowerCase()]);
    }
  }

  async onShareCandidateFull() {
    const res = await this.shareCandidateFull(+this.candidate.candidateId);

    if(res){
      this.snackBar.open('We send request for share full candidate', 'OK', {duration: 3000});
    }
  }

  errorHandling(err) {
    this.snackBar.open(err, 'ERROR', {duration: 5000})
  }

  showPreviewCode(template: TemplateRef<any>) {
    this.dialog.open(template, {width:'auto'})
  }

}
