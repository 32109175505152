import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {map, publishLast, refCount} from 'rxjs/operators';
import { YhApiService } from './yh-api.service';
import { Company } from './company.type';
import {CompanyDetail, CompanyJobOrder} from "./company-detail.type";

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  private companies: Company[];
  constructor(private api: YhApiService) { }

  createCompany(company: Company): Observable<number>{
    return this.api.request('company/add',
      this.reverseMapCompany(company))
      .pipe(map((val: any) => {
        return val.company_id;
      }));
  }

  getCompanies(): Observable<Company[]> {
    if (this.companies != null) {
      return of(this.companies);
    }
    return this.api.request('company/list',
      { isActive: 1, from: 0, limit: 1000 })
      .pipe(map((val: any) => {
        const c = this.mapCompanies(val.companies);
        this.companies = c;
        return c;
      }));
  }

  getCompany(id: string): [Observable<Company>, Observable<CompanyDetail>] {
    const request = this.api.request<any>('company/show', { company_id: id }).pipe(publishLast(), refCount());
    const detail = request.pipe(map(val => this.mapCompanyDetail(val)));

    if (this.companies != null) {
      const companies = this.companies.find(val => val.id === id);
      if (companies != null) {
        return [of(companies), detail];
      }
    }

    return [request.pipe(map(val => this.mapCompany(val.companyData))), detail];
  }

  //TODO: Need to reverse map all fields
  reverseMapCompany(src: Company): any {
    const result: any = {};

    result.name = src.name;
    result.phone1 = src.phone1;
    result.phone2 = src.phone2;
    result.fax_number = src.faxNumber;
    result.address = src.address;
    result.zip = src.zip;
    result.city = src.city
    result.state = src.state
    result.is_hot = src.isHot
    result.notes = src.notes

    return result;
  }

  mapCompanyDetail(raw: any): CompanyDetail {
    const result = new CompanyDetail();

    result.companyJobOrders = raw.companyJobOrders.map(j => this.mapCompanyJobOrder(j));

    return  result;
  }

  mapCompanies(raw: any[]): Company[] {
    return raw.map((val: any) => this.mapCompany(val));
  }

  mapCompany(raw: any): Company {
    const result: Company = new Company();

    result.id = raw.company.company_id;
    result.siteId = raw.company.site_id;
    result.billingContact = raw.company.billing_contact;
    result.name = raw.company.name;
    result.address = raw.company.address;
    result.companyLogo = raw.company.company_logo;
    result.city = raw.company.city;
    result.state = raw.company.state;
    result.zip = raw.company.zip;
    result.phone1 = raw.company.phone1;
    result.phone2 = raw.company.phone2;
    result.url = raw.company.url;
    result.keyTechnologies = raw.company.key_technologies;
    result.notes = raw.company.notes;
    result.enteredBy = raw.company.entered_by;
    result.owner = raw.company.owner;
    result.dateCreated = raw.company.date_created;
    result.dateModified = raw.company.date_modified;
    result.isHot = raw.company.is_hot;
    result.faxNumber = raw.company.fax_number;
    result.importId = raw.company.import_id;
    result.defaultCompany = raw.company.default_company;
    result.countJobOrder = raw.count_joborder;
    result.enteredByUserName = raw.entered_by_user_name;
    result.ownerUserName = raw.owner_user_name;
    return result;
  }

  mapCompanyJobOrder(raw: any): CompanyJobOrder {
    const result: CompanyJobOrder = new CompanyJobOrder();

    result.alias = raw.joborder.alias;
    result.city = raw.joborder.city;
    result.cityEn = raw.joborder.city_en;
    result.cityRu = raw.joborder.city_ru;
    result.cityUa = raw.joborder.city_ua;
    result.clientJobId = raw.joborder.client_job_id;
    result.companyDepartmentId = raw.joborder.company_department_id
    result.companyId = raw.joborder.company_id;
    result.contactId = raw.joborder.contact_id;
    result.dateCreated = raw.joborder.date_created;
    result.dateModified = raw.joborder.date_modified;
    result.description = raw.joborder.description;
    result.duration = raw.joborder.duration;
    result.enteredBy = raw.joborder.entered_by;
    result.isAdminHidden = raw.is_admin_hidden;
    result.isHot = raw.joborder.is_hot;
    result.id = raw.joborder.joborder_id;
    result.notes = raw.joborder.notes;
    result.openings = raw.joborder.openings;
    result.openingsAvailable = raw.joborder.openings_available;
    result.owner = raw.joborder.owner;
    result.public = raw.joborder.public;
    result.questionnaireId = raw.joborder.questionnaire_id;
    result.rateMax = raw.joborder.rate_max;
    result.recruiter = raw.joborder.recruiter;
    result.salary = raw.joborder.salary;
    result.seoDescriptionEn = raw.joborder.seo_description_en;
    result.seoKeywordsEn = raw.joborder.seo_keywords_en;
    result.seoTitleEn = raw.joborder.seo_title_en;
    result.siteId = raw.joborder.site_id;
    result.startDate = raw.joborder.start_date;
    result.state = raw.joborder.state;
    result.status = raw.joborder.status;
    result.title = raw.joborder.title;
    result.type = raw.joborder.type;
    result.ownerFullName = raw.user_owner_full_name;
    result.userRecruiterFullName = raw.user_recruiter_full_name;
    result.companyName = raw.company_name;

    return result;
  }
}
