<div class="main-table-wrap">
  <mat-form-field>
    <mat-label>Search</mat-label>
    <input (keyup)="applyFilter($event.target['value'])" matInput placeholder="Search">
  </mat-form-field>
    <div class="table-wrap">
      <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell *matCellDef="let hashtag">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(hashtag) : null"
                            [checked]="selection.isSelected(hashtag)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>
          <!-- End of checkbox Column -->
          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.hashtagNames['description'] }}</mat-header-cell>
            <mat-cell *matCellDef="let hashtag" (click)="goToSearchItemsList(hashtag)">{{hashtag['description']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.hashtagNames['type'] }}</mat-header-cell>
            <mat-cell *matCellDef="let hashtag">{{hashtag['dataItemType'] === '100' ? app.type.names['searchItem'] : ''}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="owner">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.hashtagNames['owner'] }}</mat-header-cell>
            <mat-cell *matCellDef="let hashtag">{{hashtag['owner']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.hashtagNames['dateCreated'] }}</mat-header-cell>
            <mat-cell *matCellDef="let hashtag">{{hashtag['dateCreated']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="modified">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.hashtagNames['dateModified'] }}</mat-header-cell>
            <mat-cell *matCellDef="let hashtag">{{hashtag['dateModified']}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
            <mat-cell *matCellDef="let hashtag">
              <button mat-icon-button class="action-remove" (click)="onRemoveHashtag(hashtag)" mat-icon-button aria-label="Remove request">
                <mat-icon [class.show] ="!hashtag.deleting" [class.hide]="hashtag.deleting">close</mat-icon>
                <mat-spinner [class.show] ="hashtag.deleting" [class.hide]="!hashtag.deleting" [diameter]="20"></mat-spinner>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row [hidden]="isLoading" *matRowDef="let contact; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </div>
</div>
