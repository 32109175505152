import { AppService } from '../app-services/app.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { YhCore, SearchItemType, ContactItemType } from '../app-services/core.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { DialogConfirmLeadCreationComponent } from '../app-dialogs/dialog-confirm-lead-creation/dialog-confirm-lead-creation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-search-item-page',
  templateUrl: './create-search-item-page.component.html',
  styleUrls: ['./create-search-item-page.component.scss']
})
export class CreateSearchItemPageComponent {

  candidate: SearchItemType = new SearchItemType();
  contact: ContactItemType = new ContactItemType();
  selectedFile: File = null;
  phoneHome = '';
  phoneWork = '';
  firstName = '';
  email2 = '';

  isCanRelocate = false;
  loading = false;

  constructor(
    private dialog: MatDialog,
    public app: AppService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  onAddCandidate() {
    this.createCandidate(false);
  }

  openConfirmDialog(id) {
    const dialogRef = this.dialog.open(DialogConfirmLeadCreationComponent,
      { width: '600px', data: {searchItemId: id, email: this.candidate.email2} });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
          this.loading = false;
      } else {
        this.createCandidate(false);
      }
    })
  }

  createCandidate(shouldCreateContact: boolean) {
    YhCore.searchItems.create(this.candidate, createdCandidateId => {
      this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), createdCandidateId]).then();
      const snackBarRef = this.snackBar.open(this.app.type.names['searchItem'] + ' was successfully created', 'OK', {
        duration: 3000,
      });

    }, error => {
      error = error.replace('email1', this.app.type.searchItemNames.email1);
      error = error.replace('email2', this.app.type.searchItemNames.email2);
      const snackBarRef = this.snackBar.open(error, 'OK', {
        duration: 10000,
      });
    });
  }

}


