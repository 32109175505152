export class CompanyDetail {
  companyJobOrders: CompanyJobOrder[];
}

export class CompanyJobOrder {
  alias: string;
  city: string;
  cityEn: string;
  cityRu: string;
  cityUa: string;
  clientJobId: string;
  companyDepartmentId: string;
  companyId: string;
  companyName: string;
  contactId: string;
  dateCreated: string;
  dateModified: string;
  description: string;
  duration: string;
  enteredBy: string;
  isAdminHidden: string;
  isHot: string;
  id: string;
  notes: string;
  openings: string;
  openingsAvailable: string;
  owner: string;
  public: string;
  questionnaireId: string;
  rateMax: string;
  recruiter: string;
  salary: string;
  seoDescriptionEn: string;
  seoKeywordsEn: string;
  seoTitleEn: string;
  siteId: string;
  startDate: string;
  state: string;
  status: string;
  title: string;
  type: string;
  ownerFullName: string;
  userRecruiterFullName: string;
}
