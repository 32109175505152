<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header data-label="id">ID</mat-header-cell>
            <mat-cell *matCellDef="let row" data-label="id">{{ row["id"] }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
            <mat-cell *matCellDef="let row" >{{ row["title"] }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row["dateCreated"] }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button (click)="reviewCv(row['id'])" mat-flat-button style="text-transform: uppercase" color="primary">Review</button>
              <span style="min-width: 15px;"></span>
              <button (click)="deleteCv(row['id'])" mat-flat-button style="text-transform: uppercase" color="warn">Delete</button>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

        <mat-paginator [pageSizeOptions]="[5,10,25,50]"></mat-paginator>
    </div>
  </div>
</div>