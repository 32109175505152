import { Component, Input, ViewChild, Output, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { JobOrder } from '../../app-services/job-order.type';
import { JoborderGroupType, UserType, WorkItemType, YhCore } from '../../app-services/core.service';
import {AppService} from "../../app-services/app.service";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WorkItemGroupDialogComponent } from 'src/app/app-dialogs/work-item-group-dialog/work-item-group-dialog.component';
import { WorkItemNotes } from 'core/dist/types/workItem-notes-type';

@Component({
  selector: 'app-work-items-table',
  templateUrl: './work-items-table.component.html',
  styleUrls: ['./work-items-table.component.scss']
})
export class WorkItemsTableComponent implements OnInit {
  public displayedColumns = ['title', 'type', 'status', 'dateCreated', 'joborderGroupTitle', 'submit'];
  public addToGroupForm:FormGroup = new FormGroup({group: new FormControl('', [Validators.required])});
  public dataSource: MatTableDataSource<WorkItemType>;
  private paginator: MatPaginator;
  private sort: MatSort;
  private dialogAddGroup: MatDialogRef<any>;
  private selectWorkItem: WorkItemType;
  public joborderGroups: JoborderGroupType[] = [];
  public currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));

  @Input() public set data(data: WorkItemType[]) {
    this.applyDataSource(data);
  }

  @Input() isLoading: boolean;

  @Input() public set columns(columns: string[]) {
    this.displayedColumns = columns;
  }

  @Output() onJobOrderSelect = new EventEmitter<WorkItemType>();
  @Output() onWorkItemCondition = new EventEmitter<WorkItemType>();
  @Output() onSubmit = new EventEmitter<WorkItemType>();

  constructor(
    public app: AppService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
  ) {}
  ngOnInit(): void {
    this.getJoborderGroups().then(res => this.joborderGroups = res)
  }
  @ViewChild('addToGroupDialog') addToGroupDialog: TemplateRef<any>;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: WorkItemType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectJobOrder(row: WorkItemType) {
    this.onJobOrderSelect.emit(row);
  }

  submitTo(row: WorkItemType){
    this.onSubmit.emit(row);
  }

  getJoborderGroups():Promise<JoborderGroupType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.joborderGroup.list(res => resolve(res), err => reject(err))
    })
  }

  updateWorkItem(item: WorkItemType) {
    return new Promise( (resolve, reject) => {
      YhCore.workItems.editWorkItem(item, res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  navigateToWorkItemCondition(item: WorkItemType) {
    this.onWorkItemCondition.emit(item)
  }

  openDialogAddToGroup(item: WorkItemType) {
    this.selectWorkItem = item;
    this.addToGroupForm.get("group").setValue(this.joborderGroups.find(val => +val.id === +item.joborderGroupId))
    this.dialogAddGroup = this.dialog.open(this.addToGroupDialog, {width: "600px"})
  }

  openDialogAddJoborderGroup() {
    if(+this.currentUser.accessLevel < 400) {
      this.snackBar.open("You dont have access for it", "OK", {duration: 5000})
      return
    }
    this.dialogAddGroup.close()
    this.dialog.open(WorkItemGroupDialogComponent, {width: "600px"}).afterClosed().subscribe( res => {
      if(res) this.openDialogAddToGroup(this.selectWorkItem);
      if(res) this.getJoborderGroups().then(data => this.joborderGroups = data)
    })
  }
  saveWorkItemGroup() {
    this.selectWorkItem.joborderGroupId = this.addToGroupForm.value.group.id;
    this.selectWorkItem.joborderGroupTitle = this.addToGroupForm.value.group.title;
    this.updateWorkItem(this.selectWorkItem).then( res => {
      this.snackBar.open(`${this.app.type.names.workItem} was added to group ${this.selectWorkItem.joborderGroupTitle}`, "OK", {duration: 3000});
      this.dialogAddGroup.close(false);
      this.addToGroupForm.reset()
    })
  }
}
