import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class YhApiBaseService {

  constructor(private http: HttpClient, @Inject('API_URL') private apiUrl: string) { }

  request<T>(method: string, body: any): Observable<T> {
    return this.http.post<T>(this.apiUrl + method, body,
      { headers: new HttpHeaders({ 'Content-Type': 'text/plain' }) });
  }
}
