<app-navigation>
  <div class="main-container-no-scroll">
    <div class="dashboard">
      <div class="dashboard__row">
        <div class="dashboard__column">
          <mat-card>
            <mat-card-subtitle>Quick Sourcing</mat-card-subtitle>
            <mat-card-content>
              <mat-form-field appearance="fill" style="width: 100%">
                <mat-label>Select Work Item</mat-label>
                <mat-select (selectionChange)="selectWorkItem($event.value)">
                  <mat-option *ngFor="let item of positions" [value]="item.value">
                    #{{ item.id }} {{ item.value }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <app-quick-sourcing-table
                [data]="workItems"
              ></app-quick-sourcing-table>
            </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-subtitle>My Upcoming Events / Calls</mat-card-subtitle>
            <mat-card-content>
              <app-upcoming-events></app-upcoming-events>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="dashboard__column">
          <mat-card>
            <mat-card-content>
              <mat-tab-group>
                <mat-tab label="Candidates in Work">
                  <app-search-items-work-table></app-search-items-work-table>
                </mat-tab>
                <mat-tab label="Contacted Candidates">
                  <app-contacted-search-items-table [data]="contactedCandidates"></app-contacted-search-items-table>
                </mat-tab>
            </mat-tab-group>
          </mat-card-content>
          </mat-card>
          <mat-card>
            <mat-card-content>
              <mat-tab-group>
                <mat-tab label="Recent Hires (Offered)">
                  <app-recent-hires-offer-table [data]="offeredCandidates"></app-recent-hires-offer-table>
                </mat-tab>
                <mat-tab label="Recent Hires (Placed)">
                  <app-recent-hires-place-table [data]="placedCandidates"></app-recent-hires-place-table>
                </mat-tab>
              </mat-tab-group>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</app-navigation>
