"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebMessengerController = void 0;
class WebMessengerController {
    constructor(api, messengerService) {
        this.api = api;
        this.messengerService = messengerService;
    }
    newEmailsCount(candidate_email, successCallback, errorCallback) { }
    fetchThread(candidateEmail, successCallback, errorCallback) {
        this.api.postRequest('mailbox/fetchThread', { email: candidateEmail }, response => {
            if (response.data.valid === true) {
                successCallback(this.messengerService.mapMessages(response.data.thread));
            }
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    fetchInbox(candidate_email, successCallback, errorCallback) { }
    send(message, successCallback, errorCallback) {
        const src = this.messengerService.mapReverseMessages(message);
        this.api.postRequest('mailbox/send', src, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    getNewEmails(successCallback, errorCallback) {
        this.api.postRequest('mailbox/getMailboxInfo', {}, response => {
            if (response.data.valid === true) {
                successCallback(response.data);
            }
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    markAsSeen(emailUids, successCallback, errorCallback) {
        this.api.postRequest('mailbox/markAsSeen', {
            emails_uids: emailUids
        }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    checkConnection(successCallback, errorCallback) {
        this.api.postRequest('mailbox/checkConnection', {}, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
}
exports.WebMessengerController = WebMessengerController;
