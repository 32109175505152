import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-search-items-work-table',
  templateUrl: './search-items-work-table.component.html',
  styleUrls: ['./search-items-work-table.component.scss']
})
export class SearchItemsWorkTableComponent implements OnInit {

  public displayedColumns = ['name', 'job', 'started'];
  private paginator: MatPaginator;
  private sort: MatSort;

  candidates = [
    {id: 1, name: 'Misha', job: {company: 'Test Company1', position:  'Front-End', submited: false }, started: '1Y ago',},
    {id: 2, name: 'Max', job: {company: 'Test Company2', position:  'Back-End', submited: true }, started: '2Y ago',},
    {id: 3, name: 'Erik', job: {company: 'Test Company3', position:  'Front-End', submited: false }, started: '3Y ago',},
    {id: 4, name: 'Miss', job: {company: 'Test Company4', position:  'Back-End', submited: false }, started: '4Y ago',},
    {id: 5, name: 'Lii', job: {company: 'Test Company5', position:  'Front-End', submited: true }, started: '2Y ago',},
    {id: 6, name: 'Worker', job: {company: 'Test Company6', position:  'Back-End', submited: false }, started: '3Y ago',},
    {id: 7, name: 'Andry', job: {company: 'Test Company7', position:  'Front-End', submited: true }, started: '4Y ago',},
    {id: 8, name: 'Alex', job: {company: 'Test Company8', position:  'Back-End', submited: false }, started: '5Y ago',},
    {id: 9, name: 'Saryman', job: {company: 'Test Company9', position:  'Front-End', submited: true }, started: '1Y ago',},
    {id: 10, name: 'Hobbi', job: {company: 'Test Company10', position:  'Back-End', submited: false }, started: '3Y ago',},
    {id: 11, name: 'Misha1', job: {company: 'Test Company11', position:  'Front-End', submited: false }, started: '1Y ago',},
    {id: 12, name: 'Max2', job: {company: 'Test Company12', position:  'Back-End', submited: false }, started: '2Y ago',},
    {id: 13, name: 'Erik3', job: {company: 'Test Company13', position:  'Front-End', submited: true }, started: '3Y ago',},
    {id: 14, name: 'Miss4', job: {company: 'Test Company14', position:  'Back-End', submited: false }, started: '4Y ago',},
    {id: 15, name: 'Lii5', job: {company: 'Test Company15', position:  'Front-End', submited: false }, started: '2Y ago',},
    {id: 16, name: 'Worker6', job: {company: 'Test Company16', position:  'Back-End', submited: true }, started: '3Y ago',},
    {id: 17, name: 'Andry7', job: {company: 'Test Company17', position:  'Front-End', submited: false }, started: '4Y ago',},
    {id: 18, name: 'Alex8', job: {company: 'Test Company18', position:  'Back-End', submited: false }, started: '5Y ago',},
    {id: 19, name: 'Saryman9', job: {company: 'Test Company19', position:  'Front-End', submited: false }, started: '1Y ago',},
    {id: 20, name: 'Hobbi10', job: {company: 'Test Company20', position:  'Back-End', submited: true }, started: '3Y ago',},
  ]

  dataSource:MatTableDataSource<any> = new MatTableDataSource();


  constructor() { }

  ngOnInit(): void {
    this.applyDataSource()
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSource() {
    this.dataSource = new MatTableDataSource(this.candidates);
    this.applyDataSourceAttributes()
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }


}
