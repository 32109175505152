import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { filter } from 'rxjs/operators';
import { ConfirmCreateAdminGroupDialogComponent } from 'src/app/app-dialogs/confirm-create-admin-group-dialog/confirm-create-admin-group-dialog.component';
import { UserActionsDialogComponent } from 'src/app/app-dialogs/user-actions-dialog/user-actions-dialog.component';
import { GroupUsersType, UserType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-departments-table',
  templateUrl: './departments-table.component.html',
  styleUrls: ['./departments-table.component.scss']
})
export class DepartmentsTableComponent implements OnInit, AfterViewInit {

  private currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem("YHminiuser"));
  public dataSource:MatTableDataSource<any> = new MatTableDataSource();
  public displayedColumns = ['title', 'dateCreated', "adminFirstName", "adminLastName"];
  private paginator: MatPaginator;
  private sort: MatSort;
  private sortState: Sort = {active: 'dateCreated', direction: 'desc'}

  @Input() public loading:boolean = false

  @Input() public set data(data: GroupUsersType[]) {
    if(data && data.length) {
      this.applyDataSource(data);
    }
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  @Output() rowEvent = new EventEmitter<any>();

  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
  }

  applyDataSourceAttributes() {
    if (this.paginator && this.sort) {
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit(this.sortState);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyDataSource(data: GroupUsersType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.applyDataSourceAttributes()
  }

  navigateToDepartment(row: GroupUsersType) {
    if(row.adminUserId === null) {
      if(+this.currentUser.accessLevel < 400) {
        this.snackBar.open("This group does not have admin. You can not see this group", "OK", {duration: 5000});
        return
      }
      this.dialog.open(ConfirmCreateAdminGroupDialogComponent, {width: "600px"}).afterClosed().pipe(filter(res => res)).subscribe( res => {
        this.dialog.open(UserActionsDialogComponent, {width: "600px", data: {user: null, creationMode: true}}).afterClosed().pipe(filter(res => res)).subscribe(res => {
          this.rowEvent.emit(row)
        })
      })
      return
    }
    this.rowEvent.emit(row)
  }

}
