import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YhCore, SearchItemType, ContactItemType } from '../../app-services/core.service';
import { AppService } from '../../app-services/app.service';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-create-contact-item-dialog',
  templateUrl: './create-contact-item-dialog.component.html',
  styleUrls: ['./create-contact-item-dialog.component.scss']
})
export class CreateContactItemDialogComponent implements OnInit {

  contact: ContactItemType = new ContactItemType();

  constructor(
    public app: AppService,
    public dialogRef: MatDialogRef<CreateContactItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    if (this.data.isContactInfoFromCandidate) this.setContactInfoFromCandidate();

    this.contact.companyId = this.data.candidate.candidateId
    this.contact.siteId = '3';
    this.contact.lastName = this.data.candidate.lastName;
    this.contact.state = this.data.candidate.state;
    this.contact.city = this.data.candidate.city;
    this.contact.companyDepartmentId = '1';
  }

  setContactInfoFromCandidate() {
    this.contact.firstName = this.data.candidate.firstName;
    this.contact.email2 = this.data.candidate.email2;
    this.contact.phoneWork = this.data.candidate.phoneHome;
    this.contact.phoneCell = this.data.candidate.phoneWork;
  }

  onAddContact() {
    YhCore.contactItems.create(this.contact, success => {
      this.dialogRef.close(true);
      this.snackBar.open('Contact add', 'OK', { duration: 5000 })
    }, error => { this.snackBar.open(error, 'ERROR', { duration: 5000 }) })
  }
}
export interface DialogData {
  candidate: SearchItemType,
  isContactInfoFromCandidate: boolean
}
