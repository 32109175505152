"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSearchItemsActivityController = void 0;
class WebSearchItemsActivityController {
    constructor(api, activityService) {
        this.api = api;
        this.activityService = activityService;
    }
    list(from, to, successCallback, errorCallback) {
        this.api.postRequest('activity/list', { from, to }, response => {
            if (response.data.valid === true)
                successCallback({ response: this.activityService.mapActivities(response.data.data), totalSearchItems: response.data.total_activities });
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('activity/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.activityService.mapActivity(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('activity/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    edit(activity, successCallback, errorCallback) {
        const src = this.activityService.mapReverseActivity(activity);
        this.api.postRequest('activity/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    create(activity, successCallback, errorCallback) {
        const src = this.activityService.mapReverseActivity(activity);
        this.api.postRequest('activity/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    getStatusList(successCallback, errorCallback) {
        this.api.postRequest('types/activityTypes', {}, response => {
            if (response.data.valid === true)
                successCallback(this.activityService.mapActivityStatuses(response.data.types));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    bySearchItemId(id, successCallback, errorCallback) {
        this.api.postRequest('activity/listBySearchItemId', {
            searchitem_id: id
        }, response => {
            if (response.data.valid === true)
                successCallback(this.activityService.mapActivities(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    find(activity, successCallback, errorCallback) { }
    sort(activities, activityField, desc, successCallback, errorCallback) { }
}
exports.WebSearchItemsActivityController = WebSearchItemsActivityController;
