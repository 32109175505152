<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle class="flex">
        <p class="add-event-btn-wrap">
          <span>Add Event</span>
          <button mat-icon-button class="calendar-add-event-btn" (click)="onAddEvent()" matTooltip="Add Event"  [matTooltipPosition]="'right'">
            <mat-icon>add_alarm</mat-icon>
          </button>
        </p>
      </mat-card-subtitle>
      <div class="calendar-btn-wrap flex flex-xs-column flex-md-row flex-align-stretch flex-md-between"> 
        <div class="calendar-view-btn flex flex-center flex-align-center">
          <button mat-flat-button color="primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            PREVIOUS
          </button>
          <button mat-flat-button color="primary" mwlCalendarToday [(viewDate)]="viewDate">
            TODAY
          </button>
          <button mat-flat-button color="primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
            (viewDateChange)="closeOpenMonthViewDay()">
            NEXT
          </button>
        </div>
        <div class="title-wrap">
          <h3 class="flex flex-center">{{ viewDate | calendarDate:(view + 'ViewTitle'):'en' }}</h3>
        </div>
        <mat-button-toggle-group class="calendar-switch-btn flex flex-center flex-align-center" appearance="legacy" value="month">
          <mat-button-toggle value="month" (click)="setView(CalendarView.Month)"
            [class.active]="view === CalendarView.Month">
            MONTH
          </mat-button-toggle>
          <mat-button-toggle value="week" (click)="setView(CalendarView.Week)"
            [class.active]="view === CalendarView.Week">
            WEEK
          </mat-button-toggle>
          <mat-button-toggle value="day" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">
            DAY
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </mat-card>

    <mat-card class="main-content">
      <mat-card-content>
        <div [ngSwitch]="view">
          <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
            (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-month-view>
          <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-week-view>
          <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
            [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)">
          </mwl-calendar-day-view>
        </div>
      </mat-card-content>
    </mat-card>
 </div>
</app-navigation>