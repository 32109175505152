import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { YhCore, UserType, ClientAuthorizationType } from '../app-services/core.service'

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, OnDestroy {
  username: FormControl;
  password: FormControl;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  hide = true;
  users: UserType[] = [];
  currentUser: UserType;

  public id = `f${(+new Date).toString(16)}`;
  public forgotPasswordForm: FormGroup;
  private intervalGetKey;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public matcher: ErrorStateMatcher,
    private snackBar: MatSnackBar,
  ) { }

  ngOnDestroy(): void {
    clearInterval(this.intervalGetKey);
  }

  ngOnInit() {

    this.intitFormForgotPassword();

    this.username = new FormControl('', [
      Validators.required,
    ]);
    this.password = new FormControl('', [
      Validators.required,
    ]);
    this.loginForm = this.formBuilder.group({
      username: this.username,
      password: this.password
    });

    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

    this.intervalGetKey = setInterval(() => {
      this.chechClientAuth();
    }, 5000)
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    YhCore.authentication.login(this.username.value, this.password.value, (success) => {
      YhCore.users.getStaticData((currentUser: UserType) => {
        this.router.navigate([this.returnUrl]);
      }, errorMsg => {this.snackBar.open(errorMsg, '', {duration: 3000});})
    },
    errorMsg => {
        this.snackBar.open(errorMsg, 'OK', {duration: 3000});
        this.loading = false;
    });
  }

  get f() {
    return this.forgotPasswordForm.controls;
  }

  intitFormForgotPassword() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email])
    })
  }

  forgotPassword(email:string):Promise<boolean> {
    return new Promise( (resolve, reject) => {
      YhCore.authentication.forgotPassword(email, res => {
        resolve(res)
      }, err => this.errorHandling(err))
    })
  }

  async sendPassword() {
    const res = await this.forgotPassword(this.forgotPasswordForm.value.email);
    
    if(res) {
      this.forgotPasswordForm.reset();
      this.snackBar.open(`We send your new password to ${this.forgotPasswordForm.value.email}`,'OK', {duration:3000});
      setTimeout( () => this.router.navigateByUrl('/login'), 3000);
    } else {
      this.errorHandling(res);
    }
  }

  goToSignUp() {
    this.router.navigateByUrl('/registration');
  }

  chechClientAuth() {
    YhCore.clientAuthorization.getByKey(this.id, (res:ClientAuthorizationType) => {
      if(res.id) {
        this.router.navigate(['auth-redirect'], {queryParams: {token: res.token, candidateId: res.candidateId, refreshToken: res.refreshToken}})
      }
    }, err => this.errorHandling(err))
  }

  errorHandling(err) {
    this.snackBar.open(err, "ERROR", {duration: 5000})
  }
}
