import { Component, OnInit } from '@angular/core';
import { CalendarView } from 'angular-calendar';
import { YhCore } from './../app-services/core.service';
import * as moment from 'moment';


@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.scss'],
})
export class UpcomingEventsComponent implements OnInit {
  view: CalendarView = CalendarView.Week;
  viewDate: Date = new Date();
  events: any[] = [];
  activeDayIsOpen: boolean = false;
  eventCalendars = [];
  CalendarView = CalendarView;

  constructor(
  ) { }

  ngOnInit() {
    this.getCalendarEvents();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  getCalendarEvents(): void {
    YhCore.calendar.list(success => {
      this.events = success;
      this.convertDateForStart();
      this.showEvents()
    }, err => { });
  }

  showEvents() {
    if(this.view === 'week') {
      let now = moment(this.viewDate)
      let monday = now.clone().weekday(-1);
      let sunday = now.clone().weekday(8);
      this.eventCalendars = this.events.filter( val => {
        const eventDate = moment(val.date);
        if(eventDate.isBetween(monday, sunday, null, '[]')) {
          return val
        }
      })
    } else {
      this.eventCalendars = this.events.filter( val => {
        if(JSON.stringify(val.start.toLocaleDateString()) === JSON.stringify(this.viewDate.toLocaleDateString())){
          return val
        }
      })
    }    
  }

  closeOpenMonthViewDay() {
    this.showEvents()
  }

  convertDateForStart() {
    this.events.map(el => {
      let arr = el.date.replace(/[ :]+/g, "-")
      arr = arr.split('-');
      arr[1] -= 1;
      Object.assign(el, { start: new Date(arr[0], arr[1], arr[2], arr[3], arr[4], 0) })
      if (el.allDay === "0") {
        el.allDay = false;
      }
      else {
        el.allDay = true
      }
    })
  }

}
