import { Component, OnInit, Input, ElementRef, Renderer2, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Portal, TemplatePortal } from '@angular/cdk/portal';
import { OverlayRef, OverlayConfig, Overlay, PositionStrategy } from '@angular/cdk/overlay';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-my-events-overlay',
  templateUrl: './my-events-overlay.component.html',
  styleUrls: ['./my-events-overlay.component.scss']
})
export class MyEventsOverlayComponent implements OnInit, OnDestroy {

  constructor(private renderer: Renderer2,
              private overlay: Overlay,
              private viewContainerRef: ViewContainerRef) { }

  @Input() flyoutFor: MatButton;
  @Input() template: TemplateRef<any>;

  private portal: TemplatePortal;
  private overlayRef: OverlayRef;
  private opened: boolean;
  private unlisten: () => void;

  ngOnInit() {
    if (this.flyoutFor == null) {
      return;
    }

    this.unlisten = this.renderer.listen(this.flyoutFor._elementRef.nativeElement, 'click', evt => { this.open(); });
  }

  ngOnDestroy() {
    this.unlisten();

    this.close();

    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  public open() {
    if (this.opened) {
      return;
    }

    if (this.portal == null) {
      this.portal = new TemplatePortal(this.template, this.viewContainerRef);
    }

    if (!this.overlayRef) {
      this.createOverlay();
    }

    if (!this.overlayRef.hasAttached()) {
      this.overlayRef.attach(this.portal);
    }

    this.opened = true;
  }

  private createOverlay() {
    const overlayConfig = new OverlayConfig({
      positionStrategy: this.createOverlayPositionStrategy(),
      hasBackdrop: true,
      backdropClass: 'mat-overlay-transparent-backdrop',
      panelClass: 'my-events-overlay-popup',
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef.overlayElement.setAttribute('role', 'dialog');

    merge(
      this.overlayRef.backdropClick(),
      this.overlayRef.detachments(),
      this.overlayRef.keydownEvents().pipe(filter(evt => {
        return evt.key === 'Escape';
      }))
    ).subscribe(evt => {
      if (evt) {
        evt.preventDefault();
      }

      this.close();
    });
  }

  public close() {
    if (!this.opened) {
      return;
    }

    if (this.overlayRef && this.overlayRef.hasAttached()) {
      this.overlayRef.detach();
    }

    this.opened = false;
  }


  private createOverlayPositionStrategy(): PositionStrategy {
    return this.overlay.position()
      .flexibleConnectedTo(this.flyoutFor._elementRef)
      .withFlexibleDimensions(true)
      .withViewportMargin(8)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'bottom'
        }
      ]);;
  }
}
