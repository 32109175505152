<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-content">
      <mat-card-content>

        <div style="margin-bottom: 20px;">
          <button class="add-workitem-button" style="margin-right: 5px;" mat-flat-button color="primary" (click)="router.navigate([app.type.names['workItems'].toLowerCase().concat('/create-').concat(app.type.names['workItem'].toLowerCase())])">ADD {{ app.type.names['workItems'].toUpperCase() }}</button>
<!--          <button mat-flat-button color="primary" [matMenuTriggerFor]="filterByGroups">FILTER BY GROUPS</button>-->
          <mat-menu #filterByGroups="matMenu">
            <button mat-menu-item *ngFor="let item of joborderGroups" (click)="selectGroupId = item.id">{{item.title}}</button>
            <button mat-menu-item (click)="selectGroupId = 0">ALL</button>
          </mat-menu>
        </div>

        <mat-divider></mat-divider>

        <div style="padding-top: 15px;">
          <app-work-items-table #jobOrdersTableComponent
                                [isLoading]="isLoadingWorkItem"
                                [data]="jobOrders | joborderGroup: selectGroupId"
                                (onJobOrderSelect)="navigateToJobOrder($event)"
                                (onSubmit)="onSubmit($event)"
                                (onWorkItemCondition)="navigateToWorkItemCodition($event)">
          </app-work-items-table>
        </div>

      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>
