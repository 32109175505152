import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachmentType, SearchItemType, YhCore } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-attachments-tab',
  templateUrl: './attachments-tab.component.html',
  styleUrls: ['./attachments-tab.component.scss']
})
export class AttachmentsTabComponent implements OnInit {

  public displayedColumns = ['attachmentId', 'title', 'dateCreated', 'actions'];
  public actions = [
    {action: 'preview', tooltip: 'Preview CV', icon: 'preview'},
  ]
  public showPreview: boolean = false;
  public selectedAttachment: AttachmentType;
  public pdfViewer: any;
  private candidate: SearchItemType;
  public attachments:AttachmentType[];
  
  @Input() public set data(data: SearchItemType) {
    if(data.candidateId) {
      this.candidate = data;
      this.getSearchItemAttachments();
    }
  }

  constructor(
    private sanckBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) { }

  async ngOnInit() {
  }

  getSearchItemAttachments():Promise<AttachmentType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.attachment.getSerachItemAttachment(+this.candidate.candidateId, (res: AttachmentType[]) => {
        this.attachments = res
        resolve(res)
      }, err => reject(err))
    })
  }

  previewCv(attachment: AttachmentType) {
    this.showPreview = true;
    attachment.directoryName = attachment.directoryName.replace(/.+:/, 'https:');
    this.pdfViewer = this.sanitizer.bypassSecurityTrustResourceUrl(`${attachment.directoryName}${attachment.storedFilename}`);
    this.selectedAttachment = attachment;
  }

  toCamelCase(str:string):string {
    return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
  }

  errorHandling(err) {
    this.sanckBar.open(err, 'ERROR', {duration:5000})
  }
}
