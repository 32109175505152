<h2 mat-dialog-title>Access {{app.type.names['workItems'].toUpperCase()}}</h2>
<mat-dialog-content *ngIf="!loading;else spinner">

  <form class="form" [formGroup]="form" (ngSubmit)="onSave()">

    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Select Group</mat-label>
      <mat-select formControlName="groupUser">
        <mat-option *ngFor="let item of groupUsers" [value]="item">{{item.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    
    <mat-button-toggle-group [multiple]="true" [(ngModel)]="accessUserIds" [ngModelOptions]="{standalone: true}">
      <mat-button-toggle *ngFor="let user of selectedUsersByGroup" [value]="user.userId" (change)="changeToggleUser($event, user.userId)">{{user.firstName}} {{user.lastName}}</mat-button-toggle>
    </mat-button-toggle-group>

    <div style="margin-top: 10px;">
      <button mat-flat-button color="primary" [disabled]="!form.valid" type="submit">SAVE</button>
    </div>
  </form>

</mat-dialog-content>

<ng-template #spinner>
  <mat-card id="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
</ng-template>