import { Component, OnInit } from '@angular/core';
import { YhCore, WorkItemType, CustomerType, WorkItemNotesType, UserType, JoborderGroupType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { WorkItemGroupDialogComponent } from '../app-dialogs/work-item-group-dialog/work-item-group-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-create-work-item-page',
  templateUrl: './create-work-item-page.component.html',
  styleUrls: ['./create-work-item-page.component.scss']
})
export class CreateWorkItemPageComponent implements OnInit {
  currentUser:UserType = JSON.parse(localStorage.getItem('YHminiuser'))
  jobOrder: WorkItemType = new WorkItemType();
  jobOrderNotes: WorkItemNotesType = new WorkItemNotesType();
  company: CustomerType = new CustomerType();
  addJoborderForm: FormGroup
  showAdditionalInfo = true;
  ageNotImportant = true;
  desiredNotImportant = true;
  educationNotImportant = true;
  kidsNotImportant = true;
  editSame = false;
  samePaymentFrom = '';
  samePaymentTo = '';
  public joborderGroups: JoborderGroupType[] = [];
  public joborderGroupControll: FormControl = new FormControl('', [Validators.required]);
  public addJoborderGroupForm: FormGroup = new FormGroup({title: new FormControl('', [Validators.required])})
  constructor(
    public app: AppService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: MatDialog

  ) { }

  ngOnInit(): void {
    this.setDefaultData();
    this.initForm();
    this.editSameFoo();
    this.getJoborderGroups().then(res => this.joborderGroups = res)
  }

  setDefaultData() {
    this.jobOrderNotes.businessTrip = 'noTrip';
    this.jobOrderNotes.socialBenefits = 'no';
    this.jobOrderNotes.subordinates = 'no';
    this.jobOrderNotes.growth = 'no';
    this.jobOrderNotes.testPeriod = 'no';
    this.jobOrderNotes.combineWorkStudying = 'no';
    this.jobOrderNotes.managementExp = 'no';
    this.jobOrderNotes.driverLicense = 'no';
    this.jobOrderNotes.enhancedPrivacy = 'no';
    this.jobOrderNotes.particularPeopleHire = 'no';
    this.jobOrderNotes.otherRecruiterAgencies = 'no';
    this.jobOrderNotes.someJobAds = 'no';
    this.jobOrderNotes.relocationHelp = 'no';
  }

  initForm() {
    this.addJoborderForm = this.fb.group({
      title: ['', Validators.required],
      description: ['', Validators.required],
      type: 'H',
      positionLocation: 'remotePosition',
      testPeriodInfo: '',
      paymentFrom: ['', Validators.required],
      paymentTo: ['', Validators.required],
      paymentSame: true,
      paymentBonuses: false,
      workingDay: 'freeSchedule',
      socialBenefitsList: '',
      contract: 'temporary',
      guarantees: '',
      relocationHelpInfo: '',
      state: '',
      city: '',
      paymentProbationPeriodFrom: '',
      paymentProbationPeriodTo: '',
      frequencyOfTrips: '',
      subordinatesAmount: '',
      growthPossiblePosition: '',
      // workSchedule
      startEndWorkingDay: '',
      lunchBreak: '',
      freeDays: '',
      // directManager
      name: '',
      position: '',
      phone: '',
      // requirementsFromCandidates
      age: '',
      ageFrom: '',
      ageTo: '',
      cadidateSex: 'doesNotMatter',
      candidateEducation: '',
      candidateDesiredDegree: '',
      combineWorkStudyingInfo: '',
      maritalStatusKids: '',
      ownVehicle: '',
      driverLicenseType: '',
      mainResponsibilities: '',
      desireWorkExp: '',
      requiredSkills: '',
      additionalSkills: '',
      managementExpInfo: '',
      foreignLanguages: '',
      personalCharacteristic: '',
      residence: 'noMatter',
      // informationForExecutant
      requiredDocForCandidate: '',
      additionDesires: '',
      numberPeopleForHire: '',
      shedleInterviewing: '',
      publicLinkJobDescription: '',
      whereSomeJobAds: '',
      nameOtherRecruiterAgencies: '',
      amountInterviewInScope: '',
      particularPeopleHireInfo: '',
      interviewStages: '',
      finalDecisionHiring: '',
      interviver: '',
      necessarilyQuestion: '',
      reasonPositionOpening: '',
      enhancedPrivacyInfo: '',
      durationRequestExecution: [{value: '', disabled: true}],
      dateOpeningPosition: [{value: '', disabled: true}],
    })
  }

  sameMode() {
    if (this.addJoborderForm.controls.paymentSame.value) {
      this.samePaymentFrom = this.addJoborderForm.controls.paymentFrom.value
      this.samePaymentTo = this.addJoborderForm.controls.paymentTo.value
    }
  }
  getJoborderGroups():Promise<JoborderGroupType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.joborderGroup.list(res => resolve(res), err => reject(err))
    })
  }
  editSameFoo() {
    this.editSame = !this.editSame
  }


  showAdditionalInfoFoo() {
    this.showAdditionalInfo = !this.showAdditionalInfo
  }

  onSubmit() {
    const valueForm = this.addJoborderForm.value;
    if (this.addJoborderForm.invalid) {
      return;
    }
    else {
      if (this.addJoborderForm.controls.paymentSame.value) {
        valueForm.paymentProbationPeriodFrom = this.samePaymentFrom
        valueForm.paymentProbationPeriodTo = this.samePaymentTo
      }
      if (this.jobOrderNotes.testPeriod === 'no') {
        valueForm.paymentProbationPeriodFrom = '';
        valueForm.paymentProbationPeriodTo = '';
      }
      valueForm.age = valueForm.ageFrom + '-' + valueForm.ageTo;
      if (this.ageNotImportant) valueForm.age = 'Not important';
      if (this.desiredNotImportant) valueForm.candidateDesiredDegree = 'Not important';
      if (this.educationNotImportant) valueForm.candidateEducation = 'Not important';
      if (this.kidsNotImportant) valueForm.maritalStatusKids = 'Not important';

      // Setting info from form into joborder and jobordernotes
      this.initJobOrder(valueForm);
      this.initJobOrderNotes(valueForm);
      this.jobOrder.joborderGroupId = this.joborderGroupControll.value;
      this.jobOrder.companyId = this.currentUser.companyId.toString();
      // Creating joborder with notes
      YhCore.workItems.create(this.jobOrder, this.jobOrderNotes, success => {
        this.router.navigate([this.app.type.names['workItems'].toLowerCase(), success]);
    }, err => { })
    }
  }
  openDialogAddJoborderGroup(event: any) {
    event.preventDefault()
    event.stopPropagation()
    this.dialog.open(WorkItemGroupDialogComponent, {width: "600px"}).afterClosed().subscribe( res => {
      if(res) this.getJoborderGroups().then(data => this.joborderGroups = data)
    })
  }
  initJobOrderNotes(valueForm: any): void {
    for (const key in valueForm) {
      if (valueForm.hasOwnProperty(key)) {
        this.jobOrderNotes[key] = valueForm[key]
      }
    }
  }

  initJobOrder(valueForm: any): void {
    this.jobOrder.title = valueForm.title;
    this.jobOrder.description = valueForm.description;
    this.jobOrder.type = valueForm.type;
    this.jobOrder.companyId = this.company.companyId;
    this.jobOrder.city = valueForm.city || '-';
    this.jobOrder.state = valueForm.state || '-';
    this.jobOrder.skillListJobOrder = [];
    this.jobOrder.rateMax = valueForm.paymentTo;
    this.jobOrder.salary = valueForm.paymentFrom;
    this.jobOrder.isHot = 0;
    this.jobOrder.isPublic = 0;
    this.jobOrder.recruiter = this.currentUser.userId;
    this.jobOrder.owner = this.currentUser.userId;
  }
}
