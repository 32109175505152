import { Component, OnInit, AfterViewInit } from '@angular/core';
import { YhCore, ReportType, PeriodType, UserType} from '../app-services/core.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit, AfterViewInit {

  chartColors = ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  yAxisLabel = 'Count Candidates';
  chartsConfig = {
    addedByUser: [],
    submittedByUser: [],
    offeredByUser: [],
    contactedByUser: []
  }
  charts = [
    {
      name: 'addedByUser',
      title: 'Added candidates to DB by user',
      data: this.chartsConfig.addedByUser,
      active: true
    },
    {
      name: 'submittedByUser',
      title: 'Submitted candidates in DB by user',
      data: this.chartsConfig.submittedByUser,
      active: false
    },
    {
      name: 'offeredByUser',
      title: 'Offered candidates in DB by user',
      data: this.chartsConfig.offeredByUser,
      active: false
    },
    {
      name: 'contactedByUser',
      title: 'Contacted Candidates in DB by User',
      data: this.chartsConfig.contactedByUser,
      active: false
    }
  ]
  slideConfig = {
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        }
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: false
        }
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false
        }
      }
  ]};
  users: UserType[] = [];
  selectedUsers: string[] = [];

  mainChartData = {
    title: '',
    data: [],
  }

  splitBy = 'day'
  startDateRaw = '2020-09-25'
  endDateRaw = '2020-10-01';
  startDay = new Date();
  endDay = new Date();
  allSelected = false;
  onlyActive = false;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    JSON.parse(YhCore.localStorageService.getItem('YHminiuser')).accessLevel >= 500 ? '' :
    this.router.navigateByUrl('/settings');

    // Setting start and end date for date picker as first day of current month and 25th day of current month
    this.startDateRaw = `${this.startDay.getFullYear()}-${this.getMonthForDatePicker(this.startDay)}-01`;
    this.endDateRaw = `${this.endDay.getFullYear()}-${this.getMonthForDatePicker(this.endDay)}-25`;
  }

  async ngAfterViewInit() {
    this.users = await this.getUsers();
    this.onlyActiveUsers();

    this.getReportData();
  }

  initCharts(user: UserType): ReportType {
    let chartData = new ReportType();
    switch(this.splitBy) {
      case 'day':
        chartData = this.splitByDay(chartData, user, this.startDay, this.endDay);
        break;
      case 'month':
        chartData = this.splitByMonth(chartData, user, this.startDay, this.endDay);
        break;
      case 'year':
        chartData = this.splitByYear(chartData, user, this.startDay, this.endDay);
        break;
    }

    return chartData;
  }

  splitByDay(chartData, user, startDay, endDate) {
    // We set all data equal to 0 for all days in chosen period
    chartData.name = user.firstName;
    chartData.id = user.userId;
    chartData.series = [];
    chartData.series[0] = {
      value: '0',
      name: '',
    };
    chartData.series[0].name = startDay.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric'});
    const nextDate = new Date(startDay);
    while (!this.checkDatesEqual(endDate, nextDate)) {
      nextDate.setDate(startDay.getDate() + 1);
      startDay = new Date (nextDate);
      const data= {
        value: '0',
        name: ''
      };
      data.name = nextDate.toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric'});
      chartData.series.push(data);
    }
    return chartData;
  }

  splitByMonth(chartData, user, startDay, endDate) {
    // We set all data equal to 0 for all days in chosen period
    chartData.name = user.firstName;
    chartData.id = user.userId;
    chartData.series = [];
    chartData.series[0] = {
      value: '0',
      name: '',
    };
    chartData.series[0].name = startDay.toLocaleString('en-US', { month: 'short', year: 'numeric'});
    const nextMonth = new Date(startDay);
    while (!this.checkDatesEqualMonthYear(endDate, nextMonth)) {
      if (startDay.getMonth() === 11) {
        nextMonth.setMonth(0);
        nextMonth.setFullYear(startDay.getFullYear() + 1);
        startDay = new Date (nextMonth);
      } else {
        nextMonth.setMonth(startDay.getMonth() + 1);
        startDay = new Date (nextMonth);
      }
      const data= {
        value: '0',
        name: ''
      };
      data.name = nextMonth.toLocaleString('en-US', { month: 'short', year: 'numeric'});
      chartData.series.push(data);
     }
    return chartData;
  }

  splitByYear(chartData, user, startDay, endDate) {
    // We set all data equal to 0 for all days in chosen period
    chartData.name = user.firstName;
    chartData.id = user.userId;
    chartData.series = [];
    chartData.series[0] = {
      value: '0',
      name: '',
    };
    chartData.series[0].name = startDay.toLocaleString('en-US', {year: 'numeric'});
    const nextYear = new Date(startDay);
    while (!this.checkDatesEqualYear(endDate, nextYear)) {
      nextYear.setFullYear(startDay.getFullYear() + 1);
      startDay = new Date (nextYear);
      const data= {
        value: '0',
        name: ''
      };
      data.name = nextYear.toLocaleString('en-US', {year: 'numeric'});
      chartData.series.push(data);
     }
    return chartData;
  }

  getUsers():Promise<UserType[]> {
    return new Promise<UserType[]> (resolve => {
      YhCore.users.list((users: UserType[]) => {
        resolve(users);
      }, err => {})
    })
  }

  getReportData() {
    const period = new PeriodType();
    period.splittingBy = this.splitBy;
    this.startDay = new Date(this.startDateRaw);
    this.endDay = new Date(this.endDateRaw)

    if (this.splitBy === 'month' && this.startDay.getMonth() === this.endDay.getMonth()) {
      this.snackBar.open('Please, chose at least two months', 'OK', {duration: 3000});
      return;
    }

    if (this.splitBy === 'year' && this.startDay.getFullYear() === this.endDay.getFullYear()) {
      this.snackBar.open('Please, chose at least two years', 'OK', {duration: 3000});
      return;
    }
    period.startDate = this.formatDate(this.startDay, false);
    period.endDate = this.formatDate(this.endDay, true);

    // Preparing raw data - with all 0, to make sure it's displayed correctly on the chart
    this.prepareRawDataForCharts();
    // Fetching the data from DB and mapping it onto raw data that was prepared before
    this.fetchChartData(period);
  }

  fetchChartData(period: PeriodType) {
    for (const chartName in this.chartsConfig) {
      if (this.chartsConfig.hasOwnProperty(chartName)) {
        // Fething report data for current report
        YhCore.reports[chartName](period, chartData => {
          this.mapChartData(chartData, chartName);
          this.charts.map(chart => {
            if (chart.name === chartName) {
              chart.data = this.chartsConfig[chartName];
              if (chart.active) {
                this.mainChartData.title = chart.title;
                this.mainChartData.data = chart.data;
              }
            }
          })
        }, err => {})
      }
    }
  }

  // First parametr - dataToMap is raw report data we get from backend.
  // Second parametr - chart - is name of chart we display this data on
  mapChartData(dataToMap, chart) {
    // We map all non zero data to be shown in chart
    dataToMap.map((fetchedDataItem:ReportType) => {
      this.chartsConfig[chart].map((rawDataItem:ReportType) => {
        if (fetchedDataItem.id === rawDataItem.id && this.selectedUsers.indexOf(fetchedDataItem.id) >= 0) {
          fetchedDataItem.series.map(chartData => {
            const date = new Date(chartData.name);
            let dateformated;
            switch(this.splitBy) {
              case 'day':
                dateformated = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric'})
                break;
              case 'month':
                dateformated = date.toLocaleDateString('en-US', { month: 'short', year: 'numeric'})
                break;
              case 'year':
                dateformated = date.toLocaleDateString('en-US', { year: 'numeric'})
                break;
            }
            rawDataItem.series.map(chartDataResult=> {
              if (chartDataResult.name === dateformated) {
                chartDataResult.value = chartData.value;
              }
            })
          })
        }
      })
    })

    this.chartsConfig[chart] = [... this.chartsConfig[chart]]
  }

  prepareRawDataForCharts() {
    // Resetting all raw data
    for (const key in this.chartsConfig) {
      if (this.chartsConfig.hasOwnProperty(key))
          this.chartsConfig[key] = [];
    }
    // Setting all raw data
    this.users.forEach((user: UserType) => {
      if (this.selectedUsers.indexOf(user.userId)>=0) {
        for (const key in this.chartsConfig) {
          if (this.chartsConfig.hasOwnProperty(key)) {
            this.chartsConfig[key].push(this.initCharts(user));
            this.chartsConfig[key] = [... this.chartsConfig[key] ]
          }
        }
      }
    })
  }

  formatDate(day: Date, thisIsEndDate: boolean):string {
    // Copying date in order to prevent overwrite of this date for whole app
    const dateString = day.toLocaleDateString();
    const date = new Date(dateString);
    switch(this.splitBy) {
      case 'day':
        // for end date we add 1 day to get data including this day
        if (thisIsEndDate) {
          date.setDate(date.getDate() + 1);
          return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
        } else {
          return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
        }
      case 'month':
        // for HTTP request of end date we add 1 month to make sure we receive full data
        if (thisIsEndDate) {
          if (date.getMonth() === 11) {
            // Setting End Date as last day on the chosen end month in data pisker form for better UX
            const endDate = new Date(date.getFullYear(), date.getMonth()+1, 0);
            this.endDateRaw = `${endDate.getFullYear()}-${this.getMonthForDatePicker(endDate)}-${endDate.getDate()}`;

            // If we have 11th month it means we should step into new year
            date.setMonth(0);
            date.setFullYear(date.getFullYear() + 1);
          } else {
            // Setting End Date as last day on the chosen end month in data pisker form for better UX
            const endDate = new Date(date.getFullYear(), date.getMonth()+1, 0);
            this.endDateRaw = `${endDate.getFullYear()}-${this.getMonthForDatePicker(endDate)}-${endDate.getDate()}`;
          }
          // Setting End Date for HTTP request adding one extra month to get full data
          return `${date.getFullYear()}-${date.getMonth()+2}-01`
        } else {
          // Setting Start Date in data pisker form for better UX
          this.startDateRaw = `${date.getFullYear()}-${this.getMonthForDatePicker(date)}-01`;

          // Setting Start Date for HTTP request adding one extra month to get full data
          return `${date.getFullYear()}-${date.getMonth()+1}-01`;
        }
      case 'year':
        if (thisIsEndDate) {
          this.endDateRaw = `${date.getFullYear()}-12-31`;
          return `${date.getFullYear()+1}-01-01`
        } else {
          this.startDateRaw = `${date.getFullYear()}-01-01`;
          return `${date.getFullYear()}-01-01`;
        }
    }
  }

  getMonthForDatePicker(day: Date) {
    return ((day.getMonth()+1).toString().length === 1) ? '0' + (day.getMonth()+1): day.getMonth()+1;
  }

  checkDatesEqual(date1: Date, date2: Date) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
  }

  checkDatesEqualMonthYear(date1: Date, date2: Date) {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear()
  }

  checkDatesEqualYear(date1: Date, date2: Date) {
    return  date1.getFullYear() === date2.getFullYear()
  }

  onSelect(chart, event) {
    event.stopPropagation();
    this.charts.map((item) => {
      item.active = (chart.title === item.title) ? true : false;
    })
    this.mainChartData.title = chart.title;
    this.mainChartData.data = chart.data;
  }

  onlyActiveUsers() {
    this.selectedUsers = this.users.map((user: UserType) => {if (+user.accessLevel > 200) return user.userId});
    this.onlyActive = true;
  }

  toggleSelection() {
    if (this.allSelected) {
      this.selectedUsers = [];
    } else {
      this.selectedUsers = this.users.map((user: UserType) => {return user.userId})
    }
    this.allSelected = !this.allSelected;
    this.onlyActive = false;
  }
}
