"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebSearchItemsSharingController = void 0;
class WebSearchItemsSharingController {
    constructor(api, searchItemsSharingService) {
        this.api = api;
        this.searchItemsSharingService = searchItemsSharingService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('sharing/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsSharingService.mapSearchItemsSharings(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    read(id, successCallback, errorCallback) {
    }
    remove(id, successCallback, errorCallback) {
    }
    edit(searchItemSharing, successCallback, errorCallback) {
    }
    create(id, successCallback, errorCallback) {
        this.api.postRequest('sharing/create', { candidate_id: id }, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    changeStatus(id, statusId, successCallback, errorCallback) {
        this.api.postRequest('sharing/changeStatus', { id: id, status_id: statusId }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    statistic(checkedPeriod, successCallback, errorCallback) {
        this.api.postRequest('sharing/statistic', { checked_period: checkedPeriod }, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsSharingService.mapSearchItemSharingHistories(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    statisticForDatePicker(checkedPeriod, periodStartDate, periodEndDate, successCallback, errorCallback) {
        this.api.postRequest('sharing/statistic', {
            checked_period: checkedPeriod,
            period_end_date: periodEndDate,
            period_start_date: periodStartDate
        }, response => {
            if (response.data.valid === true)
                successCallback(this.searchItemsSharingService.mapSearchItemSharingHistories(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    sharingTypes(successCallback, errorCallback) {
        this.api.postRequest('types/sharingTypes', {}, response => {
            if (response.data.valid === true)
                successCallback(response.data.types);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback();
        });
    }
    find(searchItemSharing, successCallback, errorCallback) { }
    sort(searchItemSharings, hashtagField, desc, successCallback, errorCallback) { }
    sendRequestForShareFull(eventId, searchItemId, successCallback, errorCallback) {
        const src = { event_id: eventId, candidate_id: searchItemId };
        this.api.postRequest('sharing/sendRequestForShareFull', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    createRequest(payload, successCallback, errorCallback) {
        this.api.postRequest('sharing/createRequest', { data_item_id: payload.dataItemId, sharing_status: payload.sharingStatus, joborder_id: payload.joborderId }, res => {
            if (res.data.valid === true) {
                successCallback(res.data.valid);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    createHotCandidateRequest(payload, successCallback, errorCallback) {
        const src = { event_sub_type: payload.eventSubType, joborder_id: payload.joborderId, company_id: payload.companyId, data_item_id: payload.dataItemId };
        this.api.postRequest("hot-candidate/createRequest", src, res => {
            if (res.data.valid) {
                successCallback(res.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebSearchItemsSharingController = WebSearchItemsSharingController;
