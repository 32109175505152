import { Injector, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class QuickSearchService {

  private quickSearch = new BehaviorSubject<string>('');
  quickSearchObserver = this.quickSearch.asObservable();

  constructor() { }

  quickSearchChange(newState: string) {
    this.quickSearch.next(newState);
  }
}

