"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionService = void 0;
const condition_type_1 = require("../types/condition-type");
class ConditionService {
    constructor() { }
    mapReversCondition(src) {
        const result = {};
        if (src.description)
            result.description = src.description;
        if (src.fee)
            result.fee = src.fee;
        if (src.feeSign)
            result.fee_sign = src.feeSign;
        if (src.feeType)
            result.fee_type = src.feeType;
        if (src.id)
            result.id = src.id;
        if (src.joborderId)
            result.joborder_id = src.joborderId;
        if (src.owner)
            result.owner = src.owner;
        if (src.prepaymentsAmount)
            result.prepayments_amount = src.prepaymentsAmount;
        if (src.prepaymentsCurrency)
            result.prepayments_currency = src.prepaymentsCurrency;
        if (src.prepaymentsRequired)
            result.prepayments_required = src.prepaymentsRequired;
        if (src.returnPolicy)
            result.return_policy = src.returnPolicy;
        if (src.slas)
            result.slas = src.slas;
        if (src.status)
            result.status = src.status;
        if (src.submit)
            result.submit = src.submit;
        if (src.title)
            result.title = src.title;
        return result;
    }
    mapConditions(raw) {
        return raw.map((val) => this.mapCondition(val));
    }
    mapCondition(raw) {
        const result = new condition_type_1.Condition();
        result.description = raw.description;
        result.fee = raw.fee;
        result.feeSign = raw.fee_sign;
        result.feeType = raw.fee_type;
        result.id = raw.id;
        result.joborderId = raw.joborder_id;
        result.owner = raw.owner;
        result.prepaymentsAmount = raw.prepayments_amount;
        result.prepaymentsCurrency = raw.prepayments_currency;
        result.prepaymentsRequired = raw.prepayments_required;
        result.returnPolicy = raw.return_policy;
        result.slas = raw.slas;
        result.status = raw.status;
        result.submit = raw.submit;
        result.title = raw.title;
        return result;
    }
}
exports.ConditionService = ConditionService;
