<div class="main-table-wrap">

  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between">

        <ng-container *ngIf="search">
          <mat-form-field>
            <label>
              <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
            </label>
          </mat-form-field>
        </ng-container>
        
      </div>

      <mat-table mat-table [dataSource]="dataSource" matSort class="abstract-table">

        <ng-container *ngFor="let column of dataColumns; index as i;" [matColumnDef]='column.value'>

          <ng-container *ngIf="column.value === 'select'">
            <mat-header-cell mat-header-cell *matHeaderCellDef >
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let row" >
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </mat-cell>
          </ng-container>


          <ng-container *ngIf="column.value === 'actions'">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <ng-container *ngFor="let item of actionsOptions">
                <span [hidden]="item.ifVisible === 'true' ? false: (row[item.ifVisible] ? false: true)">
                  <button [matTooltip]="item.tooltip" mat-icon-button (click)="onActionsEvent(item.action, row)">
                    <mat-icon>{{item.icon}}</mat-icon>
                  </button>
                </span>
              </ng-container>
            </mat-cell>
          </ng-container>

          <ng-container>
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{column.name}}</mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="column.clicked && isClickabledRow ? onRowEvent(row): ''" 
                      [ngClass]="{'cursor-pointer': column.cursor && isClickabledRow ? true: false}">

              <ng-container *ngIf="column.field">
                <div [ngSwitch]="column.field">
                  <div *ngSwitchCase="'input'">
                    <mat-form-field appearance="outline">
                      <input type="text" (ngModelChange)="editedRow(row)" matInput [(ngModel)]="row[column.value]">
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'div'" [innerHTML]="setStyles(row[column.value])"></div>
                </div>
              </ng-container>

              <ng-container *ngIf="!column.field">
                <div [innerHTML]="setStyles(row[column.value])"></div>
              </ng-container>

            </mat-cell>
          </ng-container>

        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row  *matRowDef="let row; columns: displayedColumns; index as i" 
                  [cdkDetailRowShowed]="_showRowDetail" 
                  [cdkDetailRow]="row" 
                  [cdkDetailRowTpl]="contentDetailRow._results[i]" 
                  [style.backgroundColor]="row?.statusColor ? row.statusColor: ''">
        </mat-row>
      </mat-table>

      <!-- DETAIL ROW INFO -->
      <ng-content></ng-content>
      <!-- DETAIL ROW INFO -->

      <mat-card id="spinner" *ngIf="isLoading">
        <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
      </mat-card>

      <mat-paginator  *ngIf="tablePaginator"
                      [length]="paginationOptions.length" 
                      [pageIndex]="paginationOptions.pageIndex" 
                      [pageSize]="paginationOptions.pageSize" 
                      [pageSizeOptions]="paginationOptions.pageSizeOptions">
      </mat-paginator>

    </div>
  </div>
</div>