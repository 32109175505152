export class ClientType {
  names = {
    project: 'Recruiting',

    searchItem: 'Candidate',
    searchItems: 'Candidates',

    workItem: 'Joborder',
    workItems: 'Joborders',

    customer: 'Company',
    customers: 'Companies',

    hashtag: 'List',
    hashtags: 'Lists',

    contactItem: 'Contact',
    contactItems: 'Contacts'
  };

  searchItemNames = {
    address: 'Address',
    bestTimeToCall: 'Best time to call',
    canRelocate: 'Can relocate',
    candidateId: 'Id',
    city: 'City',
    currentEmployer: '',
    currentPay: '',
    dateAvailable: '',
    dateCreated: 'Date created',
    dateModified: 'Date modified',
    desiredPay: 'Desired pay',
    dialog: 'Dialog',
    eeoDisabilityStatus: '',
    eeoEthnicTypeId: '',
    eeoGender: '',
    eeoVeteranTypeId: '',
    email1: 'First email',
    email2: 'Second email',
    enteredBy: 'Entered by',
    firstName: 'First name',
    img: '',
    imgResize: '',
    importId: '',
    isActive: '',
    isAdminHidden: '',
    isHot: '',
    keySkills: 'Key skills',
    lastName: 'Last name',
    middleName: 'Middle Name',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Phone cell',
    phoneHome: 'Phone home',
    phoneWork: 'Phone work',
    siteId: '',
    source: 'Source',
    state: 'State',
    webSite: 'Web site',
    zip: ''
  }

  workItemItemNames = {
    id:'Id',
    alias: '',
    city: 'City',
    cityEn: '',
    cityRu: '',
    cityUa: '',
    clientJobId: '',
    companyDepartmentId: '',
    company: 'Company',
    contactId: '',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    duration: '',
    entereBy: '',
    isAdminHidden: '',
    isHot: '',
    joborderId: 'Id',
    notes: 'Notes',
    openings: '',
    openingsAvailable: '',
    owner: 'Owner',
    public: '',
    questionnaireId: '',
    rateMax: 'Max rate',
    recruiter: 'Recruiter',
    salary: 'Salary',
    seoDescriptionEn: '',
    seoKeywordsEn: '',
    seoTitleEn: '',
    siteId: '',
    startDate: '',
    state: 'State',
    status: 'Status',
    submit: 'Submit To',
    title: 'Title',
    type: 'Employment type'
  }

  workItemNotesNames = {
    workConditions: 'Work Conditions',
    contract: 'Contract',
    workingDay: 'Working Day',
    workScheduleName: 'Work schedule',
    workScheduleObject: {
      startEndWorkingDay: 'Start and End of working day',
      lunchBreak: 'Lanch break',
      freeDays: 'Free days'
    },
    businessTrip: 'Business trips',
    frequencyOfTrips: 'Frequency of trips',
    subordinates: 'Subordinates',
    subordinatesAmount: 'Subordinates Amount',
    growth: 'The possibility of professional growth',
    growthPossiblePosition: 'A possible position for growth',
    directManagerName: 'Direct manager',
    directManagerObject: {
      name: 'Direct manager name',
      position: 'Direct manager position',
      phone: 'Direct manager phone',
    },
    testPeriod: 'Test period',
    testPeriodInfo: 'Test period additional info',
    payment: 'Payment',
    paymentFrom: 'Payment From',
    paymentTo: 'Payment To',
    paymentSame: '',
    paymentProbationPeriod: 'Payment for a probation period',
    paymentProbationPeriodFrom: 'Payment From Probation Period',
    paymentProbationPeriodTo: 'Payment TO Probation Period',
    paymentBonuses: 'Bonuses',
    guarantees: 'Guarantees',
    socialBenefits: 'Social benefits',
    socialBenefitsList: 'Social benefits list',
    positionLocation: 'Location',
    relocationHelp: 'Bonus or help for relocation',
    relocationHelpInfo: 'Bonus or help for relocation additional info',
    requirementsFromCandidatesName: 'Requirements From Candidates',
    positionOpenInCountry : 'Position open in country',
    positionOpenInCity : 'Position open in city',
    requirementsFromCandidatesObject: {
      age: 'Age',
      cadidateSex: 'Sex',
      candidateEducation: 'Education',
      candidateDesiredDegree: 'Desired degree',
      combineWorkStudying: 'Possibility to combine work and studying',
      combineWorkStudyingInfo: 'Possibility to combine work and studying additional info',
      mainResponsibilities: 'Main responsibilities',
      desireWorkExp: 'Desired work experience',
      requiredSkills: 'Required skills',
      additionalSkills: 'Additional skills',
      managementExp: 'Management experience',
      managementExpInfo: 'Management experience additional info',
      foreignLanguages: 'Foreign languages',
      personalCharacteristic: 'Personal characteristic',
      residence: 'Residence',
      driverLicense: 'Driver license',
      driverLicenseType: 'Driver license type',
      ownVehicle: 'Own vehicle',
      maritalStatusKids: 'Marital status, kids'
    },
    informationForExecutantName: 'information For Executant',
    informationForExecutantObject: {
      dateOpeningPosition: 'Date of opening position',
      durationRequestExecution: 'Duration of request execution',
      enhancedPrivacy: 'Enhanced Privacy',
      enhancedPrivacyInfo: 'Enhanced Privacy additional info',
      reasonPositionOpening: 'Reason for position opening',
      necessarilyQuestion: 'Questions, which candidate should be able to answer',
      interviver: 'Who, how and where will interview candidate?',
      finalDecisionHiring: 'Who will make a final decision for hiring a candidate?',
      interviewStages: 'Interview stages for a candidate',
      particularPeopleHire: 'Are there some particular people you would like to hire?',
      particularPeopleHireInfo: 'List of particular people for hire',
      amountInterviewInScope: 'How many candidates did you already interview in the scope of this position?',
      otherRecruiterAgencies: 'Are there some other recruiting agencies working in this position?',
      nameOtherRecruiterAgencies: 'List of other recruiting agencies',
      someJobAds: 'Are there some job ads for this position?',
      whereSomeJobAds: 'The location of some job ads',
      publicLinkJobDescription: 'Public Link to Job Description (if exists)',
      shedleInterviewing: 'Desired Schedule for interviewing candidates',
      numberPeopleForHire: 'Number of people you want to hire for this position?',
      additionDesires: 'Additional desires',
      requiredDocForCandidate: 'Required documents for a candidate',
    }
  }

  customerNames = {
    address: 'Actual address',
    billingContact: '',
    city: 'City',
    companyId: '',
    companyLogo: '',
    dateCreated: 'Date Created',
    dateModified: 'Date Modified',
    defaultCompany: '',
    enteredBy: '',
    faxNumber: 'Fax Number',
    importId: '',
    isHot: '',
    keyTechnologies: '',
    name: '',
    notes: '',
    owner: '',
    phone1: 'Primary Phone',
    phone2: 'Secondary Phone',
    siteId: '',
    state: 'Country',
    url: '',
    zip: 'Postal Code'
  }

  customerNotesNames = {
    yearFaundation: 'The year of foundation',
    mainActivities: 'Main activities',
    clientsManagerObject: {
      name: 'Client\'s manager name',
      position: 'Client\'s manager position',
      phone: 'Client\'s manager phone'
    },
    contactPersonObject: {
      name: 'Contact person name',
      position: 'Contact person position',
      phone: 'Contact person phone',
    },
    numberEmployeesCompany: 'Number of employees in the company',
    numberEmployeesDepartment: 'Number of employees in the departament',
  }

  hashtagNames = {
    type: 'Data type',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    isDynamic: 'Dynamic',
    savedListId: 'Id',
    owner: 'Owner',
  }

  contactItemNames = {
    address: 'Address',
    city: 'City',
    companyDepartmentId: '',
    companyId: '',
    contactId: '',
    dateCreated: '',
    dateModified: '',
    email1: 'First email',
    email2: 'Second email',
    enteredBy: '',
    firstName: 'First name',
    importId: '',
    isHot: '',
    lastName: 'Last name',
    leftCompany: '',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Personal phone',
    phoneOther: 'Other phone',
    phoneWork: 'Work phone',
    reportsTo: '',
    siteId: '',
    state: 'State',
    title: 'Title',
    zip: ''
  }
}
