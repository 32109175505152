import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { MatSort, Sort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ContactItem } from 'core/dist/types/contact-item-type';
import {AppService} from "../../app-services/app.service";

@Component({
  selector: 'app-contact-items-table',
  templateUrl: './contact-items-table.component.html',
  styleUrls: ['./contact-items-table.component.scss']
})
export class ContactItemsTableComponent {
  public displayedColumns = ['contactId', 'firstName', 'lastName', 'title', 'email1', 'email2', 'phoneWork', 'owner', 'dateCreated', 'actions'];

  public dataSource: MatTableDataSource<ContactItem>;
  private paginator: MatPaginator;
  private sort: MatSort;
  private sortState: Sort = {active: 'contactId', direction: 'desc'}

  @Input() public set data(data: ContactItem[]) {
    this.applyDataSource(data);
  }

  @Input() isLoading:boolean

  @Output() onEmailSendEvent = new EventEmitter<ContactItem>(); 
  @Output() onViewContactEvent = new EventEmitter<ContactItem>();
  @Output() onDeleteContactEvent = new EventEmitter<ContactItem>();

  constructor(
    public app: AppService
  ) {}


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: ContactItem[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(data.length) {
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit(this.sortState);
    }
  }

  onEmailSend(contact: ContactItem) {
    this.onEmailSendEvent.emit(contact);
  }

  onViewContact(contact: ContactItem) {
    this.onViewContactEvent.emit(contact);
  }

  onDeleteContact(contact: ContactItem) {
    this.onDeleteContactEvent.emit(contact);
  }

}
