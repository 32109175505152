import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { SearchItemSharingHistoryType } from "../../app-services/core.service";

@Component({
  selector: 'app-shared-search-items-table',
  templateUrl: './shared-search-items-table.component.html',
  styleUrls: ['./shared-search-items-table.component.scss']
})
export class SharedSearchItemsTableComponent {
  public displayedColumns = ['dataShared', 'candidate', 'previousOwner', 'newOwnerFullName', 'sharedBy'];

  public dataSource: MatTableDataSource<SearchItemSharingHistoryType>;
  private paginator: MatPaginator;
  private sort: MatSort;


  @Input() public set data(data: SearchItemSharingHistoryType[]) {
    this.applyDataSource(data);
  }

  @Output() onRemoveTemplateDialogEvent = new EventEmitter<SearchItemSharingHistoryType>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: SearchItemSharingHistoryType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
