import {Component, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YhCore, WorkItemType, CustomerType } from '../../app-services/core.service';

@Component({
  selector: 'app-search-item-add-to-pipeline-dialog',
  templateUrl: 'search-item-add-to-pipeline-dialog.component.html',
})
export class SearchItemAddToPipelineDialogComponent implements OnInit {
  public workItems: WorkItemType[];

  constructor(
    public dialogRef: MatDialogRef<SearchItemAddToPipelineDialogComponent>) { }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onJobOrderClick(jobOrder: WorkItemType): void {
    this.dialogRef.close(jobOrder);
  }

  ngOnInit(): void {
    YhCore.workItems.list((workItems: WorkItemType[]) => {
      this.workItems = workItems;
      YhCore.customers.list((customers: CustomerType[]) => {
        customers.map(customer => {
          this.workItems.map((item) => {
            if (customer.companyId === item.companyId) {
              item.companyName = customer.name;
            }
          })
        })
      }, err => {})
    }, error => {})
  }
}
