<div>
  <mat-form-field>
    <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
  </mat-form-field>

  <div>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header data-label="id">Id</mat-header-cell>
        <mat-cell *matCellDef="let row" data-label="id">{{row.id}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>NAME</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="countJobOrder">
        <mat-header-cell *matHeaderCellDef mat-sort-header>OFFERS</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.countJobOrder}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="state">
        <mat-header-cell *matHeaderCellDef mat-sort-header>STATE</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.state}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CITY</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.city}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="enteredByUserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>ENTERED BY</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.enteredByUserName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="ownerUserName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>OWNER</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.ownerUserName}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>CREATED</mat-header-cell>
        <mat-cell *matCellDef="let row">{{row.dateCreated}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectCompany(row)"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
