<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle>Shared</mat-card-subtitle>
    <!-- Fixing min height issue -->
    <div class="empty-space"></div>
  </mat-card>
  <mat-card class="main-content">
    <mat-card-content>
      <mat-tab-group animationDuration="0ms">
          <mat-tab label="Shared" >
              <mat-button-toggle-group class="shared-date-wrap" appearance="legacy" class="shared-search-items" value="2">
                  <mat-button-toggle #day value="2" (click)="getDate(day.value)" >DAY</mat-button-toggle>
                  <mat-button-toggle #week value="3" (click)="getDate(week.value)">WEEK</mat-button-toggle>
                  <mat-button-toggle #month value="4" (click)="getDate(month.value)">MONTH</mat-button-toggle>
                </mat-button-toggle-group>
              <div class="data-piker-form">
                  <mat-form-field appearance="outline" class="datapicker-shared-search-items" >
                      <mat-label>Choose a first date</mat-label>
                      <input matInput #firstDatePicker [matDatepicker]="picker"
                                      name="firstDate"> 
                      <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle>
                      <mat-datepicker #picker ></mat-datepicker>   
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="datapicker-shared-search-items">
                      <mat-label>Choose a second date</mat-label> 
                      <input matInput #secondDatePiker [matDatepicker]="picker2"     
                                      name="secondDate">
                      <mat-datepicker-toggle matSuffix [for]="picker2" ></mat-datepicker-toggle>
                      <mat-datepicker #picker2></mat-datepicker>
                  </mat-form-field>
                  <button mat-button class="period-button-datepicker" (click)='getDatepickerData()'>Period</button> 
              </div>
              <app-shared-search-items-table [data]="searchItemSharingHistories">
              </app-shared-search-items-table>
          </mat-tab>
          <mat-tab label="Request For Share" class="request-for-share-wrap">
              <mat-card-subtitle class="title-shared">
                  Users
              </mat-card-subtitle >
              <mat-button-toggle-group multiple class="reques-for-share shared-search-items-user"  appearance="legacy">
                  <mat-button-toggle *ngFor='let item of nameButtons' (click)='filterRequestUserSharedItems(item.enteredBy)'  class="shared-button-selected">
                      {{item.enteredBy}}</mat-button-toggle>
              </mat-button-toggle-group>

              <mat-card-subtitle class="title-shared">
                  Statuses
              </mat-card-subtitle >
              <mat-button-toggle-group multiple class="reques-for-share shared-search-items-request"  appearance="legacy" value="1">
              <mat-button-toggle *ngFor='let item of requestButtons' [value]="item.id" (click)='filterRequestSharedItems(item.id)' class="shared-button-selected"> 
                      {{item.description}}</mat-button-toggle>
              </mat-button-toggle-group>

              <mat-card-subtitle class="title-shared">
                  Operation with chekced rows
              </mat-card-subtitle>
              <mat-button-toggle-group class="shared-search-items-request-apply-denied"  appearance="legacy">
                  <mat-button-toggle (click)='applyAllCheckedData()' class="shared-apply">APPLY</mat-button-toggle>
                  <mat-button-toggle (click)='deniedAllCheckedData()' class="shared-denied">DENIED</mat-button-toggle>
              </mat-button-toggle-group>

              <app-request-for-share-search-items-table #requestShareSearchItemsTable [data]='searchItemSharings'
                                                        (onClickAllowEvent)='onClickAllow($event)'
                                                        (onClickDeniedEvent)='onClickDenied($event)'>
              </app-request-for-share-search-items-table>
          </mat-tab>
      </mat-tab-group>
    </mat-card-content>
  </mat-card>
  </div>
</app-navigation>
