<header>
  <h2 mat-dialog-title>Contact</h2>
  <button mat-icon-button color="primary" *ngIf="!editMode && !savingMode" (click)="startEditMode()" matTooltip="Edit"><mat-icon>edit</mat-icon></button>
  <button mat-icon-button color="primary" *ngIf="editMode || savingMode" (click)="updateContact()" [disabled]="savingMode" matTooltip="Save"><mat-icon>save</mat-icon></button>
</header>
<div mat-dialog-content>
  <form [formGroup]="contactItemForm">
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['firstName'] }}</mat-label>
      <input matInput formControlName="firstName" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['lastName'] }}</mat-label>
      <input matInput formControlName="lastName" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['title'] }}</mat-label>
      <input matInput formControlName="title" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['phoneWork'] }}</mat-label>
      <input matInput formControlName="phoneWork" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['phoneCell'] }}</mat-label>
      <input matInput formControlName="phoneCell" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['phoneOther'] }}</mat-label>
      <input matInput formControlName="phoneOther" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['email1'] }}</mat-label>
      <input matInput formControlName="email1" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['email2'] }}</mat-label>
      <input matInput formControlName="email2"[readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['state'] }}</mat-label>
      <input matInput formControlName="state" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['city'] }}</mat-label>
      <input matInput formControlName="city" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['address'] }}</mat-label>
      <input matInput formControlName="address" [readonly]="!editMode">
    </mat-form-field>
    <mat-form-field appearance="outline" [class.non-active]="!editMode">
      <mat-label>{{ app.type.contactItemNames['notes'] }}</mat-label>
      <textarea matInput formControlName="notes" [readonly]="!editMode"></textarea>
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onClose()">Close</button>
</div>
