<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-content>
        <button (click)="openDialogCreateHashtag()" mat-flat-button color="primary">Create {{ app.type.names['hashtag'] }}</button>
      </mat-card-content>
    </mat-card>
    <mat-card class="main-content">
      <mat-card-content>
        <app-hashtags-table #hashtagsTable [data]="hashtags" [isLoading]="isLoadingHashtags" (hashtagSelectEvent)="goToSearchItemsList($event)" (onRemoveHashtagEvent)="onRemoveHashtag($event)"></app-hashtags-table>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>
