import { Component, OnInit, ViewChild } from '@angular/core';
import { Company } from "../app-services/company.type";
import { Router } from "@angular/router";
import { CompanyService } from "../app-services/company.service";
import { YhCore } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';

@Component({
  selector: 'app-customers-page',
  templateUrl: './customers-page.component.html',
  styleUrls: ['./customers-page.component.scss']
})
export class CustomersPageComponent implements OnInit {
  @ViewChild('companiesTableComponent') companiesTableComponent;

  public companies=[]=[];

  constructor(
    private companyService: CompanyService,
    public router: Router,
    public app: AppService
  ) { }

  ngOnInit() {
    YhCore.customers.list(res => {
      this.companies = res;
    }, err => {})
    // this.companyService.getCompanies().subscribe(res => {
    //   this.companies = res;
    // })
  }

  navigateToCompany(company) {
    this.router.navigate([this.app.type.names['customers'].toLowerCase(), company.companyId]);
  }
  
}
