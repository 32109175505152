"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebShareNormsController = void 0;
class WebShareNormsController {
    constructor(api, shareNormsService) {
        this.api = api;
        this.shareNormsService = shareNormsService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('sharingNorms/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.shareNormsService.mapShareNorms(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('sharingNorms/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.shareNormsService.mapShareNorm(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('sharingNorms/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    edit(sharingNorm, successCallback, errorCallback) {
        const src = this.shareNormsService.mapReverseShareNorms(sharingNorm);
        this.api.postRequest('sharingNorms/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    create(sharingNorm, successCallback, errorCallback) {
        const src = this.shareNormsService.mapReverseShareNorms(sharingNorm);
        this.api.postRequest('sharingNorms/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    find(sharingNorm, successCallback, errorCallback) { }
    sort(sharingNorm, searchItemField, desc, successCallback, errorCallback) { }
}
exports.WebShareNormsController = WebShareNormsController;
