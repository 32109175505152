"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessengerService = void 0;
const message_type_1 = require("../types/message-type");
class MessengerService {
    constructor() { }
    mapReverseMessages(src) {
        const result = {};
        if (src.subject)
            result.subject = src.subject;
        if (src.to)
            result.to = src.to;
        if (src.message)
            result.message = src.message;
        if (src.joborderId)
            result.workitem_id = src.joborderId;
        if (src.searchitemId)
            result.searchitem_id = src.searchitemId;
        return result;
    }
    mapMessages(raw) {
        const result = [];
        for (const value in raw) {
            result.push(this.mapMessage(raw[value]));
        }
        return result;
    }
    mapMessage(raw) {
        const result = new message_type_1.Message();
        result.subject = (raw.subject && raw.subject.length) ? this.decode(raw.subject.replace('=?UTF-8?B?', '').replace('?=', '')) : '';
        result.from = raw.from;
        result.to = raw.to;
        result.date = raw.date;
        result.messageId = raw.message_id;
        result.size = raw.size;
        result.uid = raw.uid;
        result.msgno = raw.msgno;
        result.recent = raw.recent;
        result.flagged = raw.flagged;
        result.answered = raw.answered;
        result.deleted = raw.deleted;
        result.seen = raw.seen;
        result.draft = raw.draft;
        result.udate = raw.udate;
        result.message = (raw.message && raw.message.length) ? this.decode(raw.message) : '';
        result.message = result.message.replace(/<br ?\/?>/g, "\n");
        return result;
    }
    decode(str) {
        function b64_to_utf8(str) {
            try {
                return decodeURIComponent(escape(window.atob(str)));
            }
            catch (error) {
                return '';
            }
        }
        const decodedStr = b64_to_utf8(str);
        if (decodedStr) {
            return decodedStr;
        }
        else {
            return str;
        }
    }
}
exports.MessengerService = MessengerService;
