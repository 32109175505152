import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserType, YhCore } from '../app-services/core.service';

@Injectable({
  providedIn: 'root'
})
export class MyProfileGuard implements CanActivate {

  constructor(
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.checkValid()
  }

  checkValid() {
    const user:UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'))
    if(+user.accessLevel < 400) {
      this.snackBar.open('Access denied by ACL rules', 'OK', {duration: 3000});
      this.router.navigateByUrl("/")
      return false;
    }
    return true;
  }
  
}
