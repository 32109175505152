import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-work-item-submit-table',
  templateUrl: './work-item-submit-table.component.html',
  styleUrls: ['./work-item-submit-table.component.scss']
})
export class WorkItemSubmitTableComponent implements OnInit {

  public displayedColumns = ['select', 'partner', 'url', 'email'];
  public dataSource: MatTableDataSource<any>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: any[]) {
    this.applyDataSource(data);
  }

  @Input('selection') public selection = new SelectionModel<any>(true, []);

  @Input() isLoading: boolean;
  @Output() deleteStatus = new EventEmitter<any>();
  @Output() editStatus = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

}
