import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { YhCore, TemplateType } from '../../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-template-dialog',
  templateUrl: './create-template-dialog.component.html',
  styleUrls: ['./create-template-dialog.component.scss']
})
export class CreateTemplateDialogComponent implements OnInit {

  public templateDialog: TemplateType = new TemplateType();
  public exampleBodyPlaceholder: string = 'Example: \nHey %contact_name%, \nHow are you? We have a new offer "%offer_name%" for you from company %company_name%. \nHave a great day!'
  public templateVariables: string[] = ['contact_name', 'offer_name', 'company_name']
  constructor(
    public dialogRef: MatDialogRef<CreateTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {}

  dialogSubmit() {
    YhCore.templates.create(this.templateDialog, success => {
      this.snackBar.open('Template was successfully saved', 'OK', {
        duration: 3000,
      })
      this.dialogRef.close();
    }, error => { this.snackBar.open(error, 'ERROR', {duration: 5000}) })
  }

  setVariable(value: string) {
    const val = ` %${value}%`;
    this.templateDialog.body = this.templateDialog.body ? this.templateDialog.body + val : val;
  }
}
