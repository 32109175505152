"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MatrixUsersService = void 0;
const matrix_field_search_item_1 = require("../types/matrix-field-search-item");
const matrix_users_type_1 = require("../types/matrix-users-type");
class MatrixUsersService {
    constructor() { }
    mapReverseMatrixUser(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        if (src.ownerId)
            result.owner_id = src.ownerId;
        if (src.subUserId)
            result.sub_user_id = src.subUserId;
        if (src.access)
            result.access = src.access;
        return result;
    }
    mapReverseMatrixUserCreate(src) {
        const result = {};
        if (src.ownerId)
            result.owner_id = src.ownerId;
        if (src.subUserId)
            result.sub_user_id = src.subUserId;
        if (src.access)
            result.access = src.access;
        return result;
    }
    mapMatrixUsers(raw) {
        return raw.map((val) => this.mapMatrixUser(val));
    }
    mapMatrixUser(raw) {
        const result = new matrix_users_type_1.MatrixUsers();
        result.id = raw.id;
        result.ownerId = raw.owner_id;
        result.subUserId = raw.sub_user_id;
        result.access = raw.access;
        return result;
    }
    mapReverseMatrixFieldsSearchItems(raw) {
        return raw.map(val => this.mapReverseMatrixFieldsSearchItem(val));
    }
    mapReverseMatrixFieldsSearchItem(raw) {
        const result = {};
        if (raw.companyId)
            result.company_id = raw.companyId;
        if (raw.accessLevel)
            result.access_level = raw.accessLevel;
        if (raw.fieldName)
            result.field_name = raw.fieldName;
        result.is_extra_field = raw.isExtraField;
        result.extra_filed_settings_id = raw.extraFiledSettingsId;
        return result;
    }
    matMatrixFieldsSearchItems(raw) {
        return raw.map(val => this.matMatrixFieldsSearchItem(val));
    }
    matMatrixFieldsSearchItem(raw) {
        const result = new matrix_field_search_item_1.MatrixFieldSearchItem();
        result.id = raw.id;
        result.companyId = raw.company_id;
        result.accessLevel = raw.access_level;
        result.fieldName = raw.field_name;
        result.isExtraField = raw.is_extra_field;
        result.extraFiledSettingsId = raw.extra_filed_settings_id;
        return result;
    }
}
exports.MatrixUsersService = MatrixUsersService;
