<app-navigation>
  <mat-card>
    <mat-card-subtitle>
      {{app.type.names['customer']}} information
    </mat-card-subtitle>
    <mat-card-content>
      <button mat-stroked-button color="primary" *ngIf="!isEditMode" (click)="isEditMode = !isEditMode" style="color:#fff; background: #3f51b5;;">EDIT</button>
      <button mat-stroked-button color="primary" *ngIf="isEditMode" (click)="updateCompany()" style="color:#fff; background: #008a00;">SAVE</button>
      <button mat-stroked-button color="warn" (click)="deleteCompany()" style="color:#fff; background: #d51727;">DELETE</button>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-content>
      <div>
        <div>
          <mat-list [class.no-underline]="!isEditMode">
            <mat-list-item>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Name: </mat-label>
                <input matInput [(ngModel)]="company.name" [readonly]="!isEditMode">
              </mat-form-field>
            </mat-list-item>
           <mat-list-item *ngIf="isRecrutingMode">
             <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Primary Phone: </mat-label>
               <input matInput [(ngModel)]="company.phone1" [readonly]="!isEditMode">
             </mat-form-field>
           </mat-list-item>
           <mat-list-item *ngIf="isRecrutingMode">
             <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Secondary Phone: </mat-label>
               <input matInput [(ngModel)]="company.phone2" [readonly]="!isEditMode">
             </mat-form-field>
           </mat-list-item>
           <mat-list-item *ngIf="isRecrutingMode">
             <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Fax Number: </mat-label>
               <input matInput [(ngModel)]="company.faxNumber" [readonly]="!isEditMode">
             </mat-form-field>
           </mat-list-item>
           <mat-list-item *ngIf="isRecrutingMode">
             <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>Address: </mat-label>
               <input matInput [(ngModel)]="company.address" [readonly]="!isEditMode">
             </mat-form-field>
           </mat-list-item>
          </mat-list>
        </div>
        <div class="col-md-6">
          <mat-list [class.no-underline]="!isEditMode">
            <mat-list-item>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Created: </mat-label>
                <input matInput [(ngModel)]="company.dateCreated" [readonly]="!isEditMode">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Owner: </mat-label>
                <input matInput [(ngModel)]="company.owner" [readonly]="!isEditMode">
              </mat-form-field>
            </mat-list-item>
           <mat-list-item *ngIf="isRecrutingMode">
             <mat-form-field appearance="outline" floatLabel="always">
               <mat-label>City: </mat-label>
               <input matInput [(ngModel)]="company.city" [readonly]="!isEditMode">
             </mat-form-field>
           </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Entered By: </mat-label>
                <input matInput [(ngModel)]="company.enteredBy" [readonly]="!isEditMode">
              </mat-form-field>
            </mat-list-item>
            <mat-list-item>
              <mat-form-field appearance="outline" floatLabel="always">
                <mat-label>Notes: </mat-label>
                <input matInput [(ngModel)]="company.notes" [readonly]="!isEditMode">
              </mat-form-field>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-subtitle>
      Offers
    </mat-card-subtitle>
    <mat-card-content>
      <app-work-items-table #jobOrdersTableComponent [data]="companyDetail.companyJobOrders" (onJobOrderSelect)="navigateToJobOrder($event)"></app-work-items-table>
    </mat-card-content>
  </mat-card>
</app-navigation>
