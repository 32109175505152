"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebReportsController = void 0;
class WebReportsController {
    constructor(api, reportsService) {
        this.api = api;
        this.reportsService = reportsService;
    }
    addedByUser(period, successCallback, errorCallback) {
        const src = this.reportsService.mapReversePeriod(period);
        this.api.postRequest('reports/addCandidatesUsers', src, response => {
            if (response.data.valid === true)
                successCallback(this.reportsService.mapReports(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    submittedByUser(period, successCallback, errorCallback) {
        const src = this.reportsService.mapReversePeriod(period);
        this.api.postRequest('reports/submittedCandidates', src, response => {
            if (response.data.valid === true)
                successCallback(this.reportsService.mapReports(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    contactedByUser(period, successCallback, errorCallback) {
        const src = this.reportsService.mapReversePeriod(period);
        this.api.postRequest('reports/addedActivityByUser', src, response => {
            if (response.data.valid === true)
                successCallback(this.reportsService.mapReports(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    offeredByUser(period, successCallback, errorCallback) {
        const src = this.reportsService.mapReversePeriod(period);
        this.api.postRequest('reports/offeredCandidates', src, response => {
            if (response.data.valid === true)
                successCallback(this.reportsService.mapReports(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    activitiesByUser(period, successCallback, errorCallback) {
        const src = this.reportsService.mapReversePeriod(period);
        this.api.postRequest('reports/addedActivityByUser', src, response => {
            if (response.data.valid === true)
                successCallback(this.reportsService.mapReports(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
}
exports.WebReportsController = WebReportsController;
