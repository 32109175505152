import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { filter, map, startWith } from 'rxjs/operators';
import { SearchItemType, WorkItemType, YhCore } from '../app-services/core.service';

@Component({
  selector: 'app-active-poll-page',
  templateUrl: './active-poll-page.component.html',
  styleUrls: ['./active-poll-page.component.scss']
})
export class ActivePollPageComponent implements OnInit {

  public skillControll: FormControl = new FormControl('');
  public selectedSkills: string[];
  public skills: {id: string, name:string}[] = [];
  public skillsFiltered: {id: string, name:string}[] = [];
  public searchItemsActivePoll: SearchItemType[];
  public isLoading: boolean = false;
  public joborderControl: FormControl = new FormControl('');
  public joberderList: WorkItemType[] = [];
  private matDialogJoborder: MatDialogRef<TemplateRef<any>>;

  @ViewChild('dialogJoborder') private dialogJoborder: TemplateRef<any>;
  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getData();
    this.subscribeControllerSkills();
  }

  subscribeControllerSkills() {
    this.skillControll.valueChanges.pipe(startWith(''),map(value => this._filterSkills(value))).subscribe(res => this.skillsFiltered = res)
  }

  private _filterSkills(value:string):{id: string, name:string}[]{
    return this.skills.filter(val => val.name.toLowerCase().includes(value.toLowerCase()))
  }

  getData() {
    this._setLoading()
    this.getColumnPreferences().then( async res => {
      this.selectedSkills = this.parseColumnPreferences(res);
      this.searchItemsActivePoll = this.mapSettingsActivePollCandidates(await this.getSearchItemActivityPoll(this._getActivePollPayload));
      this._resetLoading();
    })
    this.getSkills().then( res => this.skills = this.skillsFiltered = res);
    this.getJobOrder().then( res => this.joberderList = res)
  }

  getJobOrder():Promise<WorkItemType[]>{
    return new Promise( (resolve, reject) => YhCore.workItems.list(res => resolve(res), err => reject(err)));
  }

  getSkills():Promise<{id: string, name:string}[]> {
    return new Promise( (resolve, reject) => YhCore.types.getSkills(res => resolve(res), err => reject(err)))
  }

  getSearchItemActivityPoll(data: {field: string, values: string[]}):Promise<SearchItemType[]> {
    return new Promise( (resolve, reject) => YhCore.searchItems.getActiveCandidatePoll(data, res => resolve(res), err => reject(err)))
  }

  getColumnPreferences():Promise<{columnPreferences: string}> {
    return new Promise( (resolve, reject) => YhCore.users.getColumnPreferences(res => resolve(res), err => reject(err)))
  }

  saveColumnPreferences(data: string, successStr: string):Promise<any> {
    return new Promise( (resolve, reject) => YhCore.users.saveColumnPreferences({columnPreferences: data}, res => (this._successHandler(successStr),resolve(res)), err => reject(err)))
  }

  createRequestSharing(eventSubType:number, dataItemId:number, joborderId: number, companyId:number){
    return new Promise( (resolve, reject) => YhCore.searchItemSharing.createHotCandidateRequest({eventSubType, joborderId, companyId, dataItemId}, res => resolve(res), err => reject(err)))
  }

  parseColumnPreferences(data: {columnPreferences: string}):string[] {
    try {
      if(data.columnPreferences) {
        const res: {skills: string[]} = JSON.parse(data.columnPreferences);
        return res.skills;
      }
      return [];
    } catch (err) {
      console.error(err)
    }
  }

  async removeSkill(skillName: string) {
    this.selectedSkills = this.selectedSkills.filter( val => val.toLowerCase() !== skillName.toLowerCase());
    this._successUpdate('Skill was successfully deleted')
  }

  async selectSkills(name:string) {
    this.selectedSkills = [...new Set([...this.selectedSkills, name])];
    this._successUpdate('Skill was successfully added')
    this.skillControll.setValue('');
  }

  selectJoborder(item: WorkItemType) {
    this.matDialogJoborder.close(item);
  }

  private mapSettingsActivePollCandidates(searchItems: SearchItemType[]) {
    return searchItems.map( item => {
      if(item.settings) {
        try {
          const tags:IHotCandidateTag[] = JSON.parse(item.settings).tags;
          tags instanceof Array ? item['tags'] = tags: item['tags'] = [];
          item['reserved'] = tags.find( val => val.value === 'reserved') ? true: false;
        } catch (err) {
          item['tags'] = [];
        }
      } else {
        item['tags'] = [];
      }
      return item;
    })
  }

  public onRequestDetailsEvent(searchItems: SearchItemType) {
    this.matDialogJoborder = this.dialog.open(this.dialogJoborder, {width: "600px"});
    this.matDialogJoborder.backdropClick().subscribe( _ => this.matDialogJoborder.close(false));
    this.matDialogJoborder.afterClosed().pipe(filter( val => val !== false)).subscribe( async (item:WorkItemType) => {
      const res =  await this.createRequestSharing(2, +searchItems.dataItemId, +item.joborderId, +item.companyId).catch( err => this._errorHandler(err.error_msg));
      res ? this._successHandler('Request for details was successfully created'): ''
    })
  }

  public onReserveEvent(searchItems: SearchItemType) {
    this.createRequestSharing(3, +searchItems.dataItemId, null, null).then( res => {
      if(res) {
        this._successHandler('Request for reserve was successfully created')
      }
    }).catch( err => this._errorHandler(err.error_msg))
  }

  private async _successUpdate(msg: string) {
    this._setLoading()
    this.searchItemsActivePoll = await this.getSearchItemActivityPoll(this._getActivePollPayload)
    await this.saveColumnPreferences(this._getStringSkills, 'Skill was successfully added');
    this._resetLoading()
  }
  private get _getStringSkills() { return JSON.stringify({skills: this.selectedSkills})}
  private get _getActivePollPayload():{field: string, values: string[]} { return {field: 'key_skills', values: this.selectedSkills} }
  private _successHandler(str: string) {this.snackBar.open(str, 'OK', {duration: 3000})}
  private _errorHandler(err: string) {this.snackBar.open(err, 'ERROR', {duration: 5000})}
  private _setLoading() { this.isLoading = true}
  private _resetLoading() { this.isLoading = false}
}


export interface ISettingsHotCandidate{
  tags: IHotCandidateTag[],
}

export interface IHotCandidateTag {
  id: number,
  name: string, 
  icon: string, 
  active: number,
  value: string,
  color: string
}
