<app-navigation>
  <mat-card class="main-content matrix-field" *ngIf="!loading; else spinner">
    <mat-card-title style="margin-bottom: 20px;" class="flex flex-align-center">Matrix Fields Search Item</mat-card-title>

    <mat-card-content> 
      <form class="form"[formGroup]="fieldsForm" (ngSubmit)="onSaveForm()">
    
        <mat-form-field appearance="outline" class="width-100" >
          <mat-label>Select Access Level</mat-label>
          <mat-select (selectionChange)="changeAccessLevel()" formControlName="accessLevel">
            <mat-option value="200">Access Level 200</mat-option>
            <mat-option value="300">Access Level 300</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-button-toggle-group multiple="true" formControlName="fields">
          <mat-button-toggle *ngFor="let item of fields" [value]="item.fieldName" [attr.data-value]="item.fieldName">{{item.fieldName}}</mat-button-toggle>
        </mat-button-toggle-group>

        <button mat-flat-button color="primary" [disabled]="!fieldsForm.valid" type="submit">SAVE</button>
      </form>
    </mat-card-content>
  </mat-card>
</app-navigation>


<ng-template #spinner>
  <mat-card id="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
</ng-template>
