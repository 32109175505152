<h1 mat-dialog-title style="text-align: center;">Write some comment</h1>
<div mat-dialog-content>

  <form (ngSubmit)="onSave()" #form="ngForm">

    <mat-form-field appearance="outline" floatLabel="always" style="width: 100%;">
      <mat-label>Comment</mat-label>
      <input matInput [formControl]="comment">
    </mat-form-field>

    <button mat-flat-button type="reset" color="basic" (click)="onClose()">Close</button>
    <button mat-flat-button type="submit" color="primary" [disabled]="comment.invalid">OK</button>
  </form>

</div>