import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { CreateHashtagDialogComponent } from '../app-dialogs/create-hashtag-dialog/create-hashtag-dialog.component';
import { AppService } from '../app-services/app.service';
import { YhCore, HashtagType, UserType } from '../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-hashtags-page',
  templateUrl: './hashtags-page.component.html',
  styleUrls: ['./hashtags-page.component.scss']
})
export class HashtagsPageComponent implements AfterViewInit {

  @ViewChild('hashtagsTable') hashtagsTable;

  hashtags: HashtagType[] = [];
  isLoadingHashtags =  true;
  users: UserType[] = [];
  currentUser = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'))

  constructor(
    public app: AppService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  async ngAfterViewInit() {
    await this.onListHashtags();
  }

  onListHashtags() {
    return new Promise(resolve => {
      this.isLoadingHashtags = true;
      YhCore.hashtags.list(async hashtags => {
        this.users = await this.getUsers();
        this.mapHashTags(hashtags)
        this.hashtags = hashtags;
        this.hashtagsTable.applyDataSource(this.hashtags);
        this.isLoadingHashtags = false;
        resolve();
      }, err => {
        this.isLoadingHashtags = false;
      })
    })
  }

  getUsers(): Promise<UserType[]> {
    return new Promise<UserType[]>(resolve => {
      YhCore.users.list(users => {
        resolve(users);
      }, err => {})
    })
  }

  onRemoveHashtag(hashtag: HashtagType) {

    if (hashtag.createdBy !== this.currentUser.userId && this.currentUser.accessLevel < 400) {
      this.snackBar.open(`Forbidden. You are not an owner of this ${this.app.type.names.hashtag.toLocaleLowerCase()}`,
      'OK', {duration: 3000});
      return;
    }

    const proceed = confirm(`Are you sure you want to delete ${this.app.type.names.hashtag.toLocaleLowerCase()} ${hashtag.description}?`);

    if (proceed) {
      YhCore.hashtags.remove(+hashtag.savedListId, async () => {
        await this.onListHashtags();
        this.snackBar.open('Reques delete', 'OK', {duration: 5000})
      }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000}) })
    }
  }

  openDialogCreateHashtag() {
    const dialogRef = this.dialog.open(CreateHashtagDialogComponent, {
      width: ' 60vw'
    })

    dialogRef.afterClosed().subscribe(async valid => {
      if (valid)
        await this.onListHashtags();
    });
  }


  goToSearchItemsList(row: HashtagType) {
    if (row.isDynamic === '0' && this.currentUser.accessLevel < 400 && this.currentUser !== row.createdBy) {
      this.snackBar.open(`Forbidden. This is private ${this.app.type.names.hashtag.toLocaleLowerCase()}`,
      'OK', {duration: 3000});
      return;
    }

    this.router.navigate([this.app.type.names['hashtags'].toLowerCase(), row.savedListId]).then();
  }

  mapHashTags(hashtags: HashtagType[]) {
    this.users.map(user => {
      hashtags.map(hashtag => {
        if (user.userId === hashtag.createdBy) {
          hashtag.owner = `${user.firstName} ${user.lastName}`
        }
      })
    })
  }
}
