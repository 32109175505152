"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebWorkItemsController = void 0;
class WebWorkItemsController {
    constructor(api, workItemsService, customersService, conditionsService, workItemNotesService) {
        this.api = api;
        this.workItemsService = workItemsService;
        this.customersService = customersService;
        this.conditionsService = conditionsService;
        this.workItemNotesService = workItemNotesService;
    }
    // Works with ER Api - YHclient
    list(successCallback, errorCallback) {
        this.api.postRequest('workItem/list', {}, response => {
            if (response.data.valid) {
                successCallback(this.workItemsService.mapWorkItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient
    read(joborderId, successCallback, errorCallback) {
        this.api.postRequest('workItem/read', { id: joborderId }, response => {
            if (response.data.valid === true) {
                successCallback([this.workItemsService.mapWorkItem(response.data.data), this.workItemNotesService.mapWorkItemNotes(JSON.parse(response.data.data.notes))]);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('workItem/delete', { id: id }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    edit(workItem, workItemNotes, successCallback, errorCallback) {
        const src = this.workItemsService.mapReverseWorkItem(workItem);
        src.notes = JSON.stringify(this.workItemNotesService.mapReverseWorkItemNotes(workItemNotes));
        this.api.postRequest('workItem/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    editWorkItem(workItem, successCallback, errorCallback) {
        const src = this.workItemsService.mapReverseWorkItem(workItem);
        this.api.postRequest('workItem/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    create(workItem, workItemNotes, successCallback, errorCallback) {
        const src = this.workItemsService.mapReverseWorkItem(workItem);
        src.notes = JSON.stringify(this.workItemNotesService.mapReverseWorkItemNotes(workItemNotes));
        this.api.postRequest('workItem/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    submit(joborderId, successCallback, errorCallback) {
        this.api.postRequest('workItem/submit', {
            joborder_id: joborderId,
            partners: [{ partner_id: "33" }]
        }, response => {
            if (response.data.valid === true)
                successCallback(response.data.valid);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    conditionsList(joborderId, successCallback, errorCallback) {
        this.api.postRequest('condition/list', {
            joborder_id: joborderId,
        }, response => {
            if (response.data.valid === true)
                successCallback(this.conditionsService.mapConditions(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    conditionsAccept(conditionId, successCallback, errorCallback) {
        this.api.postRequest('condition/accepted', {
            condition_id: conditionId,
        }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // Works with ER Api - YHclient
    conditionsDeclineAll(conditionIds, message, successCallback, errorCallback) {
        this.api.postRequest('condition/decline', {
            conditions_id: conditionIds,
            message
        }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    find(workItem, successCallback, errorCallback) { }
    sort(workItems, workItemField, desc, successCallback, errorCallback) { }
    listPipeline(id, successCallback, errorCallback) {
        successCallback([
            {
                candidate_first_name: "Alex",
                candidate_id: "707",
                candidate_joborder_id: "560",
                candidate_last_name: "Bogosta",
                city: "",
                date_created: "2019-12-09 23:56:41",
                date_modified: "2019-12-09 23:58:48",
                entered_by_first_name: "Andrey",
                entered_by_last_name: "Matey",
                last_activity: "2019-12-09 23:58:48 (Andrey Matey) Teest",
                status_color: "#2561c1",
                status_short_description: "Contacted",
            },
            {
                candidate_first_name: "Liuba",
                candidate_id: "800",
                candidate_joborder_id: "443",
                candidate_last_name: "Lozynska",
                city: null,
                date_created: "2019-07-16 17:34:05",
                date_modified: "2019-07-16 17:34:05",
                entered_by_first_name: "Andrey",
                entered_by_last_name: "Matey",
                last_activity: "2019-07-16 17:34:05 (Andrey Matey) Added candidate to pipeline.",
                status_color: "#5d56c8",
                status_short_description: "No Contact",
            }
        ]);
    }
    findSearchItemsBySkills(from, limit, skillStr, successCallback, errorCallback) {
        this.api.postRequest('workItem/findSearchItemsBySkills', { from, limit, skill_str: skillStr }, (response) => {
            if (response.data.valid === true) {
                successCallback(this.workItemsService.mapWorkItemsFindSearchItemsBySkills(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    loadWorkItemsSkills(ids, successCallback, errorCallback) {
        this.api.postRequest('workItem/loadWorkItemsSkills', { workItemsArray: ids }, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemsService.mapLoadWorkItemsSkills(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getAccessItem(id, successCallback, errorCallback) {
        this.api.postRequest('workItem/getAccessItem', { work_item_id: id }, response => {
            if (response.data.valid === true) {
                successCallback(this.customersService.mapCustomersAccessItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    setAccessItem(id, data, successCallback, errorCallback) {
        const src = this.customersService.mapReversAccessChecked(data);
        this.api.postRequest('workItem/setAccess', { work_item_id: id, data_checked_for_access: src }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    listFromClient(successCallback, errorCallback) {
        this.api.postRequest('workItem/listFromClient', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemsService.mapWorkItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    readFromClient(workItemId, successCallback, errorCallback) {
        this.api.postRequest('workItem/readFromClient', { joborder_id: workItemId }, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemsService.mapWorkItem(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
}
exports.WebWorkItemsController = WebWorkItemsController;
