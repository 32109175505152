"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItemNotesService = void 0;
const workItem_notes_type_1 = require("../types/workItem-notes-type");
class WorkItemNotesService {
    constructor() {
    }
    mapReverseWorkItemNotes(src) {
        const result = {
            working_day: src.workingDay,
            contract: src.contract,
            work_schedule: {
                star_end_working_day: src.startEndWorkingDay,
                lunch_break: src.lunchBreak,
                free_days: src.freeDays,
            },
            business_trip: src.businessTrip,
            frequency_of_trips: src.frequencyOfTrips,
            subordinates: src.subordinates,
            subordinates_amount: src.subordinatesAmount,
            growth: src.growth,
            growth_possible_position: src.growthPossiblePosition,
            direct_manager: {
                name: src.name,
                position: src.position,
                phone: src.phone,
            },
            test_period: src.testPeriod,
            test_period_info: src.testPeriodInfo,
            payment_from: src.paymentFrom,
            payment_to: src.paymentTo,
            payment_same: src.paymentSame,
            payment_probation_period: src.paymentProbationPeriod,
            payment_probation_period_from: src.paymentProbationPeriodFrom,
            payment_probation_period_to: src.paymentProbationPeriodTo,
            payment_bonuses: src.paymentBonuses,
            guarantees: src.guarantees,
            social_benefits: src.socialBenefits,
            social_benefits_list: src.socialBenefitsList,
            position_location: src.positionLocation,
            relocation_help: src.relocationHelp,
            relocation_help_info: src.relocationHelpInfo,
            requirements_from_candidates: {
                age: src.age,
                cadidate_sex: src.cadidateSex,
                candidate_education: src.candidateEducation,
                candidate_desired_degree: src.candidateDesiredDegree,
                combine_work_studying: src.combineWorkStudying,
                combine_work_studying_info: src.combineWorkStudyingInfo,
                main_responsibilities: src.mainResponsibilities,
                desired_work_exp: src.desireWorkExp,
                required_skills: src.requiredSkills,
                additional_skills: src.additionalSkills,
                management_exp: src.managementExp,
                management_exp_info: src.managementExpInfo,
                foreign_languages: src.foreignLanguages,
                personal_characteristic: src.personalCharacteristic,
                residence: src.residence,
                driver_license: src.driverLicense,
                driver_license_type: src.driverLicenseType,
                own_vehicle: src.ownVehicle,
                marital_status_kids: src.maritalStatusKids,
            },
            information_for_executant: {
                date_opening_position: src.dateOpeningPosition,
                duration_request_execution: src.durationRequestExecution,
                enhanced_privacy: src.enhancedPrivacy,
                enhanced_privacy_info: src.enhancedPrivacyInfo,
                reason_position_opening: src.reasonPositionOpening,
                necessarily_question: src.necessarilyQuestion,
                interviver: src.interviver,
                final_decision_hiring: src.finalDecisionHiring,
                interview_stages: src.interviewStages,
                particular_people_hire: src.particularPeopleHire,
                particular_people_hire_info: src.particularPeopleHireInfo,
                amount_interview_in_scope: src.amountInterviewInScope,
                other_recruiter_agencies: src.otherRecruiterAgencies,
                name_other_recruiter_agencies: src.nameOtherRecruiterAgencies,
                some_job_ads: src.someJobAds,
                where_some_job_ads: src.whereSomeJobAds,
                public_link_job_description: src.publicLinkJobDescription,
                shedle_interviewing: src.shedleInterviewing,
                number_people_for_hire: src.numberPeopleForHire,
                addition_desires: src.additionDesires,
                required_doc_for_candidate: src.requiredDocForCandidate
            }
        };
        return result;
    }
    mapWorkItemNotes(raw) {
        const result = new workItem_notes_type_1.WorkItemNotes();
        result.contract = raw.contract;
        result.workingDay = raw.working_day;
        result.startEndWorkingDay = raw.work_schedule.star_end_working_day;
        result.lunchBreak = raw.work_schedule.lunch_break;
        result.freeDays = raw.work_schedule.free_days;
        result.businessTrip = raw.business_trip;
        result.frequencyOfTrips = raw.frequency_of_trips;
        result.subordinates = raw.subordinates;
        result.subordinatesAmount = raw.subordinates_amount;
        result.growth = raw.growth;
        result.growthPossiblePosition = raw.growth_possible_position;
        result.name = raw.direct_manager.name;
        result.position = raw.direct_manager.position;
        result.phone = raw.direct_manager.phone;
        result.testPeriod = raw.test_period;
        result.testPeriodInfo = raw.test_period_info;
        result.payment = raw.payment_from + '-' + raw.payment_to;
        result.paymentFrom = raw.payment_from;
        result.paymentTo = raw.payment_to;
        result.paymentSame = raw.payment_same;
        result.paymentProbationPeriod = raw.payment_probation_period_from + '-' + raw.payment_probation_period_to;
        result.paymentProbationPeriodFrom = raw.payment_probation_period_from;
        result.paymentProbationPeriodTo = raw.payment_probation_period_to;
        result.paymentBonuses = raw.payment_bonuses;
        result.guarantees = raw.guarantees;
        result.socialBenefits = raw.social_benefits;
        result.socialBenefitsList = raw.social_benefits_list;
        result.positionLocation = raw.position_location;
        result.relocationHelp = raw.relocation_help;
        result.relocationHelpInfo = raw.relocation_help_info;
        result.age = raw.requirements_from_candidates.age;
        result.cadidateSex = raw.requirements_from_candidates.cadidate_sex;
        result.candidateEducation = raw.requirements_from_candidates.candidate_education;
        result.candidateDesiredDegree = raw.requirements_from_candidates.candidate_desired_degree;
        result.combineWorkStudying = raw.requirements_from_candidates.combine_work_studying;
        result.combineWorkStudyingInfo = raw.requirements_from_candidates.combine_work_studying_info;
        result.mainResponsibilities = raw.requirements_from_candidates.main_responsibilities;
        result.desireWorkExp = raw.requirements_from_candidates.desired_work_exp;
        result.requiredSkills = raw.requirements_from_candidates.required_skills;
        result.additionalSkills = raw.requirements_from_candidates.additional_skills;
        result.managementExp = raw.requirements_from_candidates.management_exp;
        result.managementExpInfo = raw.requirements_from_candidates.management_exp_info;
        result.foreignLanguages = raw.requirements_from_candidates.foreign_languages;
        result.personalCharacteristic = raw.requirements_from_candidates.personal_characteristic;
        result.residence = raw.requirements_from_candidates.residence;
        result.driverLicense = raw.requirements_from_candidates.driver_license;
        result.driverLicenseType = raw.requirements_from_candidates.driver_license_type;
        result.ownVehicle = raw.requirements_from_candidates.own_vehicle;
        result.maritalStatusKids = raw.requirements_from_candidates.marital_status_kids;
        result.dateOpeningPosition = raw.information_for_executant.date_opening_position;
        result.durationRequestExecution = raw.information_for_executant.duration_request_execution;
        result.enhancedPrivacy = raw.information_for_executant.enhanced_privacy;
        result.enhancedPrivacyInfo = raw.information_for_executant.enhanced_privacy_info;
        result.reasonPositionOpening = raw.information_for_executant.reason_position_opening;
        result.necessarilyQuestion = raw.information_for_executant.necessarily_question;
        result.interviver = raw.information_for_executant.interviver;
        result.finalDecisionHiring = raw.information_for_executant.final_decision_hiring;
        result.interviewStages = raw.information_for_executant.interview_stages;
        result.particularPeopleHire = raw.information_for_executant.particular_people_hire;
        result.particularPeopleHireInfo = raw.information_for_executant.particular_people_hire_info;
        result.amountInterviewInScope = raw.information_for_executant.amount_interview_in_scope;
        result.otherRecruiterAgencies = raw.information_for_executant.other_recruiter_agencies;
        result.nameOtherRecruiterAgencies = raw.information_for_executant.name_other_recruiter_agencies;
        result.someJobAds = raw.information_for_executant.some_job_ads;
        result.whereSomeJobAds = raw.information_for_executant.where_some_job_ads;
        result.publicLinkJobDescription = raw.information_for_executant.public_link_job_description;
        result.shedleInterviewing = raw.information_for_executant.shedle_interviewing;
        result.numberPeopleForHire = raw.information_for_executant.number_people_for_hire;
        result.additionDesires = raw.information_for_executant.addition_desires;
        result.requiredDocForCandidate = raw.information_for_executant.required_doc_for_candidate;
        return result;
    }
}
exports.WorkItemNotesService = WorkItemNotesService;
