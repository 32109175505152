import { Injectable } from '@angular/core';
import { SalesType } from './sales.type';
import { RecruitingType } from './recruiting.type';
import { ClientType } from './client.type';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  salesType = new SalesType();
  recruitingType = new RecruitingType();
  clientType = new ClientType();

  isSales = false;
  isRecruiting = false;
  isClient = true;

  type: ClientType;

  constructor() { this.setType(this.clientType) }

  setType(type) {
    this.type = type;
  }

}
