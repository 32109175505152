<div>
    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
    </mat-form-field>
    <div>
        <mat-table [dataSource]="dataSource" matSort style="width: 100%">
            <ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{disCol}}">
                <mat-header-cell *matHeaderCellDef mat-sort-header>{{disCol}}</mat-header-cell>
                <mat-cell *matCellDef="let element "> {{element[disCol]}} </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
    <button (click)="deleteFollowUp()" mat-button style="background: #d51727; color: #fff;">DELETE</button>
</div>