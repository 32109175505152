<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>
      <ng-container *ngIf="dataSource.data.length; else noData">
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row["name"] }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="relevant">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Relevant</mat-header-cell>
            <mat-cell *matCellDef="let row">{{ row["relevant"] }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5,10,25,50]"></mat-paginator>
      </ng-container>
      <ng-template #noData> No data available in table</ng-template>
    </div>
  </div>
</div>