import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { YhApiBaseService } from './yh-api-base.service';

@Injectable({
  providedIn: 'root'
})
export class YhApiService {

  constructor(private base: YhApiBaseService) { }


  validate(val: any): any {
    // if (val.redirectLink === '../login-page/login-page.html') {
    //   this.auth.invalidate();
    //   throw new Error('Login expired');
    // }

    return val;
  }

  request<T>(method: string, body: any): Observable<T> {
    // const authCookie = this.auth.getCookie();
    // body.authCookie = authCookie;
    return this.base.request<T>(method, body).pipe(map((val: T) => this.validate(val)));
  }
}

export class ResponseBase {
  errorMsg: boolean;
  redirectLink: string;
}
