"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtraFieldsService = void 0;
const extra_field_type_1 = require("../types/extra-field-type");
class ExtraFieldsService {
    constructor() { }
    mapReverseExtraFields(src) {
        return src.map((val) => this.mapReverseExtraField(val));
    }
    mapReverseFieldConfigforEdit(src) {
        const result = {};
        result.id = src.id;
        result.position_recruiting = src.positionRecruiting;
        result.position_sales = src.positionSales;
        if (src.extraFieldType)
            result.extra_field_type = src.extraFieldType;
        if (src.fieldName)
            result.field_name = src.fieldName;
        result.columns_recruiting = src.columnsRecruiting;
        result.columns_sales = src.columnsSales;
        return result;
    }
    mapReverseFieldConfig(src) {
        const result = {};
        if (src.id)
            result.id = src.id;
        result.position = '';
        result.position_recruiting = src.positionRecruiting;
        result.position_sales = src.positionSales;
        result.is_sales = src.isSales;
        result.is_recruiting = src.isRecruiting;
        result.data_item_type = '100';
        result.extra_field_options = '';
        result.extra_field_settings_id = src.extraFieldSettingsId;
        result.extra_field_type = src.extraFieldType;
        result.extra_field_value = '';
        result.field_name = src.fieldName;
        result.import_id = '';
        result.site_id = '';
        result.extra_field_options_array = [''];
        result.extra_field_type_name = '';
        result.read_only = src.readOnly;
        result.date_created = '';
        result.is_extra_field = src.isExtraField;
        result.columns_recruiting = src.columnsRecruiting;
        result.columns_sales = src.columnsSales;
        return result;
    }
    mapReverseExtraField(src) {
        const result = {};
        result.data_item_type = src.dataItemType;
        result.extra_field_options = src.extraFieldOptions;
        if (src.extraFieldSettingsId)
            result.extra_field_settings_id = src.extraFieldSettingsId;
        result.extra_field_type = src.extraFieldType;
        result.extra_field_value = src.extraFieldValue;
        result.field_name = src.fieldName;
        return result;
    }
    mapExtraFields(raw) {
        return raw.map((val) => this.mapExtraField(val));
    }
    mapExtraField(raw) {
        const result = new extra_field_type_1.ExtraField();
        result.dataItemType = raw.data_item_type;
        result.dateCreated = raw.date_created;
        result.extraFieldOptions = raw.extra_field_options;
        result.extraFieldSettingsId = raw.extra_field_settings_id;
        result.extraFieldType = raw.extra_field_type;
        result.extraFieldValue = raw.extra_field_value;
        result.fieldName = raw.field_name;
        result.importId = raw.import_id;
        result.position = raw.position;
        result.siteId = raw.site_id;
        result.extraFieldOptionsArray = raw.extra_field_options_array;
        result.extraFieldTypeName = raw.extra_field_type_name;
        result.isExtraField = raw.is_extra_field;
        result.readOnly = raw.read_only;
        result.id = raw.id;
        result.isRecruiting = raw.is_recruiting;
        result.isSales = raw.is_sales;
        result.positionRecruiting = raw.position_recruiting;
        result.positionSales = raw.position_sales;
        result.columnsRecruiting = raw.columns_recruiting;
        result.columnsSales = raw.columns_sales;
        return result;
    }
}
exports.ExtraFieldsService = ExtraFieldsService;
