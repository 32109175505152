import { Candidate } from './../../app-services/candidate.type';
import { User } from './../../app-services/user.type';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Router } from "@angular/router";
import { AppService } from "../../app-services/app.service";
import { CalendarType, YhCore, localStorageService, WorkItemType } from './../../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export interface DialogData {
  isEditMode: string;
  calendarEvent: any;
}

@Component({
  selector: 'app-calendar-handle-event-dialog',
  templateUrl: 'calendar-handle-event-dialog.component.html',
})
export class CalendarHandleEventDialogComponent implements OnInit {
  calendarEvent: CalendarType = new CalendarType();
  isAllDayEvent: any = false;
  minute: string;
  hour: any;
  meridiem: string;
  dateForDatePicker: any;
  user: User = new User();
  jobOrder: WorkItemType = new WorkItemType();
  candidate: Candidate = new Candidate();

  getLocalUser = JSON.parse(localStorageService.getItem('YHminiuser'));

  constructor(
    public app: AppService,
    public dialogRef: MatDialogRef<CalendarHandleEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public datePipe: DatePipe,
    private router: Router,
    private snackBar:MatSnackBar
  ) { }

  getDataForHeader(user, workItem = '-1', searchItem = '-1') {
    YhCore.users.read(user, res => { this.user = { ...res } }, err => { })
    YhCore.workItems.read(workItem, res => { this.jobOrder = { ...res } }, err => { })
    YhCore.searchItems.read(searchItem, res => { this.candidate = { ...res } }, err => { })
  }

  ngOnInit(): void {

    if (this.data.calendarEvent) {
      this.calendarEvent = this.data.calendarEvent;
      this.isAllDayEvent = this.calendarEvent.allDay;
      let arr: any = this.calendarEvent.date.replace(/[ :]+/g, "-");
      arr = arr.split('-');
      arr[1] -= 1;
      this.dateForDatePicker = new Date(arr[0], arr[1], arr[2], 0, 0, 0, 0);
      if (arr[3] <= 12) {
        let hour = arr[3];
        if (arr[3][0] == 0) {
          hour = hour.slice(1)
        }
        this.hour = hour;
        this.meridiem = "AM";
      }
      else {
        arr[3] -= 12;
        this.hour = arr[3].toString();
        this.meridiem = "PM";
      }
      this.minute = arr[4]
      this.getDataForHeader(+this.calendarEvent.enteredBy, this.calendarEvent.joborderId, this.calendarEvent.dataItemId)
    }
  }

  convertDate() {
    this.calendarEvent.date = this.datePipe.transform(this.dateForDatePicker, 'yyyy-MM-dd');
    if (this.meridiem == "PM") {
      this.hour = 12 + +this.hour;
    }
    this.calendarEvent.date += " " + `${this.hour}` + ":" + `${this.minute}` + ":00"
  }

  onCreate(): void {
    this.convertDate();

    this.calendarEvent.enteredBy = this.getLocalUser.userId;
    YhCore.calendar.create(this.calendarEvent, suc => {
      this.snackBar.open('Event create', 'OK', {duration: 5000})
     }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000}) });
    this.dialogRef.close(false);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onEdit(): void {
    this.convertDate();
    if (this.calendarEvent.allDay) {
      this.calendarEvent.allDay = "1"
    }
    else {
      this.calendarEvent.allDay = "0"
    }

    YhCore.calendar.edit(this.calendarEvent, suc => {
      this.snackBar.open('Event edit', 'OK', {duration: 5000})
     }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000}) });
    this.dialogRef.close(false);
  }

  onDelete(): void {
    YhCore.calendar.remove(+this.calendarEvent.calendarEventId, suc => {
      this.snackBar.open('Event delete', 'OK', {duration: 5000})
     }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000})})
    this.dialogRef.close(false);
  }

  onCandidateClick(): void {
    this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), this.calendarEvent.dataItemId])
    this.dialogRef.close();
  }

  onJobOrderClick(): void {
    this.router.navigate([this.app.type.names['workItems'].toLowerCase(), this.calendarEvent.joborderId])
    this.dialogRef.close();
  }

  switchAllDay() {
    this.isAllDayEvent = !this.isAllDayEvent;
  }
}
