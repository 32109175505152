<h2 mat-dialog-title>{{ creationMode ? "Create User": "Edit User" }}</h2>
<div mat-dialog-content>
  <form class="form" [formGroup]="userForm" *ngIf="userForm;else spinner">
    
    <mat-form-field appearance="outline" class="width-100">
      <mat-label>First Name:*</mat-label>
      <input matInput formControlName="firstName">
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Last Name:*</mat-label>
      <input matInput formControlName="lastName">
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Email:*</mat-label>
      <input matInput formControlName="email">
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Login:*</mat-label>
      <input matInput formControlName="login">
    </mat-form-field>

    <mat-form-field appearance="outline" class="width-100">
      <mat-label>Password :*</mat-label>
      <input matInput type="password" formControlName="password">
    </mat-form-field>

    <ng-container *ngIf="+currentUser.accessLevel >= 400">
      <mat-form-field appearance="outline" class="width-100">
        <mat-label>Group User :*</mat-label>
        <mat-select formControlName="groupUserId">
          <mat-option *ngFor="let item of groupUsers" [value]="item.id">{{item.title}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" class="width-100">
        <mat-label>Admin :*</mat-label>
        <mat-select formControlName="accessLevel">
          <mat-option value="300">Yes</mat-option>
          <mat-option value="200">No</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    
    <div mat-dialog-actions>
      <button mat-raised-button (click)="onCancel()">Close</button>
      <button mat-raised-button color="primary" [disabled]="!userForm.valid" (click)="onSave()">Save</button>
    </div>

  </form>
</div>

<ng-template #spinner>
  <mat-card id="spinner" *ngIf="isLoading">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
</ng-template>
