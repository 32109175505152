"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CalendarService = void 0;
const calendar_type_1 = require("./../types/calendar-type");
class CalendarService {
    constructor() { }
    mapReverseCalendarItem(src) {
        const result = {};
        if (src.calendarEventId)
            result.id = src.calendarEventId;
        if (src.type)
            result.type = src.type;
        if (src.title)
            result.title = src.title;
        if (src.duration)
            result.duration = src.duration;
        if (src.allDay)
            result.all_day = src.allDay;
        if (src.date)
            result.date = src.date;
        if (src.description)
            result.description = src.description;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        if (src.dataItemId)
            result.data_item_id = src.dataItemId;
        if (src.joborderId)
            result.joborder_id = src.joborderId;
        return result;
    }
    mapCalendarItems(raw) {
        return raw.map((val) => this.mapCalendarItem(val));
    }
    mapCalendarItem(raw) {
        const result = new calendar_type_1.Calendar();
        result.reminderEnabled = raw.reminder_enabled;
        result.reminderTime = raw.reminder_time;
        result.reminderEmail = raw.reminder_email;
        result.duration = raw.duration;
        result.dateModified = raw.date_modified;
        result.description = raw.description;
        result.joborderId = raw.joborder_id;
        result.dateCreated = raw.date_created;
        result.enteredBy = raw.entered_by;
        result.dataItemType = raw.data_item_type;
        result.allDay = raw.all_day;
        result.dataItemId = raw.data_item_id;
        result.title = raw.title;
        result.date = raw.date;
        result.type = raw.type;
        result.calendarEventId = raw.calendar_event_id;
        result.public = raw.public;
        return result;
    }
}
exports.CalendarService = CalendarService;
