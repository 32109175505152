"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebCustomersController = void 0;
class WebCustomersController {
    constructor(api, customersService, workItemService) {
        this.api = api;
        this.customersService = customersService;
        this.workItemService = workItemService;
    }
    // Works with ER Api - YHclient
    list(successCallback, errorCallback) {
        this.api.postRequest('customer/list', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.customersService.mapCustomers(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient - Removing client profile
    read(companyId, successCallback, errorCallback) {
        this.api.postRequest('customer/read', { company_id: companyId }, response => {
            if (response.data.valid === true) {
                successCallback(this.customersService.mapCustomer(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient - Removing client profile
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('customer/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient - Removing client profile
    edit(customer, successCallback, errorCallback) {
        const src = this.customersService.mapReverseCustomer(customer);
        this.api.postRequest('customer/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    // Works with ER Api - YHclient - Removing client profile
    create(customer, successCallback, errorCallback) {
        const src = this.customersService.mapReverseCustomer(customer);
        this.api.postRequest('customer/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.company_id);
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    find(customer, successCallback, errorCallback) { }
    sort(customers, customerField, desc, successCallback, errorCallback) { }
    getAccessItem(id, successCallback, errorCallback) {
        this.api.postRequest('/customer/getAccessItem', { customer_id: id }, response => {
            if (response.data.valid === true) {
                successCallback(this.customersService.mapCustomersAccessItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    setAccessItem(id, data, successCallback, errorCallback) {
        const src = this.customersService.mapReversAccessChecked(data);
        this.api.postRequest('/customer/setAccess', { customer_id: id, data_checked_for_access: src }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    listWorkItem(id, successCallback, errorCallback) {
        this.api.postRequest('/customer/listWorkItems', { customer_id: id }, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemService.mapWorkItems(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    listCompanyAndJobordersFromClient(successCallback, errorCallback) {
        this.api.postRequest('/customer/listCompanyAndJobordersFromClient', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.customersService.mapListCustomerAndJoborders(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
}
exports.WebCustomersController = WebCustomersController;
