<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
      <mat-card-subtitle>Template Dialog Settings</mat-card-subtitle>
      <mat-card-content>
        <button mat-flat-button color="primary" (click)="openDialogAddNew()">ADD NEW</button>
      </mat-card-content>
    </mat-card>
    <mat-card class="main-content">
      <mat-card-content>
        <app-templates-table [data]="templateDialogs" (onRemoveTemplateDialogEvent)="onRemoveTemplateDialog($event)"></app-templates-table>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>

