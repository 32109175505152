import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ExtraFieldService } from '../app-services/extra-field.service';
import { ExtraField } from '../app-services/extra-field.type';
import { YhCore, SearchItemType, CustomerType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-search-items-page',
  templateUrl: './search-items-page.component.html',
  styleUrls: ['./search-items-page.component.scss']
})
export class SearchItemsPageComponent implements AfterViewInit {
  @ViewChild('candidatesTableComponent') candidatesTableComponent;

  candidates: SearchItemType[] = [];
  allCandidates: SearchItemType[] = [];
  columnFilters: string[] = ['candidateId', 'firstName', 'lastName', 'city', 'state', 'keySkills', 'owner', 'dateCreated', 'dateModified'];
  columnFiltersAll: string[] = ['candidateId', 'firstName', 'lastName', 'email1', 'city', 'state', 'keySkills', 'owner', 'dateCreated', 'dateModified'];
  candidateFilters: CandidateFilter[] = [];
  extraFields: ExtraField[];
  columnFilterBoolean = 'or';
  isFiltersColumns = false;
  isFilters = false;
  isFreeCandidates = false;
  isMyCandidates = false;
  isLoadingCandidates =  true;
  currentUser = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));
  companyId: string;

  entriesCount = 0;
  entriesStep = 1000;
  totalSearchItems = 0;

  constructor(
    private extraFieldService: ExtraFieldService,
    public router: Router,
    public app: AppService
  ) { }

  ngAfterViewInit() {
    this.getCandidates();
    this.getCompany();
  }

  getCandidates() {
    // Some comment here
    this.isLoadingCandidates = true;
    YhCore.searchItems.list(0,this.entriesStep, data => {
      const fields = Object.keys(data.candidates[0]);
      this.columnFilters = this.columnFilters.filter(val => fields.includes(val));
      this.candidates = data.candidates;
      this.totalSearchItems = data.totalSearchItems;
      this.allCandidates = data;
      this.isLoadingCandidates = false
      }, error => this.isLoadingCandidates = false
    )
  }

  getCompany() {
    YhCore.customers.list( (res: CustomerType[]) => {
      this.companyId = res[0].companyId
    }, err => {})
  }

  getExtraFields() {
    const getExtraFields = this.extraFieldService.getExtraFields();

    getExtraFields.extraFields.subscribe(ex => {
      this.extraFields = ex;
    });
  }

  navigateToCandidate(row: SearchItemType) {
    this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), row.candidateId]);
  }

  onCreateLead() {
    this.router.navigate([this.app.type.names['searchItems'].toLowerCase(), 'create-'.concat(this.app.type.names['searchItem'].toLowerCase())])
  }

  onAddNewFilter() {
    this.candidateFilters.push(new CandidateFilter());
  }

  onRemoveAllFilters() {
    this.candidateFilters = [];
    this.getCandidates();
  }

  onApplyFilters() {
    this.candidateFilters.map(f => {
      f.column_filter_boolean = this.columnFilterBoolean;
    })

    this.getCandidates();
  }

  onFreeCandidates() {
    this.isFreeCandidates = !this.isFreeCandidates;
    this.getCandidates();
  }

  onMyCandidates() {
    this.isMyCandidates = !this.isMyCandidates;
    if(this.isMyCandidates) {
      let myCandidates;
      myCandidates = this.candidates.filter( val => {
        if(val.owner === this.currentUser.userId){
          return val
        }
      })
      this.candidates = myCandidates;
    } else {
      this.candidates = this.allCandidates
    }
  }

  onEntriesChange() {
    this.isLoadingCandidates = true;
    YhCore.searchItems.list(this.entriesCount,this.entriesStep, data => {
      this.candidates = data;
      this.isLoadingCandidates = false
      }
      , error => {
        this.isLoadingCandidates = false
      })
  }

  toggleFilterColumns() {
    this.isFiltersColumns = !this.isFiltersColumns;

    // If filter columns as switched on, we should check if we have cached columns preferences for this user
    if (this.isFiltersColumns) this.setFilterColumns();
  }

  setFilterColumns() {
    const cachedColumns = JSON.parse(YhCore.localStorageService.getItem('columnFilters'));

    // If there is cahced value, we set active columns according to users previous preferences
    if (cachedColumns) {
      this.columnFilters = cachedColumns;
    }
  }

  onColumnsChange() {
    // Caching user preferences for future use
    YhCore.localStorageService.setItem('columnFilters', JSON.stringify(this.columnFilters));
  }
}

export class CandidateFilter {
  value: string;
  column: string;
  operator: string = '==';
  column_filter_boolean: string;
  data_table_id: string = '1';
}
