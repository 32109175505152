import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort, Sort} from "@angular/material/sort";
import { SearchItemType } from '../../app-services/core.service'
import {AppService} from "../../app-services/app.service";
import { YhCore } from '../../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 'app-search-items-table',
  templateUrl: './search-items-table.component.html',
  styleUrls: ['./search-items-table.component.scss']
})
export class SearchItemsTableComponent implements OnInit {

  public displayedColumns = ['candidateId', 'firstName', 'lastName', 'email1', 'city', 'state', 'keySkills', 'owner', 'dateCreated', 'dateModified'];
  public displayedColumnsAll = ['candidateId', 'firstName', 'lastName', 'email1', 'city', 'state', 'keySkills', 'owner', 'dateCreated', 'dateModified'];
  public dataSource: MatTableDataSource<SearchItemType> = new MatTableDataSource<SearchItemType>();
  private paginator: MatPaginator;
  private sort: MatSort;
  private sortState: Sort = {active: 'candidateId', direction: 'desc'}
  currentUser = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'));

  public selection = new SelectionModel<SearchItemType>(true, []);

  constructor(
    public app: AppService,
    private snackBar: MatSnackBar
  ) {}

  @Input() public set data(data: SearchItemType[]) {
    this.applyDataSource(data);
  }

  @Input() public set columns(columns: string[]) {
    // filtering columns to preserve their initial order
    this.filterColumnsToDisplay(columns)
  }

  @Input() loading: boolean;

  @Input() isLoading: boolean

  @Output() onCandidateSelect = new EventEmitter<SearchItemType>();
  @Output() removeRelationEvent = new EventEmitter<SearchItemType>();
  @Output() loadingEvent = new EventEmitter<boolean>();

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  filterColumnsToDisplay(filter: string[]) {
    this.displayedColumns = this.displayedColumnsAll.filter(column => {
      if (filter.indexOf(column) >= 0) {
        return column;
      }
    })
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applySearchFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  applyDataSource(data: SearchItemType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if(data.length) {
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit(this.sortState);
    }
  }

  selectCandidate(row: SearchItemType) {
    this.onCandidateSelect.emit(row);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  removeRelation(row: SearchItemType) {
    if (this.currentUser.accessLevel > 300 || this.currentUser.userId === row.owner) {
      const proceed = confirm(`Are you sure you want to delete this ${this.app.type.names.searchItem.toLocaleLowerCase()} ${row.firstName} ${row.lastName}?`);

      if (proceed) {
        row.deleting = true;
        this.loading = true;
        this.loadingEvent.emit(true)
        this.removeRelationEvent.emit(row)
      }
    } else {
      this.snackBar
      .open(`Only ${this.app.type.names.searchItem.toLocaleLowerCase()} owner can perform this action`, 'OK', {duration: 3000});
    }

  }

  ngOnInit() {
    const cachedColumns = JSON.parse(YhCore.localStorageService.getItem('columnFilters'));

    // If there is cahced value, we set active columns according to users previous preferences
    if (cachedColumns) {
      this.filterColumnsToDisplay(cachedColumns);
    }
  }

}
