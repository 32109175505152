<app-navigation>
    <div class="wrapper-profile">
        <mat-card>
            <mat-card-content *ngIf="company">
                <h2 *ngIf="!editMode">{{company.name}}</h2>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>Company name</mat-label>
                    <input matInput [(ngModel)]="company.name">
                </mat-form-field>
                <p> <strong>{{app.type.customerNames['dateCreated']}}</strong>: {{company.dateCreated}}</p>
                <p> <strong>{{app.type.customerNames['dateModified']}}</strong>: {{company.dateModified}}</p>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['phone1']}}</strong>: {{company.phone1}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['phone1']}}</mat-label>
                    <input matInput [(ngModel)]="company.phone1">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['phone2']}}</strong>: {{company.phone2}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['phone2']}}</mat-label>
                    <input matInput [(ngModel)]="company.phone2">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['faxNumber']}}</strong>: {{company.faxNumber}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['faxNumber']}}</mat-label>
                    <input matInput [(ngModel)]="company.faxNumber">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['address']}}</strong>: {{company.address}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['address']}}</mat-label>
                    <input matInput [(ngModel)]="company.address">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['zip']}}</strong>: {{company.zip}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['zip']}}</mat-label>
                    <input matInput [(ngModel)]="company.zip">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['city']}}</strong>: {{company.city}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['city']}}</mat-label>
                    <input matInput [(ngModel)]="company.city">
                </mat-form-field>
                <p *ngIf="!editMode"> <strong>{{app.type.customerNames['state']}}</strong>: {{company.state}}</p>
                <mat-form-field appearance="outline" *ngIf="editMode">
                    <mat-label>{{app.type.customerNames['state']}}</mat-label>
                    <input matInput [(ngModel)]="company.state">
                </mat-form-field>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <mat-tab-group>
                    <mat-tab label="Main Info" *ngIf="company">
                        <p style="padding: 30px 0 0;" *ngIf="!editMode"><strong>{{app.type.customerNotesNames['yearFaundation']}}</strong>: {{company.notes.yearFaundation}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode" style="padding: 30px 0 0;">
                            <mat-label>{{app.type.customerNotesNames['yearFaundation']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.yearFaundation">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames['mainActivities']}}</strong>: {{company.notes.mainActivities}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames['mainActivities']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.mainActivities">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.clientsManagerObject['name']}}</strong>: {{company.notes.clientsManagerName}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.clientsManagerObject['name']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.clientsManagerName">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.clientsManagerObject['phone']}}</strong>: {{company.notes.clientsManagerPhone}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.clientsManagerObject['phone']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.clientsManagerPhone">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.clientsManagerObject['position']}}</strong>: {{company.notes.clientsManagerPosition}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.clientsManagerObject['position']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.clientsManagerPosition">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.contactPersonObject['name']}}</strong>: {{company.notes.contactPersonName }} </p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.contactPersonObject['name']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.contactPersonName">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.contactPersonObject['phone']}}</strong>: {{company.notes.contactPersonPhone}} </p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.contactPersonObject['phone']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.contactPersonPhone">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames.contactPersonObject['position']}}</strong>: {{company.notes.contactPersonPosition}}</p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames.contactPersonObject['position']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.contactPersonPosition">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames['numberEmployeesCompany']}}</strong>: {{company.notes.numberEmployeesCompany}} </p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames['numberEmployeesCompany']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.numberEmployeesCompany">
                        </mat-form-field>
                        <p *ngIf="!editMode"> <strong>{{app.type.customerNotesNames['numberEmployeesDepartment']}}</strong>: {{company.notes.numberEmployeesDepartment}} </p>
                        <mat-form-field appearance="outline" *ngIf="editMode">
                            <mat-label>{{app.type.customerNotesNames['numberEmployeesDepartment']}}</mat-label>
                            <input matInput [(ngModel)]="company.notes.numberEmployeesDepartment">
                        </mat-form-field>
                    </mat-tab>
                </mat-tab-group>
            </mat-card-content>
        </mat-card>
    </div>

    <div class="button-delete-company">
        <button (click)="editMode = !editMode" mat-button *ngIf="!editMode" class="edit">EDIT</button>
        <button (click)="editProfile()" mat-button *ngIf="editMode" class="save">SAVE</button>
        <button (click)="deleteProfile()" mat-button class="delete">DELETE</button>
    </div>
</app-navigation>