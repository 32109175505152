<mat-form-field appearance="outline" class="selectedUserCV">
    <mat-label>Write user id for preview</mat-label>
    <input matInput [(ngModel)]="selectedCandidate">
</mat-form-field>
<div [class.hideElementPreview]="hideElement" style="display: none;">
    <div [innerHTML]="cvSettings['headerText']"></div>
    <div #block1></div>
    <div #block2></div>
    <div #block3></div>
    <div [innerHTML]="cvSettings['footerText']"></div>
</div>
<button mat-button class="cv-preview-btn" (click)="showPreview()">PREVIEW</button>
