import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppService } from '../../app-services/app.service';
import { SelectionModel } from '@angular/cdk/collections';
import { HashtagType } from '../../app-services/core.service'

@Component({
  selector: 'app-hashtags-table',
  templateUrl: './hashtags-table.component.html',
  styleUrls: ['./hashtags-table.component.scss']
})
export class HashtagsTableComponent implements AfterViewInit {

  public displayedColumns = ['description', 'type', 'owner', 'created', 'modified', 'actions'];
  public dataSource: MatTableDataSource<HashtagType>;
  private paginator: MatPaginator;
  private sort: MatSort;
  selection = new SelectionModel<HashtagType>(true, []);

  @Input() public set data(data: HashtagType[]) {
    this.applyDataSource(data);
  }

  @Input() isLoading: boolean;

  @Input() public set columns(columns: string[]) {
    this.displayedColumns = columns;
  }


  @Output() onRemoveHashtagEvent = new EventEmitter<HashtagType>();
  @Output() addToListEvent = new EventEmitter<HashtagType[]>();
  @Output() hashtagSelectEvent = new EventEmitter<HashtagType>();

  constructor( public app: AppService ) {}

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(hashtags: HashtagType[]) {
    this.dataSource = new MatTableDataSource(hashtags);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.dataSource.data.forEach(row => {
      if (row.selected === '1') this.selection.select(row)
    });
  }

  onRemoveHashtag(hashtag: HashtagType) {
    this.onRemoveHashtagEvent.emit(hashtag);
  }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }

    ngAfterViewInit() {
      this.selection.changed.subscribe((change) => {
        this.addToListEvent.emit(change.source.selected);
      })
    }

    goToSearchItemsList(hashtag: HashtagType) {
      this.hashtagSelectEvent.emit(hashtag)
    }
}
