<div>
    <mat-table [dataSource]="dataSource" matSort class="cv-field-table">

        <ng-container matColumnDef="filedName">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Filed name</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row['filedName']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="isActive">
            <mat-header-cell *matHeaderCellDef>Is Active</mat-header-cell>
            <mat-cell *matCellDef="let row" class="cv-settings-slide-btn">
                <mat-slide-toggle (change)="row.isActive = !row.isActive" [checked]="row.isActive" color="primary"></mat-slide-toggle>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="block">
            <mat-header-cell *matHeaderCellDef>Block</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <mat-form-field appearance="outline"> 
                    <select matNativeControl [(ngModel)]="row.block" [value]="row.block">
                        <option *ngFor="let option of blockArr" [value]="option.value">{{ option.viewValue }}</option>
                    </select>
                </mat-form-field>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <button mat-button class="cv-field-save-btn" (click)="setDataField()">SAVE</button>
</div>