"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebTypeController = void 0;
class WebTypeController {
    constructor(api, skillService) {
        this.api = api;
        this.skillService = skillService;
    }
    getSkills(successCallback, errorCallback) {
        this.api.postRequest('/types/skills', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.skillService.mapSkills(response.data.types));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getSearchItemFieds(successCallback, errorCallback) {
        this.api.postRequest('/types/searchItemFields', {}, response => {
            if (response.data.valid === true) {
                successCallback(response.data.types);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
}
exports.WebTypeController = WebTypeController;
