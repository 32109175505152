import { OnInit, Component, Input, ViewChild } from '@angular/core';
import { CandidateDetail, CandidatePipeline } from '../../app-services/candidate-detail.type';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SearchItemPipelineType, WorkItemType, YhCore } from '../../app-services/core.service'

@Component({
  selector: 'app-work-item-pipelines-table',
  templateUrl: './work-item-pipelines-table.component.html',
  styleUrls: ['./work-item-pipelines-table.component.scss']
})
export class CandidateDetailPipelineTableComponent implements OnInit {
  @Input() public set data(data: SearchItemPipelineType[]) {
    this.applyDataSource(data);
  }

  @Input() isLoading: boolean;

  displayedColumns: string[] = ['title', 'author', 'status', 'added'];
  dataSource: MatTableDataSource<SearchItemPipelineType>;
  private paginator: MatPaginator;
  private sort: MatSort;
  workItems: WorkItemType[];

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: SearchItemPipelineType[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnInit(): void {}

}
