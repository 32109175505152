<h2 mat-dialog-title>Send Email: To - <span class="color-blue email">{{data.email}}</span></h2>
<div mat-dialog-content>
  <div>
    <span>Manage Templates</span>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Regarding</mat-label>
      <mat-select [(ngModel)]="chosenPipeline">
        <mat-option *ngFor="let pipeline of data.candidatePipeline" [value]="pipeline">
          {{pipeline.title}}
        </mat-option>
        <div *ngIf ="data.candidatePipeline.length === 0">
          <mat-option disabled>
            No {{app.type.names.workItem.toLowerCase()}} pipelines for this {{app.type.names.searchItem.toLowerCase()}} yet
          </mat-option>
          <mat-option *ngFor="let workItem of workItems" (click)="addToPipeline(workItem)">
            <mat-icon>add</mat-icon> {{workItem.title}}
          </mat-option>
        </div>
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="outline">
      <mat-label>Template</mat-label>
      <mat-select [(ngModel)]="templateDialog" (selectionChange)="onTemplateSelectionChange()">
        <mat-option *ngFor="let templateDialog of templateDialogs" [value]="templateDialog">
          {{templateDialog.title}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>New Status</mat-label>
      <mat-select [(ngModel)]="status">
        <mat-option *ngFor="let status of this.candidateStatuses" [value]="status.candidateJoborderStatusId">
          {{status.shortDescription}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Subject</mat-label>
      <input matInput [(ngModel)]="message.subject">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Message</mat-label>
      <textarea matInput [(ngModel)]="message.message" rows="10"></textarea>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-raised-button color="primary" style="background: #2d64ba" (click)="onSend()">Send</button>
</div>
