"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebApiController = void 0;
const axios_1 = require("axios");
class WebApiController {
    constructor(apiUrl, localStorageService) {
        this.localStorageService = localStorageService;
        this.createInstance(apiUrl);
    }
    createInstance(apiUrl) {
        this.instance = axios_1.default.create({ baseURL: apiUrl });
        const instance = this.instance;
        const localStorage = this.localStorageService;
        let isRefreshing = false;
        let subscribers = [];
        this.instance.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            return __awaiter(this, void 0, void 0, function* () {
                if (error.response) {
                    const originalRequest = error.config;
                    if (error.response.status === 401 && !originalRequest._retry) {
                        if (isRefreshing) {
                            return new Promise((resolve, reject) => {
                                subscribers.push({ resolve, reject });
                            }).then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return axios_1.default(originalRequest);
                            }).catch(err => {
                                return Promise.reject(err);
                            });
                        }
                        originalRequest._retry = true;
                        isRefreshing = true;
                        const refToken = localStorage.getItem('YHminiRefreshToken');
                        return new Promise((resolve, reject) => {
                            instance.post('auth/refresh', { refreshToken: refToken }).then(res => {
                                if (res.status === 200) {
                                    if (res.data.valid === true) {
                                        localStorage.setItem('YHminiToken', res.data.token);
                                        localStorage.setItem('YHminiRefreshToken', res.data.refreshToken);
                                        instance.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
                                        originalRequest.headers['Authorization'] = 'Bearer ' + res.data.token;
                                        subscribers.forEach(prom => {
                                            prom.resolve(res.data.token);
                                        });
                                        subscribers = [];
                                        isRefreshing = false;
                                        resolve(axios_1.default(originalRequest));
                                    }
                                    else {
                                        localStorage.clear();
                                        location.href = '/';
                                    }
                                }
                                else {
                                    localStorage.clear();
                                    location.href = '/';
                                }
                            }).catch(err => {
                                subscribers.forEach(prom => {
                                    prom.reject(err);
                                });
                                subscribers = [];
                            });
                        });
                    }
                    return Promise.reject(error);
                }
                else {
                    // if anything wrong with token
                    localStorage.clear();
                    location.href = '/';
                    // return Error object with Promise
                    return Promise.reject(error);
                }
            });
        });
        this.instance.interceptors.request.use((config) => __awaiter(this, void 0, void 0, function* () {
            const token = yield localStorage.getItem("YHminiToken");
            if (token && config.url !== 'auth/refresh' && config.url !== 'auth/login') {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
            return config;
        }), error => {
            Promise.reject(error);
        });
    }
    postRequest(url, params, successCallback, errorCallback) {
        return this.instance.post(url, params, {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(response => {
            successCallback(response);
            return response;
        })
            .catch(error => {
            errorCallback(error);
            return error;
        });
    }
    setAuthorization(token, refreshToken) {
        this.localStorageService.setItem('YHminiToken', token);
        this.localStorageService.setItem('YHminiRefreshToken', refreshToken);
    }
    isValid() {
        return this.localStorageService.getItem('YHminiToken') !== null
            && this.localStorageService.getItem('YHminiToken') !== 'undefined'
            && this.localStorageService.getItem('YHminiuser') !== null;
    }
    invalidate() {
        this.localStorageService.clear();
    }
}
exports.WebApiController = WebApiController;
