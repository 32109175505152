import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sourcing-page',
  templateUrl: './sourcing-page.component.html',
  styleUrls: ['./sourcing-page.component.css']
})
export class SourcingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
