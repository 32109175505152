import { ShareNormsType } from './../app-services/share-norms.type';
import { Component, OnInit } from '@angular/core';
import { YhCore } from '../app-services/core.service';
import { UserService } from '../app-services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-share-norms-page',
  templateUrl: './share-norms-page.component.html',
  styleUrls: ['./share-norms-page.component.scss']
})
export class ShareNormsComponent implements OnInit {
  public user = [] = [];
  public typesAccess: any;
  public description: any;
  public arrDescription = [] = [];
  public createrShareNorms = {
    sharingId: null,
    accessLevelId: null,
    shortDescription: 'test',
    norm: '1',
    userId: '0',
    normChangeOwner: '0',
    limitDownload: '0',
    limitViewCandidateProfile: '10',
    limitViewFreeCandidateProfile: '10',
  }

  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    JSON.parse(YhCore.localStorageService.getItem('YHminiuser')).accessLevel ? '' : this.router.navigateByUrl('/settings')
    this.getUsers();
  }

  getShareNorms() {
    YhCore.shareNorms.list(success => {
      this.arrDescription = success;
      this.user.map(el => {
        if (this.arrDescription.length === 0) {
          this.createrShareNorms.userId = el.userId;
          YhCore.shareNorms.create(this.createrShareNorms, success => { }, err => { })
        }

        for (const item of this.arrDescription) {
          if (el.userId === item.userId) {
            Object.assign(el, item)
          }
        }
      })
    }, error => { })
  }



  getUsers() {
    YhCore.users.list(response => {
      this.user = response;
      this.getShareNorms()
      this.getAccessLevels()
    },
      err => { console.log(err) })
  }

  getAccessLevels() {
    YhCore.users.getAccessLevels(success => {
      this.typesAccess = success;
      this.user.map((el) => {
        for (let item of this.typesAccess) {
          if (el.accessLevel == item.access_level_id) {
            this.description = item.short_description;
            Object.assign(el, { description: this.description });
          }
        }
      })
    }, err => { })
  }

  saveValue() {
    this.user.map(el => {
      if (el.limitDownload == '')
        el.limitDownload = '0'
      if (el.limitViewCandidateProfile == '')
        el.limitViewCandidateProfile = '0'
      if (el.limitViewFreeCandidateProfile == '')
        el.limitViewFreeCandidateProfile = '0'
      if (el.normChangeOwner == '')
        el.normChangeOwner = '0'
      YhCore.shareNorms.edit(el, success => { }, err => { })
    })
  }

}
