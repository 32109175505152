"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomersService = void 0;
const customer_access_item_type_1 = require("../types/customer-access-item-type");
const _ustomer_type_1 = require("../types/\u0441ustomer-type");
const list_company_and_joborders_type_1 = require("../types/list-company-and-joborders-type");
class CustomersService {
    constructor() { }
    mapReverseCustomer(src) {
        const result = {};
        if (src.address)
            result.address = src.address;
        if (src.billingContact)
            result.billing_contact = src.billingContact;
        if (src.city)
            result.city = src.city;
        if (src.companyId)
            result.company_id = src.companyId;
        if (src.companyLogo)
            result.company_logo = src.companyLogo;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        if (src.defaultCompany)
            result.default_company = src.defaultCompany;
        if (src.enteredBy)
            result.entered_by = src.enteredBy;
        if (src.faxNumber)
            result.fax_number = src.faxNumber;
        if (src.importId)
            result.import_id = src.importId;
        if (src.isHot)
            result.is_hot = src.isHot;
        if (src.keyTechnologies)
            result.key_technologies = src.keyTechnologies;
        if (src.name)
            result.name = src.name;
        if (src.notes)
            result.notes = src.notes;
        if (src.owner)
            result.owner = src.owner;
        if (src.phone1)
            result.phone1 = src.phone1;
        if (src.phone2)
            result.phone2 = src.phone2;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.state)
            result.state = src.state;
        if (src.url)
            result.url = src.url;
        if (src.zip)
            result.zip = src.zip;
        return result;
    }
    mapCustomers(raw) {
        return raw.map((val) => this.mapCustomer(val));
    }
    mapCustomer(raw) {
        const result = new _ustomer_type_1.Customer();
        result.address = raw.address;
        result.billingContact = raw.billing_contact;
        result.city = raw.city;
        result.companyId = raw.company_id;
        result.companyLogo = raw.company_logo;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.defaultCompany = raw.default_company;
        result.enteredBy = raw.entered_by;
        result.faxNumber = raw.fax_number;
        result.importId = raw.import_id;
        result.isHot = raw.is_hot;
        result.keyTechnologies = raw.key_technologies;
        result.name = raw.name;
        result.notes = raw.notes;
        result.owner = raw.owner;
        result.phone1 = raw.phone1;
        result.phone2 = raw.phone2;
        result.siteId = raw.site_id;
        result.state = raw.state;
        result.url = raw.url;
        result.zip = raw.zip;
        return result;
    }
    mapReversAccessChecked(raw) {
        return raw.map(val => {
            return {
                data_id: val.userId
            };
        });
    }
    mapCustomersAccessItems(raw) {
        return raw.map(val => this.mapCustomersAccessItem(val));
    }
    mapCustomersAccessItem(raw) {
        const result = new customer_access_item_type_1.CustomerAccessItem();
        result.id = raw.id;
        result.dataItemId = raw.data_item_id;
        result.userId = raw.user_id;
        return result;
    }
    mapListCustomerAndJoborders(raw) {
        return raw.map(val => this.mapListCustomerAndJoborder(val));
    }
    mapListCustomerAndJoborder(raw) {
        const result = new list_company_and_joborders_type_1.ListCompanyAndJoborders();
        result.companyId = raw.company_id;
        result.companyName = raw.company_name;
        result.fieldName = raw.field_name;
        result.joborders = raw.joborders.map(val => this.mapListCustomerJoborders(val));
        return result;
    }
    mapListCustomerJoborders(raw) {
        const result = {};
        result['fieldName'] = raw.field_name;
        result['joborderId'] = raw.joborder_id;
        result['joborderTitle'] = raw.joborder_title;
        return result;
    }
}
exports.CustomersService = CustomersService;
