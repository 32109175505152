import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../app-services/app.service';
import { YhCore, ContactItemType } from '../../app-services/core.service';
import { MatSnackBar} from '@angular/material/snack-bar';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-contact-item-dialog',
  templateUrl: './contact-item-dialog.component.html',
  styleUrls: ['./contact-item-dialog.component.scss']
})
export class ContactItemDialogComponent implements OnInit {

  editMode = false;
  savingMode = false;

  contactItemForm:FormGroup;

  constructor(
    public app: AppService,
    public dialogRef: MatDialogRef<ContactItemDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.initForm(this.data)
  }

  initForm(data) {
    this.contactItemForm = new FormGroup({
      firstName: new FormControl(data['contact']['firstName']),
      lastName: new FormControl(data['contact']['lastName']),
      title: new FormControl(data['contact']['title']),
      phoneWork: new FormControl(data['contact']['phoneWork']),
      phoneCell: new FormControl(data['contact']['phoneCell']),
      phoneOther: new FormControl(data['contact']['phoneOther']),
      email1: new FormControl(data['contact']['email1']),
      email2: new FormControl(data['contact']['email2']),
      state: new FormControl(data['contact']['state']),
      city: new FormControl(data['contact']['city']),
      address: new FormControl(data['contact']['address']),
      notes: new FormControl(data['contact']['notes']),
    })
  }

  onClose() {
    this.dialogRef.close();
  }

  startEditMode() {
    this.editMode = true;
  }

  updateContact() {
    const contactItem:ContactItemType = Object.assign(this.data.contact,this.contactItemForm.value);
    YhCore.contactItems.edit(contactItem, success => {
      this.savingMode = true;
      this.editMode = false;
      const snackBarRef = this.snackBar.open(`${this.app.type.names['contactItem']} information was successfully updated`, 'OK', {
        duration: 3000,
      });
      this.dialogRef.close()
    }, err => {
      this.snackBar.open(err, 'ERROR', {duration: 5000})
    })

  }
}


export interface DialogData {
  contact: ContactItemType
}
