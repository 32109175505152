"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebAttachmentController = void 0;
class WebAttachmentController {
    constructor(api, attachmentService) {
        this.api = api;
        this.attachmentService = attachmentService;
    }
    create(formData, successCallback, errorCallback) {
        this.api.postRequest('attachments/create', formData, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('attachments/delete', JSON.stringify({ id: id }), response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getSerachItemAttachment(id, successCallback, errorCallback) {
        this.api.postRequest('attachments/getSearchItemAttachments', JSON.stringify({ id: id }), response => {
            if (response.data.valid === true) {
                successCallback(this.attachmentService.mapAttachmets(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    downloadAttachment(id, successCallback, errorCallback) {
        this.api.postRequest('attachments/downloadAttachment', JSON.stringify({ id: id }), response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
}
exports.WebAttachmentController = WebAttachmentController;
