"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItemPipelineService = void 0;
const work_item_pipeline_type_1 = require("../types/work-item-pipeline-type");
const search_item_pipeline_1 = require("../types/search-item-pipeline");
class WorkItemPipelineService {
    constructor() {
    }
    mapReverseWorkItemPipeline(src) {
        const result = {};
        if (src.candidateFirstName)
            result.candidate_first_name = src.candidateFirstName;
        if (src.candidateLastName)
            result.result.candidate_last_name = src.candidateLastName;
        if (src.candidateId)
            result.candidate_id = src.candidateId;
        if (src.candidateJoborderId)
            result.id = src.candidateJoborderId;
        if (src.joborderId)
            result.joborder_id = src.joborderId;
        if (src.siteId)
            result.site_id = src.siteId;
        if (src.city)
            result.city = src.city;
        if (src.status)
            result.status = src.status;
        if (src.dateCreated)
            result.date_created = src.dateCreated;
        if (src.dateModified)
            result.date_modified = src.dateModified;
        if (src.enteredByFirstName)
            result.entered_by_first_name = src.enteredByFirstName;
        if (src.enteredByLastName)
            result.entered_by_last_name = src.enteredByLastName;
        if (src.statusColor)
            result.status_color = src.statusColor;
        if (src.statusShortDescription)
            result.status_short_description = src.statusShortDescription;
        if (src.lastActivity)
            result.last_activity = src.lastActivity;
        return result;
    }
    mapWorkItemPipelines(raw) {
        return raw.map((val) => this.mapWorkItemPipeline(val));
    }
    mapWorkItemPipeline(raw) {
        const result = new work_item_pipeline_type_1.WorkItemPipeline();
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        result.candidateId = raw.candidate_id;
        result.joborderId = raw.joborder_id;
        result.candidateJoborderId = raw.candidate_joborder_id;
        result.city = raw.city;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.enteredByFirstName = raw.entered_by_first_name;
        result.enteredByLastName = raw.entered_by_last_name;
        result.statusColor = raw.status_color;
        result.statusShortDescription = raw.status_short_description;
        result.lastActivity = raw.last_activity;
        result.workItemTitle = raw.work_item_title;
        if (result.candidateFirstName === null) {
            result.candidateFirstName = '';
        }
        else if (result.candidateLastName === null) {
            result.candidateLastName = '';
        }
        if (result.city === null) {
            result.city = '';
        }
        ;
        return result;
    }
    mapSearchItemPipelines(raw) {
        return raw.map((val) => this.mapSearchItemPipeline(val));
    }
    mapSearchItemPipeline(raw) {
        const result = new search_item_pipeline_1.SearchItemPipeline();
        result.candidateFirstName = raw.candidate_first_name;
        result.candidateLastName = raw.candidate_last_name;
        result.candidateId = raw.candidate_id;
        result.candidateJoborderId = raw.candidate_joborder_id;
        result.candidateJoborderId = raw.candidate_joborder_id;
        result.joborderId = raw.joborder_id;
        result.city = raw.city;
        result.dateCreated = raw.date_created;
        result.dateModified = raw.date_modified;
        result.enteredByFirstName = raw.entered_by_first_name;
        result.enteredByLastName = raw.entered_by_last_name;
        result.statusColor = raw.status_color;
        result.statusShortDescription = raw.status_short_description;
        result.lastActivity = raw.last_activity;
        return result;
    }
}
exports.WorkItemPipelineService = WorkItemPipelineService;
