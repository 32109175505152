<div class="card-view">
  <div class="card-view__wrap">
    <div class="card-view__header header-card-view">
      <ng-container *ngFor="let item of workItemsStatuses">
        <div class="header-card-view__column" [style.width.%]="getColumnWidth()">
          {{item.statusName}}
        </div>
      </ng-container>
    </div>
    <div class="card-view__body body-card-view">
      <ng-container *ngFor="let container of dragContainers">
        <div class="body-card-view__lang">{{container.name}}</div>
        <div class="body-card-view__row" cdkDropListGroup>

          <ng-container *ngFor="let status of container.statuses" >
            <div class="body-card-view__column"
                  cdkDropList
                  [cdkDropListData]="status.workItems" 
                  (cdkDropListDropped)="drop($event, status['changeStatusInfo'])" [style.width.%]="getColumnWidth()">

              <ng-container *ngFor="let item of status.workItems">
                <div class="item-card" (cdkDragDropped)="selectWorkItemToChange(item)" cdkDrag >
                  <div class="item-card__header"></div>
                  <div class="item-card__body" >
                    <div class="item-card__name">{{item.candidateFirstName}} {{item.candidateLastName}}</div>
                    <div class="item-card__text" [innerHTML]="setStyles(item.lastActivity)"></div>
                  </div>
                </div>
              </ng-container>

            </div>
          </ng-container>

        </div>
      </ng-container>
    </div>
  </div>
</div>