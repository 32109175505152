import { Injectable } from '@angular/core';
import { YhApiService } from './yh-api.service';
import { Observable, of } from "rxjs";
import { TemplateDialog } from "./template-dialog.type";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SettingService {
  constructor(
    private api: YhApiService
  ) { }

  private templateDialogs: TemplateDialog[];

  createTemplateDialog(templateDialog: TemplateDialog) {
    return this.api.request('setting/addEditTemplateDialog', this.mapReverseTemplateDialog(templateDialog))
      .pipe(map((val: any) => {

      }));
  }

  getTemplateDialogs(): Observable<TemplateDialog[]> {
    if (this.templateDialogs != null) {
      return of(this.templateDialogs);
    }
    return this.api.request('setting/listTemplateDialog', {})
      .pipe(map((val: any) => {
        const td = this.mapTemplateDialogs(val.listTemplateDialog);
        this.templateDialogs = td;
        return td;
      }));
  }

  deleteTemplateDialog(templateDialog: TemplateDialog) {
    return this.api.request('setting/deleteTemplateDialog', { list_id: templateDialog.id })
      .pipe(map((val: any) => {

      }));
  }

  mapReverseTemplateDialog(spc: TemplateDialog): any {
    const result: any = {};

    result.templateDialogId = spc.id;
    result.template_dialog_body = spc.body;
    result.template_dialog_subject = spc.subject;
    result.template_dialog_title = spc.title;

    return result;
  }

  mapTemplateDialogs(raw: any[]): TemplateDialog[] {
    return raw.map(val => this.mapTemplateDialog(val));
  }

  mapTemplateDialog(raw: any): TemplateDialog {
    const result = new TemplateDialog();

    result.id = raw.id;
    result.body = raw.body;
    result.dateCreated = raw.date_created;
    result.ownerId = raw.owner;
    result.subject = raw.subject;
    result.title = raw.title;

    return result;
  }
}
