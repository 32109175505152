<app-navigation>
  <div class="active-poll-page">
    <mat-card class="main-content">
      <mat-card-content>
        <div class="active-poll-page__body">
          <div class="active-poll-page__row">
            <div class="active-poll-page__column active-poll-page__subscriber">
              <div class="subscriber-active-poll subscriber-active-poll_sticky">
                <div class="subscriber-active-poll__body">
                  <div class="subscriber-active-poll__title">Subscibe To</div>
                  <div class="subscriber-active-poll__field">
                    <mat-form-field class="subscriber-active-poll__input autocomplete-field" appearance="outline">
                      <mat-label>Add New</mat-label>
                      <input #inputAutocomplete type="text" placeholder="Add New" matInput [formControl]="skillControll" class="autocomplete-field__input" autocomplete="off">
                      <cdk-virtual-scroll-viewport #autocomplete itemSize="30" class="autocomplete-field__select select-autocomplete">
                        <div *cdkVirtualFor="let item of skillsFiltered" class="select-autocomplete__option" (click)="selectSkills(item.name)" tabindex="0">
                          <span class="select-autocomplete__value">{{item.name}}</span>
                        </div>
                      </cdk-virtual-scroll-viewport>
                    </mat-form-field>
                  </div>
                  <div class="subscriber-active-poll__row">
                    <div *ngFor="let item of selectedSkills" class="subscriber-active-poll__skill">
                      <span>{{item}}</span>
                      <mat-icon (click)="removeSkill(item)">close</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="active-poll-page__column active-poll-page__lists">
              <div class="list-active-poll" *ngIf="!isLoading">
                <ng-container *ngFor="let item of searchItemsActivePoll">
                  <div class="list-active-poll__item item-active-poll">
                    <div class="item-active-poll__body">
                      <div class="item-active-poll__row">
                        <div class="item-active-poll__image">
                          <img src="" alt="">
                        </div>
                        <div class="item-active-poll__info">
                          <div class="item-active-poll__name">{{item.firstName}}</div>
                          <div class="item-active-poll__location">{{item.city}} {{item.state}}</div>
                          <div class="item-active-poll__position">{{item.position}}</div>
                          <div class="item-active-poll__date">Added: {{item.dateCreated}}</div>
                        </div>
                        <div class="item-active-poll__tags">
                          <ng-container *ngFor="let tag of item.tags">
                            <div class="item-active-poll__tag tag-active-poll" *ngIf="+tag.active === 1" [style.color]="tag.color ? tag.color: ''">
                              <div class="tag-active-poll__icon"><mat-icon>{{tag.icon}}</mat-icon></div>
                              <div class="tag-active-poll__title">{{tag.name}}</div>
                            </div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="item-active-poll__actions">
                        <button (click)="onRequestDetailsEvent(item)" class="item-active-poll__action" mat-flat-button color="primary">Request Details</button>
                        <button class="item-active-poll__action item-active-poll__action-o" mat-flat-button color="primary">Open CV</button>
                        <button (click)="onReserveEvent(item)" [disabled]="item.reserved" class="item-active-poll__action item-active-poll__action-g" mat-flat-button>Reserve candidate</button>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
              <mat-card id="spinner" *ngIf="isLoading">
                <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
              </mat-card>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-navigation>

<ng-template #dialogJoborder>
    <app-auto-complete  [displayValue]="'title'"
                        [fieldCtrl]="joborderControl" 
                        [smartList]="joberderList" 
                        [placeHolder]="'Select Joborder'" 
                        [appearance]="'outline'" 
                        (optionSelected)="selectJoborder($event)" >
    </app-auto-complete>
</ng-template>