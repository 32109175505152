export class Company {
  id: string;
  siteId: string;
  billingContact: string;
  name: string;
  address: string;
  companyLogo: string;
  city: string;
  state: string;
  zip: string;
  phone1: string;
  phone2: string;
  url: string;
  keyTechnologies: string;
  notes: string;
  enteredBy: string;
  owner: string;
  dateCreated: string;
  dateModified: string;
  isHot: string;
  faxNumber: string;
  importId: string;
  defaultCompany: string;
  countJobOrder: string;
  enteredByUserName: string;
  ownerUserName: string;
}
