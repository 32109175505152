import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkItemStatusType } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-search-item-edit-status-dialog',
  templateUrl: './search-item-edit-status-dialog.component.html',
  styleUrls: ['./search-item-edit-status-dialog.component.scss']
})
export class SearchItemEditStatusDialogComponent implements OnInit {

  public workItemStatus: WorkItemStatusType;
  public workItemStatusForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<SearchItemEditStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WorkItemStatusType,
  ) { }

  ngOnInit(): void {
    this.workItemStatus = this.data;
    this.initForm()
  }

  get f(): any {
    return this.workItemStatusForm.controls;
  }

  initForm() {
    if(this.workItemStatus) {
      this. workItemStatusForm = new FormGroup({
        statusName: new FormControl(this.workItemStatus.statusName, [Validators.required]),
        statusRank: new FormControl(this.workItemStatus.statusRank, [Validators.required, Validators.pattern(/\d/),]),
      })
    }
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onSaveClick() {
    this.dialogRef.close(this.workItemStatusForm.value)
  }

}
