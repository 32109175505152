import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { YhCore, WorkItemType, CustomerType, UserType, JoborderGroupType } from '../app-services/core.service';
import { AppService } from '../app-services/app.service';
import { MatDialog } from '@angular/material/dialog';
import { WorkItemSubmitDialogComponent } from '../app-dialogs/work-item-submit-dialog/work-item-submit-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-work-items-page',
  templateUrl: './work-items-page.component.html',
  styleUrls: ['./work-items-page.component.scss']
})

export class WorkItemsPageComponent implements OnInit {
  @ViewChild('jobOrdersTableComponent') jobOrdersTableComponent;

  jobOrders: WorkItemType[];
  isLoadingWorkItem =  true;
  public joborderGroups: JoborderGroupType[] = [];
  public selectGroupId:number = 0;

  constructor(
    public app: AppService,
    public router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getWorkItems();
    this.getJoborderGroups().then(res => this.joborderGroups = res)
  }

  getWorkItems() {
    this.isLoadingWorkItem = true;
    YhCore.workItems.list(async (workItems: WorkItemType[]) => {
      this.jobOrders = workItems;
      this.isLoadingWorkItem = false
    }, error => {
      this.isLoadingWorkItem = false
    })
  }

  navigateToJobOrder(jobOrder: WorkItemType) {
    this.router.navigate([this.app.type.names['workItems'].toLowerCase(), jobOrder.joborderId]);
  }

  navigateToWorkItemCodition(jobOrder: WorkItemType) {
    this.router.navigate([this.app.type.names['workItems'].toLowerCase(), jobOrder.joborderId], { queryParams: { tabs: 'condition' } });
  }

  onSubmit(item: WorkItemType) {
    const dialogRef = this.dialog.open(WorkItemSubmitDialogComponent, {width: '900px'});

    dialogRef.afterClosed().subscribe( res => {
      if(res) {
        YhCore.workItems.submit(item.joborderId, res => {
          this.getWorkItems();
          this.snackBar.open('Work Item is succefully submited to HuntME', 'OK', {duration: 5000})
        }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
      }
    })
  }
  getJoborderGroups():Promise<JoborderGroupType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.joborderGroup.list(res => resolve(res), err => reject(err))
    })
  }
}
