<div mat-dialog-title class="flex flex-between">
  <h4>Add {{ app.type.names['searchItem'] }} to {{ app.type.names['hashtag'] }}</h4>
  <button mat-icon-button (click)="this.dialogRef.close([])">
    <mat-icon>close</mat-icon>
  </button>
</div>
<div class="flex flex-xs-column flex-md-row flex-xs-align-start flex-md-align-center sub-title" *ngIf=" data.searchitemHashtags.length > 0 || data.hiddenHastags !== '0'">
  <h5 class="no-wrap">Already on the {{ app.type.names['hashtags'].toLowerCase() }}:</h5>
  <div class="chosen-lists">
    <mat-list class="flex flex-start flex-wrap">
      <mat-list-item *ngFor="let item of data.searchitemHashtags;">
        <span class="no-wrap"><span>&#x00023;{{item.description}}<span *ngIf="item.loading"><mat-spinner [diameter]="20"></mat-spinner></span></span>
            <div class="delete-list" (click) ="deleteRelation(item)" matTooltip ="Delete">
              <mat-icon>close</mat-icon>
            </div>
        </span>
      </mat-list-item>
      <mat-list-item *ngIf="data.hiddenHastags !== '0'" class="hidden-hashtag">private {{app.type.names['hashtags'].toLowerCase()}}: <span>{{data.hiddenHastags}}</span></mat-list-item>
    </mat-list>
  </div>
</div>
<div mat-dialog-actions class="flex flex-column flex-align-start">
  <div class="buttons-wrap width-100">
    <button mat-flat-button color="primary" (click)="closeDialog()" [disabled] = "selectedHashtags.length === 0">ADD TO {{ app.type.names['hashtag'].toUpperCase()}}</button>
    <button mat-stroked-button (click)="onAddNewList()">ADD NEW {{ app.type.names['hashtag'].toUpperCase() }}</button>
  </div>
</div>
<div class="add-new-hashtag width-100" *ngIf="showAddNewHashtagForm">
  <mat-card>
    <mat-form-field appearance="outline" class="width-100">
      <mat-label>{{ app.type.hashtagNames['description'] }}</mat-label>
      <input matInput [(ngModel)]="hashtag.description" required>
    </mat-form-field>
    <section class="width-100">
      <mat-checkbox [(ngModel)]="hashtag.isDynamic">Make it public</mat-checkbox>
    </section>
    <button mat-flat-button color="primary"(click)="createNewList()" class="margin-t-1">ADD</button>
  </mat-card>
</div>
<div mat-dialog-content class="margin-t-1 padding-t-1">
  <div class = "width-100 flex flex-center" *ngIf = "!tableReady">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
  <app-hashtags-table #hashtagsTable *ngIf = "tableReady" [data]="data.hashtags" [columns] = "['select', 'description', 'owner']" (addToListEvent) = "addToList($event)"></app-hashtags-table>
</div>

