"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConditionsService = void 0;
const conditions_type_1 = require("../types/conditions-type");
class ConditionsService {
    constructor() { }
    mapConditions(raw) {
        return raw.map((val) => this.mapCondition(val));
    }
    mapCondition(raw) {
        const result = new conditions_type_1.Condition();
        result.description = raw.description;
        result.fee = raw.fee;
        result.feeSign = raw.fee_sign;
        result.feeType = raw.fee_type;
        result.id = raw.id;
        result.joborderId = raw.joborder_id;
        result.owner = raw.owner;
        result.prepaymentsAmount = raw.prepayments_amount;
        result.prepaymentsCurrency = raw.prepayments_currency;
        result.prepaymentsRequired = raw.prepayments_required;
        result.returnPolicy = raw.return_policy;
        result.slas = raw.slas;
        result.status = raw.status;
        result.submit = raw.submit;
        result.title = raw.title;
        return result;
    }
}
exports.ConditionsService = ConditionsService;
