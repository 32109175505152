import { AfterViewInit, Component, Input, OnInit, Output, ViewChild, EventEmitter, ContentChildren, OnDestroy } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { IActionsOptions, IDataColumns } from './interface/abstract-table.interface';

@Component({
  selector: 'app-abstract-table',
  templateUrl: './abstract-table.component.html',
  styleUrls: ['./abstract-table.component.scss']
})
export class AbstractTableComponent implements OnInit, AfterViewInit, OnDestroy {

  public dataSource: MatTableDataSource<any>;
  private sort: MatSort;
  private sortState: Sort = {active: '', direction: 'desc'};
  private paginator: MatPaginator;

  public displayedColumns: string[] = [];
  public dataColumns: IDataColumns[] = [];
  private _columnsType: any[] = [];
  private _clickedColumns: number[] = [];
  private _cursorPointer: number[] = [];
  public _showRowDetail: boolean = false;
  public actionsOptions: IActionsOptions[] = [];
  public paginationOptions = {
    length: 1000,
    pageIndex: 0,
    pageSize: 5,
    pageSizeOptions: [5, 15, 50, 100]
  };
  public isClickabledRow = true;

  @Input('selection') public selection = new SelectionModel<any>(true, []);

  @Input('selectionSelected') public selectionSelected = [];

  @Input() isLoading;

  @Input() search = true;

  @Input() tablePaginator = true;

  @Input() set columnsType(data: any[]) {
    this._columnsType = data;
    this.setColumnsType()
  }

  @Input() set showRowDetail(flag: boolean) {
    this._showRowDetail = flag
  }

  @Input() set columns(data: string[]) {
    this.displayedColumns = data
    this.setColumns(data);
  }

  @Input() set data(data: any[]) {
    this.applyDataSource(data);
  }

  @Input() set actions(actions:IActionsOptions[]) {
    this.setAction(actions)
  }

  @Input() set clickedColumns(columns: number[]) {
    if(!columns){
      this.isClickabledRow = false;
      return
    }
    this._clickedColumns = columns;
    this.setClickedColumns()
  }

  @Input() set cursorPointer(columns: number[]) {
    if(!columns){
      this.isClickabledRow = false;
      return
    }
    this._cursorPointer = columns;
    this.setCursorPointer()
  }

  @Input() set sortBy(field: string) {
    this.sortState = {active: field, direction: 'desc'}
  }

  @Output() abstractRowEvent = new EventEmitter();
  @Output() abstractActionEmailEvent = new EventEmitter();
  @Output() abstractActionViewContactEvent = new EventEmitter();
  @Output() abstractActionEditEvent = new EventEmitter();
  @Output() abstractActionDeleteEvent = new EventEmitter();
  @Output() abstractActionPreviewEvent= new EventEmitter();

  @ContentChildren('detailRow') public contentDetailRow;
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    if(mp instanceof MatPaginator) {
      this.paginator = mp;
    }
  }
  @ViewChild(MatSort) set matSort(ms: MatSort) {
    if(ms instanceof MatSort) {
      this.sort = ms;
    }
  }

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnDestroy(): void {
    this.selection.changed.unsubscribe()
  }

  ngOnInit():void {
  }

  ngAfterViewInit():void {
    this.applyDataSourceAttributes();
    this.subscribeSelection()
  }

  applyDataSourceAttributes():void {
    if (this.dataSource instanceof MatTableDataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  applyDataSortState():void {
    if(this.sort){
      if(!this.sortState.active) {
        this.sortState.active = this.displayedColumns[0] || '';
      }
      this.sort.active = this.sortState.active;
      this.sort.direction = this.sortState.direction;
      this.sort.sortChange.emit();
    }
  }

  applyFilter(filterValue: string):void {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: any[]):void {
    if(data instanceof Array) {
      this.dataSource = new MatTableDataSource(data);
      this.applyDataSourceAttributes();
      if(data.length){
        this.applyDataSortState();
        this.sortByDate()
      }
      this.dataSource.data.forEach(row => {
        if (row.selected === '1') this.selection.select(row)
      });
    }
  }

  applySearchFilter(filterValue: string):void {
    if (this.dataSource instanceof MatTableDataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();

      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  sortByDate () {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'date': return new Date(item.dateCreated);
        default: return item[property];
      }
    };
  }

  isAllSelected():boolean {
    if (this.dataSource instanceof MatTableDataSource) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  masterToggle():void {
    if (this.dataSource instanceof MatTableDataSource){
      this.isAllSelected() ?
          this.selection.clear() :
          this.dataSource.data.forEach(row => this.selection.select(row));
    }
  }

  editedRow(row) {
    row['edited'] = true;
  }

  onRowEvent(row):void {
    this.abstractRowEvent.emit(row)
  }

  subscribeSelection() {
    this.selection.changed.subscribe( change => { this.selectionSelected.length = 0;  this.selectionSelected.push(...change.source.selected)})
  }

  onActionsEvent(action: string, row):void {
    switch (action) {
      case 'edit':
        this.onActionsEditEvents(row);
        break;
      case 'delete':
        this.onActionsDeleteEvents(row);
        break;
      case 'email':
        this.onActionsEmailEvents(row);
        break;
      case 'viewContact':
        this.onActionsViewContactEvents(row);
        break
      case 'preview':
        this.onActionsPreviewEvents(row);
        break
      default:
        break;
    }
  }

  onActionsDeleteEvents(row):void {
    this.abstractActionDeleteEvent.emit(row)
  }

  onActionsPreviewEvents(row):void{
    this.abstractActionPreviewEvent.emit(row);
  }

  onActionsEmailEvents(row):void {
    this.abstractActionEmailEvent.emit(row)
  }

  onActionsViewContactEvents(row):void {
    this.abstractActionViewContactEvent.emit(row)
  }

  onActionsEditEvents(row):void {
    this.abstractActionEditEvent.emit(row)
  }

  setColumns(data: string[]):void {
    this.dataColumns = data.map((val, ind) => {
      return {
        name: this.replaceId(this.startCase(val)),
        value: val,
        clicked: false,
        cursor: false
      }
    });
    this.setClickedColumns();
    this.setColumnsType();
  }

  setColumnsType() {
    if(this._columnsType.length) {
      this._columnsType.forEach( (type, ind) => {
        this.dataColumns[ind].field = type
      })
    }
  }

  setAction(actions:IActionsOptions[]):void {
    actions.forEach(( val ) => {
      this.actionsOptions.push({
        action: val.action || '',
        tooltip: val.tooltip || '',
        ifVisible: val.ifVisible || 'true',
        icon: val.icon || 'error'
      })
    })
  }

  setClickedColumns():void {
    this._clickedColumns.map(num => {
      this.dataColumns[num - 1].clicked = true;
    })
  }

  setCursorPointer():void {
    this._cursorPointer.map(num => {
      this.dataColumns[num -1].cursor = true
    })
  }

  startCase(str:string):string {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, str =>  str.toUpperCase() )
  }

  replaceId(str:string):string {
    return str.replace(/.+(?=Id$)/gmi, '')
  }

  setStyles(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

}