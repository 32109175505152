<div class="table-wrap">
  <div class="table-wrap__inner">
  <mat-form-field>
    <label>
      <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
    </label>
  </mat-form-field>

    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header data-label="id">ID</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog" data-label="id">{{templateDialog['id']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="title">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog">{{templateDialog['title']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Subject</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog">{{templateDialog['subject']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="body">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Body</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog">{{templateDialog['body']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateCreated">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Created Date</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog">{{templateDialog['dateCreated']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Actions</mat-header-cell>
        <mat-cell *matCellDef="let templateDialog" [hidden]="templateDialog.deleting">
          <button (click)="onRemoveTemplateDialog(templateDialog)" mat-icon-button aria-label="Remove" class="action-remove">
            <mat-icon [class.show] ="!templateDialog.deleting" [class.hide]="templateDialog.deleting">close</mat-icon>
            <mat-spinner [class.show] ="templateDialog.deleting" [class.hide]="!templateDialog.deleting" [diameter]="20"></mat-spinner>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
