
<ng-container *ngIf="!isLoading">
  <ng-container *ngIf="ifNotAcceptStatus">

    <app-work-item-condition-table (onPreviewCondition)="previewCondition($event)" [(selection)]="selection" [data]="conditions"></app-work-item-condition-table>

    <button style="margin-right: 10px;" mat-flat-button color="primary" [disabled]="!selection.selected.length" (click)="acceptCondition()">Accept</button>
    <button style="margin-right: 10px;" mat-flat-button color="warn" (click)="declineAll()">Decline All</button>

  </ng-container>

  <ng-container *ngIf="!ifNotAcceptStatus">

    <ng-container *ngIf="acceptedCondition; else ifNotAcceptedCondition">
      <app-work-item-accepted-condition [condition]="acceptedCondition"></app-work-item-accepted-condition>
    </ng-container>

    <ng-template #ifNotAcceptedCondition>No Job Order working conditions yet</ng-template>
  </ng-container>
</ng-container>

<mat-card id="spinner" *ngIf="isLoading">
  <mat-progress-spinner
    color="primary"
    mode="indeterminate">
  </mat-progress-spinner>
</mat-card>
