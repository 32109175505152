export class SalesType {
  names = {
    project: 'Sales',

    searchItem: 'Lead',
    searchItems: 'Leads',

    workItem: 'Offer',
    workItems: 'Offers',

    customer: 'Project',
    customers: 'Projects',

    hashtag: 'Request',
    hashtags: 'Requests',

    contactItem: 'Contact',
    contactItems: 'Contacts'
  };

  searchItemNames = {
    address: 'Address',
    bestTimeToCall: 'Best time to call',
    canRelocate: 'Have remote positions',
    candidateId: 'Id',
    city: 'City',
    currentEmployer: '',
    currentPay: '',
    dateAvailable: '',
    dateCreated: 'Date created',
    dateModified: 'Date modified',
    desiredPay: '',
    dialog: 'Dialog',
    eeoDisabilityStatus: '',
    eeoEthnicTypeId: '',
    eeoGender: '',
    eeoVeteranTypeId: '',
    email1: 'Company email',
    email2: 'Contact email',
    enteredBy: 'Entered by',
    firstName: 'Contact name',
    img: '',
    imgResize: '',
    importId: '',
    isActive: '',
    isAdminHidden: '',
    isHot: '',
    keySkills: 'Company domain',
    lastName: 'Company name',
    middleName: '',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Company phone',
    phoneHome: 'Contact personal phone',
    phoneWork: 'Contact phone',
    siteId: '',
    source: '',
    state: 'State',
    webSite: 'Web site',
    zip: ''
  }

  workItemItemNames = {
    id: 'Id',
    alias: '',
    city: 'City',
    cityEn: '',
    cityRu: '',
    cityUa: '',
    clientJobId: '',
    companyDepartmentId: '',
    company: 'Project',
    contactId: '',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    duration: '',
    entereBy: '',
    isAdminHidden: '',
    isHot: '',
    joborderId: 'Id',
    notes: 'Notes',
    openings: '',
    openingsAvailable: '',
    owner: 'Owner',
    public: '',
    questionnaireId: '',
    rateMax: '',
    recruiter: 'Agent',
    salary: '',
    seoDescriptionEn: '',
    seoKeywordsEn: '',
    seoTitleEn: '',
    siteId: '',
    startDate: '',
    state: 'State',
    status: 'Status',
    title: 'Title',
    type: ''
  }

  customerNames = {
    address: '',
    billingContact: '',
    city: '',
    companyId: '',
    companyLogo: '',
    dateCreated: '',
    dateModified: '',
    defaultCompany: '',
    enteredBy: '',
    faxNumber: '',
    importId: '',
    isHot: '',
    keyTechnologies: '',
    name: '',
    notes: '',
    owner: '',
    phone1: '',
    phone2: '',
    siteId: '',
    state: '',
    url: '',
    zip: ''
  }

  hashtagNames = {
    type: 'Data type',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    isDynamic: 'Dynamic',
    savedListId: 'Id',
    owner: 'Owner',
  }

  contactItemNames = {
    address: 'Address',
    city: 'City',
    companyDepartmentId: '',
    companyId: '',
    contactId: '',
    dateCreated: '',
    dateModified: '',
    email1: 'Work email',
    email2: 'Contact email',
    enteredBy: '',
    firstName: 'Contact name',
    importId: '',
    isHot: '',
    lastName: 'Company name',
    leftCompany: '',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Personal phone',
    phoneOther: 'Other phone',
    phoneWork: 'Work phone',
    reportsTo: '',
    siteId: '',
    state: 'State',
    title: 'Position',
    zip: ''
  }
}
