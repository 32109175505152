<div class="main-container">
  <div class="main-container_sidenav flex-column" [style.display]="(isSmallDevice && showMessages) ? 'none':'flex'">
    <mat-form-field class="messenger-input" appearance="outline" style="margin: 13px;">
      <input matInput placeholder="Search" [(ngModel)] = "filter">
      <mat-icon *ngIf="!isSearchMode" (click)="enableSearchMode()" matSuffix style="cursor:pointer;">search</mat-icon>
      <mat-icon *ngIf="isSearchMode" (click)="disableSearchMode()" matSuffix style="cursor:pointer;">search_off</mat-icon>
      <span class="error-message" *ngIf="quickSearchError">Please enter more than 3 symbols</span>
    </mat-form-field>
    
    <div [hidden]="isSearchMode">
      <div mat-subheader style="color: white">{{app.type.names.searchItems}}</div>
      <mat-list>
        <mat-list-item *ngFor="let item of searchItems;" 
          [class.active]="item === this.activeItem" 
          (click) ="chooseThread(item, 'searchItem')"
          >
          <div class="avatar" (click) ="goToProfile($event, item)">
            <img [src]="item.img" [alt]="item.firstName" *ngIf="item.img.length > 0" matTooltip="go to profile">
            <button mat-icon-button class="search-item-icon" *ngIf="item.img.length === 0" matTooltip="go to profile"><mat-icon>account_circle</mat-icon></button>
          </div>
          <span [hidden]="(item.firstName + item.lastName).length < 15" class="search-item-name" cdkOverlayOrigin 
          #trigger="cdkOverlayOrigin" (mouseenter)="item.isOpen = true" 
          (mouseleave)="item.isOpen = false" [matBadge]="item.newEmails" [matBadgeHidden] = "item.newEmails === 0"
          matBadgeColor="warn">{{ (item.firstName + item.lastName).substring(0, 15)}}</span>
          <span [hidden]="(item.firstName + item.lastName).length > 15"[matBadge]="item.newEmails" matBadgeColor="warn" [matBadgeHidden] = "item.newEmails === 0"> {{ (item.firstName + item.lastName) }}</span>
          <span [hidden]="(item.firstName + item.lastName).length < 15">...</span>
            <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="item.isOpen"
            class="tooltip-wrap"
            >
            <div class="creation-mode-tooltip" *ngIf="!isMenuOpen"> {{ (item.firstName + item.lastName) }}</div>
            </ng-template>
        </mat-list-item>
      </mat-list>
      <mat-divider style="color: white !important; border-color: white !important; margin: 13px !important;"></mat-divider>
      <div mat-subheader style="color: white;" *ngIf="app.isSales">Contacts</div>
      <mat-list *ngIf="app.isSales">
        <mat-list-item *ngFor="let item of contactItems;" 
          [class.active]="item === this.activeItem" 
          (click)="chooseThread(item, 'contactItem')"
          >
          <button mat-icon-button class="search-item-icon"><mat-icon>account_circle</mat-icon></button>
            <span [hidden]="(item.firstName + item.lastName).length < 15" class="search-item-name" cdkOverlayOrigin 
            #trigger="cdkOverlayOrigin" (mouseenter)="item.isOpen = true" 
            (mouseleave)="item.isOpen = false" [matBadge]="item.newEmails" [matBadgeHidden] = "item.newEmails === 0"
            matBadgeColor="warn">{{ (item.firstName + item.lastName).substring(0, 15)}}</span>
            <span [hidden]="(item.firstName + item.lastName).length > 15" [matBadge]="item.newEmails" matBadgeColor="warn" [matBadgeHidden] = "item.newEmails === 0" > {{ (item.firstName + item.lastName) }}</span>
            <span [hidden]="(item.firstName + item.lastName).length < 15">...</span>
              <ng-template
              cdkConnectedOverlay
              [cdkConnectedOverlayOrigin]="trigger"
              [cdkConnectedOverlayOpen]="item.isOpen"
              class="tooltip-wrap"
              >
              <div class="creation-mode-tooltip" *ngIf="!isMenuOpen"> {{ (item.firstName + item.lastName) }}</div>
              </ng-template>
      </mat-list-item>
      </mat-list>
    </div>
    <div [hidden]="!isSearchMode" class="search-mode">
      <div mat-subheader style="color: white">{{app.type.names.searchItems}}</div>
      <mat-list>
        <mat-list-item *ngFor="let item of filteredSearchItems;" [class.active]="item.candidateId === this.activeItem.candidateId" (click)="itemChose(item)">
        <button mat-icon-button class="search-item-icon" (click) ="goToProfile($event, item)" matTooltip="go to profile"><mat-icon>account_circle</mat-icon></button>
        <span [hidden]="(item.firstName + item.lastName).length < 15" class="search-item-name" cdkOverlayOrigin 
        #trigger="cdkOverlayOrigin" (mouseenter)="item.isOpen = true" 
        (mouseleave)="item.isOpen = false" >{{ (item.firstName + item.lastName).substring(0, 15)}}</span>
        <span [hidden]="(item.firstName + item.lastName).length > 15"> {{ (item.firstName + item.lastName) }}</span>
        <span [hidden]="(item.firstName + item.lastName).length < 15">...</span>
          <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="item.isOpen"
          class="tooltip-wrap"
          >
          <div class="creation-mode-tooltip" *ngIf="!isMenuOpen"> {{ (item.firstName + item.lastName) }}</div>
          </ng-template></mat-list-item>
      </mat-list>
      <mat-divider style="color: white !important; border-color: white !important; margin: 13px !important;"></mat-divider>
      <div mat-subheader style="color: white;" *ngIf="app.isSales">Contacts</div>
      <mat-list *ngIf="app.isSales">
        <mat-list-item *ngFor="let item of filteredcontactItems;" [class.active]="item.contactId === this.activeItem.contactId" (click)="itemChose(item)">
          <button mat-icon-button class="search-item-icon"><mat-icon>account_circle</mat-icon></button>
          <span [hidden]="(item.firstName + item.lastName).length < 15" class="search-item-name" cdkOverlayOrigin 
          #trigger="cdkOverlayOrigin" (mouseenter)="item.isOpen = true" 
          (mouseleave)="item.isOpen = false" >{{ (item.firstName + item.lastName).substring(0, 15)}}</span>
          <span [hidden]="(item.firstName + item.lastName).length > 15"> {{ (item.firstName + item.lastName) }}</span>
          <span [hidden]="(item.firstName + item.lastName).length < 15">...</span>
            <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="item.isOpen"
            class="tooltip-wrap"
            >
            <div class="creation-mode-tooltip" *ngIf="!isMenuOpen"> {{ (item.firstName + item.lastName) }}</div>
            </ng-template>
      </mat-list-item>
      </mat-list>
    </div>
  </div>
  <div class="main-container_content">
    <div class="main-content-wrap" [style.display]="(isSmallDevice && !showMessages) ? 'none':'flex'">
      <div class="mobile-view-header" [style.display]="isSmallDevice ? 'flex':'none'">
        <button mat-icon-button (click) = "showMessages = false">
          <mat-icon >arrow_back</mat-icon>
        </button>
        <div class="avatar">
          <img [src]="activeItem.img" [alt]="activeItem.firstName" *ngIf="activeItem &&  activeItem.img && activeItem.img.length > 0" matTooltip="go to profile">
          <button mat-icon-button class="search-item-icon" *ngIf="activeItem && activeItem.img && activeItem.img.length === 0"><mat-icon>account_circle</mat-icon></button>
        </div>
        <span class="search-item-name">
          {{activeItem ? activeItem.firstName: ''}} {{activeItem ? activeItem.lastName: ''}}
        </span>
      </div>
      <div class="width-100 flex flex-center">
        <mat-spinner *ngIf="loadingMessages" [diameter]="50" class="progress-spinner"></mat-spinner>
      </div>
      <div #scrollArea class="main-content">
      <div *ngIf="!email || !loadingMessages">
        <div *ngIf="activeThread.length === 0 && (!email && !loadingMessages) && !activeItemNotSet">No emails yet</div>
        <mat-list class="messenger-main">
          <ng-container *ngFor="let message of activeThread">
            <div *ngIf="message['isNew'] " class="new-message">
              NEW
            </div>
            <mat-list-item class="message" [ngClass] = "[message.from === currentUserEmail ? 'search-item-reply' : '', message.messageSending ? 'message-sending' : '']" >
              <span class="date">{{message.date}}</span>
              <span>Subject: {{message.subject}}</span>
              <span>from: {{message.from}}</span>
              <span>to {{message.to}}</span>
              <span class="message-body">
                <span [hidden]="!message['showMore']"> {{ message.message }}</span>
                <span [hidden]="message['showMore']"> {{ message.message.substring(0, 100)}}</span>
                <span *ngIf="message.message.length > 100 && !message['showMore']">...</span>
    
                <div *ngIf="message.message.length > 100" class = 'show-more-message'>
                  <button mat-icon-button
                          style="line-height: 0 !important; height: 0 !important;"
                          (click)="message['showMore'] = !message['showMore']">
                    <mat-icon *ngIf="!message['showMore']">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="message['showMore']">keyboard_arrow_up</mat-icon>
                  </button>
                </div>
              </span>
            </mat-list-item>
          </ng-container>
        </mat-list>
      </div>
      <div *ngIf="email">
        <mat-form-field appearance="outline" class="width-100">
          <mat-label>Choose Email</mat-label>
          <mat-select [(ngModel)]="chosenEmail" (selectionChange)= "onChooseEmail()" disableOptionCentering>
            <mat-option [value] = "activeItem.email1">
              {{activeItem.email1}}
            </mat-option>
            <mat-option [value] = "activeItem.email2">
              {{activeItem.email2}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
      <div class="actions">
        <mat-accordion>
          <mat-expansion-panel (afterExpand) = "scroll = true">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Type a message
              </mat-panel-title>
            </mat-expansion-panel-header>
              <mat-form-field appearance="outline" class="width-33">
                <mat-label>Regarding</mat-label>
                <mat-select [formControl]="chosenPipeline" (selectionChange) = "onChangePipeline()" required>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let pipeline of searchItemPipelines" [value]="pipeline">
                    #{{pipeline.joborderId}} {{pipeline.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="width-33">
                <mat-label>Template</mat-label>
                <mat-select [formControl]="chosenTemplate" (selectionChange) = "applyTemplate($event)" disableOptionCentering>
                  <mat-option>--</mat-option>
                  <mat-option *ngFor="let template of templates" [value]="template">
                    {{template.title}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="width-33">
                <mat-label>New Status</mat-label>
                <mat-select [(ngModel)]="newStatus" disableOptionCentering>
                  <mat-option *ngFor="let status of statuses" [value]="status.candidateJoborderStatusId">
                    {{status.shortDescription}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" class="width-100">
                <mat-label>Subject</mat-label>
                <input matInput [(ngModel)]="newMessage.subject">
              </mat-form-field>
            <mat-form-field appearance="outline" class="width-100">
              <mat-label>Type a message</mat-label>
              <textarea matInput
              [(ngModel)] = "newMessage.message"
                        rows ="3"
                        ></textarea>
                <mat-icon class="send-icon" (click) = "sendMessage()" matSuffix style="cursor:pointer;">send</mat-icon>
            </mat-form-field>
            <div class="error-message" *ngIf="showError">Regarding is required field</div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </div>
</div>
