import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { WorkItemPipeline } from 'core/dist/types/work-item-pipeline-type';
import { WorkItem } from 'core/dist/types/work-item-type';
import { YhCore } from '../app-services/core.service';


@Component({
  selector: 'app-search-items-card-view',
  templateUrl: './search-items-card-view.component.html',
  styleUrls: ['./search-items-card-view.component.scss']
})
export class SearchItemsCardViewComponent implements OnInit {

  public workItemsStatuses: any[] = [];
  public workItemLists:WorkItem[] = [];
  public workItemPipelines: WorkItemPipeline[] = []
  public dragContainers = [];
  private workItemToChange: WorkItemPipeline;
  private _companyId:string;

  @Input() public set companyId(companyId: string) {
    if(companyId) {
      this._companyId = companyId
      this.getData();
    }
  }

  constructor(
    private snackBar: MatSnackBar,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit() {
  }

  async getData() {
    await this.getWorkItemLists();
    await this.getWorkItemPipelines();
    await this.getWorkItemStatuses();

    this.createDragContainer()
  }

  getWorkItemLists(): Promise<any> {
    return new Promise( (resolve, rejcect) => {
      return YhCore.workItems.list( res => {
        res.forEach(element => {
          this.workItemLists.push(element)
        });
        resolve(true);
      }, err => {
        this.snackBar.open(err, 'ERROR', {duration: 5000});
        resolve(true);
      })
    })
  }

  getWorkItemPipelines(): Promise<any>{
    return new Promise( (resolve, reject) => {
      return YhCore.workItemsPipeline.getAllPipeline( res => {
        this.workItemPipelines = res;
        resolve(true)
      }, err => reject(err))
    })
  }

  getWorkItemPipeline(id: number): Promise<WorkItemPipeline[]> {
    return new Promise( (resolve, reject) => {
      const workItem = this.workItemLists.find(val => +val.joborderId === id);
      YhCore.workItemsPipeline.getPipelinesByWorkItem(+workItem.joborderId, res => {
        res.forEach( item => item['workItemTitle'] = workItem.title)
        resolve(res)
      }, err => {
        reject(err);
      })
    })
  }

  getWorkItemStatuses(): Promise<any> {
    return new Promise( (resolve, rejects) => {
      YhCore.workItemsStatuses.list(this._companyId, res => {
        this.workItemsStatuses.push(...res)
        resolve(res)
      }, err => this.snackBar.open(err, "ERROR", {duration: 5000}))
    })
  }

  createDragContainer() {
    this.workItemLists.forEach( val => {
      this.dragContainers.push({
        name: val.title,
        statuses: this.workItemsStatuses.map( status => {
          return {
            status: status['statusName'],
            changeStatusInfo: {
              statusId: status['statusId'],
              joborderId: val['joborderId']
            },
            workItems: this.workItemPipelines.filter( item => {
              if(item.statusShortDescription.toLowerCase() === status['statusName'].toLowerCase() && item['workItemTitle'].toLowerCase() === val.title.toLowerCase()){
                return item
              }
            })
          }
        })
      })
    })
  }

  getColumnWidth() {
    return 100 / this.workItemsStatuses.length
  }

  async drop(event: CdkDragDrop<WorkItemPipeline[]>, changeInfo) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {

      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      await this.changeStatus(this.workItemToChange, changeInfo);
      this.editDragContainerData(event, changeInfo)
    }
  }

  async editDragContainerData(event, changeInfo) {
    const workItemsPipelines:WorkItemPipeline[] = await this.getWorkItemPipeline(parseInt(changeInfo.joborderId, 10))
    event.container.data.map( (val: WorkItemPipeline) => {
      if (val.candidateJoborderId === this.workItemToChange.candidateJoborderId) {
        Object.assign(val, workItemsPipelines.find(item => item.candidateJoborderId === val.candidateJoborderId))
      }
      return val
    })
  }

  changeStatus(workItem, changeInfo: { statusId: number, joborderId: string}): Promise<boolean>{
    return new Promise( (resolve, reject) => {
      YhCore.workItemsPipeline.addActivityChangeStatus(+workItem['candidateId'],  +changeInfo.joborderId, +changeInfo.statusId, res => {
        resolve(true)
      }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
    })
  }

  selectWorkItemToChange(item: WorkItemPipeline) {
    this.workItemToChange = item;
  }

  setStyles(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
