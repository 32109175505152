export class Candidate {
  id: string;
  siteId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  phoneHome: string;
  phoneCell: string;
  phoneWork: string;
  adress: string;
  city: string;
  state: string;
  zip: string;
  source: string;
  dateAvailable: string;
  canRelocate: string;
  notes: string;
  keySkills: string;
  showMoreKeySkills: boolean;
  currentEmployer: string;
  enteredBy: string;
  owner: string;
  dateCreated: string;
  dateModified: string;
  email1: string;
  email2: string;
  webSite: string;
  importId: string;
  isHot: string;
  eeoEthnicTypeId: string;
  eeoVeteranTypeId: string;
  eeoDisabilityStatus: string;
  eeoGender: string;
  desiredPay: string;
  currentPay: string;
  isActive: string;
  isAdminHidden: string;
  bestTimeToCall: string;
  img: string;
  imgResize: string;
  dialog: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerUserName: string;
  ownerAccessLevel: string;
  recentStatus: string;
  matrixAccess: string;
  enteredByUserName: string;
}
