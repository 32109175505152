<div>
  <mat-form-field>
    <label>
      <input matInput (keyup)="applyFilter($event.target['value'])" placeholder="Search">
    </label>
  </mat-form-field>

  <div>
    <mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header>id</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">{{requestSharedSearchItem['id']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="candidateId">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Candidate</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">{{requestSharedSearchItem['candidateId']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="dateModified">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Date Modified</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">{{requestSharedSearchItem['dateModified']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="enteredBy">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Request by</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">{{requestSharedSearchItem['enteredBy']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="candidateOwner">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Owner</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">{{requestSharedSearchItem['candidateOwner']}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="requestStatus">
        <mat-header-cell *matHeaderCellDef mat-sort-header>Request status</mat-header-cell>
        <mat-cell *matCellDef="let requestSharedSearchItem">
          <button (click)="onClickAllow(requestSharedSearchItem)" mat-icon-button aria-label="ALLOW" class="allow-request-shared-items">
            ALLOW
          </button>
          <button (click)="onClickDenied(requestSharedSearchItem)" mat-icon-button aria-label="DENIED" class="denied-request-shared-items">
            DENIED
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let requestSharedSearchItem; columns: displayedColumns;" [class.hide]='requestSharedSearchItem.hide'></mat-row>
    </mat-table>

    <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
  </div>
</div>
