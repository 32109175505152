<mat-card class="calibration-wrap" *ngIf="calibrations?.length;else templateName">
    <ng-container *ngIf="calibrations?.length && !showTabs && !scoringFinished">
      <button class="calibration-score-button" mat-raised-button color="primary" (click)="showTabs = true">Start Scoring</button>
    </ng-container>
    <ng-container *ngIf="scoringFinished && !showTabs">
      <h2>
        Thanks for your feedback! Your manager will be in touch with you soon.
      </h2>
    </ng-container>
    <mat-tab-group *ngIf="showTabs" [selectedIndex]="activeTab">
      <mat-tab *ngFor="let calibration of calibrations; let i = index">

          <h2>{{app.type.names.searchItem}} {{calibration.candidateId}}</h2>
          <h3>Experience Details:</h3>
          <mat-list>
            <mat-list-item *ngFor="let item of calibration.experienceDetails">
              <p>Date From - Date To: {{item.dateFrom}} - {{item.dateTo}}</p>
              <p>Company name: {{item.companyName}}</p>
              <p>Position: {{item.position}}</p>
              <p>Notes: {{item.notes}}</p>
            </mat-list-item>
          </mat-list>
  
          <h3>Experience:</h3>
          <p>{{calibration.experience}}</p>
  
          <div class="score-container">
            <div class="score-container__stars">
              <button mat-mini-fab aria-label="Start score icon" 
                      *ngFor="let index of [1, 2, 3, 4, 5]"
                       (mouseover)="scoringModeOn ? current=index:null"
                       (mouseout)="scoringModeOn ? current=0:null"
                       (click)="scoreCandidate(index, calibration, i)"
                       [class.active]="index <=  current">
                <mat-icon>star_rate</mat-icon>
              </button>
            </div>
            <button class="score-container__skip" (click)="nextCandidate(calibration, i, false)" mat-stroked-button>Skip this {{app.type.names.searchItem.toLowerCase()}}
              <mat-icon>keyboard_arrow_right</mat-icon>
            </button>
          </div>

      </mat-tab>
    </mat-tab-group>
</mat-card>

<ng-template #templateName>
  No Calibration
</ng-template>
