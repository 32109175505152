<div class="main-table-wrap">
    <mat-form-field>
      <mat-label>Search</mat-label>
      <input matInput (keyup)="applyFilter($event.target.value)" >
    </mat-form-field>
    <div class="table-wrap">
      <div class="table-wrap__inner">
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef mat-sort-header >{{ app.type.workItemItemNames['title'] }}</mat-header-cell>
            <mat-cell *matCellDef="let row" (click)="selectJobOrder(row)" style="cursor: pointer;">{{row["title"]}}</mat-cell>
          </ng-container>>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.workItemItemNames['status'] }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row["type"]}} (Hire)</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.workItemItemNames['status'] }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row["status"]}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.workItemItemNames['dateCreated'] }}</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row["dateCreated"]}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="submit">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{ app.type.workItemItemNames['submit'] }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-flat-button color="primary" [style.display]="row.public === '0' ? 'block': 'none'" (click)="submitTo(row)">Submit To</button>
              <button style="cursor: auto;" mat-flat-button color="primary" [style.display]="row.public === '20' ? 'block': 'none'">Submitted to HuntME</button>
              <button mat-flat-button color="accent" [style.display]="row.public === '30' ? 'block': 'none'" (click)="navigateToWorkItemCondition(row)">Proposal from HuntME</button>
              <button mat-flat-button class="conditions-acepted" [style.display]="row.public === '40' ? 'block': 'none'" (click)="navigateToWorkItemCondition(row)">Conditions Accepted</button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="joborderGroupTitle">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Groups</mat-header-cell>
            <mat-cell *matCellDef="let row">{{row["joborderGroupTitle"]}}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Action</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button mat-icon-button color="primary" (click)="openDialogAddToGroup(row)">
                <mat-icon>group_add</mat-icon>
              </button>
            </mat-cell>
          </ng-container>


          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-card id="spinner" *ngIf="isLoading">
          <mat-progress-spinner
            color="primary"
            mode="indeterminate">
          </mat-progress-spinner>
        </mat-card>

        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
      </div>
    </div>
</div>
<ng-template #addToGroupDialog>
  <form [formGroup]="addToGroupForm">
    <mat-form-field appearance="outline" style="width: 100%;">
      <mat-label>Select group</mat-label>
      <mat-select formControlName="group">
        <mat-option *ngFor="let item of joborderGroups" [value]="item">{{item.title}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button *ngIf="+this.currentUser.accessLevel >= 400" style="margin-right: 10px;" mat-flat-button color="primary" (click)="openDialogAddJoborderGroup()" >ADD NEW GROUP</button>
    <button mat-flat-button color="primary" [disabled]="!addToGroupForm.valid" (click)="saveWorkItemGroup()">SAVE</button>
  </form>
</ng-template>
