"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebTemplateController = void 0;
class WebTemplateController {
    constructor(api, templatesService) {
        this.api = api;
        this.templatesService = templatesService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('template/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.templatesService.mapTemplates(response.data.data));
            else
                errorCallback(false);
        }, error => {
            errorCallback(error);
        });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('template/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.templatesService.mapTemplate(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('template/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    edit(template, successCallback, errorCallback) {
        const src = this.templatesService.mapReverseTemplate(template);
        this.api.postRequest('template/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    create(template, successCallback, errorCallback) {
        const src = this.templatesService.mapReverseTemplate(template);
        this.api.postRequest('template/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => {
            errorCallback(error);
        });
    }
    find(template, successCallback, errorCallback) { }
    sort(template, activityField, desc, successCallback, errorCallback) { }
}
exports.WebTemplateController = WebTemplateController;
