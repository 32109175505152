<mat-card>
  <mat-card-subtitle>
    Attachments
  </mat-card-subtitle>
  <mat-card-content>
    <div class="attachments-table">
      <app-abstract-table [data]="attachments" 
                          [columns]="displayedColumns"
                          [actions]="actions"
                          (abstractActionPreviewEvent)="previewCv($event)">
      </app-abstract-table>
    </div>

    <div class="pdf-viewer">

      <ng-container *ngIf="showPreview">
        <div class="pdf-viewer__title">Preview For Attachment: <span style="color: #0073ff;">{{selectedAttachment.title}}</span></div>
        <embed [src]="pdfViewer" type="application/pdf" style="width: 100%; height: 800px;">
      </ng-container>

    </div>
  </mat-card-content>
</mat-card>