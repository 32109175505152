<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between"> 
        <mat-form-field>
          <label>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
          </label>
        </mat-form-field>
      </div>
        <mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 30%;">Name</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 30%;">
              <span class="user-icon"></span>
              {{ row.firstName + " " + row.lastName }}
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="company">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 30%;">Company</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 30%;">{{ row["company"] }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="recruiter">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 25%;">Recruiter</mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 25%;">{{ row.ownerFirstName + " " + row.ownerLastName }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="dateCreated">
            <mat-header-cell *matHeaderCellDef mat-sort-header style="flex: 0 0 15%;"><mat-icon>query_builder</mat-icon> </mat-header-cell>
            <mat-cell *matCellDef="let row" style="flex: 0 0 15%;">{{ row["dateCreated"] }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>

        <mat-paginator [pageSizeOptions]="[5,10,25,50]"></mat-paginator>
    </div>
  </div>
</div>