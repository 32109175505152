<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-header">
        <mat-card-subtitle>
            Manage users
        </mat-card-subtitle>
        <mat-card-content>
            <button mat-button class="add-users-button" (click)="addUserOpenDialog()">ADD NEW USERS</button>
        </mat-card-content>
    </mat-card>

    <mat-card class="main-content">
        <mat-card-content>
            <app-manage-user-table [data]="user" (editUserEvent)="editUserOpenDialog($event)"></app-manage-user-table>
        </mat-card-content>
    </mat-card>
  </div>
</app-navigation>