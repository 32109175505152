"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebClientAuthorizationController = void 0;
class WebClientAuthorizationController {
    constructor(api) {
        this.api = api;
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('auth/qr-code-authorization/delete', { id: id }, response => {
            if (response.data.valid) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data);
            }
        }, err => errorCallback(err));
    }
    getByKey(key, successCallback, errorCallback) {
        this.api.postRequest('auth/qr-code-authorization/getByKey', { client_key: key }, response => {
            const responseData = response.data.data;
            let data;
            responseData ?
                data = { id: responseData.id, key: responseData.key, token: responseData.token, refreshToken: responseData.refresh_token, candidateId: responseData.candidate_id } :
                data = {};
            successCallback(data);
        }, err => errorCallback(err));
    }
    create(data, successCallback, errorCallback) {
        const src = { client_key: data.key, token: data.token, refresh_token: data.refreshToken, candidate_id: data.candidateId };
        this.api.postRequest('auth/qr-code-authorization/create', src, response => {
            if (response.data.valid) {
                successCallback(response.data);
            }
            else {
                errorCallback(response.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebClientAuthorizationController = WebClientAuthorizationController;
