export class RecruitingType {
  names = {
    project: 'Recruiting',

    searchItem: 'Candidate',
    searchItems: 'Candidates',

    workItem: 'Joborder',
    workItems: 'Joborders',

    customer: 'Company',
    customers: 'Companies',

    hashtag: 'List',
    hashtags: 'Lists',

    contactItem: 'Contact',
    contactItems: 'Contacts'
  };

  searchItemNames = {
    address: 'Address',
    bestTimeToCall: 'Best time to call',
    canRelocate: 'Can relocate',
    candidateId: 'Id',
    city: 'City',
    currentEmployer: '',
    currentPay: '',
    dateAvailable: '',
    dateCreated: 'Date created',
    dateModified: 'Date modified',
    desiredPay: 'Desired pay',
    dialog: 'Dialog',
    eeoDisabilityStatus: '',
    eeoEthnicTypeId: '',
    eeoGender: '',
    eeoVeteranTypeId: '',
    email1: 'First email',
    email2: 'Second email',
    enteredBy: 'Entered by',
    firstName: 'First name',
    img: '',
    imgResize: '',
    importId: '',
    isActive: '',
    isAdminHidden: '',
    isHot: '',
    keySkills: 'Key skills',
    lastName: 'Last name',
    middleName: '',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Phone cell',
    phoneHome: 'Phone home',
    phoneWork: 'Phone work',
    siteId: '',
    source: '',
    state: 'State',
    webSite: 'Web site',
    zip: ''
  }

  workItemItemNames = {
    id:'Id',
    alias: '',
    city: 'City',
    cityEn: '',
    cityRu: '',
    cityUa: '',
    clientJobId: '',
    companyDepartmentId: '',
    company: 'Company',
    contactId: '',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    duration: '',
    entereBy: '',
    isAdminHidden: '',
    isHot: '',
    joborderId: 'Id',
    notes: 'Notes',
    openings: '',
    openingsAvailable: '',
    owner: 'Owner',
    public: '',
    questionnaireId: '',
    rateMax: 'Max rate',
    recruiter: 'Recruiter',
    salary: 'Salary',
    seoDescriptionEn: '',
    seoKeywordsEn: '',
    seoTitleEn: '',
    siteId: '',
    startDate: '',
    state: 'State',
    status: 'Status',
    title: 'Title',
    type: ''
  }

  customerNames = {
    address: '',
    billingContact: '',
    city: '',
    companyId: '',
    companyLogo: '',
    dateCreated: '',
    dateModified: '',
    defaultCompany: '',
    enteredBy: '',
    faxNumber: '',
    importId: '',
    isHot: '',
    keyTechnologies: '',
    name: '',
    notes: '',
    owner: '',
    phone1: '',
    phone2: '',
    siteId: '',
    state: '',
    url: '',
    zip: ''
  }

  hashtagNames = {
    type: 'Data type',
    dateCreated: 'Created',
    dateModified: 'Modified',
    description: 'Description',
    isDynamic: 'Dynamic',
    savedListId: 'Id',
    owner: 'Owner',
  }

  contactItemNames = {
    address: 'Address',
    city: 'City',
    companyDepartmentId: '',
    companyId: '',
    contactId: '',
    dateCreated: '',
    dateModified: '',
    email1: 'First email',
    email2: 'Second email',
    enteredBy: '',
    firstName: 'First name',
    importId: '',
    isHot: '',
    lastName: 'Last name',
    leftCompany: '',
    notes: 'Notes',
    owner: 'Owner',
    phoneCell: 'Personal phone',
    phoneOther: 'Other phone',
    phoneWork: 'Work phone',
    reportsTo: '',
    siteId: '',
    state: 'State',
    title: 'Title',
    zip: ''
  }
}
