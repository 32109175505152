import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { GroupUsersDialogComponent } from '../app-dialogs/group-users-dialog/group-users-dialog.component';
import { GroupUserItemType, GroupUsersType, YhCore } from '../app-services/core.service';

@Component({
  selector: 'app-department-page',
  templateUrl: './department-page.component.html',
  styleUrls: ['./department-page.component.scss']
})
export class DepartmentPageComponent implements OnInit {

  public loading: boolean = false;
  public groupUserItems: GroupUserItemType[] = [];
  public groupUser: GroupUsersType;
  public currentUser = JSON.parse(YhCore.localStorageService.getItem("YHminiuser"))

  constructor(
    private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe( param => {
      const id = +param.get('id');
      this.getData(id);
    })
  }

  getData(id: number) {
    this.getGroupUserItems(id).then( res => {
      this.groupUserItems = res
      this.loading = false;
    })
  }

  getGroupUserItems(id):Promise<GroupUserItemType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.groupUsers.read({groupUserId: id}, (res: {groupUserRow: GroupUsersType, listUsersInGroup: GroupUserItemType[]}) => {
        this.groupUser = res.groupUserRow
        resolve(res.listUsersInGroup)
      }, err => reject(err))
    })
  }

  editGroupUsers() {
    this.dialog.open(GroupUsersDialogComponent, {width: '600px', data: {
      groupUser: this.groupUser
    }}).afterClosed().subscribe((res: {title: string}) => {
      if(res) this.groupUser.title = res.title;
    })
  }

}
