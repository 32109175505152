"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebShareNormSalaryCalendarController = void 0;
class WebShareNormSalaryCalendarController {
    constructor(api, shareNormsService) {
        this.api = api;
        this.shareNormsService = shareNormsService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('/sharingNormsSalaryCalendar/list', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.shareNormsService.mapShareNormsSallary(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    edit(shareNormsSallary, successCallback, errorCallback) {
        const src = this.shareNormsService.mapReversShareNormsSallary(shareNormsSallary);
        this.api.postRequest('/sharingNormsSalaryCalendar/update', Object.assign({}, src), response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
}
exports.WebShareNormSalaryCalendarController = WebShareNormSalaryCalendarController;
