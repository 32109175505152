"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationsController = void 0;
class NotificationsController {
    constructor(api, notificationService) {
        this.api = api;
        this.notificationService = notificationService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('events/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.notificationService.mapNotifications(response.data.data));
            else
                errorCallback(false);
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('events/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.notificationService.mapNotification(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('events/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    edit(notification, successCallback, errorCallback) {
        const src = this.notificationService.mapReverseNotification(notification);
        this.api.postRequest('events/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    create(notification, successCallback, errorCallback) {
        const src = this.notificationService.mapReverseNotification(notification);
        this.api.postRequest('events/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getEventsForUser(successCallback, errorCallback) {
        this.api.postRequest('events/getForUser', {}, response => {
            if (response.data.valid === true)
                successCallback(this.notificationService.mapNotifications(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getRequestsFromClient(successCallback, errorCallback) {
        this.api.postRequest('events/getRequestsFromClient', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.notificationService.mapRequestsFromClient(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    find(notification, successCallback, errorCallback) { }
    sort(notifications, workItemField, desc, successCallback, errorCallback) { }
}
exports.NotificationsController = NotificationsController;
