<app-navigation>
  <mat-card>
    <mat-card-subtitle>
      Add Company profile
    </mat-card-subtitle>
    <mat-card-content>
      <form [formGroup]="addCompanyForm" (ngSubmit)="submit()">
        <mat-form-field appearance="outline">
          <mat-label>Company name: </mat-label>
          <input class="form-control" matInput formControlName="name" required>
          <mat-error *ngIf="addCompanyForm.controls['name'].errors">Field Company name is required
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>The year of foundation: </mat-label>
          <input matInput formControlName="yearFaundation">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Main activities: </mat-label>
          <input matInput formControlName="mainActivities">
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Actual address: </mat-label>
          <input matInput formControlName="address" required>
          <mat-error *ngIf="addCompanyForm.controls['address'].errors">Field Actual address is required
          </mat-error>
        </mat-form-field>

        <div class="mobile-input-33">
          <mat-form-field appearance="outline">
            <mat-label> Contact person(Name): </mat-label>
            <input matInput formControlName="contactPersonName" placeholder="Name">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Contact person(Position): </mat-label>
            <input matInput formControlName="contactPersonPosition" placeholder="Position">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Contact person(Contact phone): </mat-label>
            <input matInput formControlName="contactPersonPhone" placeholder="Contact phone">
          </mat-form-field>
        </div>

        <mat-form-field appearance="outline">
          <mat-label> Number of employees in the company: </mat-label>
          <input matInput formControlName="numberEmployeesCompany">
        </mat-form-field>

        <div class="mb-14">
          Additional Information
          <button mat-flat-button color="primary" *ngIf="showAdditionalInfo"(click)="showAdditionalInfo = !showAdditionalInfo">
            {{showAdditionalInfo ? 'SHOW': 'HIDE'}}
          </button>
        </div>
        <div *ngIf="!showAdditionalInfo">
          <div class="mobile-input-33">
            <mat-form-field appearance="outline">
              <mat-label> Additional Contact Information(Name): </mat-label>
              <input matInput formControlName="clientsManagerName" placeholder="Name">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Additional Contact Information(Position): </mat-label>
              <input matInput formControlName="clientsManagerPosition" placeholder="Position">
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label> Additional Contact Information(Contact phone): </mat-label>
              <input matInput formControlName="clientsManagerPhone" placeholder="Contact phone">
            </mat-form-field>
          </div>

          <mat-form-field appearance="outline">
            <mat-label> Number of employees in the department: </mat-label>
            <input matInput formControlName="numberEmployeesDepartment">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Country: </mat-label>
            <input matInput formControlName="state">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> City: </mat-label>
            <input matInput formControlName="city">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Postal Code: </mat-label>
            <input matInput formControlName="zip">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Primary Phone: </mat-label>
            <input matInput formControlName="phone1">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Secondary Phone: </mat-label>
            <input matInput formControlName="phone2">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label> Fax Number: </mat-label>
            <input matInput formControlName="faxNumber">
          </mat-form-field>

        </div>
        <button mat-flat-button color="primary">ADD COMPANY</button>
      </form>
    </mat-card-content>
  </mat-card>
</app-navigation>