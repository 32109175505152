<h2 mat-dialog-title>Create {{ app.type.names['hashtag'] }}</h2>
<div mat-dialog-content>
  <mat-form-field appearance="outline">
    <mat-label>{{ app.type.hashtagNames['description'] }}</mat-label>
    <input matInput [(ngModel)]="hashtag.description" required>
  </mat-form-field>
  <section>
    <mat-checkbox [(ngModel)]="hashtag.isDynamic">Make it public</mat-checkbox>
  </section>
</div>
<div mat-dialog-actions>
  <button mat-stroked-button (click)="onCancel()">Cancel</button>
  <button mat-stroked-button (click)="onCreateHashtag()">Create</button>
</div>
