import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SearchItemAddStatusDialogComponent } from '../app-dialogs/search-item-add-status-dialog/search-item-add-status-dialog.component';
import { SearchItemEditStatusDialogComponent } from '../app-dialogs/search-item-edit-status-dialog/search-item-edit-status-dialog.component';
import { CustomerType, YhCore, WorkItemStatusType } from '../app-services/core.service';

@Component({
  selector: 'app-search-item-statuses-page',
  templateUrl: './search-item-statuses-page.component.html',
  styleUrls: ['./search-item-statuses-page.component.scss']
})
export class SearchItemStatusesPageComponent implements OnInit {

  public workItemStatuses: WorkItemStatusType[];
  public isLoading:boolean = false;
  private companyId: string;

  constructor(
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<any> {
    this.isLoading = true;
    await this.getCompany()
    await this.getWorkItemStatuses()
  }

  getCompany():Promise<CustomerType[]> {
    return new Promise( (resolve, rejects) => {
      YhCore.customers.list( (res: CustomerType[]) => {
        this.companyId = res[0].companyId
        resolve(res)
      }, err => {})
    })
  }

  getWorkItemStatuses():Promise<WorkItemStatusType[]> {
    return new Promise( (resolve, rejects) => {
      YhCore.workItemsStatuses.list(this.companyId, (res: WorkItemStatusType[]) => {
        this.workItemStatuses = res
        this.isLoading = false;
        resolve(res)
      }, err => {})
    })
  }

  deleteStatus(statusItem: WorkItemStatusType) {
    if(statusItem.statusId === 100 || statusItem.statusId === 850) {
      return this.snackBar.open(`This status cannot be deleted`, 'OK', {duration: 3000});
    }
    YhCore.workItemsStatuses.remove(statusItem.statusId, res => {
      this.workItemStatuses = this.workItemStatuses.filter(val => val.statusId !== statusItem.statusId);
      this.snackBar.open(`Status ${statusItem.statusName} was deleted`, 'OK', {duration: 3000})
    }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
  }

  editStatus(statusItem: WorkItemStatusType) {
    if(statusItem.statusId === 100 || statusItem.statusId === 850) {
      return this.snackBar.open(`This status cannot be edited`, 'OK', {duration: 3000});
    }
    const dialogRef = this.dialog.open(SearchItemEditStatusDialogComponent, { width: '600px', data: statusItem});

    dialogRef.afterClosed().subscribe( (res: WorkItemStatusType) => {
      if (res) {
        YhCore.workItemsStatuses.edit(this.companyId, Object.assign(statusItem, res), (result) => {
          this.workItemStatuses = this.workItemStatuses.map( val => {
            if (val.statusId === res.statusId) {
              val = res;
            }
            return val
          })
          this.snackBar.open(`Status ${res.statusName} was edited`, 'OK', {duration: 3000})
        },err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
      }
    })
  }

  addStatus() {
    const dialogRef = this.dialog.open(SearchItemAddStatusDialogComponent, {width: '600px'})

    dialogRef.afterClosed().subscribe( (statusItem: WorkItemStatusType) => {
      if (statusItem) {
        YhCore.workItemsStatuses.create(this.companyId, statusItem, (status:WorkItemStatusType) => {
          this.getWorkItemStatuses();
          this.snackBar.open(`Status was added`, 'OK', {duration: 3000})
        }, err => this.snackBar.open(err, 'ERROR', {duration: 5000}))
      }
    })
  }

}
