"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebCvSettingLayoutController = void 0;
class WebCvSettingLayoutController {
    constructor(api, cvSettingsLayoutService) {
        this.api = api;
        this.cvSettingsLayoutService = cvSettingsLayoutService;
    }
    read(successCallback, errorCallback) {
        this.api.postRequest('cv-settings-layout/read', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.cvSettingsLayoutService.mapCvSettingLayout(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    edit(cvSettingsField, successCallback, errorCallback) {
        const src = this.cvSettingsLayoutService.mapReversCvSettingsLayout(cvSettingsField);
        this.api.postRequest('cv-settings-layout/update', src, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    create(successCallback, errorCallback) {
        this.api.postRequest('cv-settings-layout/create', {}, response => {
            if (response.data.valid === true) {
                successCallback(response.data.data);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
}
exports.WebCvSettingLayoutController = WebCvSettingLayoutController;
