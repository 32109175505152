import { Component, OnInit, } from '@angular/core';
import { YhCore } from '../app-services/core.service';
import { MatrixUsersType } from './../app-services/core.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-matrix-users',
  templateUrl: './matrix-users-page.component.html',
  styleUrls: ['./matrix-users-page.component.scss']
})
export class MatrixUsersComponent implements OnInit {
  matrixUsers: MatrixUsersType = new MatrixUsersType();
  selectedUser: string;
  checkedAll: boolean = false;
  arrUsers = [];
  arrSelectedUsers = [];
  disabledButton: boolean = true;
  userID: string;
  accessRequest: string;

  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    parseInt(JSON.parse(localStorage.getItem('YHminiuser')).accessLevel) >= 500 ? '' : this.router.navigateByUrl('/settings')
    this.getUsers();
    this.defaultSelectedOption();
  };

  getUsers() {
    YhCore.users.list(response => {
      this.arrUsers = response;
    }, err => { console.log(err) });
  };

  getSelectedUsers(userId) {
    return new Promise( (resolve, rejects) => {
      YhCore.matrixUsers.listByUserId(userId, response => {
        this.userID = userId;
        this.arrSelectedUsers = response;
        resolve()
      }, err => { console.log(err) });
    })
  };

  async chooseSelectedId(user) {
    this.disabledButton = false;
    await this.getSelectedUsers(user);
    this.arrUsers.map((el) => {
      Object.assign(el, { access1: false, access2: false, access3: true });
      for (let val of this.arrSelectedUsers) {
        if (val.access == '1') {
          if (el.userId == val.subUserId) {
            Object.assign(el, { access1: true, access2: false, access3: false });
          }
        }
        else if (val.access == '2') {
          if (el.userId == val.subUserId) {
            Object.assign(el, { access1: false, access2: true, access3: false });
          }
        }
      }
    })
  };

  clickCheckAll() {
    if (this.checkedAll) {
      this.checkedAll = false;
    }
    else {
      this.checkedAll = true;
    }

    this.arrUsers.map((el) => {
      el.access1 = this.checkedAll;
      el.access2 = false;
      if (!el.access1) {
        el.access3 = true;
      }
      else {
        el.access3 = false;
      }
    })
  }

  clickUserButton(user) {
    if (user.access1) {
      user.access1 = false;
      user.access2 = false;
      user.access3 = true;
    }
    else if (user.access2) {
      user.access1 = true;
      user.access2 = false;
      user.access3 = false;
    }
    else if (user.access3) {
      user.access1 = false;
      user.access2 = true;
      user.access3 = false;
    }
  }

  defaultSelectedOption() {
    this.disabledButton = true;
    this.arrUsers.map((user) => {
      user.access1 = false;
      user.access2 = false;
      user.access3 = false;
    }
    )
  }

  saveMatrixUsers() {
    const rowLen = this.arrUsers.length;
    this.arrUsers.map((el, index) => {
      if (el.access1) {
        this.accessRequest = '1';
      }
      else if (el.access2) {
        this.accessRequest = '2';
      }

      if (el.access1 || el.access2) {

        if (this.arrSelectedUsers.length == 0) {
          this.createMatrixUser(this.userID, el.userId, this.accessRequest);
        }

        else if (this.arrSelectedUsers.every(arrEl => arrEl.subUserId != el.userId)) {
          this.createMatrixUser(this.userID, el.userId, this.accessRequest);
        }

        else if (this.arrSelectedUsers.some(arrEl => arrEl.subUserId === el.userId)) {
          for (let val of this.arrSelectedUsers) {
            if (el.userId === val.subUserId) {
              this.updateMatrixUser(val.id, this.userID, el.userId, this.accessRequest);
              continue;
            }
          }
        }
      }

      else {
        for (let val of this.arrSelectedUsers) {
          if (val.subUserId === el.userId) {
            this.deleteMatrixUser(val.id);
          }
        }
      }

      if (rowLen === index + 1) {
        this.matSnackBar.open('Matrix users saved successfully', 'OK', {duration: 3000});
      }
    })
  }

  createMatrixUser(owner, subUser, access) {
    this.matrixUsers.ownerId = owner;
    this.matrixUsers.subUserId = subUser;
    this.matrixUsers.access = access;
    YhCore.matrixUsers.create(this.matrixUsers, success => { }, error => { console.log(error) });
  };

  updateMatrixUser(id, owner, subUser, access) {
    this.matrixUsers.id = id;
    this.matrixUsers.ownerId = owner;
    this.matrixUsers.subUserId = subUser;
    this.matrixUsers.access = access;
    YhCore.matrixUsers.edit(this.matrixUsers, success => { }, error => { console.log(error) });
  };

  deleteMatrixUser(id) {
    YhCore.matrixUsers.remove(id, success => { }, error => { console.log(error) });
  };
}
