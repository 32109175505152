import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatrixFieldSearchItemType, YhCore, ExtraFiledType, UserType } from '../app-services/core.service';

@Component({
  selector: 'app-matrix-search-item-fields',
  templateUrl: './matrix-search-item-fields.component.html',
  styleUrls: ['./matrix-search-item-fields.component.scss']
})
export class MatrixSearchItemFieldsComponent implements OnInit {

  public loading: boolean = false;
  private _currentUser: UserType = JSON.parse(YhCore.localStorageService.getItem('YHminiuser'))

  public fields: IFields[] = [];
  private _matrixFieldsSearchItem: MatrixFieldSearchItemType[] = [];
  private _extraFields: ExtraFiledType[]

  public fieldsForm: FormGroup;

  constructor(
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  initForm() {
    this.fieldsForm = new FormGroup({
      accessLevel: new FormControl('200', [Validators.required]),
      fields: new FormControl('')
    })
  }

  async getData() {
    this.loading = true;
    await Promise.all([
      this.getExtraFields(),
      this.getSearchItemFields()
    ]).then((data: [ExtraFiledType[], string[]]) => {
      data[1].map(val => this.fields.push({fieldName: val, isExtraField: 0, extraFieldSettingsId: 0}))
      data[0].map(val => this.fields.push({fieldName: val.fieldName, isExtraField: 1, extraFieldSettingsId: +val.extraFieldSettingsId}))
      this._extraFields = data[0];
    })

    const res = await this.getMatrixFieldsSearchItem();
    this._matrixFieldsSearchItem = res;
    this.initForm();
    this._setFieldsControllValueByAccessLevel(+this._getAccessControll.value)
    this.loading = false;
  }

  getSearchItemFields():Promise<string[]> {
    return new Promise( (resolve, reject) => {
      YhCore.types.getSearchItemFieds(res => resolve(res), err => reject(err))
    })
  }

  getExtraFields(): Promise<ExtraFiledType[]> {
    return new Promise<ExtraFiledType[]>(resolve => {
      YhCore.extraFields.list((extraFields: ExtraFiledType[]) => {
        resolve(extraFields)
      }, error => {})
    })
  }

  getMatrixFieldsSearchItem():Promise<MatrixFieldSearchItemType[]> {
    return new Promise( (resolve, reject) => {
      YhCore.matrixUsers.getMatrixFieldSearchItemSettings((res:MatrixFieldSearchItemType[]) => {
        resolve(res)
      },err => reject(err))
    })
  }

  setMatrixFieldsSearchItem(data: MatrixFieldSearchItemType[]):Promise<any> {
    return new Promise( (resolve, reject) => {
      YhCore.matrixUsers.setMatrixFieldSearchItemSettings({fields: data}, res => {
        resolve(res)
      }, err => reject(err))
    })
  }

  changeAccessLevel() {
    this._setFieldsControllValueByAccessLevel(+this._getAccessControll.value)
  }

  onSaveForm() {
    const fields: string[] = this._getFieldsControll.value;
    const accessLevel: number = +this._getAccessControll.value;
    const data:MatrixFieldSearchItemType[] = fields.map(field => {
      const isExtraField = this._extraFields.find(val => val.fieldName.toLowerCase() === field.toLowerCase())
      return {
        fieldName: field,
        isExtraField: isExtraField ? 1: 0,
        extraFiledSettingsId: isExtraField ? +isExtraField.extraFieldSettingsId: 0,
        companyId: this._currentUser.companyId,
        accessLevel: accessLevel
      }
    }).concat( this._matrixFieldsSearchItem.filter(val => +val.accessLevel !== +accessLevel));
    this.setMatrixFieldsSearchItem(data)
      .then( res => this.snackBar.open("Fields Successfully saved", "OK", {duration: 3000}))
      .catch(err => this.snackBar.open(err.error_msg, 'ERROR', {duration: 5000}))
      .finally( () => this._matrixFieldsSearchItem = data)
  }

  private _setFieldsControllValueByAccessLevel(accessLevel: number) {
    this._getFieldsControll.setValue(this._matrixFieldsSearchItem.filter(val => +val.accessLevel === +accessLevel).map(val => val.fieldName));
  }

  private get _getFieldsControll(): AbstractControl { return this.fieldsForm.get('fields') as AbstractControl}
  private get _getAccessControll(): AbstractControl { return this.fieldsForm.get('accessLevel') as AbstractControl}
}

interface IFields {
  fieldName: string
  isExtraField: number
  extraFieldSettingsId: number
}