"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebWorkItemsPipelineController = void 0;
class WebWorkItemsPipelineController {
    constructor(api, workItemPipelineService) {
        this.api = api;
        this.workItemPipelineService = workItemPipelineService;
    }
    list(successCallback, errorCallback) { }
    read(id, successCallback, errorCallback) { }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('pipelines/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // ErAPI endpoint - YhClient
    addActivityChangeStatus(candidateId, workitemId, satatusId, successCallback, errorCallback) {
        this.api.postRequest('searchItem/addActivityChangeStatus', {
            candidate_id: candidateId,
            joborder_id: workitemId,
            status_id: satatusId
        }, response => {
            if (response.data.valid === true) {
                successCallback(response.data.valid);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    create(workItemPipeline, successCallback, errorCallback) {
        const src = this.workItemPipelineService.mapReverseWorkItemPipeline(workItemPipeline);
        this.api.postRequest('pipelines/create', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.created_id);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // ErAPI endpoint - YhClient
    getPipelinesByWorkItem(id, successCallback, errorCallback) {
        this.api.postRequest('pipelines/searchItems', { workitem_id: id }, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemPipelineService.mapWorkItemPipelines(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getPipelinesBySearchItem(id, successCallback, errorCallback) {
        this.api.postRequest('pipelines/searchItemPipelines', { searchitem_id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.workItemPipelineService.mapSearchItemPipelines(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getAllPipeline(successCallback, errorCallback) {
        this.api.postRequest('pipelines/getSearchItemPipelines', {}, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemPipelineService.mapWorkItemPipelines(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    find(workItemPipeline, successCallback, errorCallback) { }
    sort(workItemPipeline, workItemField, desc, successCallback, errorCallback) { }
}
exports.WebWorkItemsPipelineController = WebWorkItemsPipelineController;
