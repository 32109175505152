"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebUsersController = void 0;
class WebUsersController {
    constructor(api, usersService, localStorageService) {
        this.api = api;
        this.usersService = usersService;
        this.localStorageService = localStorageService;
    }
    list(successCallback, errorCallback) {
        this.api.postRequest('user/list', {}, response => {
            if (response.data.valid === true)
                successCallback(this.usersService.mapUsers(response.data.data));
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('user/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.usersService.mapUser(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    remove(id, successCallback, errorCallback) {
        this.api.postRequest('user/delete', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    edit(user, successCallback, errorCallback) {
        const src = this.usersService.mapReverseUser(user);
        this.api.postRequest('user/update', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    create(user, successCallback, errorCallback) {
        const src = this.usersService.mapReverseUser(user);
        this.api.postRequest('user/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getStaticData(successCallback, errorCallback) {
        this.api.postRequest('user/getData', {}, response => {
            if (response.data.valid === true) {
                const user = this.usersService.mapUser(response.data.data);
                this.localStorageService.setItem('YHminiuser', JSON.stringify(user));
                successCallback(user);
            }
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    getAccessLevels(successCallback, errorCallback) {
        this.api.postRequest('types/accessLevels', {}, response => {
            if (response.data.valid === true)
                successCallback(response.data.types);
            else
                errorCallback(false);
        }, error => errorCallback(error));
    }
    find(user, successCallback, errorCallback) { }
    sort(user, searchItemField, desc, successCallback, errorCallback) { }
    changePassword(userId, newPassword, currentPassword, successCallback, errorCallback) {
        const src = { user_id: userId, new_password: newPassword, current_password: currentPassword };
        this.api.postRequest('/user/changePassword', src, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    changeEmailPassword(userId, newPassword, currentPassword, successCallback, errorCallback) {
        const src = { user_id: userId, new_email_password: newPassword, current_email_password: currentPassword };
        this.api.postRequest('/user/changeEmailPassword', src, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => errorCallback(error));
    }
    getColumnPreferences(successCallback, errorCallback) {
        this.api.postRequest('user/getUserColumnPreferences', {}, res => {
            if (res.data.valid === true) {
                successCallback(res.data.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
    saveColumnPreferences(payload, successCallback, errorCallback) {
        this.api.postRequest('user/saveUserColumnPreferences', { column_preferences: payload.columnPreferences }, res => {
            if (res.data.valid === true) {
                successCallback(res.data.data);
            }
            else {
                errorCallback(res.data);
            }
        }, err => errorCallback(err));
    }
}
exports.WebUsersController = WebUsersController;
