import yh from 'core/dist';
import { User } from 'core/dist/types/user-type';
import { SearchItem } from 'core/dist/types/search-item-type';
import { Customer, CustomerNotes } from 'core/dist/types/сustomer-type';
import { ContactItem } from 'core/dist/types/contact-item-type';
import { Hashtag } from 'core/dist/types/hashtag-type';
import { WorkItem } from 'core/dist/types/work-item-type';
import { WorkItemStatus } from 'core/dist/types/work-item-status';
import { Notification } from 'core/dist/types/notifications-type';
import { WorkItemPipeline } from 'core/dist/types/work-item-pipeline-type';
import { LocalStorageService } from 'core/dist/services/local-storage-service';
import { SearchItemPipeline } from 'core/dist/types/search-item-pipeline';
import { Activity } from 'core/dist/types/activity-type';
import { ActivityStatus } from 'core/dist/types/activity-status-type';
import { SearchItemSharing } from 'core/dist/types/search-item-sharing-type';
import { SearchItemSharingHistory } from 'core/dist/types/search-item-sharing-history-type';
import { Message } from 'core/dist/types/message-type';
import { Template } from 'core/dist/types/template-type';
import { MatrixUsers } from 'core/dist/types/matrix-users-type';
import { Report } from 'core/dist/types/reports-type';
import { Period } from 'core/dist/types/period-type';
import { Calendar } from 'core/dist/types/calendar-type';
import { FollowUp } from 'core/dist/types/follow-up-type';
import { WorkItemNotes } from 'core/dist/types/workItem-notes-type';
import { ExtraField } from 'core/dist/types/extra-field-type';
import { SearchItemHistory } from 'core/dist/types/search-item-history-type';
import { Calibration } from 'core/dist/types/client-calibration';
import { Attachment } from 'core/dist/types/attachments-type';
import { ClientAuthorization } from 'core/dist/types/client-authorization-type';
import { JoborderGroup } from "core/dist/types/joborder-group-type"
import { GroupUsers, GroupWithUsers } from 'core/dist/types/group-users-type';
import { GroupUserItem } from 'core/dist/types/group-user-item-type';
import { MatrixFieldSearchItem } from 'core/dist/types/matrix-field-search-item';

yh.init('web', 'https://capi.huntme.ua/api/');


export const YhCore = yh;
export const localStorageService = new LocalStorageService('web');
export class UserType extends User {};
export class SearchItemType extends SearchItem {};
export class CustomerType extends Customer {};
export class ContactItemType extends ContactItem {};
export class HashtagType extends Hashtag {};
export class WorkItemType extends WorkItem {};
export class WorkItemStatusType extends WorkItemStatus {};
export class NotificationType extends Notification {};
export class WorkItemPipelineType extends WorkItemPipeline{};
export class SearchItemPipelineType extends SearchItemPipeline{};
export class ActivityType extends Activity{};
export class ActivityStatusType extends ActivityStatus{};
export class MessageType extends Message {};
export class SearchItemSharingType extends SearchItemSharing {};
export class SearchItemSharingHistoryType extends SearchItemSharingHistory {};
export class TemplateType extends Template {};
export class MatrixUsersType extends MatrixUsers{};
export class ReportType extends Report {};
export class PeriodType extends Period {};
export class CalendarType extends Calendar {};
export class FollowUpType extends FollowUp {};
export class WorkItemNotesType extends WorkItemNotes {};
export class CustomerNotesType extends CustomerNotes {};
export class ExtraFiledType extends ExtraField {};
export class SearchItemHistoryType extends SearchItemHistory {};
export class Calibrationtype extends Calibration {};
export class AttachmentType extends Attachment {};
export class ClientAuthorizationType extends ClientAuthorization {};
export class JoborderGroupType extends JoborderGroup {};
export class GroupUsersType extends GroupUsers {};
export class GroupWithUsersType extends GroupWithUsers {};
export class GroupUserItemType extends GroupUserItem {};
export class MatrixFieldSearchItemType extends MatrixFieldSearchItem {};
