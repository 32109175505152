import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UserType, YhCore } from '../app-services/core.service';

@Component({
  selector: 'app-auth-redirect-page',
  templateUrl: './auth-redirect-page.component.html',
  styleUrls: ['./auth-redirect-page.component.scss']
})
export class AuthRedirectPageComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe( param => {
      const token = param.token;
      const refreshToken = param.refreshToken;
      const candidateId = +param.candidateId;
      this.setTokenToLocalStorage(token,refreshToken);
      this.getStaticData().then( res => {
        if(res && candidateId) this.router.navigateByUrl(`/candidates/${candidateId}`);
        else this.router.navigateByUrl(`/candidates`);
      }).catch( err => {
        this.snackBar.open('Auth credentional is invalid', 'ERROR', {duration:5000});
        YhCore.api.invalidate();
        this.router.navigateByUrl('/login');
      })
    })
  }

  getStaticData():Promise<UserType> {
    return new Promise( (resolve, reject) => {
      YhCore.users.getStaticData((currentUser: UserType) => {
        resolve(currentUser)
      }, err => reject(err))
    })
  }

  setTokenToLocalStorage(token:string, refreshToken: string) {
    YhCore.api.setAuthorization(token, refreshToken);
  }

}
