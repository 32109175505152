<h2 mat-dialog-title>Access {{app.type.names['workItems'].toUpperCase()}}</h2>
<mat-dialog-content>
  <form [formGroup]="accessForm" #form="ngForm">

    <ng-container *ngIf="!data.onlyWorkItem">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Set company</mat-label>
        <mat-select formControlName="company">
          <mat-option value="company">Set company access</mat-option>
          <mat-option value="workItem">Set company work items access</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="ifWorkItemAccess && !data.onlyWorkItem">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label>Set work item</mat-label>
        <mat-select formControlName="workItem">
          <mat-option value="">Select work items</mat-option>
          <mat-option *ngFor="let workItem of customerWorkItem" [value]="workItem">{{workItem.title}}</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <div>
      <ng-container *ngIf="users">
        <mat-button-toggle-group [multiple]="true" [(ngModel)]="accessUsers" [ngModelOptions]="{standalone: true}">
          <mat-button-toggle *ngFor="let user of users" (change)="toogleUser(user, $event)" [value]="user">{{user.firstName}} {{user.lastName}}</mat-button-toggle>
        </mat-button-toggle-group>
      </ng-container>
    </div>

  </form>
</mat-dialog-content>
<div mat-dialog-actions style="padding-top: 10px;">
  <button mat-raised-button color="primary" (click)="close()">Close</button>
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!accessForm.valid">Save</button>
</div>

