import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  YhCore,
  TemplateType,
  SearchItemPipelineType,
  SearchItemType,
  ContactItemType,
  MessageType,
  WorkItemPipelineType,
  WorkItemType
} from '../../app-services/core.service';
import { AppService } from '../../app-services/app.service';


export interface DialogData {
  candidate: SearchItemType,
  candidatePipeline: SearchItemPipelineType[],
  email: string,
  contactActivity: boolean,
  contact: ContactItemType
}

@Component({
  selector: 'app-search-item-send-email-dialog',
  templateUrl: 'search-item-send-email-dialog.component.html',
  styleUrls: ['./search-item-send-email-dialog.component.scss']
})
export class SearchItemSendEmailDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SearchItemSendEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    public app: AppService
  ) { }

  public templateDialogs: TemplateType[] = [];
  public candidateStatuses = [] = []
  chosenPipeline: SearchItemPipelineType;
  public templateDialog: TemplateType;
  public status: string;
  public message: MessageType = new MessageType();
  public workItems: WorkItemType[] = [];
  private selectedWorkItemJobOrderId:string

  async ngOnInit() {
    this.templateDialogs = await this.getTemplateDialogs();


    if (this.data.candidatePipeline.length === 0) {
      this.workItems = await this.getPipelines();
    }
    // YhCore.workItemsStatuses.list(success => { this.candidateStatuses = success }, error => { })

  }

  getTemplateDialogs() {
    return new Promise<TemplateType[]>((resolve) => {
      YhCore.templates.list(templates => { resolve(templates); }, err => { })
    })
  }

  getPipelines() {
    return new Promise<WorkItemType[]>((resolve) => {
      YhCore.workItems.list(pipelines => { resolve(pipelines); }, err => { })
    })
  }

  constructMessageBody(message: string): string {
    message = message.replace('%contact_name%', this.data.candidate.firstName || this.data.candidate.lastName || this.data.contact.firstName);
    message = message.replace('%company_name%', this.data.candidate.lastName || this.data.contact.lastName);
    message = message.replace('%offer_name%', this.chosenPipeline.title || this.data.contact.title);

    return message;
  }

  onTemplateSelectionChange() {
    if (this.chosenPipeline === undefined) {
      this.snackBar.open('Please select Regarding ', 'OK', {
        duration: 3000,
      });
      this.templateDialog = new TemplateType()
      return;
    }
    this.message.message = this.constructMessageBody(this.templateDialog.body);
    this.message.subject = this.templateDialog.subject;
  }

  async onSend(): Promise<void> {
    // if (this.data.contactActivity) {
    //   this.message.message += 'Activity related to contact - ' + this.data.contact.firstName;
    // }

    if (this.chosenPipeline === undefined || this.status === undefined) {
      this.snackBar.open('Please select Regarding & Status', 'OK', {
        duration: 3000,
      });
      return;
    }

    this.message.to = this.data.email;
    this.message.searchitemId = this.data.candidate.candidateId;
    this.message.joborderId = this.chosenPipeline.joborderId;
    
    if (this.data.candidatePipeline.length) {
      const workItem = this.data.candidatePipeline.find(val => val.joborderId === this.selectedWorkItemJobOrderId);
      this.data.candidatePipeline = await this.createSIPipelines(workItem)
      this.chosenPipeline = this.data.candidatePipeline[0];
    }

    YhCore.messenger.send(this.message, success => {

      // Updating Status
      YhCore.workItemsPipeline.getPipelinesByWorkItem(+this.chosenPipeline.joborderId, workItemPipelines => {
        let pipelineToEdit: string;
        workItemPipelines.map((item: WorkItemPipelineType) => {
          if (item.candidateId === this.data.candidate.candidateId) {
            pipelineToEdit = item.candidateJoborderId;
          }
        })

        const editedPipeline = new WorkItemPipelineType();
        editedPipeline.candidateJoborderId = pipelineToEdit;
        editedPipeline.status = this.status;
        // YhCore.workItemsPipeline.edit(editedPipeline, result => {
        //   const snackBarRef = this.snackBar.open(` Your email was successfully sent and status was updated`, 'OK', {
        //     duration: 3000,
        //   }).afterDismissed().subscribe(res => { this.onCancel()})

        // }, err => { })
      }, err => {})
    }, err => {
      this.snackBar.open(err, 'OK', {
        duration: 3000,
      }) })
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  addToPipeline(workItem:WorkItemType) {
    const pipeline:SearchItemPipelineType = new SearchItemPipelineType()
    pipeline.title = workItem.title
    pipeline.joborderId = workItem.joborderId
    this.data.candidatePipeline.push(pipeline)
    this.chosenPipeline = this.data.candidatePipeline[0];
    this.mapSIPipelineandWorkItems();
    this.selectedWorkItemJobOrderId = workItem.joborderId;
  }

  createSIPipelines(workItem): Promise<SearchItemPipelineType[]> {
    return new Promise<SearchItemPipelineType[]>(resolve => {

      const workItemPipeline = new WorkItemPipelineType();
      workItemPipeline.candidateId = this.data.candidate.candidateId || this.data.contact.companyId;
      workItemPipeline.joborderId = workItem.joborderId;
      workItemPipeline.siteId = '2';
      workItemPipeline.status = '100';
      YhCore.workItemsPipeline.create(workItemPipeline, sucess => {
        YhCore.workItemsPipeline.getPipelinesBySearchItem(+workItemPipeline.candidateId,
          (pipelines: SearchItemPipelineType[]) => {
            resolve(pipelines);
          }, err => { })
      }, err => { })
    })
  }

  // Would be better to move it into core???
  mapSIPipelineandWorkItems(): void {
    this.data.candidatePipeline.map(async pipeline => {
      this.workItems.map((workItem: WorkItemType) => {
        if (pipeline.joborderId === workItem.joborderId) {
          pipeline.title = workItem.title;
        }
      })
    })
  }
}
