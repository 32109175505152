<div class="main-table-wrap">
  <div class="table-wrap">
    <div class="table-wrap__inner">
      <div class="table-header flex flex-between">
        <mat-form-field>
          <label>
            <input matInput (keyup)="applySearchFilter($event.target['value'])" placeholder="Search">
          </label>
        </mat-form-field>
      </div>

      <mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(row) : null"
                          [checked]="selection.isSelected(row)">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Title</mat-header-cell>
          <mat-cell *matCellDef="let row" style="cursor: pointer;" (click)="previewCondition(row)">{{row['title']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="fee">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Fee</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row['fee']}} {{row['feeSign']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="feeType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Fee type</mat-header-cell>
          <mat-cell *matCellDef="let row">{{row['feeType']}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="partner">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Partner</mat-header-cell>
          <mat-cell *matCellDef="let row">HuntME</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>

      <mat-card id="spinner" *ngIf="isLoading">
        <mat-progress-spinner
          color="primary"
          mode="indeterminate">
        </mat-progress-spinner>
      </mat-card>

      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div><!-- End of Table Wrap Inner-->
  </div>
</div>
