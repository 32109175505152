import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-create-admin-group-dialog',
  templateUrl: './confirm-create-admin-group-dialog.component.html',
  styleUrls: ['./confirm-create-admin-group-dialog.component.scss']
})
export class ConfirmCreateAdminGroupDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfirmCreateAdminGroupDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  onCancel() {
    this.dialogRef.close(false)
  }

  onAccept() {
    this.dialogRef.close(true) 
  }

}
