import { Component, Input, OnInit } from '@angular/core';
import { Condition } from 'core/dist/types/conditions-type';

@Component({
  selector: 'app-work-item-accepted-condition',
  templateUrl: './work-item-accepted-condition.component.html',
  styleUrls: ['./work-item-accepted-condition.component.scss']
})
export class WorkItemAcceptedConditionComponent implements OnInit {


  @Input() public condition: Condition;

  constructor() { }

  ngOnInit(): void {
  }

}
