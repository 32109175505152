<app-navigation>
  <div class="main-container-no-scroll">
    <mat-card class="main-content">
      <mat-card-content>

        <div style="margin-bottom: 20px;">
          <button mat-flat-button color="primary" (click)="addStatus()">ADD NEW STATUS</button>
        </div>

        <mat-divider></mat-divider>

        <div style="padding-top: 15px;">
          <app-search-item-statuses-table (editStatus)="editStatus($event)" (deleteStatus)="deleteStatus($event)" [isLoading]="isLoading" [data]="workItemStatuses"></app-search-item-statuses-table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  </app-navigation>