"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebAuthenticationController = void 0;
class WebAuthenticationController {
    constructor(userService, api) {
        this.userService = userService;
        this.api = api;
    }
    /**
     * Login method
     */
    login(login, password, successCallback, errorCallback) {
        this.api.postRequest('auth/login', { login: login, password: password }, response => {
            if (response.data.valid === true) {
                this.api.setAuthorization(response.data.token, response.data.refreshToken);
                successCallback(response);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    logout(successCallback, errorCallback) { }
    signUp(user, successCallback, errorCallback) {
        const src = this.userService.mapReverseUser(user);
        this.api.postRequest('auth/sign-up', src, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
    forgotPassword(email, successCallback, errorCallback) {
        this.api.postRequest('auth/reset-password', { email }, response => {
            if (response.data.valid === true) {
                successCallback(true);
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, err => errorCallback(err));
    }
}
exports.WebAuthenticationController = WebAuthenticationController;
