<app-navigation>
  <mat-card class="main-content" *ngIf="groupUser; else spinner">
    <mat-card-title style="margin-bottom: 20px;" class="flex flex-align-center">
      {{groupUser.title}}
      <button style="margin-left: 5px;" mat-icon-button color="primary" (click)="editGroupUsers()" *ngIf="+currentUser.accessLevel >= 400"><mat-icon>edit</mat-icon></button>
    </mat-card-title>
    <mat-divider></mat-divider>

    <mat-card-content>
      <app-group-user-table [loading]="loading" [data]="groupUserItems"></app-group-user-table>
    </mat-card-content>
  </mat-card>
</app-navigation>

<ng-template #spinner>
  <mat-card id="spinner">
    <mat-progress-spinner color="primary" mode="indeterminate"></mat-progress-spinner>
  </mat-card>
</ng-template>
