"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkItemsStatusesService = void 0;
const work_item_status_1 = require("../types/work-item-status");
class WorkItemsStatusesService {
    constructor() { }
    mapReverseWorkItemStatus(src) {
        const result = {};
        if (src.canBeScheduled)
            result.can_be_scheduled = src.canBeScheduled;
        if (src.candidateJoborderStatusId)
            result.id = src.candidateJoborderStatusId;
        if (src.isEnabled)
            result.is_enabled = src.isEnabled;
        if (src.shortDescription)
            result.short_description = src.shortDescription;
        if (src.statusColor)
            result.status_color = src.statusColor;
        if (src.triggersEmail)
            result.triggers_email = src.triggersEmail;
        if (src.statusId)
            result.status_id = src.statusId;
        if (src.statusName)
            result.status_name = src.statusName;
        if (src.statusRank)
            result.status_rank = src.statusRank;
        return result;
    }
    mapWorkItemStatuses(raw) {
        return raw.map((val) => this.mapWorkItemStatus(val));
    }
    mapWorkItemStatus(raw) {
        const result = new work_item_status_1.WorkItemStatus();
        result.canBeScheduled = raw.can_be_scheduled;
        result.candidateJoborderStatusId = raw.candidate_joborder_status_id;
        result.isEnabled = raw.is_enabled;
        result.shortDescription = raw.short_description;
        result.statusColor = raw.status_color;
        result.triggersEmail = raw.triggers_email;
        result.statusId = raw.status_id;
        result.statusName = raw.status_name;
        result.statusRank = raw.status_rank;
        return result;
    }
}
exports.WorkItemsStatusesService = WorkItemsStatusesService;
