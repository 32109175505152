<app-navigation>
  <div class="main-container-no-scroll">
  <mat-card class="charts-carousel">
    <mat-card-content >
    <ngx-slick-carousel class="carousel" 
                        #slickModal="slick-carousel" 
                        [config]="slideConfig" 
                        >
        <div ngxSlickItem *ngFor="let chart of charts; index as i" class="slide cursor-pointer" (click) ="onSelect(chart, $event)"> 
          <div class="slide-wrap" [class.active] = "chart.active">
            <h4 class="slide-wrap_header">
              {{chart.title}}
            </h4>
              <div class="slide-wrap_body">
                <ngx-charts-area-chart
                      [scheme]="{domain: chartColors}"
                      [showXAxisLabel]="false"
                      [showYAxisLabel]="false"
                      [legend] = "false"
                      [xAxis]="false"
                      [yAxis]="true"
                      [xAxisLabel]="xAxisLabel"
                      [yAxisLabel]="yAxisLabel"
                      [results]="chart.data"
                      >
                </ngx-charts-area-chart>
            </div>
          </div>
        </div>
    </ngx-slick-carousel>
    </mat-card-content>
  </mat-card>

  <mat-card class="main-chart">
    <div class="main-chart__inner">
      <div class="header flex flex-between">
        <mat-card-title class="flex flex-center">
          {{mainChartData.title}}
        </mat-card-title>
  
        <div class="data-picker-form flex flex-align-center">
          <mat-form-field appearance="outline">
            <mat-label>Split by</mat-label>
              <mat-select [(ngModel)]="splitBy">
                <mat-option [value]="'day'">Day</mat-option>
                <mat-option [value]="'month'">Month</mat-option>
                <mat-option [value]="'year'">Year</mat-option>
              </mat-select>
           </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Chose Users</mat-label>
              <mat-select [(ngModel)]="selectedUsers" multiple>
                <div class="filter__top">
                    <mat-checkbox [checked] = "allSelected" (change) = "toggleSelection()" class="flex flex-align-center"> {{(allSelected) ? 'Clear All': 'Select All'}}</mat-checkbox>
                  <button mat-flat-button color="primary" (click)="onlyActiveUsers()" [class.non-active] = "!onlyActive">Only Active</button>
                </div>
                <mat-option *ngFor="let user of users" [value]="user.userId">{{user.firstName}} {{user.lastName}}</mat-option>
              </mat-select>
           </mat-form-field>
           <mat-form-field appearance="outline">
            <mat-label>Start date</mat-label>
            <input matInput [matDatepicker]="picker"
                            [(ngModel)]="startDateRaw"
                            name="startDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
           </mat-form-field>
  
          <mat-form-field appearance="outline">
            <mat-label>End date</mat-label>
            <input matInput [matDatepicker]="picker2"
                            [(ngModel)]="endDateRaw"
                            name="endDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
        <button class="action" mat-flat-button color="primary" (click)='getReportData()'>Apply</button>
      </div>
    </div>
  
    <mat-card-content>
      <ngx-charts-area-chart
          [scheme]="{domain: chartColors}"
          [showXAxisLabel]="true"
          [showYAxisLabel]="true"
          [legend] = "true"
          [xAxis]="true"
          [yAxis]="true"
          [yAxisLabel]="yAxisLabel"
          [results]="mainChartData.data">
    </ngx-charts-area-chart>
      </mat-card-content>
    </div>
  </mat-card>
</div>
</app-navigation>

