import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppService } from '../../app-services/app.service';
import { YhCore, HashtagType } from '../../app-services/core.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-create-hashtag-dialog',
  templateUrl: './create-hashtag-dialog.component.html',
  styleUrls: ['./create-hashtag-dialog.component.scss']
})
export class CreateHashtagDialogComponent {

  hashtag: HashtagType = new HashtagType();

  constructor(
    public app: AppService,
    public dialogRef: MatDialogRef<CreateHashtagDialogComponent>,
    private snackBar: MatSnackBar
  ) { }

  onCreateHashtag() {
    if (!this.hashtag.description) {
      this.snackBar.open(`Please, prodive a description for this ${this.app.type.names.hashtag.toLocaleLowerCase()}`,
      'OK', {duration: 3000});
      return;
    }
    this.hashtag.dataItemType = '100';
    YhCore.hashtags.create(this.hashtag, () => {
      this.dialogRef.close(true);
      this.snackBar.open("Request add", 'OK', {duration: 5000})
    }, err => { this.snackBar.open(err, 'ERROR', {duration: 5000})})
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
