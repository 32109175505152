import { User } from './../../app-services/user.type';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import {AppService} from "../../app-services/app.service";

@Component({
  selector: 'app-manage-user-table',
  templateUrl: './manage-user-table.component.html',
  styleUrls: ['./manage-user-table.component.scss']
})
export class ManageUserTableComponent {
  public displayedColumns = ['firstName', 'lastName', 'userName', 'edit'];

  public dataSource: MatTableDataSource<User>;
  private paginator: MatPaginator;
  private sort: MatSort;

  @Input() public set data(data: User[]) {
    this.applyDataSource(data);
  }

  @Output() editUserEvent = new EventEmitter<User>();

  constructor(
    public app: AppService
  ) {}


  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.applyDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.applyDataSourceAttributes();
  }

  applyDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter('');
    }
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  applyDataSource(data: User[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editUser(user: User) {
    this.editUserEvent.emit(user);
  }

}
