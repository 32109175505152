import { YhCore } from './../../app-services/core.service';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SearchItemType } from 'src/app/app-services/core.service';

@Component({
  selector: 'app-preview-cv-settings-dialog',
  templateUrl: './preview-cv-settings-dialog.component.html',
  styleUrls: ['./preview-cv-settings-dialog.component.scss']
})
export class PreviewCvSettingsDialogComponent implements OnInit {
  @ViewChild('block1') block1: ElementRef;
  @ViewChild('block2') block2: ElementRef;
  @ViewChild('block3') block3: ElementRef;

  public hideElement: boolean;
  public selectedCandidate: any;
  public candidate:SearchItemType= new SearchItemType();
  public cvSettings = {}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
  ) { }

  ngOnInit(): void {
    this.cvSettings = this.data['cvSettings'];
  }

  getPreviewData(): Promise<SearchItemType> {
    return new Promise<SearchItemType>(resolve => {
    YhCore.searchItems.read( this.selectedCandidate,response => {
      resolve(response);
    }, err => { })
    })
   }

  async showPreview() {
    this.candidate = await this.getPreviewData();
    this.block1.nativeElement.innerHTML = '';
    this.block2.nativeElement.innerHTML = '';
    this.block3.nativeElement.innerHTML = '';
        // tslint:disable-next-line:forin
        for (const key in this.candidate) {
          for (const item of this.cvSettings['fieldSettingsData']) {
            if (item.filedNameSet === key && item.isActive === true) {
              const p = this.renderer.createElement('p');
              p.innerHTML = `${item.filedName + ' : ' + this.candidate[key]}`;
              if (item.block === '1') {
                this.renderer.appendChild(this.block1.nativeElement, p);
              }
              else if (item.block === '2') {
                this.renderer.appendChild(this.block2.nativeElement, p);
              }
              else {
                this.renderer.appendChild(this.block3.nativeElement, p);
              }
            }
          }
        }
    this.hideElement = true;
  }

  hideElementFoo() {
    if (this.hideElement) {
      this.hideElement = false;
    }
  }
}
