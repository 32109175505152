"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WebWorkItemsStatusesController = void 0;
class WebWorkItemsStatusesController {
    constructor(api, workItemsStatusesService) {
        this.api = api;
        this.workItemsStatusesService = workItemsStatusesService;
    }
    // ErAPI endpoint - YhClient
    list(companyId, successCallback, errorCallback) {
        this.api.postRequest('workitemStatus/list', { company_id: companyId }, response => {
            if (response.data.valid === true) {
                successCallback(this.workItemsStatusesService.mapWorkItemStatuses(response.data.data));
            }
            else {
                errorCallback(response.data.error_msg);
            }
        }, error => { errorCallback(error); });
    }
    read(id, successCallback, errorCallback) {
        this.api.postRequest('workitemStatus/read', { id: id }, response => {
            if (response.data.valid === true)
                successCallback(this.workItemsStatusesService.mapWorkItemStatus(response.data.data));
            else
                errorCallback(response.data.error_msg);
        }, error => { errorCallback(error); });
    }
    // ErAPI endpoint - YhClient
    remove(statusId, successCallback, errorCallback) {
        this.api.postRequest('workitemStatus/delete', { status_id: statusId }, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // ErAPI endpoint - YhClient
    edit(companyId, workitemStatus, successCallback, errorCallback) {
        const src = this.workItemsStatusesService.mapReverseWorkItemStatus(workitemStatus);
        src.company_id = companyId;
        this.api.postRequest('workitemStatus/update', src, response => {
            if (response.data.valid === true)
                successCallback(response.data.valid);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    // ErAPI endpoint - YhClient
    create(companyId, workitemStatus, successCallback, errorCallback) {
        const src = this.workItemsStatusesService.mapReverseWorkItemStatus(workitemStatus);
        src.company_id = companyId;
        this.api.postRequest('workitemStatus/create', src, response => {
            if (response.data.valid === true)
                successCallback(true);
            else
                errorCallback(response.data.error_msg);
        }, error => errorCallback(error));
    }
    find(workitemStatus, successCallback, errorCallback) { }
    sort(workitemStatuses, workItemField, desc, successCallback, errorCallback) { }
}
exports.WebWorkItemsStatusesController = WebWorkItemsStatusesController;
