import {Injectable} from '@angular/core';
import {ExtraField} from "./extra-field.type";
import {map, publishLast, refCount} from "rxjs/operators";
import {YhApiService} from "./yh-api.service";
import {ExtraFieldType} from "./extra-field-type.type";
import {Observable, of} from "rxjs";
import {Candidate} from "./candidate.type";

@Injectable({
  providedIn: 'root'
})
export class ExtraFieldService {
  private extraFields: ExtraField[];
  private extraFieldTypes: ExtraFieldType[];

  constructor(
    private api: YhApiService
  ) { }

  getExtraFields(): {
    extraFields: Observable<ExtraField[]>; extraFieldTypes: Observable<ExtraFieldType[]>
  } {
    const request = this.api.request<any>('candidate/getForAdd', { }).pipe(
      publishLast(), refCount()
    );

    if (this.extraFields != null && this.extraFieldTypes != null) {
      return {
        extraFields: of(this.extraFields), extraFieldTypes: of(this.extraFieldTypes)
      };
    }

    return {
      extraFields: request.pipe(
        map(val => this.mapExtraFields(val.listExtraField))
      ),
      extraFieldTypes: request.pipe(
        map(val => this.mapExtraFieldTypes(val.listExtraFieldType))
      )
    };
  }

  mapExtraFields(raw: any[]) {
    return raw.map(val => this.mapExtraField(val));
  }

  mapExtraFieldTypes(raw: any[]) {
    return raw.map(val => this.mapExtraFieldType(val));
  }

  mapExtraField(raw: any) {
    const result = new ExtraField();

    result.dataItemType = raw.data_item_type;
    result.dateCreated = raw.date_created;
    result.extraFieldOptions = raw.extra_field_options !== null ? this.mapExtraFieldOptions(raw.extra_field_options) : null;
    result.extraFieldSettingsId = raw.extra_field_settings_id;
    result.extraFieldType = raw.extra_field_type;
    result.extraFieldValue = 'extra_field_value' in raw ? raw.extra_field_value : '';
    result.fieldName = raw.field_name;
    result.importId = raw.import_id;
    result.position = raw.position;
    result.siteId = raw.site_id;

    return result;
  }

  mapExtraFieldType(raw: any) {
    const result = new ExtraFieldType();

    result.hasOptions = raw.hasOptions;
    result.name = raw.name;
    result.value = raw.value;

    return result;
  }

  mapExtraFieldOptions(raw: any): string[] {
    const result = raw.split(',').filter(function (el) {
      return el != '';
    });

    return result;
  }

  reverseMapExtraFields(src: ExtraField[]) {
    return src.map(val => this.reverseMapExtraField(val));
  }

  reverseMapExtraField(src: ExtraField): any {
    const result: any = {};

    result.data_item_type = src.dataItemType;
    result.date_created = src.dateCreated;
    result.extraFieldOptions = [];
    result.extra_field_settings_id = src.extraFieldSettingsId;
    result.extra_field_type = src.extraFieldType;
    result.value= src.extraFieldValue;
    result.field_name = src.fieldName;
    result.import_id = src.importId;
    result.position = src.position;
    result.site_id = src.siteId;

    return result;
  }
}
