"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoborderGroupService = void 0;
const joborder_group_type_1 = require("../types/joborder-group-type");
class JoborderGroupService {
    constructor() { }
    mapReverseJoborderGroup(raw) {
        const result = {};
        raw.companyId ? result.company_id = raw.companyId : '';
        raw.id ? result.id = raw.id : '';
        raw.title ? result.title = raw.title : '';
        return result;
    }
    mapJoborderGroups(raw) {
        return raw.map(val => this.mapJoborderGroup(val));
    }
    mapJoborderGroup(raw) {
        const result = new joborder_group_type_1.JoborderGroup();
        result.id = raw.id;
        result.title = raw.title;
        result.companyId = raw.company_id;
        return result;
    }
}
exports.JoborderGroupService = JoborderGroupService;
